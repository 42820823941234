import Component from '@glimmer/component';

interface LayoutLoadingSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class LayoutLoading extends Component<LayoutLoadingSignature> {
  <template>
    <div data-test-layout-loading class="layout--loading" ...attributes>
      {{yield}}
    </div>
  </template>
}
