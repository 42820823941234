/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointCheckTFCOrganizationResponseFromJSON(json) {
    return HashicorpCloudWaypointCheckTFCOrganizationResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointCheckTFCOrganizationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isValid': !exists(json, 'is_valid') ? undefined : json['is_valid'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'hasTemplatesAndAddons': !exists(json, 'has_templates_and_addons') ? undefined : json['has_templates_and_addons'],
        'hasActions': !exists(json, 'has_actions') ? undefined : json['has_actions'],
    };
}
export function HashicorpCloudWaypointCheckTFCOrganizationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'is_valid': value.isValid,
        'permissions': value.permissions,
        'has_templates_and_addons': value.hasTemplatesAndAddons,
        'has_actions': value.hasActions,
    };
}
