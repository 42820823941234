/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { concat } from '@ember/helper';
import { t } from 'ember-intl';
import { and, eq, gte, or } from 'ember-truth-helpers';
import { get } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';

import tooltip from '../../../modifiers/tooltip.js';

interface ChartsStackedBarChartSignature {
  Args: {
    segments: {
      value: number;
      color: string | ((segment: { value: number }) => string);
      text?: string | ((segment: { value: number }) => string);
    }[];
    identifier: unknown;
    max: number;
    total: number;
  };
  Element: SVGElement;
}

export default class ChartsStackedBarChart extends Component<ChartsStackedBarChartSignature> {
  @tracked _element: { clientHeight: any; clientWidth: any } | undefined;

  // height and width of the SVG element aka the "chart" area
  @tracked height = 0;
  @tracked width = 0;

  // constants
  spacing = 2;

  //Window resize listeners.
  registerListener = () => {
    window.addEventListener('resize', this.updateChart);
  };

  unregisterListener = () => {
    window.removeEventListener('resize', this.updateChart);
  };

  // Arguments
  /**
   * Segments are the various "bars" in this stacked bar
   * This is the sanitized list passed down from the parent
   * @argument segments
   * @type {[]Segments}
   *
   */

  /**
   * max value of segments that will be displayed in the chart
   * @argument max
   * @type {number}
   */

  /**
   * total value of all the segments
   * @argument total
   * @type {number}
   */

  /**
   * Calculates the radius of the arc on the endcaps based on height of the chart
   * @method StackedBar::Chart#radius
   * @return {number}
   */
  get description() {
    return `A stacked bar chart containing ${this.args.segments.length} segments with a maximum total of ${this.args.max}`;
  }

  /**
   * displayMax is based on the total value the chart can show at once.
   * This lets the chart represent the rough ratio of parts even if the total is greater than the pass in max value
   * @method StackedBar::Chart#displayMax
   * @return {number}
   */
  get displayMax() {
    const { total, max } = this.args;

    if (total > max) return total;

    return max;
  }

  /**
   * Calculates the radius of the arc on the endcaps based on height of the chart
   * @method StackedBar::Chart#radius
   * @return {number}
   */
  get radius() {
    return this.height / 2;
  }
  /**
   * formatted objects specifically for rendering the segments to svgs
   * @method StackedBar::Chart#displaySegments
   * @return {Array}
   */
  get displaySegments() {
    const { displayMax, width } = this;
    const { segments } = this.args;

    if (!width) return [];

    // if we don't have any values passed in just show a little dot
    if (segments.length === 0) return [];

    // width of chart - spacers
    const usableWidth = width - segments.length * this.spacing;

    // number of pixels per "unit" of value
    const pixels = usableWidth / displayMax;

    // starting point from the previous rect
    let x1 = 0;

    // total value so far (could also just be "is end")
    let total = 0;

    return segments.map((s, i) => {
      // handle values over the max
      const value =
        s.value + total > displayMax
          ? displayMax - total // the leftover
          : s.value;

      const segmentWidth = value * pixels;

      // current x coordinate for this index
      const x = x1;

      x1 += segmentWidth + this.spacing;
      total += s.value;

      const isStart = i === 0;
      const isEnd = total >= displayMax;
      let path;

      if (isStart) {
        path = this.computeFirstPath(x, segmentWidth);
      } else if (isEnd)
        path = this.computeLastPath(x, segmentWidth + this.spacing);
      else {
        path = this.computeMiddlePath(x, segmentWidth);
      }

      return {
        x,
        path,
        isStart,
        isEnd,
        color: typeof s.color === 'function' ? s.color(s) : s.color,
        value: s.value,
        text: typeof s.text === 'function' ? s.text(s) : s.text,
      };
    });
  }

  // helper methods
  //  We need to use paths for the endcaps because svg rect's can't have ONLY one side rounded
  computeFirstPath = (start: number, width: number) => {
    const { radius, height } = this;

    // /*
    //   (M) Move to upper left at top of arc (0 + 6)
    //   (A) Draw an arc from upper left to lower left with radius "radius", then pick back up from lower left (0 + r)
    //   (L) Draw Line To the lower right from start of arc
    //   (L) Draw Line To upper right
    //   (and it fills itself in)
    // */
    return `M ${radius}, ${start} A ${radius} ${radius} 180 0 0 ${radius},${height} L ${width} ${height} L ${width} ${start}`;
  };

  computeMiddlePath = (start: number, width: number) => {
    const { height } = this;

    // /*
    //   (M) Move to upper left X, 0
    //   (L) Draw Line To the upper right
    //   (L) Draw Line To the bottom right
    //   (L) Draw Line To the bottom left
    //   (and it fills itself in)
    // */
    return `M ${start}, 0 L ${start + width} 0 L ${
      start + width
    } ${height} L ${start} ${height}`;
  };

  computeLastPath = (start: number, width: number) => {
    const { radius, height } = this;
    width = width - radius;

    // /*
    //   (M) Move to upper left X, 0
    //   (L) Draw Line To the upper right start of th arc
    //   (A) Draw an arc from upper right to lower right with radius "radius", then pick back up from lower right
    //   (L) Draw Line To bottom left
    //   (and it fills itself in)
    // */
    return `M ${start}, 0 L ${start + width} 0 A ${radius} ${radius} 180 0 1 ${
      start + width
    }, ${height} L ${start} ${height}`;
  };

  // actions
  registerElement = (element: any) => {
    this._element = element;
    this.updateChart();
  };

  updateChart = () => {
    if (!this._element) return;

    const { clientHeight, clientWidth } = this._element;

    this.height = clientHeight;
    this.width = clientWidth;
  };

  <template>
    <div
      {{didInsert this.registerListener}}
      {{willDestroy this.unregisterListener}}
    >
      <svg
        aria-labelledby={{concat @identifier "-chart-title"}}
        aria-describedby={{concat @identifier "-chart-desc"}}
        class="center-xs stacked-bar__chart"
        data-test-chart-area
        height="8"
        ...attributes
        {{didInsert this.registerElement}}
      >
        <title id={{concat @identifier "-chart-title"}}>
          {{t "components.charts.stacked-bar.accessibility.chart-title"}}
        </title>
        <desc id={{concat @identifier "-chart-desc"}}>
          {{t
            "components.charts.stacked-bar.accessibility.chart-desc"
            size=@segments.length
            total=@total
          }}
        </desc>

        <rect
          aria-hidden="true"
          class="stacked-bar__background"
          data-test-chart-background
          x="0"
          width={{this.width}}
          height={{this.height}}
          rx={{this.radius}}
        ></rect>

        <g data-test-filled={{@total}}>
          {{#if (eq this.displaySegments.length 0)}}
            <rect
              data-test-segment-empty
              class="stacked-bar__empty"
              rx={{this.radius}}
              width={{this.height}}
              height={{this.height}}
            ></rect>
          {{else if
            (and
              (eq this.displaySegments.length 1) (gte @total this.displayMax)
            )
          }}
            {{#let (get this.displaySegments 0) as |segment|}}
              <rect
                aria-label={{t
                  "components.stacked-bar.accessibility.segment-info"
                  index=0
                  text=segment.text
                }}
                class="stacked-bar__filled"
                data-test-display-segment
                data-test-segment-start
                data-test-segment-end
                data-test-segment-value={{segment.value}}
                data-test-segment-index={{0}}
                rx={{this.radius}}
                x={{0}}
                width={{this.width}}
                height={{this.height}}
                fill={{segment.color}}
                {{! @glint-expect-error }}
                {{tooltip (or segment.text segment.value)}}
              ></rect>
            {{/let}}
          {{else}}
            {{#each this.displaySegments as |segment index|}}
              <path
                aria-label={{t
                  "components.stacked-bar.accessibility.segment-info"
                  index=index
                  text=segment.text
                }}
                class="stacked-bar__filled"
                data-test-display-segment
                data-test-segment-start={{segment.isStart}}
                data-test-segment-end={{segment.isEnd}}
                data-test-segment-value={{segment.value}}
                data-test-segment-index={{index}}
                d={{segment.path}}
                fill={{segment.color}}
                {{! @glint-expect-error }}
                {{tooltip (or segment.text segment.value)}}
              ></path>
            {{/each}}
          {{/if}}
        </g>
      </svg>
    </div>
  </template>
}
