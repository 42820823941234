import Component from '@glimmer/component';
import { service } from '@ember/service';
import { typeOf } from '@ember/utils';
import { and } from 'ember-truth-helpers';
import { formatDate } from 'ember-intl';
import { HdsTooltipButton } from '@hashicorp/design-system-components/components';

import TimeInner from './time/inner.gts';

import type TimeService from '../services/time';

interface TimeSignature {
  Args: {
    date: Date | string | undefined;
    display: string;
    hasTooltip?: boolean;
  };
  Element: HTMLElement;
}

const dateIsValid = (date?: Date | string): date is Date =>
  date instanceof Date && !isNaN(+date);

/**
 *
 * `Time` renders a semantic time element based on a JS Date and the
 * Structure Design System patterns for date formatting.
 *
 *
 * @example
 * // returns 'Sep 5, 2018 (30 minutes ago)'
 * <Time @date={{this.date}} @display='friendly-relative' />
 * @example
 * // returns 'Sep 5, 2018, 4:07:32 pm'
 * <Time @date={{this.date}} @display='friendly-local' />
 * @example
 * // returns 'Sep 5, 2018'
 * <Time @date={{this.date}} @display='friendly-only' />
 * @example
 * // returns 'about 2 hours ago'
 * <Time @date={{this.date}} @display='relative' />
 * @example
 * // returns '2018-09-05T23:15:17345Z'
 * <Time @date={{this.date}} @display='utc' />
 *
 *
 * @class Time
 */

/**
 * @argument date
 * @type {Date}
 * @description A JavaScript native Date to display.
 */

/**
 * @argument display
 * @type {string}
 * @enum {('friendly-relative'|'friendly-local'|'friendly-only'|'relative'|'utc')}
 * @description The selected pattern to display the date. This is optional and
 *    forces the date to forced into a pattern. If left blank, an algorithm
 *    will display the best option.
 * @see {@link https://docs.google.com/document/d/1I91wdyCa8iO91TlVmKd2n1NI1aK7bRm5YM2BF8FaQfY/edit|Structure date and time}
 */

/**
 * @argument hasTooltip
 * @type {boolean}
 * @description Controls whether or not the component includes tooltip with the
 *    full date. Under normal circumstances, the time component should show a
 *    tooltip, so please only use this option in exceptional circumstances, such as
 *    when the time component is used inside another interactive element such as a
 *    button or form control.
 * @default true
 */

export default class Time extends Component<TimeSignature> {
  @service declare readonly time: TimeService;

  get date() {
    const { date } = this.args;

    // Sometimes an ISO date string might be passed in instead of a JS Date.
    if (typeOf(date) === 'string') {
      return new Date(date as string);
    }

    return date;
  }

  get isValid() {
    return dateIsValid(this.date);
  }

  get hasTooltip() {
    return this.args.hasTooltip ?? true;
  }

  register = () => {
    const date = this.date;

    if (dateIsValid(date)) {
      this.time.register(date);
    }
  };

  unregister = () => {
    const date = this.date;

    this.time.unregister(date);
  };

  get isoUtcString() {
    const date = this.date;

    if (dateIsValid(date)) return this.time.toIsoUtcString(date);
    return '';
  }

  get display() {
    const date = this.date;
    const { display } = this.args;

    if (dateIsValid(date)) {
      const nextDiff = this.time.getTimeDifference(this.time.now, date);
      return this.time.format(nextDiff, display);
    }
    return '';
  }

  <template>
    {{! @glint-nocheck }}
    {{#if (and this.hasTooltip this.isValid)}}
      <HdsTooltipButton
        @text={{if
          this.display.options.tooltipFormat
          (formatDate
            this.date
            month=this.display.options.tooltipFormat.month
            day=this.display.options.tooltipFormat.day
            year=this.display.options.tooltipFormat.year
            hour=this.display.options.tooltipFormat.hour
            minute=this.display.options.tooltipFormat.minute
            second=this.display.options.tooltipFormat.second
          )
          this.isoUtcString
        }}
        @placement="bottom"
      >
        <TimeInner
          @date={{this.date}}
          @isoUtcString={{this.isoUtcString}}
          @display={{this.display}}
          @register={{this.register}}
          @unregister={{this.unregister}}
          @isValid={{this.isValid}}
          ...attributes
        />
      </HdsTooltipButton>
    {{else}}
      <TimeInner
        @date={{this.date}}
        @isoUtcString={{this.isoUtcString}}
        @display={{this.display}}
        @register={{this.register}}
        @unregister={{this.unregister}}
        @isValid={{this.isValid}}
        ...attributes
      />
    {{/if}}
  </template>
}
