import Component from '@glimmer/component';
import { HdsSideNavPortal } from '@hashicorp/design-system-components/components';

import type { HdsSideNavPortalSignature } from '@hashicorp/design-system-components/components/hds/side-nav/portal/index';

interface LayoutSidebarSubnavSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: HdsSideNavPortalSignature['Blocks'];
  Element: HTMLElement;
}

export default class LayoutSidebarSubnav extends Component<LayoutSidebarSubnavSignature> {
  <template>
    {{! @glint-expect-error: missing types }}
    <HdsSideNavPortal
      @ariaLabel="Secondary"
      ...attributes
      data-test-sidebar-subnav-content
      as |Subnav|
    >
      {{yield Subnav}}
    </HdsSideNavPortal>
  </template>
}
