import Component from '@glimmer/component';
import { LinkTo } from '@ember/routing';
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import {
  HdsBadge,
  HdsIcon,
} from '@hashicorp/design-system-components/components';

import Flex from './flex.gts';
import DetailCard from './detail/card.gts';
import Typography from './typography.gts';
import optionForSourceState from '../helpers/option-for-source-state.ts';
import boxPadding from '../modifiers/box-padding.ts';
import { FlexDirection, FlexGap } from '../utils/flex.ts';
import LinkToExternal from './link-to-external.js';

interface NetworkCardSignature {
  Args: {
    network: {
      id: string;
      location: {
        organizationId: string;
        projectId: string;
        region: {
          provider: string;
          region: string;
        };
      };
      state: string;
      cidrBlock: string;
    };
    route: string;
    useLinkToExternal: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `NetworkCard` renders a card with info related to a resource's HVN and then links to the HVN detail page.
 *
 * ## Example usage
 *
 * ```
 * <NetworkCard
 *   @clusterProductName='Vault'
 *   @network={{@model.network}}
 *   @route='some.route'
 *   @useLinkToExternal={{true}}
 * />
 * ```
 *
 * @class NetworkCard
 *
 */
export default class NetworkCard extends Component<NetworkCardSignature> {
  /**
   *  The name of the HashiCorp product for the cluster.
   * @argument clusterProductName
   * @type {string}
   */
  /**
   * The full model representation of a HashiCorp Virtual Network
   * @argument network
   * @type {object}
   */
  /**
   *  The name of the route to use to construct the LinkTo or LinkToExternal.
   * @argument route
   * @type {string}
   */
  /**
   *  The name of the peering list route to use to construct the LinkTo or LinkToExternal.
   * @argument peeringRoute
   * @type {string}
   *
   */
  /**
   *  If the component should show a warning about not having peerings.
   * @argument showPeeringWarning
   * @type {boolean}
   *
   */
  /**
   *  If true, the component will render a link using ember-engines' LinkToExternal compoent instead of the default LinkTo.
   * @argument useLinkToExternal
   * @type {boolean}
   */

  <template>
    <div ...attributes data-test-network-card {{boxPadding "lg 0 0 0"}}>
      <DetailCard as |DC|>
        <DC.Header>
          <Flex @direction={{FlexDirection.Row}} @gap={{FlexGap.Xs}}>
            {{#let
              (optionForSourceState "region" @network.location.region.provider)
              as |option|
            }}
              {{! @glint-expect-error }}
              <HdsIcon @name={{option.icon}} @size="24" />
            {{/let}}
            <div>
              <Typography @variant="h3">
                {{#if @useLinkToExternal}}
                  <LinkToExternal
                    @route={{@route}}
                    @models={{array
                      @network.location.organizationId
                      @network.location.projectId
                      @network.id
                    }}
                  >
                    {{@network.id}}
                  </LinkToExternal>
                {{else}}
                  <LinkTo
                    @route={{@route}}
                    @models={{array
                      @network.location.organizationId
                      @network.location.projectId
                      @network.id
                    }}
                  >
                    {{@network.id}}
                  </LinkTo>
                {{/if}}
              </Typography>

              <Typography @component="p">
                {{@network.location.region.region}}
              </Typography>
            </div>
          </Flex>
        </DC.Header>

        <DC.Content>
          <Typography @component="p">
            {{#let (optionForSourceState "hvn" @network.state) as |option|}}
              <HdsBadge
                @text={{t option.text}}
                {{! @glint-expect-error }}
                @color={{option.color}}
                {{! @glint-expect-error }}
                @icon={{option.icon}}
                @isIconOnly={{option.isIconOnly}}
                {{! @glint-expect-error }}
                @size={{option.size}}
                {{! @glint-expect-error }}
                @type={{option.type}}
              />
            {{/let}}
          </Typography>

          <Typography @component="p">
            {{@network.cidrBlock}}
          </Typography>
        </DC.Content>
      </DetailCard>
    </div>
  </template>
}
