/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointActionConfigFromJSON, HashicorpCloudWaypointActionConfigToJSON, HashicorpCloudWaypointActionConfigRequestFromJSON, HashicorpCloudWaypointActionConfigRequestToJSON, HashicorpCloudWaypointActionRunResponseStatusFromJSON, HashicorpCloudWaypointActionRunResponseStatusToJSON, HashicorpCloudWaypointActionRunScopeFromJSON, HashicorpCloudWaypointActionRunScopeToJSON, HashicorpCloudWaypointActionRunVariableFromJSON, HashicorpCloudWaypointActionRunVariableToJSON, HashicorpCloudWaypointJobFromJSON, HashicorpCloudWaypointJobToJSON, HashicorpCloudWaypointRefNamespaceFromJSON, HashicorpCloudWaypointRefNamespaceToJSON, HashicorpCloudWaypointStatusLogFromJSON, HashicorpCloudWaypointStatusLogToJSON, } from './';
export function HashicorpCloudWaypointActionRunFromJSON(json) {
    return HashicorpCloudWaypointActionRunFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionRunFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointRefNamespaceFromJSON(json['namespace']),
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'actionConfig': !exists(json, 'action_config') ? undefined : HashicorpCloudWaypointActionConfigFromJSON(json['action_config']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'completedAt': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'runBy': !exists(json, 'run_by') ? undefined : json['run_by'],
        'responseStatus': !exists(json, 'response_status') ? undefined : HashicorpCloudWaypointActionRunResponseStatusFromJSON(json['response_status']),
        'statusLog': !exists(json, 'status_log') ? undefined : (json['status_log'].map(HashicorpCloudWaypointStatusLogFromJSON)),
        'request': !exists(json, 'request') ? undefined : HashicorpCloudWaypointActionConfigRequestFromJSON(json['request']),
        'scope': !exists(json, 'scope') ? undefined : HashicorpCloudWaypointActionRunScopeFromJSON(json['scope']),
        'backgroundJob': !exists(json, 'background_job') ? undefined : HashicorpCloudWaypointJobFromJSON(json['background_job']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'actionConfigRef': !exists(json, 'action_config_ref') ? undefined : HashicorpCloudWaypointActionCfgRefFromJSON(json['action_config_ref']),
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointActionRunVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointActionRunToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointRefNamespaceToJSON(value.namespace),
        'sequence': value.sequence,
        'action_config': HashicorpCloudWaypointActionConfigToJSON(value.actionConfig),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'completed_at': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'run_by': value.runBy,
        'response_status': HashicorpCloudWaypointActionRunResponseStatusToJSON(value.responseStatus),
        'status_log': value.statusLog === undefined ? undefined : (value.statusLog.map(HashicorpCloudWaypointStatusLogToJSON)),
        'request': HashicorpCloudWaypointActionConfigRequestToJSON(value.request),
        'scope': HashicorpCloudWaypointActionRunScopeToJSON(value.scope),
        'background_job': HashicorpCloudWaypointJobToJSON(value.backgroundJob),
        'id': value.id,
        'action_config_ref': HashicorpCloudWaypointActionCfgRefToJSON(value.actionConfigRef),
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointActionRunVariableToJSON)),
    };
}
