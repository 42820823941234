/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - CHARGE_UNKNOWN: CHARGE_UNKNOWN invalid value
 *  - CHARGE_USAGE: CHARGE_USAGE is the regular usage based charge
 *  - CHARGE_EXPIRED_BALANCE: CHARGE_EXPIRED_BALANCE is the charge generated for a balance that has been
 * expired (forfeited) when a Flex renewal has happened
 *  - CHARGE_CREDIT_APPLIED: CHARGE_CREDIT_APPLIED is a negative charge for an applied credit
 *  - CHARGE_BALANCE_TRANSFER: CHARGE_BALANCE_TRANSFER is for a balance transfer charges, for example transfer
 * of a balance from one consumption pool to another during a rollover
 *  - CHARGE_LEFTOVER_BALANCE: CHARGE_LEFTOVER_BALANCE is the charge generated for a balance that has been
 * leftover on the account when customer decides not to renew/churn.
 *  - TRIAL_TERMINATION_CREDIT: TRIAL_TERMINATION_CREDIT is an additional negative charge for an applied credit.
 * Additional charges are applied if a PAYG billing account has just finished its trial, has no other payment methods set up,
 * and has a statement with a balance still owed after the trial balance has been applied.
 *  - FLEX_NEGATIVE_BALANCE_REPAYMENT: FLEX_NEGATIVE_BALANCE_REPAYMENT is for a negative balance transfer charge. This will get charged against a renewal
 * consumption pool if there is a balance owed on the current consumption pool at the time of rollover.
 *  - FLEX_BALANCE_ROLLOVER: FLEX_BALANCE_ROLLOVER is for a positive balance transfer to a renewal consumption pool. This can happen
 * if there is still a positive balance on the current consumption pool at the time of rollover.
 *  - ONE_TIME_CHARGE: ONE_TIME_CHARGE is a one-time charge.
 * @export
 * @enum {string}
 */
export var Billing20201105ChargeType;
(function (Billing20201105ChargeType) {
    Billing20201105ChargeType["CHARGEUNKNOWN"] = "CHARGE_UNKNOWN";
    Billing20201105ChargeType["CHARGEUSAGE"] = "CHARGE_USAGE";
    Billing20201105ChargeType["CHARGEEXPIREDBALANCE"] = "CHARGE_EXPIRED_BALANCE";
    Billing20201105ChargeType["CHARGECREDITAPPLIED"] = "CHARGE_CREDIT_APPLIED";
    Billing20201105ChargeType["CHARGEBALANCETRANSFER"] = "CHARGE_BALANCE_TRANSFER";
    Billing20201105ChargeType["CHARGELEFTOVERBALANCE"] = "CHARGE_LEFTOVER_BALANCE";
    Billing20201105ChargeType["TRIALTERMINATIONCREDIT"] = "TRIAL_TERMINATION_CREDIT";
    Billing20201105ChargeType["FLEXNEGATIVEBALANCEREPAYMENT"] = "FLEX_NEGATIVE_BALANCE_REPAYMENT";
    Billing20201105ChargeType["FLEXBALANCEROLLOVER"] = "FLEX_BALANCE_ROLLOVER";
    Billing20201105ChargeType["ONETIMECHARGE"] = "ONE_TIME_CHARGE";
})(Billing20201105ChargeType || (Billing20201105ChargeType = {}));
export function Billing20201105ChargeTypeFromJSON(json) {
    return Billing20201105ChargeTypeFromJSONTyped(json, false);
}
export function Billing20201105ChargeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105ChargeTypeToJSON(value) {
    return value;
}
