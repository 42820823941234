import Component from '@glimmer/component';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
import { eq, gt } from 'ember-truth-helpers';
import { optional, dec } from '@nullvoxpopuli/ember-composable-helpers';
import {
  HdsButton,
  HdsButtonSet,
} from '@hashicorp/design-system-components/components';

import StepperFormTab from './stepper-form/tab.gts';

interface StepperFormSignature {
  Args: {
    onPrevious: () => void;
    onTabChange: () => void;
    step: number;
    steps: number;
  };
  Blocks: {
    actions: [unknown];
    body: [unknown];
    tabs: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 * `StepperForm` component is used to structure a sequential (or quasi-sequential)
 * form broken down into phases.
 *
 * Each step can be completed independently and marked as so. Additionally each
 * step may be "jumped to" at the discretion of the implementer of the form.
 *
 * @class StepperForm
 */
export default class StepperForm extends Component<StepperFormSignature> {
  /**
   * `@step` The step the stepper is currently on.
   *
   * @argument @step
   * @type {Number}
   */
  /**
   * `@steps` The number of steps the stepper has.
   *
   * @argument @steps
   * @type {Number}
   */
  /**
   * `@onTabChange` Called with the selected step number when a tab is clicked
   *
   * @argument @onTabChange
   * @type {Function}
   * @default noop
   */
  /**
   * `@onPrevious` Called with the current step number when the previous button
   * is clicked
   *
   * @argument @onPrevious
   * @type {Function}
   * @default noop
   */

  // Make sure step is numeric (could be provided like @step=5)
  get step() {
    return this.args.step ?? parseInt(this.args.step, 10);
  }

  get steps() {
    return this.args.steps ?? parseInt(this.args.steps, 10);
  }

  <template>
    <div class="stepper-form" data-test-stepper-form ...attributes>
      <div class="stepper-form__nav" data-test-tab-nav>
        <ol role="tablist">
          {{yield
            (hash
              Tab=(component
                StepperFormTab currentStep=this.step onTabChange=@onTabChange
              )
            )
            to="tabs"
          }}
        </ol>
      </div>

      <div class="stepper-form__body" data-test-stepper-form-body>
        {{yield this.step to="body"}}
      </div>

      {{#if (has-block "actions")}}
        <div class="stepper-form__actions" data-test-stepper-form-actions>
          <HdsButtonSet>
            {{#if (gt this.step 0)}}
              <HdsButton
                data-test-stepper-form-previous
                @color="tertiary"
                @text={{t "components.stepper-form.actions.previous"}}
                @icon="chevron-left"
                {{on "click" (fn (optional @onPrevious) this.step)}}
              />
            {{/if}}

            {{yield (eq this.step (dec this.steps)) to="actions"}}
          </HdsButtonSet>
        </div>
      {{/if}}
    </div>
  </template>
}
