import Helper from '@ember/component/helper';
import { DateTime } from 'luxon';

/**
 * A date range data model.
 * @typedef {Object} DateRange
 * @property {String} datetime - The datetime for the html time element.
 * @property {String} display - The human-friendly date range.
 */

export default class FormatDateRange extends Helper {
  /**
   * Formats two dates to display a date range. If the date is the same month
   * and the same year then the date range will condense down.
   * @param {array} args - 0th element of args must be a non-empty string.
   * @param {string} args[0] - The start date of the range.  An ISO date string or JS Date object is accepted.
   * @param {string} args[1] - The end date of the range. An ISO date string or JS Date object is accepted.
   * @param {Object} additionalArgs - More configuration for the helper.
   * @param {string} additionalArgs.condensed - A flag to condense the date range if possible.
   * @param {string} additionalArgs.formatOpts - The Intl.DateTimeFormat formatOpts.
   * @returns {DateRange} - The date range model.
   */
  compute(
    [startDate, endDate],
    { condensed = false, formatOpts = DateTime.DATE_MED }
  ) {
    let startISOString = startDate.toISOString
      ? startDate.toISOString()
      : startDate;
    let endISOString = endDate.toISOString ? endDate.toISOString() : endDate;

    let startDateTime = DateTime.fromISO(startISOString);
    let endDateTime = DateTime.fromISO(endISOString);

    let utcStartDateTime = startDateTime.toUTC();
    let utcEndDateTime = endDateTime.toUTC();

    let localFormattedStart = startDateTime.toLocaleString(formatOpts);
    let localFormattedEnd = endDateTime.toLocaleString(formatOpts);

    let utcFormattedStart = utcStartDateTime.toFormat('LLL d, yyyy');
    let utcFormattedEnd = utcEndDateTime.toFormat('LLL d, yyyy');
    let utcFormattedSameDay = utcStartDateTime.toFormat('LLL d, yyyy');

    if (condensed) {
      // for local times
      if (startDateTime.weekYear === endDateTime.weekYear) {
        localFormattedStart = startDateTime.toLocaleString({
          month: 'short',
          day: 'numeric',
        });
        localFormattedEnd = endDateTime.toLocaleString({
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

        if (startDateTime.month === endDateTime.month) {
          localFormattedEnd = localFormattedEnd
            .replace(`${endDateTime.toLocaleString({ month: 'short' })}`, '')
            .trim();
        }
      }

      // for UTC comparison
      if (utcStartDateTime.year === utcEndDateTime.year) {
        utcFormattedStart = utcStartDateTime.toFormat('LLL d');
        utcFormattedEnd = utcEndDateTime.toFormat('LLL d, yyyy');

        if (utcStartDateTime.month === utcEndDateTime.month) {
          utcFormattedEnd = utcFormattedEnd
            .replace(`${utcEndDateTime.toFormat('LLL')}`, '')
            .trim();
        }
      }
    }

    return {
      utcIsoStart: `${DateTime.fromISO(startISOString, {
        zone: 'utc',
      }).toISO()}`,
      utcIsoEnd: `${DateTime.fromISO(endISOString, { zone: 'utc' }).toISO()}`,
      utcFormattedStart,
      utcFormattedEnd,
      utcFormattedSameDay,
      localFormattedStart,
      localFormattedEnd,
    };
  }
}
