import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { typeOf } from '@ember/utils';
import { and, or } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import { t } from 'ember-intl';
import {
  HdsCardContainer,
  HdsLinkInline,
  HdsIcon,
} from '@hashicorp/design-system-components/components';
// @ts-expect-error: missing types
import CopyButton from 'ember-cli-clipboard/components/copy-button';

import type { IntlService } from 'ember-intl';

import {
  COPY_SUCCESS_ICON,
  COPY_ACTION_ICON,
} from '../utils/consts/copy-card.js';
import boxPadding from '../modifiers/box-padding.ts';
import ActionCard from './action-card.gts';
import Typography from './typography.gts';
import classNames from '../helpers/class-names.ts';
import dataPrivacy from '../modifiers/data-privacy.js';

interface CopyCardSignature {
  Args: {
    buttonDescription?: string;
    clipboardHref?: string;
    clipboardText?: string;
    container?: string;
    copyText?: string;
    isMultiline?: boolean;
    onSuccess?: () => void;
    showCopied?: boolean;
    title?: string;
  };
  Element: HTMLElement;
}

/**
 * The title/label for the text to copy.
 * @argument title
 * @type {?string}
 */

/**
 * The content to be copied. If `@clipboardHref` is also provided,
 * `@clipboardHref` takes precedence.
 * @argument clipboardText
 * @type {?string}
 */

/**
 * The content to be copied. If  `@clipboardText` and `@clipboardHref` are
 * specified , `@clipboardHref` takes precedence.
 * @argument clipboardHref
 * @type {?string}
 */

/**
 * Whether to display the post copy text or not.
 * @argument showCopied
 * @type {?boolean}
 */

/**
 * Whether or not the text is a multiline string. If so, the content will be
 * wrapped in a <pre> element instead of <code>.
 * @argument isMultiline
 * @type {?boolean}
 */

/**
 * Function to be executed upon successful copy
 * @argument onSuccess
 * @type {?function}
 */

/**
 * If `<CopyCard />` is in a modal, specify the container class/selector.
 * @argument container
 * @type {?string}
 */

/**
 * Override default description of the copy button for accessibility.
 * @argument buttonDescription
 * @type {?string}
 */

/**
 *
 * `CopyCard` is a box with text, a label, and a CopyButton.
 *
 * ```
 * <CopyCard
 *   @title={{'Admin Token'}}
 *   @clipboardText={{this.adminToken}}
 *   @showCopied={{true|false}}
 *   @onSuccess={{this.onSuccess}}
 * />
 * ```
 *
 * @class CopyCard
 *
 */
export default class CopyCard extends Component<CopyCardSignature> {
  @service declare readonly intl: IntlService;

  @tracked copyText: string = '';

  get copied() {
    const { copyText } = this.args;
    return copyText ?? this.copyText;
  }

  get icon() {
    const copied = this.copied;
    return copied ? COPY_SUCCESS_ICON : COPY_ACTION_ICON;
  }

  get isMultiline() {
    return this.args.isMultiline ?? false;
  }

  get showCopied() {
    return this.args.showCopied ?? true;
  }

  onCopySuccess = task(async () => {
    const { onSuccess } = this.args;

    this.copyText = this.intl.t('components.copy-card.copied');

    if (onSuccess && typeOf(this.args.onSuccess) === 'function') {
      this.args.onSuccess?.();
    }

    await timeout(1000);

    this.copyText = '';
  });

  <template>
    <HdsCardContainer
      {{! @glint-expect-error }}
      @background="neutral-primary"
      @hasBorder={{true}}
      {{! @glint-expect-error }}
      @level="mid"
      ...attributes
      {{boxPadding "md"}}
    >
      <ActionCard>
        <:header>
          {{#if @title}}
            <Typography
              @variant="h4"
              class="hds-typography-display-200 hds-font-weight-medium"
              data-test-copy-card-title
            >
              {{@title}}
            </Typography>
          {{/if}}
        </:header>
        <:description>
          {{#if @clipboardHref}}
            <HdsLinkInline
              @href={{@clipboardHref}}
              @isHrefExternal={{true}}
              id="hyperlink"
              data-test-copy-card-hyperlink-text
            >
              <samp>
                {{@clipboardHref}}
              </samp>
            </HdsLinkInline>
          {{else}}
            <div
              class={{classNames
                "hds-typography-code-100"
                "hds-foreground-primary"
                (if this.isMultiline "multiline-content")
              }}
            >
              <code
                data-test-copy-card-clipboard-text
                {{! @glint-expect-error: is not typed yet}}
                {{dataPrivacy "redact"}}
              >
                {{~@clipboardText~}}
              </code>
            </div>
          {{/if}}

          {{#if (and this.showCopied this.copied)}}
            <span
              class="hds-typography-display-100 hds-font-weight-regular hds-foreground-faint"
              data-test-copy-card-confirmation
              {{boxPadding "0 0 0 2xs"}}
            >
              {{this.copied}}
            </span>
          {{/if}}
        </:description>
        <:button>
          {{#if @clipboardHref}}
            <CopyButton
              @target="#hyperlink"
              @onSuccess={{this.onCopySuccess.perform}}
              aria-label={{or
                @buttonDescription
                (t "components.copy-card.button-description")
              }}
              class="hds-button hds-button--size-medium hds-button--color-secondary"
              data-test-copy-button
              {{! @glint-expect-error: is not typed yet}}
              {{dataPrivacy "redact"}}
            >
              <HdsIcon @name={{this.icon}} aria-hidden="true" />
            </CopyButton>
          {{else}}
            {{#if @container}}
              <CopyButton
                {{! this is a hack to typecast to a string in cases when @text is a number}}
                @text={{concat @clipboardText}}
                @container={{@container}}
                @onSuccess={{this.onCopySuccess.perform}}
                aria-label={{or
                  @buttonDescription
                  (t "components.copy-card.button-description")
                }}
                class="hds-button hds-button--size-medium hds-button--color-secondary"
                data-test-copy-button
                {{! @glint-expect-error: is not typed yet}}
                {{dataPrivacy "redact"}}
              >
                <HdsIcon @name={{this.icon}} aria-hidden="true" />
              </CopyButton>
            {{else}}
              <CopyButton
                @text={{@clipboardText}}
                @onSuccess={{this.onCopySuccess.perform}}
                aria-label={{or
                  @buttonDescription
                  (t "components.copy-card.button-description")
                }}
                class="hds-button hds-button--size-medium hds-button--color-secondary"
                data-test-copy-button
                {{! @glint-expect-error: is not typed yet}}
                {{dataPrivacy "redact"}}
              >
                <HdsIcon @name={{this.icon}} aria-hidden="true" />
              </CopyButton>
            {{/if}}
          {{/if}}
        </:button>
      </ActionCard>
    </HdsCardContainer>
  </template>
}
