import Component from '@glimmer/component';

export interface ZeroStateActionSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `ZeroStateAction` displays the message element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Action>Action</ZeroState::Action>
 * ```
 *
 * @class ZeroStateAction
 *
 */

export default class ZeroStateAction extends Component<ZeroStateActionSignature> {
  <template>
    <div
      class="zero-state__action"
      data-test-zero-state-action-container
      ...attributes
    >
      {{yield}}
    </div>
  </template>
}
