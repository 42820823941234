import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { eq } from 'ember-truth-helpers';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import {
  FLIGHT_ICON_HEXAGON,
  FLIGHT_ICON_HEXAGON_FILL,
  FLIGHT_ICON_CHECK_HEXAGON,
  FLIGHT_ICON_CHECK_HEXAGON_FILL,
  FLIGHT_ICON_X_HEXAGON,
  FLIGHT_ICON_X_HEXAGON_FILL,
  FLIGHT_ICON_LOADING,
  FLIGHT_ICON_SIZE_16,
  FLIGHT_ICON_SIZE_24,
} from '../utils/consts/hexagon-step.js';
import classNames from '../helpers/class-names.ts';

import type Owner from '@ember/owner';
import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

const ALLOWED_ICONS = [
  FLIGHT_ICON_HEXAGON,
  FLIGHT_ICON_HEXAGON_FILL,
  FLIGHT_ICON_CHECK_HEXAGON,
  FLIGHT_ICON_CHECK_HEXAGON_FILL,
  FLIGHT_ICON_X_HEXAGON,
  FLIGHT_ICON_X_HEXAGON_FILL,
  FLIGHT_ICON_LOADING,
] as const;

const ALLOWED_SIZES = [
  FLIGHT_ICON_SIZE_16,
  FLIGHT_ICON_SIZE_24,
  `${FLIGHT_ICON_SIZE_16}`,
  `${FLIGHT_ICON_SIZE_24}`,
];

interface HexagonStepSignature {
  Args: {
    icon: HdsIconSignature['Args']['name'];
    size: HdsIconSignature['Args']['size'];
    text: string;
  };
  Element: HTMLSpanElement;
}

/**
 *
 * `HexagonStep` renders an empty HdsIcon hexagon with the ability to
 *     position a number inside of the container and centered. If the icon is
 *     an empty hexagon, outlined or filled, then the `@text` argument's value
 *     will be rendered on top via absolute positining.
 *
 *
 * ```
 * <HexagonStep
 *   @icon='hexagon-fill'
 *   @text={{1}}
 * />
 * ```
 *
 * @class HexagonStep
 *
 */
export default class HexagonStep extends Component<HexagonStepSignature> {
  constructor(owner: Owner, args: HexagonStepSignature['Args']) {
    super(owner, args);

    // @ts-expect-error: this is fine
    if (this.args.icon && !ALLOWED_ICONS.includes(this.args.icon)) {
      assert(
        `The @icon "${
          this.args.icon
        }" provided should be a hexagon HdsIcon (${ALLOWED_ICONS.join(
          ', '
        )}). Please verify it exists on https://flight-hashicorp.vercel.app/`
      );
    }

    if (this.args.size && !ALLOWED_SIZES.includes(this.args.size)) {
      assert(
        `The @size "${
          this.args.size
        }" provided should be an allowed HdsIcon size (${ALLOWED_SIZES.join(
          ', '
        )}). Please verify it exists on https://flight-hashicorp.vercel.app/`
      );
    }
  }

  FLIGHT_ICON_SIZE_24 = FLIGHT_ICON_SIZE_24;

  /**
   * Gets the icon's name
   *
   * @param icon {string}
   * @returns the value of `icon` if set
   * @default `hexagon`
   */
  get icon() {
    const { icon = FLIGHT_ICON_HEXAGON } = this.args;
    return icon;
  }

  /**
   * Determines if this icon is filled with a black background
   *
   * @param icon {string}
   * @returns if the icon is a filled hexagon
   */
  get isFilled() {
    const icon = this.icon;
    return icon.includes('fill');
  }

  /**
   * Gets if text should be rendered
   *
   * @param icon {string}
   * @returns if the text should be visible based on the icon
   */
  get showText() {
    const icon = this.icon;

    switch (icon) {
      case FLIGHT_ICON_HEXAGON:
      case FLIGHT_ICON_HEXAGON_FILL:
        return true;
      default:
        return false;
    }
  }

  /**
   * Gets the icon's size
   *
   * @param size
   * @returns the value of `size` if set
   * @default `24`
   */
  get size() {
    const { size = FLIGHT_ICON_SIZE_24 } = this.args;
    return size;
  }

  <template>
    <span class="hexagon-step" ...attributes>
      <HdsIcon
        @name={{this.icon}}
        @size={{this.size}}
        @isInline={{true}}
        class="hexagon-step__icon"
      />
      {{#if this.showText}}
        <span
          class={{classNames
            (if
              (eq this.size this.FLIGHT_ICON_SIZE_24)
              "hds-typography-body-200"
              "hds-typography-body-100"
            )
            "hds-foreground-strong"
            "hds-font-weight-semibold"
            (if
              this.isFilled
              "hds-foreground-high-contrast"
              "hds-foreground-strong"
            )
            "hexagon-step__text"
          }}
        >
          {{@text}}
        </span>
      {{/if}}
    </span>
  </template>
}
