import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { HdsLinkStandalone } from '@hashicorp/design-system-components/components';

import type { IntlService } from 'ember-intl';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';

import Typography from './typography.gts';
import CopyCard from './copy-card.gts';

interface ImportToTerraformSignature {
  Args: {
    command: string;
  };
}

/**
 * Allows Terraform import commands to be copied to user's clipboard. When used
 * within the dropdown, must be used in conjunction with the CSS class
 * `.withImportToTerraform` placed on or the `Menu::Content`.
 * ```
 * <ImportToTerraform @command="terraform import..." />
 * ```
 * @class ImportToTerraform
 *
 * Terraform import command
 * @argument command
 * @type {string}
 */
export default class ImportToTerraform extends Component<ImportToTerraformSignature> {
  @service declare readonly intl: IntlService;
  @service declare readonly flashMessages: FlashMessageService;

  onSuccess = () => {
    this.flashMessages.success(
      this.intl.t('components.import-to-terraform.copied')
    );
  };

  <template>
    <div class="importToTerraform__item">
      <Typography class="title">
        {{t "components.import-to-terraform.title"}}
      </Typography>

      <Typography>
        {{t "components.import-to-terraform.body"}}
      </Typography>

      <HdsLinkStandalone
        @text={{t "components.import-to-terraform.docs-link"}}
        @icon="docs-link"
        @href="https://www.terraform.io/docs/cli/import/usage.html"
      />

      <CopyCard
        @clipboardText={{@command}}
        @onSuccess={{this.onSuccess}}
        @showCopied={{false}}
      />
    </div>
  </template>
}
