/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFlavorAgentFromJSON, HashicorpCloudWaypointActionConfigFlavorAgentToJSON, HashicorpCloudWaypointActionConfigFlavorCustomFromJSON, HashicorpCloudWaypointActionConfigFlavorCustomToJSON, HashicorpCloudWaypointActionConfigFlavorGitHubFromJSON, HashicorpCloudWaypointActionConfigFlavorGitHubToJSON, } from './';
export function HashicorpCloudWaypointActionConfigRequestFromJSON(json) {
    return HashicorpCloudWaypointActionConfigRequestFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionConfigRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'custom': !exists(json, 'custom') ? undefined : HashicorpCloudWaypointActionConfigFlavorCustomFromJSON(json['custom']),
        'github': !exists(json, 'github') ? undefined : HashicorpCloudWaypointActionConfigFlavorGitHubFromJSON(json['github']),
        'agent': !exists(json, 'agent') ? undefined : HashicorpCloudWaypointActionConfigFlavorAgentFromJSON(json['agent']),
    };
}
export function HashicorpCloudWaypointActionConfigRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'custom': HashicorpCloudWaypointActionConfigFlavorCustomToJSON(value.custom),
        'github': HashicorpCloudWaypointActionConfigFlavorGitHubToJSON(value.github),
        'agent': HashicorpCloudWaypointActionConfigFlavorAgentToJSON(value.agent),
    };
}
