/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefActionFromJSON, HashicorpCloudWaypointRefNamespaceFromJSON, HashicorpCloudWaypointRefNamespaceToJSON, HashicorpCloudWaypointV20241122IntegrationGitHubFromJSON, HashicorpCloudWaypointV20241122IntegrationGitHubToJSON, HashicorpCloudWaypointV20241122IntegrationStateFromJSON, HashicorpCloudWaypointV20241122IntegrationStateToJSON, } from './';
export function HashicorpCloudWaypointV20241122IntegrationFromJSON(json) {
    return HashicorpCloudWaypointV20241122IntegrationFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122IntegrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointRefNamespaceFromJSON(json['namespace']),
        'actions': !exists(json, 'actions') ? undefined : (json['actions'].map(HashicorpCloudWaypointRefActionFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdByIamid': !exists(json, 'created_by_iamid') ? undefined : json['created_by_iamid'],
        'updatedByIamid': !exists(json, 'updated_by_iamid') ? undefined : json['updated_by_iamid'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudWaypointV20241122IntegrationStateFromJSON(json['state']),
        'github': !exists(json, 'github') ? undefined : HashicorpCloudWaypointV20241122IntegrationGitHubFromJSON(json['github']),
    };
}
export function HashicorpCloudWaypointV20241122IntegrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'namespace': HashicorpCloudWaypointRefNamespaceToJSON(value.namespace),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_iamid': value.createdByIamid,
        'updated_by_iamid': value.updatedByIamid,
        'state': HashicorpCloudWaypointV20241122IntegrationStateToJSON(value.state),
        'github': HashicorpCloudWaypointV20241122IntegrationGitHubToJSON(value.github),
    };
}
