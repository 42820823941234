/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';
import { HdsFormTextInputField } from '@hashicorp/design-system-components/components';

const MATCH_STRING_DEFAULT = 'DELETE';

interface ModalConfirmSignature {
  Args: {
    confirmHelp?: string;
    confirmLabel?: string;
    matchString: string;
    onAllowedChange: unknown;
  };
}

/**
 *
 * `ModalConfirm` is a component that handles the typing of confirm text for modals.
 *
 *
 * ```
 * <ModalConfirm
 *   @confirmLabel={{t 'components.modals.restore.confirm-label'}}
 *   @confirmHelp={{t 'components.modals.restore.confirm-help'}}
 *   @matchString={{t 'components.modals.restore.match-string'}}
 *   @onAllowedChange={{fn this.foo}}
 * />
 * ```
 *
 * @class ModalConfirm
 *
 */

/**
 * The string the confirm text should match. If no argument is passed in, this defaults to 'DELETE'.
 *
 * @argument matchString
 * @type {string}
 */

/**
 * The form label string for the confirm component.
 *
 * @argument confirmLabel
 * @type {string}
 */

/**
 * The form help string for the confirm component.
 *
 * @argument confirmHelp
 * @type {string}
 */

/**
 *
 * `ModalConfirm` is a component that handles the typing of confirm text for modals.
 *
 *
 * ```
 * <ModalConfirm
 *   @confirmLabel={{t 'components.modals.restore.confirm-label'}}
 *   @confirmHelp={{t 'components.modals.restore.confirm-help'}}
 *   @matchString={{t 'components.modals.restore.match-string'}}
 *   @onAllowedChange={{fn this.foo}}
 * />
 * ```
 *
 * @class ModalConfirm
 *
 */
export default class ModalConfirm extends Component<ModalConfirmSignature> {
  @tracked confirmString = '';

  change =
    typeof this.args?.onAllowedChange === 'function'
      ? this.args.onAllowedChange
      : () => {};

  get matchString() {
    return this.args.matchString || MATCH_STRING_DEFAULT;
  }

  get confirmed() {
    const { matchString } = this;
    return this.confirmString === matchString;
  }

  setConfirm = (evt: any) => {
    this.confirmString = evt.target?.value;
    this.change(this.confirmed);
  };

  // this is called on will-destroy so that closing the modal and re-opening
  // does not perist a previous match
  resetConfirm = () => {
    this.change(false);
  };

  <template>
    <div {{willDestroy this.resetConfirm}}>
      <HdsFormTextInputField
        @id="confirm"
        @isRequired={{true}}
        @type="text"
        @value={{this.confirmString}}
        placeholder={{this.matchString}}
        autocomplete="off"
        name="confirm"
        data-test-modal-confirm
        {{on "input" this.setConfirm}}
        as |F|
      >
        <F.Label>
          {{or @confirmLabel (t "components.modal-confirm.confirm-label")}}
        </F.Label>
        <F.HelperText>
          {{or
            @confirmHelp
            (t
              "components.modal-confirm.confirm-help"
              matchString=this.matchString
            )
          }}
        </F.HelperText>
      </HdsFormTextInputField>
    </div>
  </template>
}
