import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import { timeout, task } from 'ember-concurrency';
import { concat } from '@ember/helper';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';
// @ts-expect-error: missing types
import CopyButton from 'ember-cli-clipboard/components/copy-button';

import type { IntlService } from 'ember-intl';
import classNames from '../helpers/class-names.ts';

interface ClipboardCopyButtonSignature {
  Args: {
    clipboardText: string;
    container: string;
    onSuccess?: () => void;
    successText?: string;
    timeout?: string | number;
    variant?: 'primary' | 'no-success-text';
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `ClipboardCopyButton` wraps [ember-cli-clipboard's `CopyButton` component]( https://github.com/jkusa/ember-cli-clipboard#angle-bracket-invocation ) and uses `ember-concurrency` to show a success message after copying.
 *
 * The default appearance is the same as a compact "ghost" button from HDS, and it replaces the yielded text with the success message.
 *
 * There is also a "primary" variant that renders the button as a primary button and shows the success message to the right of the button.
 *
 * The "no-success-text" variant renders the clipboard icon, but does not show a success text upon copying.
 *
 *
 * ### Usage
 *
 * #### Minimal usage
 *
 * Just the clipboard icon
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 * />
 * ```
 *
 * A primary button that says 'Copy' with the clipboard icon.
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 *   @variant='primary'
 * />
 * ```
 *
 * #### All of the args
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 *   @container='.some-selector'
 *   @variant='primary'
 *   @timeout='2000'
 *   @successText='Some text'
 *   @onSuccess={{this.onSuccess}}
 * >
 *   Copy some text
 *</ClipboardCopyButton>
 * ```
 *
 * @class ClipboardCopyButton
 *
 */
export default class ClipboardCopyButton extends Component<ClipboardCopyButtonSignature> {
  /**
   * The text to copy to the clipboard. This is passed through to the ember-cli-clipboard component.
   * @argument clipboardText
   * @required
   * @type {string}
   */

  /**
   * A DOM selector representing the ancestor element in the DOM that events should be delegated to. This is useful when rendering a CopyButton in a modal. This argument is passed through to the ember-cli-clipboard component.
   * @argument container
   * @required
   * @type {string}
   */

  /**
   * The amount of time in milliseconds to wait before removing the success text. If this argument isn't specified, a timeout of 1000 is used.
   * @argument timeout
   * @type {?string|?number}
   */

  /**
   * Changes the variant of the ClipboardCopyButton. The only currently supported variants are `'primary'` and `'no-success-text'`.
   * @argument variant
   * @type {?string}
   */

  /**
   * A message that can be displayed upon successful copy
   * @argument successText
   * @type {?string}
   */

  @service declare readonly intl: IntlService;
  @tracked successText: string = '';

  // Internal state-tracking
  @tracked showSuccess = false;

  get timeout() {
    return this.args.timeout || isTesting() ? 10 : 1000;
  }

  get isPrimary() {
    return this.args.variant === 'primary';
  }

  get isNoSuccessText() {
    return this.args.variant === 'no-success-text';
  }

  copied = task({ drop: true }, async () => {
    this.successText =
      this.args.successText ??
      this.intl.t('components.clipboard-copy-button.copied');
    this.showSuccess = true;

    this.args?.onSuccess?.();

    await timeout(this.timeout);

    this.successText = '';
    this.showSuccess = false;
  });

  <template>
    <CopyButton
      {{! this is a hack to typecast to a string in cases when @text is a number}}
      @text={{concat @clipboardText}}
      @container={{@container}}
      @onSuccess={{this.copied.perform}}
      class={{classNames
        "clipboard-copy-button"
        (if
          this.isPrimary
          "clipboard-copy-button--primary"
          "clipboard-copy-button--inline"
        )
        "hds-typography-body-200"
        "hds-font-weight-medium"
      }}
      data-test-clipboard-copy-button
      ...attributes
    >
      <HdsIcon
        @name={{if this.showSuccess "clipboard-checked" "clipboard-copy"}}
        data-test-icon-type={{if
          this.showSuccess
          "clipboard-checked"
          "clipboard-copy"
        }}
        data-test-clipboard-copy-button-icon
      />
      {{#if this.isPrimary}}
        {{! feedback will be after the button }}
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{t "components.clipboard-copy-button.copy"}}
        {{/if}}
      {{else if this.isNoSuccessText}}
        {{yield}}
      {{else}}
        {{! give feedback on the button text itself by replacing the yielded content }}
        {{#if this.successText}}
          {{this.successText}}
        {{else}}
          {{yield}}
        {{/if}}
      {{/if}}
    </CopyButton>

    {{#if this.isPrimary}}
      {{#if this.showSuccess}}
        <span
          class="clipboard-copy--success"
          data-test-clipboard-copy-button-success
        >
          <HdsIcon
            @name="check"
            @color="var(--token-color-foreground-success)"
            aria-hidden="true"
          />

          {{this.successText}}
        </span>
      {{/if}}
    {{/if}}
  </template>
}
