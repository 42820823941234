import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { hash } from '@ember/helper';
import {
  HdsCardContainer,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';

import Flex from '../../flex.gts';
import { FlexDirection, FlexGap } from '../../../utils/flex.ts';
import { Type, TYPES } from '../../../utils/consts/card-summary.ts';
import CoreCardSummaryHeader from './summary/header.gts';
import CoreCardSummaryPair from './summary/pair.gts';

import type Owner from '@ember/owner';
import type { ComponentLike, WithBoundArgs } from '@glint/template';

const DEFAULT_BACKGROUND = 'neutral-secondary';
const DEFAULT_HAS_BORDER = true;
// const DEFAULT_TYPE = 'horizontal';

interface CoreCardSummarySignature {
  Args: {
    background?: string;
    hasBorder?: boolean;
    type: Type;
  };
  Blocks: {
    default: [
      {
        Header?: ComponentLike<CoreCardSummaryHeader>;
        Pair?: WithBoundArgs<typeof CoreCardSummaryPair, 'keyIcon'>;
        LinkStandalone?: ComponentLike<HdsLinkStandalone>;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `CoreCardSummaryComponent` provides a set of components to construct key/value pairs.
 *
 *
 * ```
 * <Core::Card::Summary as |Card|>
 *   <Card.Pair as |Pair|>
 *     <Pair.Key>Term1</Pair.Key>
 *     <Pair.Value>Value1</Pair.Value>
 *   </Card.Pair>
 *   <Card.Pair @keyIcon="zap" as |Pair|>
 *     <Pair.Key>Term2</Pair.Key>
 *     <Pair.Value>Value2</Pair.Value>
 *   </Card.Pair>
 *   <Card.LinkStandalone @text="hello" @href="#" @icon="arrow-right" />
 * </Core::Card::Summary>
 * ```
 *
 * @class CoreCardSummaryComponent
 *
 */
export default class CoreCardSummary extends Component<CoreCardSummarySignature> {
  constructor(owner: Owner, args: CoreCardSummarySignature['Args']) {
    super(owner, args);

    assert(
      `@type for "Core::Card::Summary" must be one of the following: ${TYPES.join(
        ', '
      )}; received: ${this.args.type}`,
      TYPES.includes(this.args.type)
    );
  }

  /**
   * @param background
   * @type {enum}
   * @default neutral-secondary
   * @description The "background" string to apply to the hds card component.
   */
  get background() {
    const { background = DEFAULT_BACKGROUND } = this.args;
    return background;
  }

  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const classes = ['core-card-summary'];

    // Add a class based on the @type argument
    classes.push(`core-card-summary--type-${this.args.type}`);

    return classes.join(' ');
  }

  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryComponent#hasBorder
   * @return {string} The "hasBorder" boolean to apply to the hds card component.
   */
  get hasBorder() {
    const { hasBorder = DEFAULT_HAS_BORDER } = this.args;
    return hasBorder;
  }

  <template>
    <HdsCardContainer
      {{! @glint-expect-error }}
      @background={{this.background}}
      @hasBorder={{this.hasBorder}}
      {{! @glint-expect-error }}
      @level="base"
      class={{this.classNames}}
      data-test-core-card-summary-container
      ...attributes
    >
      <Flex @direction={{FlexDirection.Column}} @gap={{FlexGap.Sm}}>
        {{yield (hash Header=(component CoreCardSummaryHeader))}}

        <div class="core-card-summary__body">
          <div class="core-card-summary__data">
            <dl class="core-card-summary__definition-list">
              {{yield (hash Pair=(component CoreCardSummaryPair))}}
            </dl>
          </div>

          <div class="core-card-summary__actions">
            {{yield (hash LinkStandalone=(component HdsLinkStandalone))}}
          </div>
        </div>
      </Flex>
    </HdsCardContainer>
  </template>
}
