/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn, array, concat } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';
import can from 'ember-can/helpers/can';

import { PROJECT_PICKER_MAX_PROJECT_LENGTH } from '../utils/consts/project-picker.js';
import classNames from '../helpers/class-names.ts';
import boxMargin from '../modifiers/box-margin.ts';

import type {
  HashicorpCloudResourcemanagerOrganization,
  HashicorpCloudResourcemanagerProject,
} from '@clients/cloud-resource-manager';

interface ProjectPickerSignature {
  Args: {
    currentOrg: HashicorpCloudResourcemanagerOrganization | undefined;
    currentProject: HashicorpCloudResourcemanagerProject | undefined;
    organizations: HashicorpCloudResourcemanagerOrganization[];
    projects: HashicorpCloudResourcemanagerProject[];
    projectsNextPageToken?: string | null;
    projectsNextToken?: string | null;
  };
  Element: HTMLDivElement;
}

/**
 * `ProjectPicker` renders a dropdown that allows users to switch Project
 *  context in the HCP navigation app. It should only be rendered if an org
 *  is already in context.
 *
 * ```
 * <ProjectPicker
 *   @currentOrg={{this.currentOrg}}
 *   @currentProject={{this.currentProject}}
 *   @organizations={{this.model.organizations}}
 *   @projects={{this.model.projects}}
 *   @projectsNextPageToken={{this.userContext.projectsNextToken}}
 * />
 * ```
 *
 * @class ProjectPicker
 *
 */

export default class ProjectPicker extends Component<ProjectPickerSignature> {
  @tracked maxProjectLength = PROJECT_PICKER_MAX_PROJECT_LENGTH;

  get currentOrg() {
    const { currentOrg } = this.args;
    return currentOrg;
  }

  get currentProject() {
    const { currentProject } = this.args;
    return currentProject;
  }

  get organizations() {
    const { organizations = [] } = this.args;
    return organizations;
  }

  get projects() {
    const { projects = [] } = this.args;
    return projects.slice(0, this.maxProjectLength);
  }

  get hasProjectListOverflowing() {
    const { projects = [] } = this.args;
    return projects.length > this.maxProjectLength;
  }

  get hasMoreProjectsPaginated() {
    const { projectsNextToken } = this.args;
    return projectsNextToken ? true : false;
  }

  closeDropdown = (dropdown: any, event: any) => {
    // only close if we're not focused on something inside the dropdown and if
    // the component is not destroyed.
    if (
      !event.currentTarget.contains(
        event.relatedTarget || document.activeElement
      ) &&
      (this.isDestroying || this.isDestroyed)
    ) {
      dropdown.actions.close();
    }
  };

  <template>
    <div class="project-picker" ...attributes>
      <BasicDropdown
        @verticalPosition="above"
        @renderInPlace={{true}}
        @matchTriggerWidth={{true}}
        data-test-project-picker
        as |dd|
      >
        <dd.Trigger
          data-test-project-picker-dropdown-trigger
          class="project-picker__button"
          aria-label={{if
            this.currentProject
            this.currentProject.name
            this.currentOrg.name
          }}
        >
          <HdsIcon
            @color="var(--token-color-palette-neutral-300)"
            @name={{if this.currentProject "file-text" "org"}}
            class="trigger-project-icon"
            data-test-project-picker-project-icon
          />
          <span
            class="trigger-text"
            data-test-project-picker-dropdown-trigger-label
          >
            {{#if this.currentProject}}
              {{this.currentProject.name}}
            {{else}}
              {{this.currentOrg.name}}
            {{/if}}
          </span>
          {{#if dd.isOpen}}
            <HdsIcon
              @color="var(--token-color-palette-neutral-300)"
              @name="chevron-up"
              class="trigger-chevron-icon"
              data-test-project-picker-trigger-icon-open
            />
          {{else}}
            <HdsIcon
              @color="var(--token-color-palette-neutral-300)"
              @name="chevron-down"
              class="trigger-chevron-icon"
              data-test-project-picker-trigger-icon-closed
            />
          {{/if}}
        </dd.Trigger>
        <dd.Content
          data-test-project-picker-dropdown-content
          class="project-picker__content"
          {{on "focusout" (fn this.closeDropdown dd)}}
          @animationEnabled={{true}}
        >
          <div class="hds-dropdown__content">
            <ul class="hds-dropdown__list">

              <li
                class={{classNames
                  "hds-dropdown-list-item"
                  "hds-dropdown-list-item--variant-interactive"
                  "hds-dropdown-list-item--color-action"
                }}
              >
                <LinkTo
                  @route="cloud.orgs.list"
                  data-test-project-picker-organizations-list-link
                >
                  <div
                    class="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading"
                  >
                    <HdsIcon @name="hashicorp" />
                  </div>
                  <div
                    class={{classNames
                      "hds-dropdown-list-item__interactive-text"
                      "hds-typography-body-200"
                      "hds-font-weight-medium"
                    }}
                  >
                    {{t
                      "components.project-picker.view-all-organizations"
                      count=this.organizations.length
                    }}
                  </div>
                </LinkTo>
              </li>
              <li
                class="hds-dropdown-list-item--variant-separator"
                role="separator"
                {{boxMargin "0 0 2xs 0"}}
              ></li>

              {{#let this.projects as |projects|}}
                <li
                  class={{classNames
                    "hds-dropdown-list-item"
                    "hds-dropdown-list-item--variant-description"
                    "hds-typography-body-100"
                    "hds-font-weight-regular"
                  }}
                  data-test-project-picker-organization-list-header
                >
                  {{t "components.project-picker.organization"}}
                </li>
                <li
                  class={{classNames
                    "hds-dropdown-list-item"
                    "hds-dropdown-list-item--variant-interactive"
                    "hds-dropdown-list-item--color-action"
                  }}
                  data-test-project-picker-current-organization-name
                >
                  <LinkTo
                    @models={{array this.currentOrg.id}}
                    @route="cloud.orgs.detail.index"
                    class={{classNames
                      "hds-dropdown-list-item__interactive-text"
                      "hds-typography-body-200"
                      "hds-font-weight-medium"
                    }}
                  >
                    <HdsIcon
                      @name="org"
                      class="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading"
                    />
                    <span class="project-picker__link-label">
                      {{this.currentOrg.name}}
                    </span>
                  </LinkTo>
                </li>
                {{#if projects}}
                  <li
                    class={{classNames
                      "hds-dropdown-list-item"
                      "hds-dropdown-list-item--variant-description"
                      "hds-typography-body-100"
                      "hds-font-weight-regular"
                      "project-picker__indented-list-item"
                    }}
                    data-test-project-picker-projects-list-header
                  >
                    {{t "components.project-picker.projects"}}
                  </li>
                  {{#each projects as |project|}}
                    <li
                      class={{classNames
                        "hds-dropdown-list-item"
                        "hds-dropdown-list-item--variant-interactive"
                        "hds-dropdown-list-item--color-action"
                      }}
                      data-test-project-picker-project-link-item
                    >
                      <LinkTo
                        @models={{array project.parent.id project.id}}
                        @route="cloud.orgs.detail.projects.detail"
                        class={{classNames
                          "hds-dropdown-list-item__interactive-text"
                          "hds-typography-body-200"
                          "hds-font-weight-medium"
                          "project-picker__indented-list-item"
                        }}
                      >
                        <HdsIcon
                          @name="file-text"
                          class="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading"
                        />
                        <span class="project-picker__link-label">
                          {{project.name}}
                        </span>
                        {{#if (eq project.id this.currentProject.id)}}
                          <HdsIcon
                            class="project-selected-icon"
                            @name="check"
                            @color="var(--token-color-foreground-action)"
                          />
                        {{/if}}
                      </LinkTo>
                    </li>
                  {{/each}}
                {{/if}}
                {{#if this.hasProjectListOverflowing}}
                  <li
                    class={{classNames
                      "hds-dropdown-list-item"
                      "hds-dropdown-list-item--variant-description"
                      "hds-typography-body-100"
                      "hds-font-weight-regular"
                      "project-picker__indented-list-item"
                    }}
                    data-test-project-picker-projects-list-and-more
                  >
                    ...
                    {{t "components.project-picker.and-more"}}
                  </li>
                {{/if}}
              {{/let}}
              {{#if (can "list projects")}}
                <li
                  class={{classNames
                    "hds-dropdown-list-item"
                    "hds-dropdown-list-item--variant-interactive"
                    "hds-dropdown-list-item--color-action"
                  }}
                >
                  <LinkTo
                    @models={{array this.currentOrg.id}}
                    @route="cloud.orgs.detail.projects"
                    class={{classNames
                      "hds-dropdown-list-item__interactive-text"
                      "hds-typography-body-200"
                      "hds-font-weight-medium"
                      "project-picker__indented-list-item"
                    }}
                    data-test-project-picker-projects-list-link
                  >
                    <span>
                      {{t
                        "components.project-picker.view-all-projects"
                        count=(if
                          this.hasMoreProjectsPaginated
                          (concat @projects.length "+")
                          @projects.length
                        )
                      }}
                    </span>
                  </LinkTo>
                </li>
              {{/if}}
            </ul>
          </div>
        </dd.Content>
      </BasicDropdown>
    </div>
  </template>
}
