import Component from '@glimmer/component';
import { service } from '@ember/service';
import { HdsBreadcrumb } from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

import type BreadcrumbsService from '@bagaar/ember-breadcrumbs/services/breadcrumbs';

interface BreadcrumbsContainerSignature {
  Args: {
    itemClass?: string;
    linkClass?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `Breadcrumbs::Container` component renders the registered items
 * sequentially in the order they were registered. These items are registered
 * via the `Breadcrumbs::Item` component and are nested throughout the route
 * heirarchy.
 *
 * This component uses an upstream library's service to keep track of Items and
 * the components here in `core` are near identical clones of the
 * packaged components. We've added these here locally in order to utilize
 * our own custom components in a template and simply inject their service.
 *
 * We utilize nested routes throughout our route architecture and as we traverse
 * downward through outlets, we can drop `<Breadcrumbs::Item>` along the way.
 * Each route template will ultimately have a single breadcrumb and any container
 * rendered inside the page component will output the dropped crumbs.
 *
 * Read more at https://github.com/Bagaar/ember-breadcrumbs/
 *
 * * ## Example usage
 *
 * ```
 * <Breadcrumbs::Container />
 * ```
 *
 * @class BreadcrumbsContainer
 *
 */

export default class BreadcrumbsContainer extends Component<BreadcrumbsContainerSignature> {
  @service('breadcrumbs')
  declare readonly breadcrumbsService: BreadcrumbsService;

  container = null;

  registerContainer = (element: HTMLElement) => {
    const { itemClass, linkClass } = this.args;

    // A child `ol` is rendered in the Hds::Breadcrumb container and this is
    // the element that the in-element helper should drop breadcrumbs into.
    // @ts-expect-error
    this.container = {
      element: element.querySelector('ol'),
      itemClass,
      linkClass,
    };

    // @ts-expect-error
    this.breadcrumbsService.registerContainer(this.container);
  };

  willDestroy() {
    // @ts-expect-error
    // eslint-disable-next-line prefer-rest-params
    super.willDestroy(...arguments);

    // @ts-expect-error
    this.breadcrumbsService.unregisterContainer(this.container);
  }

  <template>
    <HdsBreadcrumb
      ...attributes
      data-test-breadcrumbs-container
      {{didInsert this.registerContainer}}
    >
      {{yield}}
    </HdsBreadcrumb>
  </template>
}
