import { DateTime } from 'luxon';
import { assert } from '@ember/debug';

import OnDemandStatus from './on-demand-status.js';
import { BILLING_ACCOUNT_STATUS } from '../constants/status.js';

// TODO: Enable either private or static method Babel transpilation.
function isPaymentExpired({ expMonth, expYear }) {
  if (!expMonth || !expYear) {
    assert('Must provide expiry month and year');
  }
  let expiration = DateTime.fromObject({ month: expMonth, year: expYear })
    .toUTC()
    .endOf('month');
  return DateTime.utc() > expiration;
}

export default class OnDemand {
  #billingAccount;
  #onDemandBillingMethodDetails;

  /**
   * @param {object} [seed={}]
   * @param {object} [seed.onDemandBillingMethodDetails]
   */

  constructor(billingAccount, onDemandBillingMethodDetails) {
    this.#billingAccount = billingAccount;
    this.#onDemandBillingMethodDetails = onDemandBillingMethodDetails;
  }

  /** @type {object} */
  get cardDetails() {
    return this.#onDemandBillingMethodDetails?.cardDetails || null;
  }

  /** @type {boolean} */
  get hasCardError() {
    return (
      this.#billingAccount?.status == BILLING_ACCOUNT_STATUS.DELINQUENT ||
      this.cardIsExpired
    );
  }

  /** @type {boolean} */
  get cardIsExpired() {
    return this.cardDetails && isPaymentExpired(this.cardDetails);
  }

  /** @type {OnDemandStatus} */
  get status() {
    return new OnDemandStatus(this.#billingAccount?.onDemandStatus);
  }

  /** @type {object|null} */
  get billingMethodDetails() {
    return this.#onDemandBillingMethodDetails?.billingMethod || null;
  }
}
