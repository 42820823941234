import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';

import FlexGridItem from '../../flex-grid/item.gts';

interface LayoutPageHeaderTitleSignature {
  Args: {
    lg?: string;
    md?: string;
    sm?: string;
    xs?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class LayoutPageHeaderTitle extends Component<LayoutPageHeaderTitleSignature> {
  <template>
    <FlexGridItem
      @lg={{or @lg 8}}
      @md={{or @md 8}}
      @sm={{or @sm 12}}
      @xs={{or @xs 12}}
      class="pageHeader__title"
      data-test-page-header-title
      ...attributes
    >
      {{yield}}
    </FlexGridItem>
  </template>
}
