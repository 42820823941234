/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointTFCOrganizationFromJSON, HashicorpCloudWaypointTFCOrganizationToJSON, } from './';
export function HashicorpCloudWaypointListTFCOrganizationsResponseFromJSON(json) {
    return HashicorpCloudWaypointListTFCOrganizationsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListTFCOrganizationsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tfcOrganizations': !exists(json, 'tfc_organizations') ? undefined : (json['tfc_organizations'].map(HashicorpCloudWaypointTFCOrganizationFromJSON)),
    };
}
export function HashicorpCloudWaypointListTFCOrganizationsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tfc_organizations': value.tfcOrganizations === undefined ? undefined : (value.tfcOrganizations.map(HashicorpCloudWaypointTFCOrganizationToJSON)),
    };
}
