import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';

import FlexGridItem from '../../flex-grid/item.gts';

interface LayoutPageHeaderActionsSignature {
  Args: {
    lg?: string;
    md?: string;
    sm?: string;
    xs?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class LayoutPageHeaderActions extends Component<LayoutPageHeaderActionsSignature> {
  <template>
    <FlexGridItem
      @lg={{or @lg 4}}
      @md={{or @md 4}}
      @sm={{or @sm 12}}
      @xs={{or @xs 12}}
      class="pageHeader__actions"
      data-test-page-header-actions
    >
      {{yield}}
    </FlexGridItem>
  </template>
}
