import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { hash } from '@ember/helper';
import { concat } from '@ember/helper';
import { or } from 'ember-truth-helpers';
import { HdsTextBody } from '@hashicorp/design-system-components/components';

import FlexGrid from '../flex-grid.gts';
import Typography from '../typography.gts';
import ChartsStackedBarChart from './stacked-bar/chart.gts';
import ChartsStackedBarSummary from './stacked-bar/summary.gts';

import type { WithBoundArgs } from '@glint/template';

interface ChartsStackedBarSignature {
  Args: {
    segments: {
      value: number;
      color: string | ((segment: { value: number }) => string);
      text?: string | ((segment: { value: number }) => string);
    }[];
    max?: number;
    subtitle?: string;
    title?: string;
  };
  Blocks: {
    default?: [
      {
        segments: {
          value: number;
          color: string | ((segment: { value: number }) => string);
          text?: string | ((segment: { value: number }) => string);
        }[];
        total: number;
        max: number;
        isOverMax: boolean;
        Chart: WithBoundArgs<
          typeof ChartsStackedBarChart,
          'segments' | 'max' | 'total' | 'identifier'
        >;
        Summary: WithBoundArgs<
          typeof ChartsStackedBarSummary,
          'total' | 'max' | 'isOverMax'
        >;
      },
    ];
  };
  Element: HTMLDivElement | HTMLElement;
}

// TODO: enable when this is fixed: https://github.com/thgh/rollup-plugin-scss/issues/70
// import './index.scss';

export default class ChartsStackedBar extends Component<ChartsStackedBarSignature> {
  uuid = guidFor(this);

  /**
   * A title for the chart, if omitted the section is not rendered
   * @argument title
   * @type {?string}
   */

  /**
   * A subtitle for the chart, if omitted the section is not rendered
   * Rendered as Helptext
   * @argument subtitle
   * @type {?string}
   */

  /**
   * the value (0-100 intended as a percentage) that bar will display
   * For more information see
   *
   * ```
   * type ColorFunction = (segment: { value: number }) => string;
   * type TextFunction = (segment: { value: number }) => string;
   *
   * interface Segment {
   *   value: number,
   *   color: string | ColorFunction, // can be any svg supported color string (rgb, hex, etc)
   *   text?: string | TextFunction,
   * }
   * ```
   * @argument segments
   * @type {Segment[]}
   */

  /**
   * Int representing the max value shown on the chart
   * @argument max
   * @type {?number}
   */

  /**
   * Guard around segments
   * @method StackedBar#segments
   * @return {Segment[]}
   */
  get segments() {
    return this.args.segments.filter(({ value }) => value > 0) || [];
  }

  /**
   * Guard around max, handles the case where there is no max
   * @method StackedBar#max
   * @return {number}
   */
  get max() {
    return this.args.max || this.total;
  }

  /**
   * Calculates the total of all values in the chart
   * @method StackedBar#total
   * @return {number}
   */
  get total() {
    return this.segments.reduce((total, { value }) => {
      total += value;
      return total;
    }, 0);
  }
  /**
   * Single calculation for whether the total is over the max
   * @method StackedBar#isOverMax
   * @return {boolean}
   */
  get isOverMax() {
    return this.total > this.max;
  }

  <template>
    {{#if (has-block)}}
      <div class="stacked-bar" data-test-stacked-bar ...attributes>
        {{yield
          (hash
            segments=this.segments
            total=this.total
            max=this.max
            isOverMax=this.isOverMax
            Chart=(component
              ChartsStackedBarChart
              segments=this.segments
              max=this.max
              total=this.total
              identifier=this.uuid
            )
            Summary=(component
              ChartsStackedBarSummary
              total=this.total
              max=@max
              isOverMax=this.isOverMax
            )
          )
        }}
      </div>
    {{else}}
      <FlexGrid
        aria-label={{concat "Stacked Bar Chart" @title}}
        class="stacked-bar"
        data-test-stacked-bar
        ...attributes
        as |G|
      >
        {{#if (or @title @subtitle)}}
          <G.Item @xs="12">
            {{#if @title}}
              <Typography data-test-chart-title @variant="h3">
                {{@title}}
              </Typography>
            {{/if}}

            {{#if @subtitle}}
              <HdsTextBody
                data-test-chart-subtitle
                @color="var(--token-color-foreground-faint)"
                @weight="regular"
                @size="100"
                @tag="p"
              >
                {{@subtitle}}
              </HdsTextBody>
            {{/if}}
          </G.Item>
        {{/if}}

        <G.Item @xs="12" @sm="11">
          <ChartsStackedBarChart
            @segments={{this.segments}}
            @max={{this.max}}
            @total={{this.total}}
            @identifier={{this.uuid}}
          />
        </G.Item>

        <G.Item @xs="12" @sm="1">
          <ChartsStackedBarSummary
            @total={{this.total}}
            @max={{@max}}
            @isOverMax={{this.isOverMax}}
          />
        </G.Item>
      </FlexGrid>
    {{/if}}
  </template>
}
