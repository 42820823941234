import Component from '@glimmer/component';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import { DEFAULT_ICON_TYPE } from '../utils/consts/error.js';
import Flex from './flex.gts';
import { AlignItem, FlexDirection, FlexGap } from '../utils/flex.ts';
import Typography from './typography.gts';

import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

interface ErrorSignature {
  Args: {
    icon: HdsIconSignature['Args']['name'];
  };
  Blocks: {
    content: [];
    footer: [];
    subtitle: [];
    title: [];
  };
  Element: HTMLElement;
}

/**
 * The icon name to render via a HdsIcon component.
 * @argument icon
 * @type {string}
 */

/**
 *
 * `Error` displays relevant error messaging.
 *
 *
 * ```
 * <Error @icon="x-square-fill">
 *   <:title>Not Found</:title>
 *   <:subtitle>Error 404</:subtitle>
 *   <:content>Some content message</:content>
 *   <:footer>
 *    <LinkTo @route="cloud">
 *      <HdsIcon @name='chevron-left' @size='16' />
 *      Go back
 *    </LinkTo>
 *  </:footer>
 * </Error>
 * ```
 *
 * @class Error
 *
 */
export default class Error extends Component<ErrorSignature> {
  get icon() {
    const { icon } = this.args;
    return icon || DEFAULT_ICON_TYPE;
  }

  <template>
    <div class="hcp-min-height-100-percent hcp-flex-centered">
      <section
        class="error-state"
        ...attributes
        data-test-error-state-container
      >
        <header>
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @gap={{FlexGap.Sm}}
            @grow={{1}}
          >
            <HdsIcon
              class="error-state__icon"
              @name={{this.icon}}
              data-test-error-state-icon
            />
            <div>
              <div class="error-state__title">
                <Typography @variant="h1" data-test-error-state-title>
                  {{yield to="title"}}
                </Typography>
              </div>
              <div class="error-state__subtitle" data-test-error-state-subtitle>
                <Typography @variant="h2">
                  {{yield to="subtitle"}}
                </Typography>
              </div>
            </div>
          </Flex>
        </header>
        {{#if (has-block "content")}}
          <div class="error-state__content" data-test-error-state-content>
            {{yield to="content"}}
          </div>
        {{/if}}
        {{#if (has-block "footer")}}
          <footer class="error-state__footer" data-test-error-state-footer>
            {{yield to="footer"}}
          </footer>
        {{/if}}
      </section>
    </div>
  </template>
}
