/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointTerraformTFRunState;
(function (HashicorpCloudWaypointTerraformTFRunState) {
    HashicorpCloudWaypointTerraformTFRunState["UNKNOWN"] = "UNKNOWN";
    HashicorpCloudWaypointTerraformTFRunState["RUNNING"] = "RUNNING";
    HashicorpCloudWaypointTerraformTFRunState["SUCCESS"] = "SUCCESS";
    HashicorpCloudWaypointTerraformTFRunState["ERROR"] = "ERROR";
})(HashicorpCloudWaypointTerraformTFRunState || (HashicorpCloudWaypointTerraformTFRunState = {}));
export function HashicorpCloudWaypointTerraformTFRunStateFromJSON(json) {
    return HashicorpCloudWaypointTerraformTFRunStateFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTerraformTFRunStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointTerraformTFRunStateToJSON(value) {
    return value;
}
