/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFromJSON, HashicorpCloudWaypointActionConfigToJSON, } from './';
export function HashicorpCloudWaypointUpdateActionConfigResponseFromJSON(json) {
    return HashicorpCloudWaypointUpdateActionConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointUpdateActionConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionConfig': !exists(json, 'action_config') ? undefined : HashicorpCloudWaypointActionConfigFromJSON(json['action_config']),
    };
}
export function HashicorpCloudWaypointUpdateActionConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_config': HashicorpCloudWaypointActionConfigToJSON(value.actionConfig),
    };
}
