/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointNoCodeModuleDefinitionFromJSON(json) {
    return HashicorpCloudWaypointNoCodeModuleDefinitionFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointNoCodeModuleDefinitionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'versions': !exists(json, 'versions') ? undefined : json['versions'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastUpdated': !exists(json, 'last_updated') ? undefined : (new Date(json['last_updated'])),
        'tfNamespace': !exists(json, 'tf_namespace') ? undefined : json['tf_namespace'],
        'registryName': !exists(json, 'registry_name') ? undefined : json['registry_name'],
        'moduleId': !exists(json, 'module_id') ? undefined : json['module_id'],
        'pinnedVersion': !exists(json, 'pinned_version') ? undefined : json['pinned_version'],
    };
}
export function HashicorpCloudWaypointNoCodeModuleDefinitionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'versions': value.versions,
        'name': value.name,
        'provider': value.provider,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'last_updated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'tf_namespace': value.tfNamespace,
        'registry_name': value.registryName,
        'module_id': value.moduleId,
        'pinned_version': value.pinnedVersion,
    };
}
