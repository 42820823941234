/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefVariableFromJSON, HashicorpCloudWaypointRefVariableToJSON, HashicorpCloudWaypointVariableFromJSON, HashicorpCloudWaypointVariableToJSON, TheNamespaceTheActionToBeStoredInFromJSON, TheNamespaceTheActionToBeStoredInToJSON, } from './';
export function HashicorpCloudWaypointV20241122WaypointServiceUpdateVariableBodyFromJSON(json) {
    return HashicorpCloudWaypointV20241122WaypointServiceUpdateVariableBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122WaypointServiceUpdateVariableBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : TheNamespaceTheActionToBeStoredInFromJSON(json['namespace']),
        'ref': !exists(json, 'ref') ? undefined : HashicorpCloudWaypointRefVariableFromJSON(json['ref']),
        'variable': !exists(json, 'variable') ? undefined : HashicorpCloudWaypointVariableFromJSON(json['variable']),
        'updateMask': !exists(json, 'update_mask') ? undefined : json['update_mask'],
    };
}
export function HashicorpCloudWaypointV20241122WaypointServiceUpdateVariableBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': TheNamespaceTheActionToBeStoredInToJSON(value.namespace),
        'ref': HashicorpCloudWaypointRefVariableToJSON(value.ref),
        'variable': HashicorpCloudWaypointVariableToJSON(value.variable),
        'update_mask': value.updateMask,
    };
}
