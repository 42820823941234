import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { task, timeout } from 'ember-concurrency';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';
// @ts-expect-error: missing types
import CopyButton from 'ember-cli-clipboard/components/copy-button';

import {
  COPY_ICON_NAME,
  COPY_ICON_CLASS,
  COPIED_ICON_NAME,
  COPIED_ICON_CLASS,
} from '../utils/consts/code-block.js';
import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

interface CodeBlockSignature {
  Args: {
    disabled: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `disabled` is optional and if true, it does not render the copy button
 * @argument disabled;
 * @type {Boolean}
 *
 */

/**
 *
 * `CodeBlock` wraps code or terminal examples with the proper styling.
 *
 *
 * ```
 * <CodeBlock @disabled={{true}}>rm -rf .</CodeBlock>
 * ```
 *
 * @class CodeBlock
 *
 */
export default class CodeBlock extends Component<CodeBlockSignature> {
  @tracked codeBlockId = guidFor(this);
  @tracked copyIconName: HdsIconSignature['Args']['name'] = COPY_ICON_NAME;
  @tracked copyIconClass = COPY_ICON_CLASS;

  copied = task(async () => {
    const originalIconName = this.copyIconName;
    const originalIconClass = this.copyIconClass;

    this.copyIconName = COPIED_ICON_NAME;
    this.copyIconClass = COPIED_ICON_CLASS;

    await timeout(1000);

    this.copyIconName = originalIconName;
    this.copyIconClass = originalIconClass;
  });

  <template>
    <div class="code-block" ...attributes data-test-code-block-container>
      <pre><code id="code-block-{{this.codeBlockId}}">{{~yield~}}</code></pre>
      {{#unless @disabled}}
        <CopyButton
          data-test-code-block-copy-button
          @target="#code-block-{{this.codeBlockId}}"
          @onSuccess={{this.copied.perform}}
          aria-label={{t "components.code-block.copy-text"}}
        >
          <HdsIcon
            class={{this.copyIconClass}}
            @name={{this.copyIconName}}
            @size="16"
          />
        </CopyButton>
      {{/unless}}
    </div>
  </template>
}
