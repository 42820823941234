import AccountStatus from './account-status.js';
import BillingCredits from './billing-credits.js';
import Flex from './flex.js';
import OnDemand from './on-demand.js';
import { getTimeSince, sevenDays } from '../helpers/time.js';
import { PRICING_MODEL } from '../constants/billing.js';

export default class Billing {
  #actions;
  #billingAccount;
  #onDemandBillingMethodDetails;
  #entitlementBillingMethodDetails;
  #flexibleConsumptionBillingMethodDetails;
  #fcp;
  #transitions;

  /**
   * @param {object} [seed={}]
   * @param {object} [seed.actions]
   * @param {object} [seed.billingAccount]
   * @param {string} [seed.billingAccount.id]
   * @param {object} [seed.billingAccount.status]
   * @param {boolean} [seed.billingAccount.status.isTrial]
   * @param {object} [seed.onDemandBillingMethodDetails]
   * @param {object} [seed.entitlementBillingMethodDetails]
   * @param {object} [seed.flexibleConsumptionBillingMethodDetails]
   * @param {object} [seed.fcp]
   * @param {object} [seed.transitions]
   */
  constructor({
    actions = null,
    billingAccount = null,
    onDemandBillingMethodDetails = null,
    entitlementBillingMethodDetails = null,
    flexibleConsumptionBillingMethodDetails = null,
    fcp = null,
    transitions = null,
  } = {}) {
    this.#actions = actions;
    this.#billingAccount = billingAccount;
    this.#onDemandBillingMethodDetails = onDemandBillingMethodDetails;
    this.#entitlementBillingMethodDetails = entitlementBillingMethodDetails;
    this.#flexibleConsumptionBillingMethodDetails =
      flexibleConsumptionBillingMethodDetails;
    this.#fcp = fcp;
    this.#transitions = transitions;
  }

  get actions() {
    return this.#actions;
  }

  get billingAccount() {
    return this.#billingAccount;
  }

  //#region allowed actions getters

  // These getters are used by service producers
  // to determine which actions related to resources
  // a billing account is allowed to perform.

  /** @type {boolean} */
  get canCreateResource() {
    return this.actions?.includes('ACTION_CREATE');
  }

  /** @type {boolean} */
  get canUseResource() {
    return this.actions?.includes('ACTION_USE');
  }
  //#endregion

  /** @type {AccountStatus} */
  get status() {
    return new AccountStatus(this.#billingAccount?.status);
  }

  /** @type {BillingCredits} */
  get credits() {
    // estimatedRemainingCredits is an empty string if not OnDemand or Trial account
    return this.billingAccount?.estimatedRemainingCredits
      ? new BillingCredits(
          this.billingAccount?.estimatedRemainingCredits,
          this.billingAccount?.createdAt,
        )
      : null;
  }

  /** @type {OnDemand|null} */
  get onDemand() {
    return (
      new OnDemand(this.billingAccount, this.#onDemandBillingMethodDetails) ||
      null
    );
  }

  //#region pricing model getters

  /** @type {string} */
  get pricingModel() {
    return this.billingAccount?.pricingModel;
  }

  get pricingModelTransitions() {
    return this.#transitions;
  }

  /** @type {boolean} */
  get hasConsumptionPayment() {
    return this.pricingModel === PRICING_MODEL.FLEX;
  }

  // Will return true for both Trial and On Demand accounts
  // To check for On Demand only, use hasOnDemandPayment
  /** @type {boolean} */
  get hasTrialOrOnDemandPayment() {
    return this.pricingModel === PRICING_MODEL.PAYG;
  }

  // Both Trial and On Demand accounts will return PRICING_MODEL_PAYG
  // so we also need to check that the On Demand status is not `UNSET`
  // to confirm that it is not a Trial account
  /** @type {boolean} */
  get hasOnDemandPayment() {
    return (
      this.pricingModel === PRICING_MODEL.PAYG &&
      !this.onDemand?.status.isOnDemandUnset
    );
  }

  get recentDowngradeFlexToPAYG() {
    let length = this.pricingModelTransitions
      ? this.pricingModelTransitions.length
      : 0;

    // Must be at least two pricing model transitions for a downgrade to have occurred
    if (length <= 1) {
      return false;
    }

    let timeSinceTransition = getTimeSince(
      this.pricingModelTransitions[length - 2].end,
    ).milliseconds;

    return (
      this.pricingModelTransitions[length - 2].pricingModel ===
        PRICING_MODEL.FLEX &&
      this.pricingModelTransitions[length - 1].pricingModel ===
        PRICING_MODEL.PAYG &&
      timeSinceTransition <= sevenDays
    );
  }

  // Contract here refers to an Entitlement contract
  /** @type {boolean} */
  get hasContractPayment() {
    return this.pricingModel === PRICING_MODEL.ENTITLEMENT;
  }
  //#endregion

  // We can return the entitlement contract's details from the
  // entitlementBillingMethodDetails for now, but will need to
  // consider the concept of latestEntitlementContract,
  // expired, future, etc. once the BE infrastructure supports this
  /** @type {object|null} */
  get entitlementContractDetails() {
    return this.hasContractPayment
      ? this.#entitlementBillingMethodDetails?.billingMethod
      : null;
  }
  //#endregion

  /** @type {Flex|null} */
  get flex() {
    return (
      new Flex(
        this.#fcp,
        this.#flexibleConsumptionBillingMethodDetails,
        this.hasConsumptionPayment,
      ) || null
    );
  }
}
