import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { and, notEq, or } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import {
  HdsButton,
  HdsButtonSet,
  HdsModal,
  HdsFormTextInputField,
} from '@hashicorp/design-system-components/components';

import WithErrors from '../../with-errors.gts';
import {
  FieldViolation,
  FormValidationError,
} from '../../../utils/form-validation.ts';

import type Owner from '@ember/owner';
import type { IntlService } from 'ember-intl';
import type { SafeString } from '@ember/template';
import type { TaskForAsyncTaskFunction } from 'ember-concurrency';
import type { HdsModalSignature } from '@hashicorp/design-system-components/components/hds/modal/index';
import type { HdsDialogPrimitiveHeaderSignature } from '@hashicorp/design-system-components/components/hds/dialog-primitive/header';

class ModalConfirmFormState {
  @tracked confirmText = '';

  constructor(confirmText?: string) {
    if (confirmText) {
      this.confirmText = confirmText;
    }
  }
}

type onSubmitTask = TaskForAsyncTaskFunction<unknown, () => Promise<void>>;

const DEFAULT_MODAL_COLOR = 'critical';
const DEFAULT_CONFIRM_BUTTON_TEXT = 'Delete';

interface CoreModalConfirmSignature {
  Args: {
    confirmButtonColor?: string;
    confirmButtonText?: string;
    confirmHelp?: string;
    confirmLabel?: string;
    confirmText: string;
    headerColor?: HdsModalSignature['Args']['color'];
    headerIcon?: HdsDialogPrimitiveHeaderSignature['Args']['icon'];
    headerTagline?: string;
    headerText: string | SafeString;
    onCancel: () => void;
    onSubmit: onSubmitTask;
  };
  Blocks: {
    body: [];
  };
}

export default class CoreModalConfirm extends Component<CoreModalConfirmSignature> {
  /**
   * String that user must type in modal input in order to successfully validate and submit form
   * @argument confirmText
   * @type {String}
   * @required
   */

  /**
   * Passed in callback function to cancel / close modal
   * @argument onCancel
   * @type {Function}
   * @required
   */

  /**
   * Passed in as async/generator Ember concurrency task with a perform callback
   * function to invoke upon successful form validation & submission
   * @argument onSubmit
   * @type {Function}
   * @required
   */

  /**
   * Optional argument to update the modal input's help text
   * Default is "To confirm, type {confirmText} below"
   * @argument confirmHelp
   * @type {String}
   */

  /**
   * Optional argument to update the modal input's label. Default is "Confirm"
   * @argument confirmLabel
   * @type {String}
   */

  /**
   * Optional argument to change the confirm button color
   * Default is "critical"
   * Additional options: primary, secondary
   * @argument confirmButtonColor
   * @type {String}
   */

  /**
   * Optional argument to change the confirm button text
   * Default is "Delete"
   * @argument confirmButtonText
   * @type {String}
   */

  /**
   * Optional argument to change the modal header color
   * Available options: neutral, warning, critical (default)
   * @argument headerColor
   * @type {String}
   */

  /**
   * Optional argument to include an icon in the modal header
   * @argument headerIcon
   * @type {String}
   */

  /**
   * Required argument to populate the modal header text
   * @argument headerText
   * @type {String}
   * @required
   */

  @service declare readonly intl: IntlService;
  @tracked formState: ModalConfirmFormState | null = null;

  constructor(owner: Owner, args: CoreModalConfirmSignature['Args']) {
    super(owner, args);

    assert(
      '<Core::Modal::Confirm> required argument @onCancel is a defined function',
      typeof this.args.onCancel === 'function'
    );

    assert(
      '<Core::Modal::Confirm> argument @onSubmit is a required Ember concurrency task with a .perform callback function',
      typeof this.args.onSubmit?.perform === 'function'
    );

    assert(
      '<Core::Modal::Confirm> required argument @confirmText is a defined string',
      typeof this.args.confirmText === 'string'
    );

    assert(
      '<Core::Modal::Confirm> required argument @headerText is a defined string',
      // in cases where htmlSafe=true is passed in as part of the headerText argument,
      // it is converted to a SafeString and made an object, hence the extra assertion
      typeof this.args.headerText === 'string' ||
        this.args.headerText.constructor.name === 'SafeString'
    );

    this.formState = new ModalConfirmFormState();
  }

  get headerColor(): HdsModalSignature['Args']['color'] {
    return this.args.headerColor || DEFAULT_MODAL_COLOR;
  }

  get headerIcon() {
    return this.args.headerIcon || undefined;
  }

  get headerTagline() {
    return this.args.headerTagline || undefined;
  }

  get confirmButtonColor() {
    return this.args.confirmButtonColor || DEFAULT_MODAL_COLOR;
  }

  get confirmButtonText() {
    return this.args.confirmButtonText || DEFAULT_CONFIRM_BUTTON_TEXT;
  }

  updateConfirmText = (evt: Event & { target: HTMLInputElement }) => {
    this.formState = new ModalConfirmFormState(evt.target.value);
  };

  submitForm = task(async (evt: SubmitEvent) => {
    evt.preventDefault();
    this.validateForm();
    await this.args.onSubmit?.perform?.();
  });

  cancelForm = () => {
    this.resetFormState();
    this.args.onCancel();
  };

  validateForm = () => {
    const validationError = new FormValidationError(
      this.intl.t('components.core.modal.confirm.invalid-submission', {
        confirmText: this.args.confirmText,
      })
    );
    if (this.formState?.confirmText === '') {
      validationError.details.push(
        new FieldViolation(
          this.intl.t('components.core.modal.confirm.invalid-submission', {
            confirmText: this.args.confirmText,
          }),
          'confirm-input'
        )
      );
    } else if (this.formState?.confirmText !== this.args.confirmText) {
      validationError.details.push(
        new FieldViolation(
          this.intl.t('components.core.modal.confirm.invalid-input', {
            confirmText: this.args.confirmText,
          }),
          'confirm-input'
        )
      );
    }
    if (validationError.details.length) {
      throw validationError;
    }
  };

  resetFormState = () => {
    this.formState = new ModalConfirmFormState();
  };

  <template>
    {{#let
      (notEq this.formState.confirmText @confirmText)
      as |isInvalidSubmission|
    }}
      <HdsModal
        @color={{this.headerColor}}
        @onClose={{this.cancelForm}}
        data-test-core-modal-confirm
        as |M|
      >
        <M.Header
          @icon={{this.headerIcon}}
          @tagline={{this.headerTagline}}
          data-test-core-modal-confirm-header
        >
          {{@headerText}}
        </M.Header>

        <M.Body data-test-core-modal-confirm-body>
          {{#if (has-block "body")}}
            {{yield to="body"}}
          {{/if}}

          <WithErrors
            {{! @glint-expect-error }}
            @error={{this.submitForm.last.error}}
            as |FormError FieldError|
          >
            <FormError />

            <form
              id="modal-confirm"
              novalidate
              {{on "submit" this.submitForm.perform}}
            >
              <FieldError
                @field="confirm-input"
                @hdsDelegatedError={{true}}
                as |err|
              >
                <HdsFormTextInputField
                  {{! @glint-expect-error }}
                  @isInvalid={{and err isInvalidSubmission}}
                  @isRequired={{true}}
                  @type="text"
                  @value={{this.formState.confirmText}}
                  id="confirm"
                  placeholder={{@confirmText}}
                  autocomplete="off"
                  name="confirm"
                  {{! @glint-expect-error }}
                  {{on "input" this.updateConfirmText}}
                  data-test-core-modal-confirm-input
                  as |F|
                >
                  <F.Label data-test-core-modal-confirm-label>
                    {{or
                      @confirmLabel
                      (t "components.core.modal.confirm.label")
                    }}
                  </F.Label>

                  <F.HelperText data-test-core-modal-confirm-helper-text>
                    {{or
                      @confirmHelp
                      (t
                        "components.core.modal.confirm.help"
                        confirmText=@confirmText
                      )
                    }}
                  </F.HelperText>

                  {{#if (and err isInvalidSubmission)}}
                    <F.Error as |E|>
                      <E.Message data-test-core-modal-confirm-error>
                        {{err.description}}
                      </E.Message>
                    </F.Error>
                  {{/if}}
                </HdsFormTextInputField>
              </FieldError>
            </form>
          </WithErrors>
        </M.Body>

        <M.Footer>
          <HdsButtonSet>
            <HdsButton
              {{! @glint-expect-error }}
              @color={{this.confirmButtonColor}}
              @text={{this.confirmButtonText}}
              disabled={{@onSubmit.isRunning}}
              form="modal-confirm"
              type="submit"
              data-test-core-modal-confirm-submit
            />

            <HdsButton
              @color="secondary"
              @text={{t "components.form.cancel"}}
              disabled={{@onSubmit.isRunning}}
              {{on "click" this.cancelForm}}
              data-test-core-modal-confirm-cancel
            />
          </HdsButtonSet>
        </M.Footer>
      </HdsModal>
    {{/let}}
  </template>
}
