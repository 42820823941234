import Component from '@glimmer/component';
import { HdsAlert } from '@hashicorp/design-system-components/components';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';

import boxPadding from '../../modifiers/box-padding.ts';

import type { Error } from '../../utils/consts/with-errors.ts';
import type { FieldViolation } from '../../utils/consts/with-errors.ts';

interface WithErrorsFormErrorSignature {
  Args: {
    error?: Error;
    shouldRenderPageError?: boolean;
    unclaimedFieldErrors?: FieldViolation[];
  };
  Blocks: {
    default?: [];
  };
  Element: HTMLElement;
}

export default class WithErrorsFormError extends Component<WithErrorsFormErrorSignature> {
  /**
   * The error object from `WithErrors` parent component.
   * @argument error
   * @type {string}
   *
   */

  /**
   * An array of errors that other instances of WithErrors::FieldError have not used as their `@field` value
   * @argument unclaimedFieldErrors
   * @type {array}
   *
   */

  get shouldRenderPageError() {
    const { shouldRenderPageError } = this.args;

    if (shouldRenderPageError && typeof shouldRenderPageError === 'boolean') {
      return shouldRenderPageError;
    }

    return (
      this.args.unclaimedFieldErrors?.length ||
      this.violations?.length ||
      (this.args.error && !this.args.error.details) ||
      this.args.error?.details?.length === 0
    );
  }

  // an array extracted from rolling up all error.details[].violations[]
  get violations() {
    return this.args.error?.details
      ?.flatMap((d) => d.violations)
      .filter(Boolean);
  }

  <template>
    {{#if this.shouldRenderPageError}}
      <HdsAlert
        @type="inline"
        @color="critical"
        data-test-with-errors-form-error
        ...attributes
        as |A|
      >
        <A.Title>
          {{or @error.title (t "components.form.page-error-title")}}
        </A.Title>
        <A.Description>
          {{@error.message}}
          {{#if (or this.violations.length @unclaimedFieldErrors.length)}}
            <ul {{boxPadding "0 0 0 sm"}}>
              {{#each this.violations as |violation|}}
                <li>
                  {{violation.description}}
                </li>
              {{/each}}
              {{#each @unclaimedFieldErrors as |err|}}
                <li>
                  <b>
                    {{err.field}}:
                  </b>
                  {{err.description}}
                </li>
              {{/each}}
            </ul>
          {{/if}}
        </A.Description>
      </HdsAlert>
    {{/if}}
  </template>
}
