import Component from '@glimmer/component';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import Flex from '../../../flex.gts';
import { AlignItem, FlexDirection, FlexGap } from '../../../../utils/flex.ts';

import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

export interface CoreCardSummaryKeySignature {
  Args: {
    contextualClass?: string;
    icon?: HdsIconSignature['Args']['name'];
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `CoreCardSummaryKeyComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryKeyComponent
 *
 */

export default class CoreCardSummaryKey extends Component<CoreCardSummaryKeySignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryKeyComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const { contextualClass } = this.args;

    const classes = [
      'core-card-summary-key',
      'hds-typography-body-100',
      'hds-font-weight-semibold',
      'hds-foreground-strong',
    ];

    if (contextualClass) {
      classes.push(contextualClass);
    }

    return classes.join(' ');
  }

  <template>
    <dt
      class={{this.classNames}}
      data-test-core-card-key-container
      ...attributes
    >
      <Flex
        @alignItems={{AlignItem.Center}}
        @direction={{FlexDirection.Row}}
        @gap={{FlexGap.Xxs}}
      >
        <div
          class="core-card-summary__icon-container"
          data-test-core-card-key-icon
        >
          {{#if @icon}}
            <HdsIcon @name={{@icon}} />
          {{/if}}
        </div>

        <div data-test-core-card-key-yield>
          {{yield}}
        </div>
      </Flex>
    </dt>
  </template>
}
