import Service, { service } from '@ember/service';
import { TFC_GEO_REGIONS } from '../utils/constants.ts';

import type { GeoRegion } from '../utils/v2-resource';

import {
  TYPE_TERRAFORM_ORGANIZATION,
  TYPE_PROJECT,
  TYPE_TERRAFORM_WORKSPACE,
} from 'common/utils/cloud-resource-types';

enum ResourceType {
  TerraformOrganization = `${TYPE_TERRAFORM_ORGANIZATION}`,
  TerraformProject = `${TYPE_PROJECT}`,
  TerraformWorkspace = `${TYPE_TERRAFORM_WORKSPACE}`,
}

interface resourceIdentifierMap {
  terraformOrganizationName: string;
  projectId: string;
  resourceId: string;
}

interface Deployment {
  geo: GeoRegion;
  url: string;
}

interface Config {
  deployments: Deployment[];
}

interface TFCConfig {
  integration: Config;
  production: Config;
}

interface DeploymentOptions {
  geo: GeoRegion;
}

interface ResourceOptions {
  url: string;
  resourceType: string;
  resourceIdentifiers: resourceIdentifierMap;
}

const { REGION_US, REGION_EU } = TFC_GEO_REGIONS;

export const TFC_CONFIG: TFCConfig = {
  integration: {
    deployments: [
      {
        geo: REGION_US,
        url: 'http://app.staging.terraform.io/',
      },
      {
        geo: REGION_EU,
        url: 'http://app.staging.eu.terraform.io/',
      },
    ],
  },
  production: {
    deployments: [
      {
        geo: REGION_US,
        url: 'https://app.terraform.io/',
      },
      {
        geo: REGION_EU,
        url: 'https://app.eu.terraform.io/',
      },
    ],
  },
};

export default class TerraformUnification extends Service {
  @service declare readonly config: { app: { deployTarget?: string } };

  get tfcConfig(): Config {
    const target = (this.config.app?.deployTarget ??
      'production') as keyof TFCConfig;
    return TFC_CONFIG[target] ?? TFC_CONFIG['production'];
  }

  buildUrl({ url }: { url: string }) {
    const baseUrl = new URL(url);
    const queryParams = new URLSearchParams();

    baseUrl.pathname = `/`;
    baseUrl.search = queryParams.toString();
    return baseUrl.toString();
  }

  appendResourceRouteToUrl({
    url,
    resourceType,
    resourceIdentifiers,
  }: ResourceOptions) {
    const baseUrl = new URL(url);
    const { terraformOrganizationName, projectId, resourceId } =
      resourceIdentifiers;
    switch (resourceType) {
      case ResourceType.TerraformOrganization:
        baseUrl.pathname = `/app/${terraformOrganizationName}`;
        break;
      case ResourceType.TerraformProject:
        baseUrl.pathname = `app/${terraformOrganizationName}/projects/${projectId}`;
        break;
      case ResourceType.TerraformWorkspace:
        baseUrl.pathname = `app/${terraformOrganizationName}/projects/${projectId}/workspaces/${resourceId}`;
        break;
      default:
        break;
    }
    return baseUrl.toString();
  }

  getDeploymentByGeo({ geo = REGION_US }) {
    return this.tfcConfig.deployments.find((deployment) => {
      return deployment.geo === geo;
    });
  }

  getDeploymentUrl({ geo = REGION_US }: DeploymentOptions) {
    const deployment = this.getDeploymentByGeo({ geo });

    if (!deployment) {
      return;
    }

    return this.buildUrl({
      url: deployment.url,
    });
  }

  getResourceUrl({ url, resourceType, resourceIdentifiers }: ResourceOptions) {
    return this.appendResourceRouteToUrl({
      url,
      resourceType,
      resourceIdentifiers,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    terraformUnification: TerraformUnification;
  }
}
