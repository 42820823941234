/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointAgentGroupFromJSON, HashicorpCloudWaypointAgentGroupToJSON, } from './';
export function HashicorpCloudWaypointGetAgentGroupResponseFromJSON(json) {
    return HashicorpCloudWaypointGetAgentGroupResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointGetAgentGroupResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'group': !exists(json, 'group') ? undefined : HashicorpCloudWaypointAgentGroupFromJSON(json['group']),
    };
}
export function HashicorpCloudWaypointGetAgentGroupResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'group': HashicorpCloudWaypointAgentGroupToJSON(value.group),
    };
}
