import Component from '@glimmer/component';
import { service } from '@ember/service';
import { and, or } from 'ember-truth-helpers';
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import { hash } from '@ember/helper';
import { variation } from 'ember-launch-darkly';
import can from 'ember-can/helpers/can';

import type UserContextService from '../../services/user-context.ts';
import type { ComponentLike } from '@glint/template';
import type { HdsSideNavListLinkSignature } from '@hashicorp/design-system-components/components/hds/side-nav/list/link';

interface CoreProjectLinksSignature {
  Args: {
    component: ComponentLike<{
      Element: HdsSideNavListLinkSignature['Element'];
      Args: HdsSideNavListLinkSignature['Args'];
    }>;
  };
}

export default class CoreProjectLinks extends Component<CoreProjectLinksSignature> {
  /**
   * This is a component to use to render the links. The component *must* take
   * the same arguments that align with Hds components like the `dd.Interactive`
   * or `Hds::Link::*` components. This argument is not a string. Additionally,
   * this argument cannot be retrieved and returned via a getter so validation
   * is not possible with this approach.
   * @argument component
   * @type {Component}
   * @required
   */

  @service declare readonly userContext: UserContextService;

  get organization() {
    return this.userContext.organization;
  }

  get project() {
    return this.userContext.project;
  }

  <template>
    {{#if (and this.organization this.project)}}
      {{#if (can "list resources")}}
        <@component
          @icon="cloud"
          @models={{array this.organization.id this.project.id}}
          @route="cloud.orgs.detail.projects.detail.active-resources"
          @text={{t "nav.project.active-resources"}}
          data-test-project-manage-link="active-resources"
        />
      {{/if}}
      {{#if (variation "hcp-ui-monitoring-org-and-project-platform-logs")}}
        <@component
          @icon="logs"
          @models={{array this.organization.id this.project.id}}
          @route="cloud.orgs.detail.projects.detail.monitoring.platform-logs.resource"
          @text={{t "nav.project.logs"}}
          data-test-project-manage-link="project-logs"
        />
      {{/if}}
      {{#if (can "list networks")}}
        <@component
          @icon="network-alt"
          @models={{array this.organization.id this.project.id}}
          @route="cloud.orgs.detail.projects.detail.hvns"
          @text={{t "nav.project.virtual-networks"}}
          data-test-project-manage-link="hvns"
        />
      {{/if}}
      {{#if (variation "hcp-ui-role-assignments-engine-projects")}}
        {{#if
          (or
            (can "view role assignments in project")
            (and (can "get service-principal") (can "list service-principal"))
          )
        }}
          <@component
            @icon="users"
            @query={{hash
              org_id=this.organization.id
              project_id=this.project.id
            }}
            @route="cloud.access-control"
            @text={{t "nav.project.access-control"}}
            data-test-project-manage-link="access-control"
          />
        {{/if}}
      {{else}}
        {{#if (can "list user")}}
          <@component
            @icon="users"
            @query={{hash
              org_id=this.organization.id
              project_id=this.project.id
            }}
            @route="cloud.access-control"
            @text={{t "nav.project.access-control"}}
            data-test-project-manage-link="access-control"
          />
        {{/if}}
      {{/if}}

      <@component
        @icon="settings"
        @models={{array this.organization.id this.project.id}}
        @route="cloud.orgs.detail.projects.detail.settings.general"
        @text={{t "nav.project.settings"}}
        data-test-project-manage-link="settings"
      />
    {{/if}}
  </template>
}
