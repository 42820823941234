import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import type FlashMessageService from 'ember-cli-flash/services/flash-messages';

interface SystemNotificationsContractExpirationSignature {
  Args: {
    flash: {
      id: string;
      message: string;
      content: string;
      color: string;
      context: {
        expirationDate: string;
        resourceDeletionDate: string;
      };
    };
  };
}

export default class SystemNotificationsContractExpiration extends Component<SystemNotificationsContractExpirationSignature> {
  @service declare readonly flashMessages: FlashMessageService;

  <template>
    <HdsAlert
      @type="page"
      {{! @glint-expect-error }}
      @color={{@flash.color}}
      data-test-system-notification={{@flash.id}}
      as |A|
    >
      <A.Title>
        {{t @flash.message}}
      </A.Title>
      <A.Description>
        {{t
          @flash.content
          expirationDate=@flash.context.expirationDate
          htmlSafe=true
        }}
      </A.Description>
      <A.LinkStandalone
        @href={{t "billing.common.contact-support.link-href"}}
        @text={{t "billing.common.contact-support.link-text"}}
        @iconPosition="trailing"
        @icon="external-link"
        @size="medium"
      />
    </HdsAlert>
  </template>
}
