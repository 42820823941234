import type { TOC } from '@ember/component/template-only';

export interface ListContentsGenericSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLLIElement;
}

const ListContentsGeneric: TOC<ListContentsGenericSignature> = <template>
  <li class="cut-list-item--generic">
    {{yield}}
  </li>
</template>;

export default ListContentsGeneric;
