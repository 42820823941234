import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type { HdsAlertSignature } from '@hashicorp/design-system-components/components/hds/alert/index';

interface SystemNotificationsFcpBalanceSignature {
  Args: {
    flash: {
      id: string;
      message: string;
      content: string;
      context: {
        percentage: number;
      };
      actionText: string;
      color: HdsAlertSignature['Args']['color'];
    };
  };
}

export default class SystemNotificationsFcpBalance extends Component<SystemNotificationsFcpBalanceSignature> {
  @service declare readonly flashMessages: FlashMessageService;

  <template>
    <HdsAlert
      @type="page"
      @color={{@flash.color}}
      data-test-system-notification={{@flash.id}}
      as |A|
    >
      <A.Title>
        {{t @flash.message}}
      </A.Title>
      <A.Description>
        {{t @flash.content percentage=@flash.context.percentage htmlSafe=true}}
      </A.Description>
      {{#if @flash.actionText}}
        <A.LinkStandalone
          @href={{t "billing.common.contact-support.link-href"}}
          @text={{t "billing.common.contact-support.link-text"}}
          @iconPosition="trailing"
          @icon="external-link"
          @size="medium"
        />
      {{/if}}
    </HdsAlert>
  </template>
}
