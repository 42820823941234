import Component from '@glimmer/component';

interface MenuTriggerSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class MenuTrigger extends Component<MenuTriggerSignature> {
  <template>
    <summary class="menuTrigger" ...attributes data-test-menu-summary>
      <div>
        {{yield}}
      </div>
    </summary>
  </template>
}
