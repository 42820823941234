import Component from '@glimmer/component';
import { HdsTable } from '@hashicorp/design-system-components/components';
import { get } from '@ember/object';

interface Column {
  key: string;
  header: string;
}

interface DataRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface FilterableTableSignature {
  Args: {
    columns: Column[];
    data: DataRecord[];
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class FilterableTable extends Component<FilterableTableSignature> {
  get columns() {
    return this.args.columns ?? [];
  }

  get data() {
    return this.args.data ?? [];
  }

  <template>
    <HdsTable @model={{this.data}} @caption="Table" data-test-filterable-table>
      <:head as |H|>
        <H.Tr>
          {{#each this.columns as |col|}}
            <H.Th>{{col.header}}</H.Th>
          {{/each}}
        </H.Tr>
      </:head>
      <:body as |B|>
        {{#each this.data as |record|}}
          <B.Tr>
            {{#each this.columns as |col|}}
              <B.Td>{{get record col.key}}</B.Td>
            {{/each}}
          </B.Tr>
        {{/each}}
      </:body>
    </HdsTable>
  </template>
}
