/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseInstallationFromJSON, HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseInstallationToJSON, } from './';
export function HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseFromJSON(json) {
    return HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'installations': !exists(json, 'installations') ? undefined : (json['installations'].map(HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseInstallationFromJSON)),
    };
}
export function HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'installations': value.installations === undefined ? undefined : (value.installations.map(HashicorpCloudWaypointV20241122GetGitHubInstallationsResponseInstallationToJSON)),
    };
}
