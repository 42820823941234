/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { LinkTo } from '@ember/routing';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { HdsIcon } from '@hashicorp/design-system-components/components';
import BasicDropdown from 'ember-basic-dropdown/components/basic-dropdown';

import classNames from '../helpers/class-names.ts';

import type { HashicorpCloudResourcemanagerOrganization } from '@clients/cloud-resource-manager';

interface OrganizationPickerSignature {
  Args: {
    currentOrg: HashicorpCloudResourcemanagerOrganization;
    organizations: HashicorpCloudResourcemanagerOrganization[];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `OrganizationPicker` renders a dropdown that allows users to swith
 *  Organization contexts in the HCP navigation bar. This component replaces the
 * `OrgPicker` component.
 *
 *  Compared to `OrgPicker`, `OrganizationPicker` uses BasicDropdown. The
 *  dropdown content opens above the trigger, the content is styled using HDS
 *  classes and is set to the default light-theme set by the HDS dropdown
 *  classes. Ideally the dependency on BasicDropdown will be dropped when HDS
 *  allows for dropdowns that have a configurable dropdown that can open upward.
 *
 *
 * ```
 * <OrganizationPicker
 *  @currentOrg={{this.currentOrg}}
 *  @organization={{this.organizations}}
 * />
 * ```
 *
 * @class OrganizationPicker
 *
 */

export default class OrganizationPicker extends Component<OrganizationPickerSignature> {
  get currentOrg() {
    return this.args.currentOrg;
  }

  get organizations() {
    return this.args.organizations;
  }

  closeDropdown = (
    dropdown: { actions: { close: () => void } },
    event: {
      currentTarget: { contains: (arg0: any) => any };
      relatedTarget: any;
    }
  ) => {
    // only close if we're not focused on something inside the dropdown and if
    // the component is not destroyed.
    if (
      !event.currentTarget.contains(
        event.relatedTarget || document.activeElement
      ) &&
      (this.isDestroying || this.isDestroyed)
    ) {
      dropdown.actions.close();
    }
  };

  <template>
    <div class="organization-picker" ...attributes>
      <BasicDropdown
        @verticalPosition="above"
        @renderInPlace={{true}}
        @matchTriggerWidth={{true}}
        data-test-organization-picker
        as |dd|
      >
        <dd.Trigger
          data-test-dropdown-trigger
          class="organization-picker-button"
          aria-label={{t "components.organization-picker.select"}}
        >
          <HdsIcon
            @color="var(--token-color-palette-neutral-300)"
            @name="org"
            class="trigger-org-icon"
            data-test-org-icon
          />
          <span class="trigger-text">
            {{#if this.currentOrg}}
              {{this.currentOrg.name}}
            {{else}}
              {{t "components.organization-picker.select"}}
            {{/if}}
          </span>
          <HdsIcon
            @color="var(--token-color-palette-neutral-300)"
            @name="caret"
            class="trigger-chevron-icon"
            data-test-trigger-icon
          />
        </dd.Trigger>
        <dd.Content
          @animationEnabled={{true}}
          data-test-dropdown-content
          class="organization-picker-content"
          {{! @glint-expect-error }}
          {{on "focusout" (fn this.closeDropdown dd)}}
        >
          <div class="hds-dropdown__content">
            <ul class="hds-dropdown__list">
              {{#each this.organizations as |org|}}
                <li
                  class="hds-dropdown-list-item hds-dropdown-list-item--variant-interactive hds-dropdown-list-item--color-action"
                  data-test-org-link-item
                >
                  <LinkTo
                    @route="cloud.orgs.detail.index"
                    @model={{org.id}}
                    class={{classNames
                      "hds-dropdown-list-item__interactive-text"
                      "hds-typography-body-200"
                      "hds-font-weight-medium"
                    }}
                    data-test-org-link-anchor
                  >
                    <span class="org-name">
                      {{org.name}}
                    </span>
                    {{#if (eq org.id this.currentOrg.id)}}
                      <HdsIcon
                        class="org-selected"
                        @name="check"
                        @color="var(--token-color-foreground-action)"
                      />
                    {{/if}}
                  </LinkTo>
                </li>
              {{/each}}
              {{#if this.organizations}}
                <li
                  class="hds-dropdown-list-item--variant-separator"
                  role="separator"
                ></li>
              {{/if}}

              <li
                class="hds-dropdown-list-item hds-dropdown-list-item--variant-interactive hds-dropdown-list-item--color-action"
              >
                <LinkTo @route="cloud.orgs.list" data-test-org-list-link>
                  <div
                    class="hds-dropdown-list-item__interactive-icon hds-dropdown-list-item__interactive-icon--leading"
                  >
                    <HdsIcon @name="menu" />
                  </div>
                  <div
                    class="hds-dropdown-list-item__interactive-text hds-typography-body-200 hds-font-weight-medium"
                  >
                    {{t "components.page.organizations.list.title"}}
                  </div>
                </LinkTo>
              </li>

            </ul>
          </div>
        </dd.Content>
      </BasicDropdown>
    </div>
  </template>
}
