import Component from '@glimmer/component';

interface StepperStepContentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class StepperStepContent extends Component<StepperStepContentSignature> {
  <template>
    <div
      class="stepper_stepContentBlock"
      ...attributes
      data-test-stepper-step-content-block
    >
      {{yield}}
    </div>
  </template>
}
