import Component from '@glimmer/component';
import {
  HdsCardContainer,
  HdsCardLevelValues,
} from '@hashicorp/design-system-components/components';

import boxPadding from '../../../modifiers/box-padding.ts';

interface DetailSectionZeroStateSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class DetailSectionZeroState extends Component<DetailSectionZeroStateSignature> {
  <template>
    <HdsCardContainer
      ...attributes
      data-test-detail-section-zero-state-container
      @level={{HdsCardLevelValues.Mid}}
      @hasBorder={{true}}
      class="detailSection__zeroState text--muted"
      {{boxPadding "lg"}}
    >
      {{yield}}
    </HdsCardContainer>
  </template>
}
