/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamPrincipalTypeFromJSON, HashicorpCloudIamPrincipalTypeToJSON, HashicorpCloudIamUserPrincipalIdentityTypeFromJSON, HashicorpCloudIamUserPrincipalIdentityTypeToJSON, } from './';
export function HashicorpCloudIamSearchPrincipalsResultFromJSON(json) {
    return HashicorpCloudIamSearchPrincipalsResultFromJSONTyped(json, false);
}
export function HashicorpCloudIamSearchPrincipalsResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'principalType': !exists(json, 'principal_type') ? undefined : HashicorpCloudIamPrincipalTypeFromJSON(json['principal_type']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'identityTypes': !exists(json, 'identity_types') ? undefined : (json['identity_types'].map(HashicorpCloudIamUserPrincipalIdentityTypeFromJSON)),
    };
}
export function HashicorpCloudIamSearchPrincipalsResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'principal_type': HashicorpCloudIamPrincipalTypeToJSON(value.principalType),
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'resource_name': value.resourceName,
        'identity_types': value.identityTypes === undefined ? undefined : (value.identityTypes.map(HashicorpCloudIamUserPrincipalIdentityTypeToJSON)),
    };
}
