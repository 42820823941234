/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointV20241122IntegrationGitHubRepoFromJSON, HashicorpCloudWaypointV20241122IntegrationGitHubRepoToJSON, } from './';
export function HashicorpCloudWaypointV20241122IntegrationGitHubFromJSON(json) {
    return HashicorpCloudWaypointV20241122IntegrationGitHubFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122IntegrationGitHubFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'installationId': !exists(json, 'installation_id') ? undefined : json['installation_id'],
        'installationName': !exists(json, 'installation_name') ? undefined : json['installation_name'],
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
        'repos': !exists(json, 'repos') ? undefined : (json['repos'].map(HashicorpCloudWaypointV20241122IntegrationGitHubRepoFromJSON)),
        'allRepos': !exists(json, 'all_repos') ? undefined : json['all_repos'],
    };
}
export function HashicorpCloudWaypointV20241122IntegrationGitHubToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'installation_id': value.installationId,
        'installation_name': value.installationName,
        'session_id': value.sessionId,
        'repos': value.repos === undefined ? undefined : (value.repos.map(HashicorpCloudWaypointV20241122IntegrationGitHubRepoToJSON)),
        'all_repos': value.allRepos,
    };
}
