/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointActionConfigFlavorCustomMethod;
(function (HashicorpCloudWaypointActionConfigFlavorCustomMethod) {
    HashicorpCloudWaypointActionConfigFlavorCustomMethod["GET"] = "GET";
    HashicorpCloudWaypointActionConfigFlavorCustomMethod["POST"] = "POST";
    HashicorpCloudWaypointActionConfigFlavorCustomMethod["PUT"] = "PUT";
    HashicorpCloudWaypointActionConfigFlavorCustomMethod["DELETE"] = "DELETE";
    HashicorpCloudWaypointActionConfigFlavorCustomMethod["PATCH"] = "PATCH";
})(HashicorpCloudWaypointActionConfigFlavorCustomMethod || (HashicorpCloudWaypointActionConfigFlavorCustomMethod = {}));
export function HashicorpCloudWaypointActionConfigFlavorCustomMethodFromJSON(json) {
    return HashicorpCloudWaypointActionConfigFlavorCustomMethodFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionConfigFlavorCustomMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointActionConfigFlavorCustomMethodToJSON(value) {
    return value;
}
