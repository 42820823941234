/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105CountryFromJSON, Billing20201105CountryToJSON, } from './';
export function OrganizationOwnershipBusinessOwnershipFromJSON(json) {
    return OrganizationOwnershipBusinessOwnershipFromJSONTyped(json, false);
}
export function OrganizationOwnershipBusinessOwnershipFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'businessName': !exists(json, 'business_name') ? undefined : json['business_name'],
        'addressLine1': !exists(json, 'address_line1') ? undefined : json['address_line1'],
        'addressLine2': !exists(json, 'address_line2') ? undefined : json['address_line2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'countryCode': !exists(json, 'country_code') ? undefined : Billing20201105CountryFromJSON(json['country_code']),
    };
}
export function OrganizationOwnershipBusinessOwnershipToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'business_name': value.businessName,
        'address_line1': value.addressLine1,
        'address_line2': value.addressLine2,
        'city': value.city,
        'state': value.state,
        'postal_code': value.postalCode,
        'country_code': Billing20201105CountryToJSON(value.countryCode),
    };
}
