import Component from '@glimmer/component';

import Key from './definition-list/key.gts';
import Value from './definition-list/value.gts';
import Divider from './definition-list/divider.gts';
import classNames from '../helpers/class-names.ts';
import { hash } from '@ember/helper';
import type { WithBoundArgs } from '@glint/template';

interface DefinitionListSignature {
  Args: {
    compressed?: boolean;
    dense?: boolean;
    narrow?: boolean;
    vertical?: boolean;
  };
  Blocks: {
    default: [
      {
        Key: WithBoundArgs<typeof Key, never>;
        Value: WithBoundArgs<typeof Value, never>;
        Divider: WithBoundArgs<typeof Divider, never>;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 * `compressed` reduces margin to zero and grid-gap is reduced. This is intended
 *  for use in a modal.
 * @argument compressed
 * @type {boolean}
 */

/**
 * `dense` reduces the row gap.
 * @argument dense
 * @type {boolean}
 */

/**
 * `narrow` reduces the key column to be min-content width.
 * @argument narrow
 * @type {boolean}
 */

/**
 * `vertical` flag stacks the key/values.
 * @argument vertical
 * @type {boolean}
 */

/**
 *
 * `DefinitionList` provides a set of components to construct key/value pairs.
 *
 *
 * ```
 * <DefinitionList as |DL|>
 *  <DL.Key>Term1</DL.Key>
 *  <DL.Value>Value1</DL.Value>
 *  <DL.Divider />
 * </DefinitionList>
 * ```
 *
 * @class DefinitionList
 *
 */
export default class DefinitionList extends Component<DefinitionListSignature> {
  <template>
    <dl
      class={{classNames
        "definition-list"
        (if @compressed "definition-list--compressed")
        (if @dense "definition-list--dense")
        (if @narrow "definition-list--narrow")
        (if @vertical "definition-list--vertical")
      }}
      data-test-definition-list-container
      ...attributes
    >
      {{yield
        (hash
          Key=(component Key)
          Value=(component Value)
          Divider=(component Divider)
        )
      }}
    </dl>
  </template>
}
