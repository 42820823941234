import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';
// @ts-expect-error: missing types
import CopyButton from 'ember-cli-clipboard/components/copy-button';
import {
  COPY_ICON_NAME,
  COPIED_ICON_NAME,
} from '../../utils/consts/toolbar.js';
import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

interface CodeEditorToolbarSignature {
  Args: {
    container?: unknown;
    copyText: string;
    onCopy?: () => void;
  };
  Blocks: {
    default: [];
    action: [];
    menu: [];
  };
}

/**
 *
 * `CodeEditorToolbar` is intended to go on top of the FieldEditor and provides a 'Copy code'
 * button as well as other optional consumer-provided actions.
 * Consumer-provided actions are arranged into the following slots:
 * ----------------------------------------------------------------------------------------
 *  :action:                                                       :menu:   |   Copy code
 * ----------------------------------------------------------------------------------------
 *
 * Toolbar usage with no actions:
 * ```
 * <CodeEditor::Toolbar
 *   @copyText={{this.codeToCopy}}
 *   @onCopy={{this.onCopy}}
 * />
 * ```
 *
 *  * Toolbar usage with actions:
 * ```
 * <CodeEditor::Toolbar
 *   @copyText={{this.codeToCopy}}
 *   @onCopy={{this.onCopy}}
 * >
 *   <:action>
 *     <Hds::Link::Inline @href='#'>
 *       Download Terraform
 *     </Hds::Link::Inline>
 *   </:action>
 *
 *   <:menu>
 *    ...
 *   </:menu>
 * </CodeEditor::Toolbar>
 * ```
 *
 * @class CodeEditorToolbar
 *
 */

export default class CodeEditorToolbar extends Component<CodeEditorToolbarSignature> {
  /**
   *
   * `copyText` is what will be copied to clipboard when the user clicks the 'Copy code' button.
   * @argument copyText;
   * @type {String}
   *
   */
  /**
   *
   * `onCopy` is a called when the copyText is successfully copied.
   * @argument onCopy;
   * @type {?Function}
   *
   */
  @tracked copyIconName: HdsIconSignature['Args']['name'] = COPY_ICON_NAME;

  copied = task(async () => {
    const { onCopy } = this.args;
    const originalIconName = this.copyIconName;

    this.copyIconName = COPIED_ICON_NAME;
    onCopy && onCopy();

    await timeout(1000);

    this.copyIconName = originalIconName;
  });

  <template>
    <div class="code-editor-toolbar" data-test-code-editor-toolbar>
      <div
        data-test-code-editor-toolbar-action
        class="code-editor-toolbar__action"
      >
        {{yield to="action"}}
      </div>

      <div class="code-editor-toolbar__right-aligned">
        {{#if (has-block "menu")}}
          <div
            data-test-code-editor-toolbar-menu
            class="code-editor-toolbar__menu"
          >
            {{yield to="menu"}}
          </div>
          <div
            class="code-editor-toolbar__menu-divider"
            data-test-code-editor-toolbar-menu-divider
          ></div>
        {{/if}}

        <CopyButton
          @text={{@copyText}}
          @onSuccess={{this.copied.perform}}
          @container={{@container}}
          class="code-editor-toolbar__copy-button"
          data-test-code-editor-toolbar-copy-button
        >
          <HdsIcon
            class="code-editor-toolbar__copy-button-icon"
            @name={{this.copyIconName}}
            @size="16"
          />
          {{t "components.code-editor.toolbar.copy-text"}}
        </CopyButton>
      </div>
    </div>
  </template>
}
