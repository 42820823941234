import Component from '@glimmer/component';

import { HdsAlert } from '@hashicorp/design-system-components/components';

import type { HdsAlertSignature } from '@hashicorp/design-system-components/components/hds/alert/index';

interface CoreMarketingAlertSignature {
  Args: Omit<HdsAlertSignature['Args'], 'type'>;
  Blocks: HdsAlertSignature['Blocks'];
  Element: HTMLElement;
}

export default class CoreMarketingAlert extends Component<CoreMarketingAlertSignature> {
  get classNames() {
    const classes = ['core-marketing-alert'];
    return classes.join(' ');
  }

  <template>
    <HdsAlert
      class={{this.classNames}}
      @icon={{@icon}}
      @onDismiss={{@onDismiss}}
      @type="inline"
      data-test-core-marketing-alert
      ...attributes
      as |A|
    >
      {{yield A}}
    </HdsAlert>
  </template>
}
