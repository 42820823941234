/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointVariableFromJSON, HashicorpCloudWaypointVariableToJSON, } from './';
export function HashicorpCloudWaypointCreateVariableResponseFromJSON(json) {
    return HashicorpCloudWaypointCreateVariableResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointCreateVariableResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'variable': !exists(json, 'variable') ? undefined : HashicorpCloudWaypointVariableFromJSON(json['variable']),
    };
}
export function HashicorpCloudWaypointCreateVariableResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'variable': HashicorpCloudWaypointVariableToJSON(value.variable),
    };
}
