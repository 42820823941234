import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';

import RolesService from '../services/roles.ts';

import type { Role } from '../services/roles.ts';

export default class RbacSortEffectiveRoles extends Helper {
  @service declare readonly roles: RolesService;

  @action
  compute([roles = []]: [Array<Role> | undefined]) {
    return this.roles.sortEffectiveRoles(roles);
  }
}
