import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import { HdsFormTextInputBase } from '@hashicorp/design-system-components/components';
import { or } from 'ember-truth-helpers';

import type { ComponentLike } from '@glint/template';
import type { FilterBarSortGroupSignature } from './sort-group.gts';

export interface FilterBarSearchSignature {
  Args: {
    group?: ComponentLike<FilterBarSortGroupSignature>;
    search?: string;
    width?: string;
    isInvalid?: boolean;
    isLoading?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearchKeyup?: (event: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearchInput?: (event: any) => void;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLInputElement;
}

export default class FilterBarSearch extends Component<FilterBarSearchSignature> {
  onSearchKeyup = (event: Event) => {
    this.args.onSearchKeyup?.(event);
  };

  onSearchInput = (event: Event) => {
    this.args.onSearchInput?.(event);
  };

  <template>
    {{#if @group}}
      {{#let @group as |SG|}}
        {{! @glint-expect-error }}
        <SG.TextInput
          @type="search"
          @value={{@search}}
          @width={{@width}}
          @isInvalid={{@isInvalid}}
          @isLoading={{@isLoading}}
          placeholder="Search"
          aria-label="Search"
          {{on "keyup" this.onSearchKeyup}}
          {{on "input" this.onSearchInput}}
          ...attributes
        />
      {{/let}}
    {{else}}
      <HdsFormTextInputBase
        @type="search"
        @value={{@search}}
        @width={{or @width "196px"}}
        @isInvalid={{@isInvalid}}
        @isLoading={{@isLoading}}
        placeholder="Search"
        aria-label="Search"
        class="cut-filter-bar-search"
        {{on "keyup" this.onSearchKeyup}}
        {{on "input" this.onSearchInput}}
        ...attributes
      />
    {{/if}}
  </template>
}
