import Component from '@glimmer/component';
import { HdsTooltipButton } from '@hashicorp/design-system-components/components';

import type { HdsTooltipSignature } from '@hashicorp/design-system-components/components/hds/tooltip-button/index';

interface TooltipConditionalHdsSignature {
  Args: {
    offset?: HdsTooltipSignature['Args']['offset'];
    placement?: HdsTooltipSignature['Args']['placement'];
    tooltipText: HdsTooltipSignature['Args']['text'];
    useTooltip?: boolean;
  };
  Blocks: {
    anchor: [];
  };
}

// /**
//  *
//  * The TooltipConditionalHds component yields the tooltip sub-elements to the
//  * Hds::TooltipButton component if the provided property evaluates to true.
//  *
//  *
//  * ```
//  * <TooltipConditionalHds @useTooltip={{true}} @tooltipText={{"hello"}} @offset={{array 50 30}} @placement="bottom">
//     <:anchor>
//       Anchor
//     </:anchor>
//  * </TooltipConditionalHds>
//  * ```
//  *
//  * @class TooltipConditionalHds

//  */

/**
 * Whether to display the Hds::TooltipButton or not
 *
 * @argument useTooltip
 * @type {boolean}
 */

/**
 * The text to display in the tooltip
 *
 * @argument tooltipText
 * @type {string}
 */

/**
 * The offset of the tooltip in relation to the opener element content
 *
 * @argument offset
 * @type {array}
 */

/**
 * Defines the starting position of the tooltip (options are top (the default), bottom, left, right)
 *
 * @argument placement
 * @type {string}
 */

export default class TooltipConditionalHds extends Component<TooltipConditionalHdsSignature> {
  <template>
    {{#if @useTooltip}}
      <HdsTooltipButton
        @text={{@tooltipText}}
        @placement={{@placement}}
        @offset={{@offset}}
      >
        {{yield to="anchor"}}
      </HdsTooltipButton>
    {{else}}
      {{yield to="anchor"}}
    {{/if}}
  </template>
}
