import Component from '@glimmer/component';

export interface ModalDialogFooterActionsSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class ModalDialogFooterActions extends Component<ModalDialogFooterActionsSignature> {
  <template>
    <div
      class="modal-dialog-actions-container"
      data-test-modal-dialog-actions-container
    >
      {{yield}}
    </div>
  </template>
}
