import Component from '@glimmer/component';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import { HdsStepperStepIndicator } from '@hashicorp/design-system-components/components';

import boxPadding from '../../modifiers/box-padding.ts';
import {
  GUIDED_STEPPER_STATUS_PROGRESS,
  GUIDED_STEPPER_STATUS_INCOMPLETE,
  GUIDED_STEPPER_STATUS_COMPLETE,
} from '../../utils/consts/guided-stepper-tab.js';

interface GuidedStepperTabSignature {
  Args: {
    complete: unknown;
    content: string;
    currentStep: string | number;
    label: string;
    step: number | string;
  };
  Element: HTMLElement;
}

/**
 * `@step` The numeric placement of this step in the guided stepper.
 *
 * @argument @step
 * @type {Number}
 */

/** `@currentStep` The current step the guided stepper is on. This is
 * typically set in GuidedStepper on the contextual component.
 *
 * @argument @currentStep
 * @type {Number}
 */

/**
 * `GuidedStepper::Tab is meant to be used as a contextual component yielded by
 * `GuidedStepper`. It is the state indicator element of the stepper nav and the
 * parent `GuidedStepper`.
 *
 * @class GuidedStepper::Tab
 */
export default class GuidedStepperTab extends Component<GuidedStepperTabSignature> {
  get currentStatus() {
    return this.isActive
      ? GUIDED_STEPPER_STATUS_PROGRESS
      : this.step > this.currentStep
        ? GUIDED_STEPPER_STATUS_INCOMPLETE
        : GUIDED_STEPPER_STATUS_COMPLETE;
  }

  get currentStep() {
    const { currentStep } = this.args;

    assert(
      `@currentStep must be a string or a number.`,
      typeOf(currentStep) === 'string' || typeOf(currentStep) === 'number'
    );

    return parseInt(currentStep as string, 10);
  }

  get isActive() {
    return this.step === this.currentStep;
  }

  get step() {
    const { step } = this.args;

    assert(
      `@step must be a string or a number.`,
      typeOf(step) === 'string' || typeOf(step) === 'number'
    );

    return parseInt(step as string, 10);
  }

  <template>
    <li
      class="{{if this.isActive 'is-active'}} {{if @complete 'is-complete'}}"
      data-test-guided-stepper-tab
    >
      <div class="guided-stepper-container" ...attributes>
        <HdsStepperStepIndicator
          {{! @glint-expect-error }}
          @text={{@step}}
          @status={{this.currentStatus}}
        />
        <div class="guided-stepper-container__step guided-stepper-step">
          <span
            class="hds-typography-body-200 hds-font-weight-regular"
            data-test-guided-stepper-label
          >
            {{@label}}
          </span>
          <span
            class="hds-typography-body-100 hds-font-weight-regular hds-foreground-disabled"
            data-test-guided-stepper-content
            {{boxPadding "2xs 0 0 0"}}
          >
            {{@content}}
          </span>
        </div>
      </div>
    </li>
  </template>
}
