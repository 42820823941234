import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { typeOf } from '@ember/utils';

/**
 * This receives an object from the cloud-network API (connection) that is either a Peering or TGW Attachment.
 * Each object contains a providerPeeringId or providerTgwAttachment, respectively.
 *
 * This helper maps the connection information based on its target (Peering) or awsData (TGW Attachment) value.
 * A Peering can have a target value of awsTarget, hvnTarget, or azureTarget.
 * TGW Attachment does not have a target value but has ProviderData.
 * This helper will need to be modified when more providers are added, currently this helper accounts for AWS and Azure only.
 *
 * Example usage as an Ember template helper:
 * ```handlebars
 *   {{#let (networks-connection-info @peering) as |connection|}}
 *     <td>
 *       {{#let
 *         (option-for-source-state
 *           'region' connection.provider
 *         ) as |option|
 *       }}
 *         <Flex @alignItems='center' @gap='2xs'>
 *           <Hds::Icon @name={{option.icon}} data-test-provider-logo />
 *           <span
 *             class='hds-typography-body-200 hds-font-weight-regular hds-foreground-primary'
 *           >
 *             {{connection.accountId}}
 *           </span>
 *         </Flex>
 *       {{/let}}
 *     </td>
 *     <td>{{connection.vpc}}</td>
 *     <td>{{connection.region}}</td>
 *   {{/let}}
```
 *
 *
 * @class connectionInfo
 *
 * @param {array} args - 0th element of args must be an object.
 * @param {Object} args[0] - The object representation of a Peering or TGW Attachment from the cloud-network API
 * @returns {NetworkConnectionInfo}
 *

 *
 */

/**
 * The Network connection info description.
 * @typedef {Object} NetworkConnectionInfo
 *
 * If target is hvnTarget and connection is a Peering
 * @property {string=} provider - The connection target's provider.
 * @property {string=} accountId - The slug-id for the connection.
 * @property {string} hvnId - The connection target's network id.
 * @property {string} cidr - The connection target's cidr block.
 * @property {string} subscriptionId - The connection target's subscriptionId [Azure networking only]
 * @property {string} resourceGroupName - The connection target's resourceGroupName [Azure networking only]
 * @property {string} vnetName - The connection target's vnetName [Azure networking only]
 *
 * If target is awsTarget and connection is a Peering
 * @property {string=} provider - The connection target's provider.
 * @property {string=} accountId - The slug-id for the connection.
 * @property {string} vpcId - The connection target's VPC ID.
 * @property {string} region - The connection target's region.
 * @property {string} cidr - The connection target's cidr block.
 *
 * If target is azureTarget and connection is a Peering
 * @property {string=} provider - The connection target's provider.
 * @property {string=} accountId - The slug-id for the connection.
 * @property {string} subscriptionId - The connection target's network id.
 * @property {string} vnetName - The connection target's VNet Name.
 * @property {string} region - The connection target's region.
 * @property {string} cidr - The connection target's cidr block.
 *
 * If connection is a Transit Gateway Attachment (AWS only)
 * @property {string=} provider - The connection target's provider.
 * @property {string=} accountId - The slug-id for the connection.
 * @property {string} tgwId - The connection target's transit gateway id.
 * @property {string} region - The connection target's region.
 */

export default class connectionInfo extends Helper {
  @service intl;

  compute([connection]) {
    if (!connection || typeOf(connection) !== 'object') {
      return null;
    }
    // FIXME:
    // eslint-disable-next-line no-unsafe-optional-chaining
    let { provider, region } = connection?.hvn?.location?.region;
    let connectionTarget;

    //Peering Connection Info
    if (connection?.target) {
      let peeringTarget = connection?.target;

      if (peeringTarget?.hvnTarget) {
        connectionTarget = connection?.target?.hvnTarget?.hvn;
        return {
          provider: connectionTarget?.location?.region?.provider,
          accountId: this.intl.t(
            'components.page.hvn-peerings.detail.peering-details.values.hcp-managed',
          ),
          hvnId: connectionTarget.id,
          region,
          cidr: connectionTarget.cidrBlock,
          subscriptionId: this.intl.t(
            'components.page.hvn-peerings.detail.peering-details.values.hcp-managed',
          ),
          resourceGroupName: this.intl.t(
            'components.page.hvn-peerings.detail.peering-details.values.hcp-managed',
          ),
          vnetName: connectionTarget.id,
        };
      }

      if (peeringTarget?.awsTarget) {
        connectionTarget = connection?.target?.awsTarget;
        return {
          provider,
          accountId: connectionTarget.accountId,
          vpcId: connectionTarget.vpcId,
          region: connectionTarget.region,
          cidr: connectionTarget.cidr,
        };
      }

      if (peeringTarget?.azureTarget) {
        connectionTarget = connection?.target?.azureTarget;
        return {
          provider,
          accountId: connectionTarget.tenantId,
          subscriptionId: connectionTarget.subscriptionId,
          vnetName: connectionTarget.vnetName,
          region: connectionTarget.region,
          cidr: connectionTarget.cidr,
          resourceGroupName: connectionTarget.resourceGroupName,
        };
      }
    }

    // TGW Attachment Info
    if (connection?.providerData?.awsData) {
      return {
        provider,
        accountId: connection.providerData.awsData.accountId,
        tgwId: connection.providerData.awsData.tgwId,
        region: connection.location.region.region,
      };
    }
  }
}
