import { helper } from '@ember/component/helper';

export function isSameDay([date1, date2]) {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

export default helper(isSameDay);
