import Component from '@glimmer/component';

interface StepperStepIconSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

export default class StepperStepIcon extends Component<StepperStepIconSignature> {
  <template>
    <span class="stepper_stepLabelIconWrapper">
      {{yield}}
    </span>
  </template>
}
