import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';

import type { TaskForTaskFunction, TaskFunction } from 'ember-concurrency';

interface SplitListLeftPaneListItemSignature<ListItemType, DetailType> {
  Args: {
    item: ListItemType;
    onSelectItem: TaskForTaskFunction<TaskFunction<DetailType, [ListItemType]>>;
    identifyListItem: (listItem: ListItemType) => string;
    selectedItemIdentifier: string | null;
    detailElementId: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLLIElement;
}

/**
 *
 * `SplitListLeftPaneListItem` allows the consumer to provide their own yielded content for a list item
 * in the left pane of the `SplitList`.
 *
 * The parent SplitList components provide all the necessary args to this component except for `item`, which
 * must be provided by the consumer for each `ListItem`.
 *
 * ```
 * <SplitList as |SL>
 *   <SL.LeftPane as |LP|>
 *     {{#each LP.ListData as |listItem|}}
 *       <LP.ListItem @item={{listItem}}>
 *         Consumer list item content here
 *       </LP.ListItem>
 *     {{/each}}
 *   </SL.LeftPane>
 * </SplitList>
 * ```
 *
 * @class SplitListLeftPaneListItem
 *
 */

export default class SplitListLeftPaneListItem<
  ListItemType,
  DetailType,
> extends Component<
  SplitListLeftPaneListItemSignature<ListItemType, DetailType>
> {
  get itemIdentifier() {
    const { item, identifyListItem } = this.args;

    return typeof identifyListItem === 'function' && identifyListItem?.(item);
  }

  get isSelected() {
    const { selectedItemIdentifier } = this.args;
    return (
      selectedItemIdentifier && this.itemIdentifier === selectedItemIdentifier
    );
  }

  onKeyUp = (e: KeyboardEvent) => {
    // List items are selectable via the Enter or Space keys, in addition to click
    if (!this.isSelected && (e.key === 'Enter' || e.key === ' ')) {
      const { item, onSelectItem } = this.args;
      onSelectItem.perform(item);
    }
  };

  <template>
    <li
      class="split-list__list-item {{if this.isSelected 'active'}}"
      id={{this.itemIdentifier}}
      role="tab"
      tabindex="0"
      aria-details={{if this.isSelected @detailElementId}}
      aria-selected="{{this.isSelected}}"
      data-test-split-list-item
      ...attributes
      {{on "click" (fn @onSelectItem.perform @item)}}
      {{on "keyup" this.onKeyUp}}
    >
      {{yield}}
    </li>
  </template>
}
