import Component from '@glimmer/component';
import { hash } from '@ember/helper';
import { HdsSeparator } from '@hashicorp/design-system-components/components';

import Flex from '../../../flex.gts';
import { FlexDirection, FlexGap } from '../../../../utils/flex.ts';
import CoreCardSummaryTitle from './title.gts';

import type { ComponentLike } from '@glint/template';

interface CoreCardSummaryHeaderSignature {
  // Args: {};
  Blocks: {
    default: [
      {
        Title: ComponentLike<CoreCardSummaryTitle>;
      },
    ];
  };
}

/**
 *
 * `CoreCardSummaryKeyComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryKeyComponent
 *
 */
export default class CoreCardSummaryHeader extends Component<CoreCardSummaryHeaderSignature> {
  <template>
    <Flex @direction={{FlexDirection.Column}} @gap={{FlexGap.Xs}}>
      <div class="core-card-summary__header" data-test-core-card-summary-header>
        {{yield (hash Title=(component CoreCardSummaryTitle))}}
      </div>
      <HdsSeparator @spacing="0" />
    </Flex>
  </template>
}
