import Component from '@glimmer/component';

import Typography from '../../typography.gts';

export interface ChartsPercentageBarSummarySignature {
  Args: {
    value: number;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class ChartsPercentageBarSummary extends Component<ChartsPercentageBarSummarySignature> {
  <template>
    <Typography
      class="percentage-bar__summary"
      data-test-chart-summary
      @variant="body1"
    >
      {{@value}}%
    </Typography>
  </template>
}
