import Component from '@glimmer/component';

// Components
import Flex from 'core/components/flex';
import CoreCardSummary from 'core/components/core/card/summary';
import {
  HdsCopyButton,
  HdsCopySnippet,
  HdsTextBody,
  HdsTooltipButton,
  HdsIcon,
} from '@hashicorp/design-system-components/components';
import Time from 'core/components/time';
import ManageAccessTooltipSourceProjectInfo from '../tooltip/source-project-info.gts';

// Helpers
import { t } from 'ember-intl';
import { notEq } from 'ember-truth-helpers';
import optionForSourceState from 'core/helpers/option-for-source-state';
import iamFindResourceNamePart from '../../../helpers/iam-find-resource-name-part.ts';

// Utils
import { Type } from 'core/utils/consts/card-summary';
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import { ParseResourceName } from 'core/utils/resource-name';
import type { HashicorpCloudIamServicePrincipal } from '@clients/cloud-iam';
import type { HashicorpCloudResourcemanagerProject } from '@clients/cloud-resource-manager';

export interface ManageAccessServicePrincipalsCardSignature {
  Element: HTMLElement;
  Args: {
    servicePrincipal: HashicorpCloudIamServicePrincipal;
    sourceProject?: HashicorpCloudResourcemanagerProject;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessServicePrincipalsSummaryCardComponent extends Component<ManageAccessServicePrincipalsCardSignature> {
  get parsedResourceName() {
    const {
      servicePrincipal: { resourceName },
    } = this.args;

    if (!resourceName) {
      return undefined;
    }

    return ParseResourceName(resourceName);
  }

  get resourceNameProjectId() {
    const parsedResourceName = this.parsedResourceName;

    if (!parsedResourceName) {
      return undefined;
    }

    const projectPart = iamFindResourceNamePart(parsedResourceName, {
      part: 'project',
    });

    return projectPart?.id;
  }

  get truncatedResourceName() {
    const {
      servicePrincipal: { resourceName },
    } = this.args;

    const parsedResourceName = this.parsedResourceName;

    if (!parsedResourceName) {
      // if parsing fails, return the service principal resource name
      return resourceName;
    }
    const { parts } = parsedResourceName;
    const principal = parts[parts.length - 1];
    const name = principal?.[1];

    // return truncated name, but fallback to full resourceName if name is undefined
    return name ? `.../${name}` : resourceName;
  }

  <template>
    <CoreCardSummary
      @type={{Type.Horizontal}}
      data-test-service-principal-detail-summary-card
      as |Card|
    >
      <Card.Pair as |P|>
        <P.Key
          data-test-service-principal-detail-summary-card-key
          data-test-service-principal-detail-summary-card-key-type
        >
          {{t
            "manage-access.components.service-principals.summary-card.principal-type"
          }}
        </P.Key>
        <P.Value
          data-test-service-principal-detail-summary-card-value
          data-test-service-principal-detail-summary-card-value-type
        >
          {{#let
            (optionForSourceState "policy-type" "service-principal")
            as |option|
          }}
            <Flex
              @alignItems={{AlignItem.Center}}
              @direction={{FlexDirection.Row}}
              @gap={{FlexGap.Xs}}
            >
              <span>
                {{! @glint-expect-error: fix incoming icon type }}
                <HdsIcon @name={{option.icon}} @isInline={{true}} />
              </span>
              <span>
                {{t option.text}}
              </span>
            </Flex>
          {{/let}}
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-service-principal-detail-summary-card-key
          data-test-service-principal-detail-summary-card-key-id
        >
          {{t "manage-access.components.service-principals.summary-card.id"}}
        </P.Key>
        <P.Value
          data-test-service-principal-detail-summary-card-value
          data-test-service-principal-detail-summary-card-value-id
        >
          <HdsCopySnippet
            @color="secondary"
            @textToCopy={{@servicePrincipal.id}}
          />
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-service-principal-detail-summary-card-key
          data-test-service-principal-detail-summary-card-key-resource-name
        >
          {{t
            "manage-access.components.service-principals.summary-card.resource-name"
          }}
        </P.Key>
        <P.Value
          data-test-service-principal-detail-summary-card-value
          data-test-service-principal-detail-summary-card-value-resource-name
        >
          {{#if @servicePrincipal.resourceName}}
            <Flex
              @alignItems={{AlignItem.Center}}
              @direction={{FlexDirection.Row}}
              @gap={{FlexGap.Xxs}}
            >
              {{this.truncatedResourceName}}
              <HdsTooltipButton
                @text={{t
                  "manage-access.components.service-principals.summary-card.this-is-shortened-full-name-is-resource-name"
                  resourceName=@servicePrincipal.resourceName
                  htmlSafe=true
                }}
                aria-label={{t
                  "manage-access.components.service-principals.summary-card.resource-name-info"
                }}
              >
                <HdsIcon @name="info" @isInline={{true}} />
              </HdsTooltipButton>
              <HdsCopyButton
                @isIconOnly={{true}}
                @size="small"
                @text={{t
                  "manage-access.components.service-principals.summary-card.resource-name"
                }}
                @textToCopy={{@servicePrincipal.resourceName}}
              />
            </Flex>
          {{else}}
            —
          {{/if}}
        </P.Value>
      </Card.Pair>
      {{#let this.resourceNameProjectId as |resourceNameProjectId|}}
        {{#if resourceNameProjectId}}
          <Card.Pair as |P|>
            <P.Key
              data-test-service-principal-detail-summary-card-key
              data-test-service-principal-detail-summary-card-key-source-project
            >
              <Flex
                @alignItems={{AlignItem.Center}}
                @direction={{FlexDirection.Row}}
                @gap={{FlexGap.Xxs}}
              >
                <span>
                  {{t
                    "manage-access.components.service-principals.summary-card.source-project"
                  }}
                </span>
                {{#if (notEq resourceNameProjectId @sourceProject.id)}}
                  <ManageAccessTooltipSourceProjectInfo />
                {{/if}}
              </Flex>
            </P.Key>
            <P.Value
              data-test-service-principal-detail-summary-card-value
              data-test-service-principal-detail-summary-card-value-source-project
            >
              {{#if @sourceProject}}
                {{@sourceProject.name}}
              {{else}}
                <Flex
                  @alignItems={{AlignItem.Center}}
                  @direction={{FlexDirection.Row}}
                  @gap={{FlexGap.Xxs}}
                  data-test-service-principal-detail-summary-card-restricted-access-badge
                >
                  <HdsIcon @name="skip" @isInline={{true}} />
                  <HdsTextBody @tag="span" @weight="regular" @color="faint">
                    {{t
                      "manage-access.components.service-principals.summary-card.restricted-access"
                    }}
                  </HdsTextBody>
                </Flex>
              {{/if}}
            </P.Value>
          </Card.Pair>
        {{/if}}
      {{/let}}
      <Card.Pair as |P|>
        <P.Key
          data-test-service-principal-detail-summary-card-key
          data-test-service-principal-detail-summary-card-key-created
        >
          {{t
            "manage-access.components.service-principals.summary-card.created"
          }}
        </P.Key>
        <P.Value
          data-test-service-principal-detail-summary-card-value
          data-test-service-principal-detail-summary-card-value-created
        >
          <Time
            @date={{@servicePrincipal.createdAt}}
            @display="friendly-only"
          />
        </P.Value>
      </Card.Pair>
    </CoreCardSummary>
  </template>
}
