import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { and, eq, notEq, or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import {
  SystemNotifications as SystemNotificationsTypes,
  SYSTEM_TYPE,
} from '../utils/constants.ts';
import ContractExpiration from './system-notifications/contract-expiration.gts';
import FcpBalance from './system-notifications/fcp-balance.gts';
import AccessRestricted from './system-notifications/access-restricted.gts';
import PricingUpdate from './system-notifications/pricing-update.gts';

import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type FlashObject from 'ember-cli-flash/flash/object';

interface SystemNotificationsSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

/**
 * The flash object being triggered.
 * @typedef {Object} Flash
 * @property {function?} action - Flash message action callback (optional).
 * @property {string?} actionText - Flash message action button text (optional).
 * @property {string} content - Flash message content / description i18n string.
 * @property {string} message - Flash message title i18n string.
 */

/**
 *
 * `SystemNotifications` utilizes the FlashMessage queue to render
 * a choice of Hds::Alert banners based on flash.id. The SystemNotifications
 * will only ever render one banner at a time based on priority.
 *
 * ```
 * <SystemNotifications />
 * ```
 *
 * @class SystemNotifications
 *
 */
export default class SystemNotifications extends Component<SystemNotificationsSignature> {
  @service declare readonly flashMessages: FlashMessageService;
  @service declare readonly router: EnginesRouterService;

  SYSTEM_TYPE = SYSTEM_TYPE;

  CONTRACT_EXPIRED = SystemNotificationsTypes['CONTRACT_EXPIRED'];
  CONTRACT_EXPIRING = SystemNotificationsTypes['CONTRACT_EXPIRING'];
  FCP_LOW_BALANCE = SystemNotificationsTypes['FCP_LOW_BALANCE'];
  FCP_ZERO_BALANCE = SystemNotificationsTypes['FCP_ZERO_BALANCE'];
  ACCESS_RESTRICTED = SystemNotificationsTypes['ACCESS_RESTRICTED'];
  PRICING_UPDATE = SystemNotificationsTypes['PRICING_UPDATE'];

  ADD_CC_ROUTE = 'billing.accounts.account.payments.credit-card.index';

  get activeRoute() {
    return this.router.currentRouteName;
  }

  /**
   * Executes the `onAction` method if it exists.
   * @param {Flash} flash - The flash message.
   */
  action = task({ drop: true }, async (flash: FlashObject) => {
    // @ts-expect-error: we are extending the object
    if (flash.onAction) {
      // @ts-expect-error: we are extending the object
      if (typeof flash.onAction === 'function') {
        // @ts-expect-error: we are extending the object
        await flash.onAction();
      } else if (
        // TODO: Swap this conditional to check the instance of `onAction`.
        //
        // Ideally this would check if `onAction` is an instanceof an
        // ember-concurrency task. I think this would be a better way to
        // conditionally perform a task instead of checking if `perform` exists.
        // @ts-expect-error: we are extending the object
        flash.onAction.perform &&
        // @ts-expect-error: we are extending the object
        typeof flash.onAction.perform === 'function'
      ) {
        // @ts-expect-error: we are extending the object
        await flash.onAction.perform();
      }
    }
  });

  <template>
    {{! @glint-expect-error: need better types for flashMessages.arrangedQueue}}
    {{#each this.flashMessages.arrangedQueue as |flash index|}}
      {{#if (and (eq flash.type this.SYSTEM_TYPE) (eq index 0))}}
        <div data-test-system-notifications>
          {{#if
            (or
              (eq flash.id this.CONTRACT_EXPIRING.id)
              (eq flash.id this.CONTRACT_EXPIRED.id)
            )
          }}
            {{! Requires special treatment because expiration date is required in translation message}}
            <ContractExpiration @flash={{flash}} />
          {{else if
            (or
              (eq flash.id this.FCP_LOW_BALANCE.id)
              (eq flash.id this.FCP_ZERO_BALANCE.id)
            )
          }}
            {{! Requires special treatment because percentage remaining of contract's initial amount is required in translation message}}
            <FcpBalance @flash={{flash}} />
          {{else if (eq flash.id this.ACCESS_RESTRICTED.id)}}
            {{! Requires special treatment because html/styling is required in translation message}}
            <AccessRestricted @flash={{flash}} />
          {{else if (eq flash.id this.PRICING_UPDATE.id)}}
            {{! Requires special treatment because call to action must be a standalone link, not a button.}}
            <PricingUpdate @flash={{flash}} />
          {{else}}
            <HdsAlert
              @type="page"
              @color={{flash.color}}
              data-test-system-notification={{flash.id}}
              as |A|
            >
              <A.Title>
                {{t flash.message}}
              </A.Title>
              <A.Description>
                {{t flash.content}}
              </A.Description>
              {{#if
                (and
                  flash.actionText
                  flash.onAction
                  (notEq this.activeRoute this.ADD_CC_ROUTE)
                )
              }}
                <A.Button
                  data-test-notification-action
                  @color="secondary"
                  @size="small"
                  @icon={{if flash.actionIcon flash.actionIcon ""}}
                  @text={{t flash.actionText}}
                  disabled={{this.action.isRunning}}
                  {{on "click" (fn this.action.perform flash)}}
                />
              {{/if}}
            </HdsAlert>
          {{/if}}
        </div>
      {{/if}}
    {{/each}}
  </template>
}
