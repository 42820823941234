/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointV20241122IntegrationState;
(function (HashicorpCloudWaypointV20241122IntegrationState) {
    HashicorpCloudWaypointV20241122IntegrationState["UNSPECIFIED"] = "UNSPECIFIED";
    HashicorpCloudWaypointV20241122IntegrationState["ACTIVE"] = "ACTIVE";
    HashicorpCloudWaypointV20241122IntegrationState["DISCONNECTED"] = "DISCONNECTED";
})(HashicorpCloudWaypointV20241122IntegrationState || (HashicorpCloudWaypointV20241122IntegrationState = {}));
export function HashicorpCloudWaypointV20241122IntegrationStateFromJSON(json) {
    return HashicorpCloudWaypointV20241122IntegrationStateFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122IntegrationStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointV20241122IntegrationStateToJSON(value) {
    return value;
}
