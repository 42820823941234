import Component from '@glimmer/component';

import Logo from '../../logo.gts';

interface LayoutFullScreenMainSignature {
  // Args: {};
  Blocks: {
    content: [];
    footer: [];
    logo: [];
  };
  Element: HTMLElement;
}

export default class LayoutFullScreenMain extends Component<LayoutFullScreenMainSignature> {
  <template>
    <main data-test-full-screen-main class="full-screen__main" ...attributes>
      <header class="full-screen__main-header">
        {{#if (has-block "logo")}}
          {{yield to="logo"}}
        {{else}}
          <Logo />
        {{/if}}
      </header>
      <div class="full-screen__main-content" data-test-full-screen-main-content>
        {{#if (has-block "content")}}
          {{yield to="content"}}
        {{/if}}
      </div>
      <footer
        class="full-screen__main-footer"
        data-test-full-screen-main-footer
      >
        {{#if (has-block "footer")}}
          {{yield to="footer"}}
        {{/if}}
      </footer>
    </main>
  </template>
}
