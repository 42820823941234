import Component from '@glimmer/component';

import TooltipConditional from './tooltip-conditional/hds.gts';
import formatDateRange from '../helpers/format-date-range.js';

interface DateRangeSignature {
  Args: {
    asUTC: unknown;
    condensed: unknown;
    endDate: unknown;
    formatOpts: unknown;
    startDate: unknown;
    withTooltip: boolean;
  };
}

/**
 * `startDate` is the starting date to be displayed in the date range
 * @argument startDate
 * @type {boolean}
 */

/**
 * `endDate` is the ending date to be displayed in the date range
 * @argument endDate
 * @type {boolean}
 */

/**
 * `condensed` is a flag to condense the date range is possible if the range is within the same year.
 * For example, the date range for May 31, 2023 - June 31, 2023 would be condensed to May 31 - June 31, 2023
 * @argument condensed
 * @type {boolean}
 */

/**
 * `formatOpts` are the Intl.DateTimeFormat formatting options for the dates
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * @argument formatOpts
 * @type {string}
 */

/**
 * `asUTC` is a flag to determine if the dates should be displayed as UTC instead of locale
 * @argument asUTC
 * @type {boolean}
 */

/**
 * `withTooltip` is a flag to determine if the tooltip displaying the ISO date string should be displayed.
 * @argument withTooltip
 * @type {boolean}
 */

/**
 *
 * `DateRangeComponent` provides a container to display a range of two dates.
 *
 *
 * ```
 * <DateRangeComponent>
 * ```
 *
 * @class DateRangeComponent
 *
 */

export default class DateRange extends Component<DateRangeSignature> {
  <template>
    <div class="date-range" data-test-date-range-container>
      {{#let
        (formatDateRange
          @startDate @endDate condensed=@condensed formatOpts=@formatOpts
        )
        as |range|
      }}
        <TooltipConditional
          @useTooltip={{@withTooltip}}
          @placement="bottom"
          @tooltipText={{range.utcIsoStart}}
        >
          <:anchor>
            <time
              datetime={{range.utcIsoStart}}
              data-test-date-range-start-time
            >
              {{if @asUTC range.utcFormattedStart range.localFormattedStart}}
            </time>
          </:anchor>
        </TooltipConditional>
        &ndash;
        <TooltipConditional
          @useTooltip={{@withTooltip}}
          @placement="bottom"
          @tooltipText={{range.utcIsoEnd}}
        >
          <:anchor>
            <time datetime={{range.utcIsoEnd}} data-test-date-range-end-time>
              {{if @asUTC range.utcFormattedEnd range.localFormattedEnd}}
            </time>
          </:anchor>
        </TooltipConditional>
      {{/let}}
    </div>
  </template>
}
