import Component from '@glimmer/component';

export interface ZeroStateMessageSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `ZeroStateMessage` displays the message element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Message>Message</ZeroState::Message>
 * ```
 *
 * @class ZeroStateMessage
 *
 */

export default class ZeroStateMessage extends Component<ZeroStateMessageSignature> {
  <template>
    <div
      class="zero-state__message"
      data-test-zero-state-message-container
      ...attributes
    >
      {{yield}}
    </div>
  </template>
}
