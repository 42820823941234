import Service from '@ember/service';
import { warn } from '@ember/debug';
import { variation } from 'ember-launch-darkly';

import {
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_NETWORK,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
  TYPE_SERVICE_PRINCIPAL,
  TYPE_SERVICE_PRINCIPAL_KEY,
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
  TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
} from 'common/utils/cloud-resource-types';

const DEFAULT_LIMIT = 0;
export const DEFAULT_LIMITS: Record<string, number> = {
  [TYPE_NETWORK]: 1,
  [TYPE_NETWORK_PEERING]: 10,
  [TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT]: 10,
  [TYPE_CONSUL]: 1,
  [TYPE_CONSUL_GLOBAL_NETWORK_MANAGER]: 1,
  [TYPE_VAULT]: 1,
  [TYPE_SERVICE_PRINCIPAL]: 5,
  [TYPE_SERVICE_PRINCIPAL_KEY]: 2,
  [TYPE_ORGANIZATION]: 1,
  [TYPE_PROJECT]: 10,
};

export const QUOTA_FEATURE_FLAGS: Record<string, string> = {
  [TYPE_NETWORK]: 'hcp-quota-network',
  [TYPE_NETWORK_PEERING]: 'hcp-quota-peerings-v2',
  [TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT]: 'hcp-quota-tgw-attachments',
  [TYPE_CONSUL]: 'hcp-quota-consul-clusters',
  [TYPE_CONSUL_GLOBAL_NETWORK_MANAGER]:
    'hcp-quota-global-network-manager-clusters',
  [TYPE_VAULT]: 'hcpv-quota-vault-clusters',
  [TYPE_SERVICE_PRINCIPAL]: 'hcp-quota-service-principals',
  [TYPE_SERVICE_PRINCIPAL_KEY]: 'hcp-quota-service-principal-keys',
  [TYPE_ORGANIZATION]: 'hcp-quota-organizations',
  [TYPE_PROJECT]: 'hcp-quota-projects',
};

// https://github.com/microsoft/TypeScript/pull/44458
interface TypedProxyConstructor {
  revocable<T extends object>(
    target: T,
    handler: ProxyHandler<T>
  ): { proxy: T; revoke: () => void };
  revocable<T extends object, U extends object>(
    target: T,
    handler: ProxyHandler<T>
  ): { proxy: U; revoke: () => void };
  new <T extends object>(target: T, handler: ProxyHandler<T>): T;
  new <T extends object, U extends object>(
    target: T,
    handler: ProxyHandler<T>
  ): U;
}
const TypedProxy = Proxy as TypedProxyConstructor;

const isInteger = (candidate: unknown): candidate is number =>
  Number.isInteger(candidate);

// The QuotaService provides a numerical limit for given resources in the HCP.
//
// If a resource type is not configured, the default limit returned from `#for`
// is zero.
//
// Usage:
//
//import Component from '@glimmer/component';
//import { service } from '@ember/service';
//import { TYPE_CONSUL } from 'common/utils/cloud-resource-types';
//
//export default class ExampleComponent extends Component {
//  @service quota;
//
//  get allowedConsulClusters() {
//    return this.quota.for(TYPE_CONSUL);
//  }
//}

export default class QuotaService extends Service {
  static DEFAULT_LIMITS = DEFAULT_LIMITS;

  // This was originally typed to by [name:string]: () => number
  // That's been changed to reflect what the consuming party would expect with from `for`
  // `for` should return a number, not a function returning a number
  // IF THIS BECOMES A PROBLEM start investigating which way is correct here
  limits = new TypedProxy<QuotaService, { [name: string]: number }>(this, {
    // target here will refer to the QuotaService instance
    get(_, type: string): number {
      const flag = QUOTA_FEATURE_FLAGS[type];
      const limit = flag ? variation(flag) : '';
      return (isInteger(limit) ? limit : DEFAULT_LIMITS[type]) || DEFAULT_LIMIT;
    },

    set(_, type: string, value) {
      warn(
        `tried to set limit '${type}' to '${value}' - this is not supported`,
        {
          id: 'cloud-ui-service-quota-limit-warn',
        }
      );
      return false;
    },
  });

  for(type: string): number {
    return this.limits[type] || DEFAULT_LIMIT;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    quota: QuotaService;
  }
}
