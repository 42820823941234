/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import tippy from 'tippy.js';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

export interface TooltipSignature {
  Args: {
    tooltipOptions: Record<string, unknown>;
  };
  Blocks: {
    anchor: [];
    tooltip: [];
  };
}

export default class Tooltip extends Component<TooltipSignature> {
  // Tippy parts
  // @ts-expect-error
  @tracked tooltipContent;
  // @ts-expect-error
  @tracked anchor;
  // @ts-expect-error
  @tracked arrow;

  // Tippy instance
  // @ts-expect-error
  @tracked tooltip;

  captureContent = (tooltipContent: any) => {
    this.tooltipContent = tooltipContent;
    this.createTooltip();
  };

  captureAnchor = (anchor: any) => {
    this.anchor = anchor;
    this.createTooltip();
  };

  captureArrow = (arrow: any) => {
    this.arrow = arrow;
    this.createTooltip();
  };

  createTooltip() {
    // Only create the tooltip once the anchor, arrow, and content have all been
    // captured. This casuses a flaky race condition in tests. The test helper
    // accounts for this and should be refactored eventually.
    if (!this.tooltip && (!this.tooltipContent || !this.anchor || !this.arrow))
      return;

    const tippyOptions = {
      content: this.tooltipContent,
      arrow: this.arrow,
      interactive: true,
      delay: 0,
      duration: 0,
      ...this.args.tooltipOptions, // see https://atomiks.github.io/tippyjs/v6/all-props/ for list of available options
    };

    this.tooltip = tippy(this.anchor, tippyOptions);
  }

  <template>
    <svg
      class="rich-tooltip__arrow"
      width="18"
      height="8"
      viewBox="0 0 18 8"
      xmlns="http://www.w3.org/2000/svg"
      {{didInsert this.captureArrow}}
    >
      <path class="rich-tooltip__arrow__fill" d="M17 7H1l8-6 8 6Z"></path>
      <path class="rich-tooltip__arrow__stroke" d="m1 7 8-6 8 6"></path>
    </svg>

    <div
      data-test-tooltip-content
      class="rich-tooltip"
      {{didInsert this.captureContent}}
    >
      {{yield to="tooltip"}}
    </div>
    <div
      data-test-tooltip-anchor
      class="rich-tooltip__anchor"
      {{didInsert this.captureAnchor}}
    >
      {{yield to="anchor"}}
    </div>
  </template>
}
