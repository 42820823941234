/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudWaypointApplicationTemplateFromJSON, HashicorpCloudWaypointApplicationTemplateToJSON, } from './';
export function HashicorpCloudWaypointListApplicationTemplatesResponseFromJSON(json) {
    return HashicorpCloudWaypointListApplicationTemplatesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListApplicationTemplatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'applicationTemplates': !exists(json, 'application_templates') ? undefined : (json['application_templates'].map(HashicorpCloudWaypointApplicationTemplateFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
    };
}
export function HashicorpCloudWaypointListApplicationTemplatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'application_templates': value.applicationTemplates === undefined ? undefined : (value.applicationTemplates.map(HashicorpCloudWaypointApplicationTemplateToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'total_count': value.totalCount,
    };
}
