import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';

import Box from '../box.gts';

export interface ModalDialogBodySignature {
  Args: {
    padding?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class ModalDialogBody extends Component<ModalDialogBodySignature> {
  <template>
    <Box
      @padding={{or @padding "lg"}}
      ...attributes
      data-test-modal-dialog-body
    >
      {{yield}}
    </Box>
  </template>
}
