import Component from '@glimmer/component';

import Box from '../../box.gts';

interface HvnSelectHvnGroupSignature {
  Args: {
    group: {
      groupName: string;
      options: unknown[];
    };
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class FormInputsHvnSelectHvnGroup extends Component<HvnSelectHvnGroupSignature> {
  <template>
    {{#if @group.options.length}}
      <div
        class="form-inputs__hvn-select__hvn-group"
        data-test-form-inputs-network-hvn-group={{@group.groupName}}
      >
        <Box @padding="sm sm xs sm">
          {{@group.groupName}}
        </Box>

        {{! NOTE: Interface for ember-power-select @groupComponent requires yielding to render grouped select options. }}
        {{yield}}
      </div>
    {{/if}}
  </template>
}
