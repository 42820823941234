import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { hash } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';

import Trigger from './menu/trigger.gts';
import Content from './menu/content.gts';

interface MenuSignature {
  Args: {
    isOpen: boolean;
    onToggle?: (val: boolean) => void;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `Menu` renders a `<details>` element styled as a drop-down menu. It yields `Trigger` and `Content` contextual components. `Trigger` is a `<summary>` element and `Content` is a `div` that is revealed by `<details>`.
 *
 *
 * ## Example usage
 *
 * ```
 * <Menu as |M| >
 *   <M.Trigger>
 *     Open up!
 *   </M.Trigger>
 *   <M.Content>
 *     <a href="">Sign out</a>
 *   </M.Content>
 * </Menu>
 * ```
 *
 * @class Menu
 * @yield {MenuTrigger} Trigger `Menu::Trigger` component
 * @yield {MenuContent} Content `Menu::Content` component
 *
 */

export default class Menu extends Component<MenuSignature> {
  /**
   * `isOpen` is a binding that controls if the current `<details>` element's `open` attribute.
   * @argument isOpen
   * @type {boolean}
   */

  /**
   * `onToggle` is a funciton that will be called with the details "toggle" event fires. It will be passed the value of the details `open` attribute.
   * @argument onToggle
   * @type {function}
   */
  @tracked _isOpen = false;

  get isOpen() {
    return this.args.isOpen || this._isOpen;
  }

  set isOpen(val) {
    if (this.args.onToggle && typeof this.args.onToggle === 'function') {
      this.args.onToggle(val);
    }
    this._isOpen = val;
  }

  trackOpen = (evt: Event & { target: { open: boolean } }) => {
    this.isOpen = evt.target.open;
  };

  setupClickOutside = (el: HTMLElement) => {
    document.addEventListener(
      'click',
      (evt) => this.clickOutside(evt, el),
      true
    );
  };

  removeClickOutside = (el: HTMLElement) => {
    document.removeEventListener(
      'click',
      (evt) => this.clickOutside(evt, el),
      true
    );
  };

  close = () => {
    this.isOpen = false;
  };

  clickOutside = (evt: MouseEvent, el: HTMLElement) => {
    if (!el.contains(evt.target as Node)) {
      this.removeClickOutside(el);
      this.isOpen = false;
    }
  };

  <template>
    <details
      class="menu"
      open={{this.isOpen}}
      ...attributes
      data-test-menu-details
      {{didInsert this.setupClickOutside}}
      {{willDestroy this.removeClickOutside}}
      {{! @glint-expect-error }}
      {{on "toggle" this.trackOpen}}
    >
      {{yield
        (hash
          isOpen=this.isOpen
          close=this.close
          Trigger=(component Trigger)
          Content=(component Content isOpen=this.isOpen)
        )
      }}
    </details>
  </template>
}
