import Component from '@glimmer/component';
import { eq } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import Box from '../../box.gts';
import FlexGrid from '../../flex-grid.gts';
import DefinitionList from '../../definition-list.gts';

interface HvnSelectHvnOptionSignature {
  Args: {
    option: {
      network: {
        id: string;
        location: {
          region: {
            provider: string;
            region: string;
          };
        };
        cidrBlock: string;
      };
    };
    select: {
      selected: {
        network: {
          id: string;
        };
      };
    };
  };
}

export default class FormInputsHvnSelectHvnOption extends Component<HvnSelectHvnOptionSignature> {
  <template>
    <Box
      @padding="sm 0 0 sm"
      class="form-inputs__hvn-select__hvn-option"
      data-test-hvn-select-hvn-option={{@option.network.id}}
    >
      <FlexGrid as |Grid|>
        <Grid.Item @xs="1">
          {{#if (eq "aws" @option.network.location.region.provider)}}
            <HdsIcon @name="aws-color" @size="24" />
          {{else if (eq "azure" @option.network.location.region.provider)}}
            <HdsIcon @name="azure-color" @size="24" />
          {{/if}}
        </Grid.Item>

        <Grid.Item @xs="11">
          <span class="network-id">
            {{@option.network.id}}
          </span>

          {{#if (eq @select.selected.network.id @option.network.id)}}
            <HdsIcon
              @name="check"
              @size="16"
              @color="var(--token-color-foreground-action)"
            />
          {{/if}}
        </Grid.Item>

        <Grid.Item @xsOffset="1" @xs="3">
          <DefinitionList @vertical={{true}} as |List|>
            <List.Key>
              {{t "components.form-inputs.hvn-select.hvn-option.labels.region"}}
            </List.Key>
            <List.Value>
              {{@option.network.location.region.region}}
            </List.Value>
          </DefinitionList>
        </Grid.Item>

        <Grid.Item @xs="8">
          <DefinitionList @vertical={{true}} as |List|>
            <List.Key>
              {{t
                "components.form-inputs.hvn-select.hvn-option.labels.cidr-block"
              }}
            </List.Key>
            <List.Value>
              {{@option.network.cidrBlock}}
            </List.Value>
          </DefinitionList>
        </Grid.Item>
      </FlexGrid>
    </Box>
  </template>
}
