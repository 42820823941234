/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointTFWorkspaceInfoFromJSON, HashicorpCloudWaypointTFWorkspaceInfoToJSON, } from './';
export function HashicorpCloudWaypointGetTFWorkspaceInfoResponseFromJSON(json) {
    return HashicorpCloudWaypointGetTFWorkspaceInfoResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointGetTFWorkspaceInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'workspaceInfo': !exists(json, 'workspace_info') ? undefined : HashicorpCloudWaypointTFWorkspaceInfoFromJSON(json['workspace_info']),
    };
}
export function HashicorpCloudWaypointGetTFWorkspaceInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'workspace_info': HashicorpCloudWaypointTFWorkspaceInfoToJSON(value.workspaceInfo),
    };
}
