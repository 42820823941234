import Component from '@glimmer/component';
import { HdsButton } from '@hashicorp/design-system-components/components';

interface SubmitButtonSignature {
  Args: {
    disabled: boolean;
    text: string;
  };
  Element: HTMLSpanElement;
}

/**
 *
 * Just a regular old submit button, nothing fancy.
 *
 *
 * ```
 * <FormInputs::SubmitButton
 *  @disabled={{disabled}}
 *  @text='Submit this form !!!'
 * />
 * ```
 *
 * @class FormInputsSubmitButton
 *
 */

export default class FormInputsSubmitButton extends Component<SubmitButtonSignature> {
  /**
   * @argument disabled
   * @type {Boolean}
   */

  <template>
    <span data-test-form-inputs-submit-button ...attributes>
      <HdsButton @text={{@text}} disabled={{@disabled}} type="submit" />
    </span>
  </template>
}
