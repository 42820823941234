import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { HdsLinkInline } from '@hashicorp/design-system-components/components';

import type { IntlService } from 'ember-intl';

import ZeroState from '../zero-state.gts';
import boxPadding from '../../modifiers/box-padding.ts';

interface MaintenanceModeOfflineServiceSignature {
  Args: {
    serviceName: unknown;
  };
  Element: HTMLDivElement;
}

/**
 *
 * `MaintenanceModeOfflineService` renders a zero-state empty message to
 * communicate that a message that is offline. This component is used at the
 * root of a service's ember in the application.hbs.
 * ```
 * <MaintenanceMode::OfflineService
 *   @serviceName={{'Vault'}}
 * />
 * ```
 *
 * @class MaintenanceModeOfflineServiceComponent
 *
 */

export default class MaintenanceModeOfflineService extends Component<MaintenanceModeOfflineServiceSignature> {
  @service declare readonly intl: IntlService;

  <template>
    <div
      class="maintenance-mode-offline-service"
      ...attributes
      data-test-maintenance-mode-offline-service-container
      {{boxPadding "xl 0 0 0"}}
    >
      <ZeroState as |ZS|>
        <ZS.Header>
          {{t
            "components.maintenance-mode.offline-service.currently-unavailable"
            serviceName=@serviceName
          }}
        </ZS.Header>
        <ZS.Message>
          {{t
            "components.maintenance-mode.offline-service.be-back-soon"
            serviceName=@serviceName
          }}
        </ZS.Message>
        <ZS.Action>
          <HdsLinkInline
            @href="https://status.hashicorp.com"
            @icon="external-link"
            @iconPosition="trailing"
            @isHrefExternal={{true}}
          >
            {{t
              "components.maintenance-mode.offline-service.subscribe-to-updates"
            }}
          </HdsLinkInline>
        </ZS.Action>
      </ZeroState>
    </div>
  </template>
}
