import Component from '@glimmer/component';

interface MenuContentSignature {
  Args: {
    isOpen: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class MenuContent extends Component<MenuContentSignature> {
  <template>
    <div
      class="menuContent {{if @isOpen 'menuContent--isOpen'}}"
      data-test-menu-content
      ...attributes
    >
      {{yield}}
    </div>
  </template>
}
