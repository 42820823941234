import Component from '@glimmer/component';
import { service } from '@ember/service';
import { notEq } from 'ember-truth-helpers';
import FlashMessageService from 'ember-cli-flash/services/flash-messages';

import Notification from './notifications/notification.gts';

interface NotificationsSignature {
  // Args: {};
}

/**
 *
 * `Notifications` utilizes the FlashMessage queue to render Notification
 * child elements.
 *
 *
 * ```
 * <Notifications />
 * ```
 *
 * @class Notifications
 *
 */

export default class Notifications extends Component<NotificationsSignature> {
  @service declare readonly flashMessages: FlashMessageService;

  <template>
    <div class="notifications">
      {{#each this.flashMessages.queue key="initializedTime" as |flash|}}
        {{! don't render system messages, they are rendered by SystemNotifications component}}
        {{#if (notEq flash.type "system")}}
          <Notification @flash={{flash}} />
        {{/if}}
      {{/each}}
    </div>
  </template>
}
