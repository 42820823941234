import { BILLING_ACCOUNT_STATUS } from '../constants/status.js';

export default class AccountStatus {
  #status;

  constructor(status) {
    this.#status = status;
  }

  get isActive() {
    return this.#status === BILLING_ACCOUNT_STATUS.ACTIVE;
  }

  get isDelinquent() {
    return this.#status === BILLING_ACCOUNT_STATUS.DELINQUENT;
  }

  get isTrial() {
    return this.#status === BILLING_ACCOUNT_STATUS.TRIAL;
  }

  get isIncomplete() {
    return this.#status === BILLING_ACCOUNT_STATUS.INCOMPLETE;
  }

  valueOf() {
    return this.#status;
  }
}
