/*****************************************************************************
 *                                                                           *
 *              This file is owned by HCP's Cloud SRE Team                   *
 *              ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~                  *
 *                                                                           *
 *  Updating the component IDs listed for HCP and each Hashi product below   *
 *  will effect the "System Status" indicator link (in the footer).        *
 *                                                                           *
 *  Please note the logic to roll up the listed IDs when making changes:     *
 *  1. When NONE of the listed IDs are found, no status will be shown        *
 *     (an unstyled link will to the status page will still be displayed)     *
 *  2. When MIXED statuses are found, always the worst / most severe         *
 *     component status will be displayed                                    *
 *  3. When ALL SAME status are found, that status will be displayed         *
 *                                                                           *
 *  When updating the `baseUrl` for any environment, please remember to:     *
 *  -  Also update the Content Security Policy (CSP) to match                *
 *     (SEE: /hcp/config/content-security-policy.js)                         *
 *  -  Always make sure the `jsonApiUrl` begins with the `baseUrl`           *
 *                                                                           *
 *****************************************************************************/

/**
 * @typedef {HcpStatusPageConfig}
 * @property {String} baseUrl - base URL for the status page, also used for Cloud UI's Content Security Policy (CSP)
 * @property {String} jsonApiUrl - full URL for the status page's v1 JSON API endpoint (must match `baseUrl`)
 * @property {Array.<String>} hcpComponentIds - HCP component IDs to roll status up from the status page's v1 JSON API data
 * @property {Array.<String>} consulComponentIds - Consul component IDs to roll status up from the status page's v1 JSON API data
 * @property {Array.<String>} packerComponentIds - Packer component IDs to roll status up from the status page's v1 JSON API data
 * @property {Array.<String>} vaultComponentIds - Vault component IDs to roll status up from the status page's v1 JSON API data
 */

/**
 * @type {HcpStatusPageConfig}
 * @see https://app.incident.io/hashicorp/status-pages/01JK8R0BRY0ATZKTX3VQB5SHN3/settings/components
 */
export const PRODUCTION = {
  baseUrl: 'https://status.hashicorp.com',
  jsonApiUrl: 'https://status.hashicorp.com/api/v1/summary',
  hcpComponentIds: [
    '01JK8R0BRYKPJS5K35R2ZCSHV0', // HCP Portal
    '01JK8R0BRY4185T4NHJFAXP35D', // HCP API
    '01JKJP1CYEVMQKJ1Z5WAX0W12F', // HCP Consul Dedicated - this group-level ID is not returned in affected components
    '01JK8R0BRY1E2GC38R4C67YF1P', // AWS-ap-northeast-1
    '01JK8R0BRY6MB6BNBAWB5SWBY4', // AWS-ap-southeast-1
    '01JK8R0BRYXGGRSRXYABZHNQCN', // AWS-ap-southeast-2
    '01JK8R0BRYM2X7F1H8QGFS1E6R', // AWS-ca-central-1
    '01JK8R0BRY3Y31G6EZFKCHSX5Z', // AWS-eu-central-1
    '01JK8R0BRYVT1C57T28SH60WH5', // AWS-eu-west-1
    '01JK8R0BRY3ZV64B3X0ZYNQEM9', // AWS-eu-west-2
    '01JK8R0BRYGJM73QDCPC9Z5WTQ', // AWS-us-east-1
    '01JK8R0BRY3R24EJDMV1N9ARY7', // AWS-us-east-2
    '01JK8R0BRYQ1CJ1Z4FFX4QPTGZ', // AWS-us-west-2
    '01JK8R0BRYZSQYNH61B1JQRZ9M', // Azure-Australia Southeast
    '01JK8R0BRYBR3XY1JS7NVW3XX2', // Azure-Canada Central
    '01JK8R0BRYS7FZED9Y7QEDZDNN', // Azure-Central US
    '01JK8R0BRYFNVV1PT9Y1GB0S8K', // Azure-East US
    '01JK8R0BRYW1N13M4GWV028JT0', // Azure-East US 2
    '01JK8R0BRY64NBWT6GBEDF2XHJ', // Azure-France Central
    '01JK8R0BRY7VCP8FYMHT5MQHNC', // Azure-Japan East
    '01JK8R0BRYPPG09CK5ZEYM0Z3X', // Azure-North Europe
    '01JK8R0BRYM8E2AXRRBJ9WJ3KG', // Azure-Southeast Asia
    '01JK8R0BRY197B0BPWHZBX8TF5', // Azure-UK South
    '01JK8R0BRYB8RMTQ0TWSQ1242K', // Azure-West Europe
    '01JK8R0BRY523PAJYM9SZYD8HT', // Azure-West US 2
    '01JK8R0BRYR9EYAGMNJ5EKC6CS', // HCP Packer
    '01JKJP1CYEVMQKJ1Z5WESBEQ7M', // HCP Vault Dedicated - this group-level ID is not returned in affected components
    '01JK8R0BRYQ2P8X8E4FNQ4C58S', // AWS-ap-northeast-1
    '01JK8R0BRYFD4NPA0Q4ZNWR286', // AWS-ap-southeast-1
    '01JK8R0BRY8WWVTQGSRH3D8K7R', // AWS-ap-southeast-2
    '01JK8R0BRY6BZ7NKHV70DCG9A2', // AWS-ca-central-1
    '01JK8R0BRYNAYMW6JSTD5VKV97', // AWS-eu-central-1
    '01JK8R0BRY46BX741H8HQCM9VF', // AWS-eu-west-1
    '01JK8R0BRYTCP7GWX8F4CT0XVZ', // AWS-eu-west-2
    '01JK8R0BRY7RKKW8J9D8VZEPXK', // AWS-us-east-1
    '01JK8R0BRYRTZEE58WBXJ9R6PZ', // AWS-us-east-2
    '01JK8R0BRY8ABXFA0R5FFP3VRJ', // AWS-us-west-2
    '01JK8R0BRY4XNX4VC07BG0QCVX', // Azure-Australia Southeast
    '01JK8R0BRYXEVX9QSENWETDFW9', // Azure-Canada Central
    '01JKJP0KHVFM7T50RSABY2MMZ1', // Azure-Canada East
    '01JK8R0BRY0YQXVTRMME92H3XS', // Azure-Central US
    '01JK8R0BRY3B7F05ZWKXP31DD2', // Azure-East US
    '01JK8R0BRY89FEGDEPE4FGEZ9K', // Azure-East US 2
    '01JK8R0BRYMPPS367165PMSFE2', // Azure-France Central
    '01JK8R0BRYYE4852ZW9667GNAN', // Azure-Japan East
    '01JK8R0BRYPSTN7EZ5RBAV6Q8V', // Azure-North Europe
    '01JK8R0BRY04P0C892W2WVZ5KQ', // Azure-Southeast Asia
    '01JK8R0BRYCT5MEH4KCHZ2BM1V', // Azure-UK South
    '01JK8R0BRY0YHHSYN0Z7W8J4HT', // Azure-West Europe
    '01JK8R0BRY56W1VESY14VDDR0Z', // Azure-West US 2
    '01JK8R0BRYHN4JYQ1H3WC42RWV', // HCP Boundary
    // '01JK8R0BRYY1ZM4NCA18A5T43A', // HCP Vault Secrets
    // '01JK8R0BRYDYZFQH1V8ZSJKDFF', // HCP Vault Radar
    // '01JK8R0BRY0Q21819AYRKH5GZZ', // HCP Waypoint
    // '01JK8R0BRYB0GC3HVYYBDPMZ4E', // HCP Terraform
    // '01JK8R0BRYBTNJ8Q0YRTCFTQFF', // Terraform Registry
    // '01JK8R0BRYZXNKPETXBRGKHWEK', // Vagrant Cloud
    // '01JK8R0BRYD4QK8MMAD671NTH0', // Waypoint URL Service
    // '01JKJP1CYEVMQKJ1Z5WFK86FRY', // Releases - this group-level ID is not returned in affected components
    // '01JK8R0BRYWRZGCG3RFS4PBVTW', // APT Repository
    // '01JK8R0BRYC4YR628P5T8GEEQT', // Helm Chart Repository
    // '01JK8R0BRYPB7XB0M737SVWD52', // RPM Repository
    // '01JK8R0BRY1XABF74DVMMSP7W5', // Releases Site
    // '01JK8R0BRYT7JKA55VTKYYPCQN', // Private Container Registry
    // '01JK8R0BRY8FE9P2RKCDKT7110', // Linux Package Archive Site
  ],
  consulComponentIds: [
    '01JKJP1CYEVMQKJ1Z5WAX0W12F', // HCP Consul Dedicated - this group-level ID is not returned in affected components
  ],
  packerComponentIds: [
    '01JK8R0BRYR9EYAGMNJ5EKC6CS', // HCP Packer
  ],
  vaultComponentIds: [
    '01JKJP1CYEVMQKJ1Z5WESBEQ7M', // HCP Vault Dedicated - this group-level ID is not returned in affected components
  ],
};

/**
 * @type {HcpStatusPageConfig}
 * @see https://app.incident.io/hc-sandbox-test/status-pages/01JADGGSBR08MVB5YQ1NWT5TGX/settings/components
 */
export const NON_PRODUCTION = {
  baseUrl: 'https://statuspage.incident.io',
  jsonApiUrl:
    'https://statuspage.incident.io/status-page-sandbox-8162182495/api/v1/summary',
  hcpComponentIds: [
    '01JADGGSJTM1102ZE8F65Q3F56', // HCP Portal
    '01JADGGSJTVW1232EZB1Q4FZ23', // HCP API
    '01JN7PPZK0D0N35DG74JBB6ZXG', // HCP Consul Dedicated - this group-level ID is not returned in affected components
    '01JN7PPZG5XHAZN6E6VACNZXC3',
    '01JN7PPZG5B2Y1ANEZ5871GCTR',
    '01JADGGSJTBMFKBMB0VAV603B5', // HCP Packer
    '01JADGGSJTGXKH3956JRS04JW1', // HCP Vault Dedicated - this group-level ID is not returned in affected components
    '01JADGGSJTSFZNQRHHW51AQHQT',
    '01JADGGSJTGXKH3956JRS04JW1',
    '01JADGGSJT2EP819MN82M6FBX9', // HCP Boundary
  ],
  consulComponentIds: [
    '01JN7PPZK0D0N35DG74JBB6ZXG', // HCP Consul Dedicated - this group-level ID is not returned in affected components
  ],
  packerComponentIds: [
    '01JADGGSJTBMFKBMB0VAV603B5', // HCP Packer
  ],
  vaultComponentIds: [
    '01JADGGSJTGXKH3956JRS04JW1', // HCP Vault Dedicated - this group-level ID is not returned in affected components
  ],
};

export default {
  PRODUCTION,
  NON_PRODUCTION, // NOTE: Used in development (https://portal.hcp.dev), and/or integration (https://portal.hcp.to)
};
