import { helper } from '@ember/component/helper';
/**
 * Returns a boolean for the presence of any of the passed items in an array.
 * If the array or items are nil, returns false.
 *
 * @param {array} arr - The array to search within.
 * @param {...any} items - One or more items to search for in the array.
 * @returns {boolean} - Returns true if any item is found in the array, otherwise false.
 *
 * @example
 * {{list-includes {@model.arr} item1 item2 item3}}
 *
 **/

export default helper(([arr, ...items]) => {
  if (!arr || items.length === 0) {
    return false;
  }

  // Check if any of the items are in the array
  return items.some((item) => arr.includes(item));
});
