import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type EnginesRouterService from 'ember-engines-router-service/services/router';
import { hash } from '@ember/helper';
import { notEq } from 'ember-truth-helpers';

import { HdsAlert } from '@hashicorp/design-system-components/components';
import type { HdsAlertSignature } from '@hashicorp/design-system-components/components/hds/alert/index';
import type UserContextService from '../../services/user-context.ts';

interface SystemNotificationsPricingUpdateSignature {
  Args: {
    flash: {
      id: string;
      color: HdsAlertSignature['Args']['color'];
      icon: HdsAlertSignature['Args']['icon'];
      message: string;
      content: string;
      actionText: string;
    };
  };
}

export default class SystemNotificationsPricingUpdate extends Component<SystemNotificationsPricingUpdateSignature> {
  @service declare readonly flashMessages: FlashMessageService;
  @service declare readonly userContext: UserContextService;
  @service declare readonly router: EnginesRouterService;

  PRICING_ROUTE = 'billing.accounts.account.pricing.index';

  get activeRoute() {
    return this.router.currentRouteName;
  }

  <template>
    {{#if (notEq this.activeRoute this.PRICING_ROUTE)}}
      <HdsAlert
        @color={{@flash.color}}
        @icon={{@flash.icon}}
        @type="page"
        data-test-system-notification={{@flash.id}}
        as |A|
      >
        <A.Title>
          {{t @flash.content}}
        </A.Title>
        <A.LinkStandalone
          @color="secondary"
          @icon="external-link"
          @model={{this.userContext.billing.billingAccount.id}}
          @query={{hash org_id=this.userContext.organization.id}}
          @route={{this.PRICING_ROUTE}}
          @text={{t @flash.actionText}}
          data-test-notification-action={{@flash.id}}
        />
      </HdsAlert>
    {{/if}}
  </template>
}
