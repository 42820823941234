import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { concat } from '@ember/helper';
import { gte, or } from 'ember-truth-helpers';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import boxPadding from '../modifiers/box-padding.ts';
import { supportTicketPrefillHref } from '../utils/support-ticket.js';

import type UserContextService from '../services/user-context.ts';
import type QuotaService from '../services/quota.ts';

interface LimitBannerSignature {
  Args: {
    count: number;
    padding: string;
    variant: string;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 * The number of resources that currently exist for the given variant. If the
 * variant is 'hashicorp.consul.cluster', this would be the number of clusters.
 *
 * @argument count
 * @type {number}
 */

/**
 * Forwards this string to the box-padding modifier.
 *
 * @argument padding
 * @type {string}
 */

/**
 * Determines what translation strings to show when rendering the LimitBanner.
 *
 * @argument variant
 * @type {string}
 */

/**
 *
 * `LimitBanner` renders an alert with the given text for the passed
 *  variant (resource). This will be rendered when a limit from the quota
 * service has been exceeded.
 *
 *
 * ```
 * <LimitBanner
 *  @count={{numClusters}}
 *  @padding={{"0 0 0 0"}}
 *  @variant={{resource-type-for "consul"}}
 * />
 * ```
 *
 * @class LimitBanner
 *
 *
 */
export default class LimitBanner extends Component<LimitBannerSignature> {
  @service declare readonly quota: QuotaService;
  @service declare readonly userContext: UserContextService;

  get limit() {
    const { variant } = this.args;
    return this.quota.for(variant);
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  <template>
    {{#if (gte @count this.limit)}}
      <div data-test-limit-banner {{boxPadding (or @padding "0 0 xl")}}>
        <HdsAlert @type="inline" @color="neutral" as |A|>
          <A.Title>
            {{t
              (concat "components.limit-banner.title." @variant)
              limit=this.limit
            }}
          </A.Title>
          <A.Description>
            {{t
              (concat "components.limit-banner.content." @variant)
              limit=this.limit
              supportTicketHref=this.supportTicketHref
              htmlSafe=true
            }}
          </A.Description>
          {{yield A}}
        </HdsAlert>
      </div>
    {{/if}}
  </template>
}
