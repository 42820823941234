import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';
import cloudIamRbacMemberTypes from '../utils/cloud-iam-rbac-member-types.ts';
export * from '../utils/cloud-iam-rbac-member-types.ts';

import type { IntlService } from 'ember-intl';

export default class RbacMemberTypes extends Helper {
  @service declare readonly intl: IntlService;

  @action
  compute([value]: [string | undefined], { active }: { active?: boolean }) {
    let memberTypes = cloudIamRbacMemberTypes.map(
      ({ description, label, ...memberType }) => {
        return {
          ...memberType,
          description: this.intl.t(description),
          label: this.intl.t(label),
        };
      }
    );

    if (value) {
      return memberTypes.find((memberType) => memberType.value === value);
    }

    if (active !== undefined) {
      memberTypes = memberTypes.filter((memberType) => {
        return memberType.active === active;
      });
    }

    return memberTypes;
  }
}
