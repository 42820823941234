/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointApplicationTemplateFromJSON, HashicorpCloudWaypointApplicationTemplateToJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationTemplateBodyFromJSON(json) {
    return HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationTemplateBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationTemplateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointApplicationTemplateFromJSON(json['application_template']),
        'useModuleReadme': !exists(json, 'use_module_readme') ? undefined : json['use_module_readme'],
    };
}
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationTemplateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'application_template': HashicorpCloudWaypointApplicationTemplateToJSON(value.applicationTemplate),
        'use_module_readme': value.useModuleReadme,
    };
}
