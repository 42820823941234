import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';
import { HdsTooltipButton } from '@hashicorp/design-system-components/components';

import userAgentDisplay from '../../../helpers/user-agent-display.ts';

interface CoreUserAgentDisplaySignature {
  Args: {
    userAgent?: string | null;
  };
}

export default class CoreUserAgentDisplay extends Component<CoreUserAgentDisplaySignature> {
  <template>
    <HdsTooltipButton
      data-test-core-user-agent-display
      @text={{or @userAgent "-"}}
      @placement="bottom"
    >
      {{userAgentDisplay @userAgent}}
    </HdsTooltipButton>
  </template>
}
