import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import Box from '../box.gts';
import FlexGrid from '../flex-grid.gts';
import DetailCardHeader from './card/header.gts';
import DetailCardContent from './card/content.gts';

import type { ComponentLike } from '@glint/template';
import type { DetailCardHeaderSignature } from './card/header.gts';
import type { DetailCardContentSignature } from './card/content.gts';

interface DetailCardSignature {
  // Args: {};
  Blocks: {
    default: [
      {
        Content: ComponentLike<DetailCardContentSignature>;
        Header: ComponentLike<DetailCardHeaderSignature>;
      },
    ];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `DetailCard` wraps content for display on detail pages.
 *
 *
 * ```
 * <Detail::Card as |DC|>
 *   <DC.Header>Header Content</DC.Header>
 *   <DC.Content>Body Content</DC.Content>
 * </Detail::Card>
 * ```
 *
 * @class DetailCard
 *
 */

export default class DetailCard extends Component<DetailCardSignature> {
  <template>
    <div class="detailCard" ...attributes data-test-detail-card-container>
      <Box @padding="xs lg xs lg">
        <FlexGrid class="between-sm">
          {{yield
            (hash
              Content=(component DetailCardContent)
              Header=(component DetailCardHeader)
            )
          }}
        </FlexGrid>
      </Box>
    </div>
  </template>
}
