import Component from '@glimmer/component';
import { hash } from '@ember/helper';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import LoadingElapsed from './loading/elapsed.gts';
import LoadingHeader from './loading/header.gts';
import LoadingMessage from './loading/message.gts';

import Flex from './flex.gts';
import { AlignItem, FlexDirection } from '../utils/flex.ts';
import type { WithBoundArgs } from '@glint/template';

interface LoadingSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [
      {
        Elapsed: WithBoundArgs<typeof LoadingElapsed, never>;
        Header: WithBoundArgs<typeof LoadingHeader, never>;
        Message: WithBoundArgs<typeof LoadingMessage, never>;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `Loading` displays a loading icon by default but can also display a header
 * and a message for use when a page is "initializing" or loading any async
 * request.
 *
 *
 * ```
 * <Loading as |L|>
 *   <L.Elapsed />
 *   <L.Header>
 *     {{t 'components.page.hvns.detail.initializing.header'}}
 *   </L.Header>
 *   <L.Message>
 *     {{t 'components.page.hvns.detail.initializing.message'}}
 *   </L.Message>
 * </Loading>
 * ```
 *
 * @class Loading
 *
 */

export default class Loading extends Component<LoadingSignature> {
  <template>
    <div class="hcp-min-height-100-percent hcp-flex-centered">
      <section class="loading-state" ...attributes data-test-loading-container>
        <Flex
          @alignItems={{AlignItem.Center}}
          @direction={{FlexDirection.Column}}
          @grow={{1}}
          data-test-loading-icon-container
        >
          <HdsIcon
            class="loading-state__icon"
            @name="loading"
            @size="24"
            data-test-loading-icon
          />
          {{yield
            (hash
              Elapsed=(component LoadingElapsed)
              Header=(component LoadingHeader)
              Message=(component LoadingMessage)
            )
          }}
        </Flex>
      </section>
    </div>
  </template>
}
