import Component from '@glimmer/component';

interface StepperStepLabelSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLSpanElement;
}

export default class StepperStepLabel extends Component<StepperStepLabelSignature> {
  <template>
    <span class="stepper_stepLabel" ...attributes>
      {{yield}}
    </span>
  </template>
}
