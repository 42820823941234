import Component from '@glimmer/component';

interface LayoutCardSignature {
  Args: {
    disabled: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * Layout primitive to wrap content in a card, with a drop-shadow.
 * Disabled state has no drop shadow, lightened border, and tooltip as visual cues.
 *
 * NOTE: Intentionally unopinionated about max-width, padding, margin styles etc. to remain generic.
 *       combine with other layout components (like <Box />)
 *
 *
 * ```
 * <Layout::Card @disabled={{disabled}}>
 *   King of Hearts
 * </Layout::Card>
 * ```
 *
 * @class LayoutCard
 *
 */
export default class LayoutCard extends Component<LayoutCardSignature> {
  /**
   * @argument disabled
   * @type {Boolean}
   */

  <template>
    <div data-test-layout-card class="layout--card" disabled={{@disabled}}>
      {{yield}}
    </div>
  </template>
}
