import { hash } from '@ember/helper';
import Component from '@glimmer/component';

import ZeroStateHeader from './zero-state/header.gts';
import ZeroStateMessage from './zero-state/message.gts';
import ZeroStateAction from './zero-state/action.gts';

import type { ZeroStateHeaderSignature } from './zero-state/header.gts';
import type { ZeroStateMessageSignature } from './zero-state/message.gts';
import type { ZeroStateActionSignature } from './zero-state/action.gts';
import type { ComponentLike } from '@glint/template';

interface ZeroStateSignature {
  // Args: {};
  Blocks: {
    default: [
      {
        Header: ComponentLike<{
          // Args: {};
          Blocks: ZeroStateHeaderSignature['Blocks'];
          Element: ZeroStateHeaderSignature['Element'];
        }>;
        Message: ComponentLike<{
          // Args: {};
          Blocks: ZeroStateMessageSignature['Blocks'];
          Element: ZeroStateMessageSignature['Element'];
        }>;
        Action: ComponentLike<{
          // Args: {};
          Blocks: ZeroStateActionSignature['Blocks'];
          Element: ZeroStateActionSignature['Element'];
        }>;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `ZeroState` is displayed when a page has nothing to display. You may render
 * a header, a message, and an action.
 *
 *
 * ```
 * <ZeroState as |ZS|>
 *   <ZS.Header>
 *     {{t 'components.page.hvns.list.empty.header'}}
 *   </ZS.Header>
 *   <ZS.Message>
 *     {{t 'components.page.hvns.list.empty.message'}}
 *   </ZS.Message>
 *   <ZS.Action>
 *     <button type='submit'>
 *       {{t 'components.page.hvns.create.title'}}
 *     </button>
 *   </ZS.Action>
 * </ZeroState>
 * ```
 *
 * @class ZeroState
 *
 */
export default class ZeroState extends Component<ZeroStateSignature> {
  <template>
    <section class="zero-state" ...attributes data-test-zero-state-container>
      {{yield
        (hash
          Header=(component ZeroStateHeader)
          Message=(component ZeroStateMessage)
          Action=(component ZeroStateAction)
        )
      }}
    </section>
  </template>
}
