import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { not } from 'ember-truth-helpers';
import { HdsButton } from '@hashicorp/design-system-components/components';

import Flex from '../flex.gts';
import { JustifyContent } from '../../utils/flex.ts';
import { AlignItem } from '../../utils/flex.ts';
import { FlexGap } from '../../utils/flex.ts';
import boxPadding from '../../modifiers/box-padding.ts';

interface SplitListPaginationSignature {
  Args: {
    onNextPage?: () => void;
    onPreviousPage?: () => void;
  };
  Blocks: {
    default: [];
  };
}

/**
 *
 * `SplitListPagination` provides pagination controls for the `SplitList`.
 *
 * These controls are optional, the consumer does not have to render them if pagination
 * is not applicable to their use case.
 *
 * The pagination component takes two arguments:
 * - `onNextPage`: callback that is invoked when the next page button is clicked
 * - `onPreviousPage`: callback that is invoked when the previous page button is clicked
 *
 * These callbacks should only be provided *if* the associated action is available. For example:
 * - the `onNextPage` argument should not be provided if the current page is the last available page
 * - the `onPreviousPage` argument should not be provided if the current page is the first available page
 *
 * ```
 * <SplitList
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}} as |SL|
 * >
 *   <SL.Pagination
 *     @onNextPage={{if this.nextPageToken this.onNext}}
 *     @onPreviousPage={{if this.prevPageToken this.onPrev}}
 *   />
 * </SplitList>
 * ```
 *
 * @class SplitListPagination
 *
 */

export default class SplitListPagination extends Component<SplitListPaginationSignature> {
  onNextPage = () => {
    const { onNextPage } = this.args;
    onNextPage?.();
  };

  onPreviousPage = () => {
    const { onPreviousPage } = this.args;
    onPreviousPage?.();
  };

  <template>
    <Flex
      @justifyContent={{JustifyContent.End}}
      @alignItems={{AlignItem.Center}}
      @gap={{FlexGap.Xs}}
      class="split-list__pagination-content"
      data-test-split-list-pagination
      {{boxPadding "xs"}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        <Flex>
          <HdsButton
            @isIconOnly={{true}}
            @icon="chevron-left"
            @text="Previous page"
            @size="small"
            @color="tertiary"
            disabled={{not @onPreviousPage}}
            data-test-split-list-pagination-previous-page
            {{on "click" this.onPreviousPage}}
          />
          <HdsButton
            @isIconOnly={{true}}
            @icon="chevron-right"
            @text="Next page"
            @iconPosition="trailing"
            @size="small"
            @color="tertiary"
            disabled={{not @onNextPage}}
            data-test-split-list-pagination-next-page
            {{on "click" this.onNextPage}}
          />
        </Flex>
      {{/if}}
    </Flex>
  </template>
}
