import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import SplitListLeftPaneListItem from './left-pane/list-item.gts';

import type { TaskForTaskFunction, TaskFunction } from 'ember-concurrency';

interface SplitListLeftPaneSignature<DetailType, ListItemType> {
  Args: {
    onSelectItem: TaskForTaskFunction<TaskFunction<DetailType, [ListItemType]>>;
    identifyListItem: (listItem: ListItemType) => string;
    selectedItemIdentifier: string | null;
    detailElementId: string;
    listData: ListItemType[];
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLUListElement;
}

/**
 *
 * `SplitListLeftPane` renders a container for left side list content in the `SplitList`
 *
 * `SplitListLeftPane` yields a hash with two objects available to the consumer:
 * - `ListData`: the array of data provided to the `SplitList`
 * - `ListItem`: a component the consumer renders for each item that should be visible in the left side list
 *
 * The left pane will be auto sized to its content, and the user can provide an explicit width to this content by applying a class
 * to the `LeftPane` or by indicating an explicit width on the content rendered in their `ListItem`s.
 *
 * ```
 * <SplitList
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}} as |SL|
 * >
 *   <SL.LeftPane as |LP|>
 *     {{#each LP.ListData as |listItem|}}
 *       <LP.ListItem @item={{listItem}}>
 *         <div>
 *           List item content
 *           {{listItem.id}}
 *         </div>
 *       </LP.ListItem>
 *     {{/each}}
 *   </SL.LeftPane>
 * </SplitList>
 * ```
 *
 * @class SplitListLeftPane
 *
 */

export default class SplitListLeftPane<
  ListItemType,
  DetailType,
> extends Component<SplitListLeftPaneSignature<ListItemType, DetailType>> {
  <template>
    <ul
      class="split-list__left-pane"
      role="tablist"
      aria-orientation="vertical"
      data-test-split-list-left-pane
      ...attributes
    >
      {{yield
        (hash
          ListItem=(component
            SplitListLeftPaneListItem
            selectedItemIdentifier=@selectedItemIdentifier
            onSelectItem=@onSelectItem
            identifyListItem=@identifyListItem
            detailElementId=@detailElementId
          )
          ListData=@listData
        )
      }}
    </ul>
  </template>
}
