import Component from '@glimmer/component';
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq, and } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import {
  HdsButton,
  HdsButtonSet,
  HdsDropdown,
  HdsIcon,
  HdsTag,
  HdsTooltipButton,
} from '@hashicorp/design-system-components/components';
import hdsTooltip from '@hashicorp/design-system-components/modifiers/hds-tooltip';
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import Flex from 'core/components/flex';
import Button from 'core/components/button';
import boxPadding from 'core/modifiers/box-padding';
import boxMargin from 'core/modifiers/box-margin';

import FilterBarDateRangeDropdown from './filter-bar/date-range-dropdown.gts';
import FilterBarMultiSelectDropdown from './filter-bar/multi-select-dropdown.gts';
import FilterBarSingleSelectDropdown from './filter-bar/single-select-dropdown.gts';
import FilterBarTextSearchDropdown from './filter-bar/text-search-dropdown.gts';
import { DropdownManager } from '../utils/filter-bar/dropdown-manager.ts';

import './filter-bar.scss';

export interface FilterBarSignature {
  Args: {
    hideAdditionalActions: boolean;
    dropdownManager: DropdownManager;
    hideExpandedFilters: boolean;
    toggleExpandedFilters: () => boolean;
    hasMultipleFilterRows: boolean;
    showDatePicker: boolean;
    listPosition?: 'right' | 'left';
  };

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

type SelectedValues = {
  key: string;
  value: string[];
};

/**
 * The FilterBar component is a component containing a collection of dropdowns
 * that can be used to create compound filter criteria.
 *
 * The FilterBar component is configuration based, and instantiated though the DropdownManager class.
 *
 * Here is an example featuring the four unique dropdown types:
 *
 * ```js
 *  selectionCallback(updatedValues: object) {
 *    console.log('selectionCallback', updatedValues);
 *  }
 *
 *  dropdownManager = new DropdownManager(
 *   [
 *      new DropdownConfig('DATE_RANGE', 'dateRange', 'Date', [
 *      new DropdownListItem(
 *        DropdownListItemType.DATE_RANGE,
 *         'Last 24 hours',
 *         '24-hour'
 *      ),
 *      new DropdownListItem(
 *         DropdownListItemType.DATE_RANGE,
 *         'Last 7 days',
 *        '7-day'
 *       ),
 *       new DropdownListItem(
 *         DropdownListItemType.DATE_RANGE,
 *         'Custom Range',
 *         'custom'
 *       ),
 *       options: { maxLookbackInDays: 30, maxLookaheadInDays: 0 }, // optional, use these to constrain the selectable date values
 *     ]),
 *     new DropdownConfig('SELECT', 'singleSelect', 'Single Select', [
 *       new DropdownListItem(
 *         DropdownListItemType.SINGLE_SELECT,
 *         'First Option',
 *         'option1'
 *       ),
 *       new DropdownListItem(
 *         DropdownListItemType.SINGLE_SELECT,
 *         'Second Option',
 *         'option2'
 *       ),
 *     ]),
 *     new DropdownConfig('RAWTEXT_SEARCH', 'rawText', 'Raw Text', [
 *       new DropdownListItem(
 *         DropdownListItemType.STRING_SEARCH,
 *         'Raw Text',
 *         '',
 *         'e.g. path/foo/bar'
 *       ),
 *     ]),
 *     new DropdownConfig('MULTISELECT', 'operation', 'Operation', [
 *       new DropdownListItem(DropdownListItemType.CHECKBOX, 'Create', 'create'),
 *       new DropdownListItem(DropdownListItemType.CHECKBOX, 'Update', 'update'),
 *       new DropdownListItem(DropdownListItemType.CHECKBOX, 'Delete', 'delete'),
 *     ]),
 *    ],
 *   this.selectionCallback
 * );
 * ```
 *
 * There is also a convenience function to generate the dropdown instance using an array of POJOs:
 *
 * ```js
 * {
 *        dropdownType: 'SELECT',
 *        id: 'time',
 *        label: 'Time',
 *        listItems: [
 *          {
 *            optionType: 'SINGLE_SELECT',
 *            optionValue: 'first_option',
 *            optionLabel: 'First Option',
 *          },
 *          {
 *            optionType: 'SINGLE_SELECT',
 *            optionValue: 'second_option',
 *            optionLabel: 'Second Option',
 *          },
 *          {
 *            optionType: 'SINGLE_SELECT',
 *            optionValue: 'third_option',
 *            optionLabel: 'Third Option',
 *        },
 *      ],
 *    },
 *  ];
 * this.dropdownManager = dropdownManager(dropdownConfig);
 * ```
 *
 * You will likely want to use translated strings for the `optionLabel` value.
 * The class helper loses the binding, so you will have to manually bind the
 * translation to the component. Here's an example of how to make that work:
 *
 * ```ts
 *  @service intl;
 *
 *  @action translate(translationPath:string) {
 *    return this.intl.t(translationPath)
 *   }
 *
 * dropdownManager = new DropdownManager(
 *   [
 *     new DropdownConfig('DATE_RANGE', 'dateRange', 'Date', [
 *       new DropdownListItem(
 *         DropdownListItemType.DATE_RANGE,
 *         this.translate('path.to-translation'),
 *         '24-hour'
 *       ),
 *       ...
 *     ]
 *   ]
 * );
 * ```
 *
 * In order to track user interactions with the filter bar, pass a third optional callback. This will get called on filter change and reset, but only when it's triggered from a user interaction (not on dropdown init). It will also return all the dropdown selections, rather than only the one changed (which is what is returned to the selectionCallback). We have also published a helper `getTrackingParam` to normalize the dropdownSelections into an object.
 *
 *  ```ts
 *  @service analytics;
 *
 *  @action trackAnalytics(dropdownSelections?:DropdownSelection[]) {
 *      if (!dropdownSelections) {
 *        // when dropdownSelections is falsy, it's a reset
 *        this.analytics.trackEvent('FILTERS_RESET')
 *        return
 *      }
 *      const filters = getTrackingParams(dropdownSelections, ['operations'])
 *       // the helper returns the actual values for the second array params, or the count:
 *       //  {
 *       //    operation: ['value1', 'value2'],
 *       //    path: 4
 *       //  }
 *      this.analytics.trackEvent('FILTERS_CLICK', { filters })
 *    }
 *
 *  dropdownManager = new DropdownManager(
 *    [
 *       // dropdown config
 *    ],
 *    this.selectionCallback,
 *    this.trackAnalytics
 *  )
 * ```
 *
 *  * To use the component, invoke it and pass in the dropdownManager instance:
 *
 * ```hbs
 *   <FilterBar @dropdownManager={{this.dropdownManager}} />
 *  ```
 *  Out of the box, when an item is in the selected state, an additional actions section containing a 'Clear' button
 * will appear. This section will also display a 'More' button if any dropdowns are wrapped due to
 * exceeding the maximum width of the filter bar. This may be hidden by passing @hideAdditionalActions={{true}}
 * to the FilterBar component.
 *
 * By default, the listPosition property on dropdowns is set to 'bottom-right'. Override this by providing
 * @listPosition='bottom-left' to the FilterBar component.
 *
 * On a change in any of the filter criteria, the DropdownManager provides the changed filter
 * to the callback function as an object where the key is the name of the filter, and the value
 * is an array containing the updated values, for example:
 *
 * ```js
 *    // single select example
 *  {
 *    dropdown1: ['option1'],
 *  }
 *
 *  // multiselect example
 *  {
 *    dropdown2: ['option3,option4'],
 *  }
 *
 *  // following dropdown reset
 *  {
 *    dropdown1: [],
 *  }
 *
 *  // dateRange example
 *  {
 *    dropdown3: ['1661992375:1663288382']
 *  }
 * ```
 *
 * @class FilterBar
 */

export default class FilterBar extends Component<FilterBarSignature> {
  // Collects selected values from all of the dropdowns, puts them in an array of SelectedValues
  get activeFilters() {
    const { currentDropdownSelections } = this.args.dropdownManager;

    const reducedSelections = currentDropdownSelections.reduce(
      (accumulatedDropdowns: SelectedValues[], currentDropdown) => {
        const dropdownValues = Object.values(currentDropdown);
        const dropdownKeys = Object.keys(currentDropdown);
        if (dropdownValues[0]) {
          accumulatedDropdowns.push({
            key: dropdownKeys[0] as string,
            value: dropdownValues[0] as string[],
          });
        }
        return accumulatedDropdowns;
      },
      [],
    );
    return reducedSelections;
  }

  uncheckSingleSelect = (dropdownId: string, value: string) => {
    const dropdown = this.args.dropdownManager.getDropdownById(dropdownId);
    if (
      dropdown?.dropdownType === 'DATE_RANGE' ||
      dropdown?.dropdownType === 'SELECT'
    ) {
      this.args.dropdownManager.resetDropdownSelection(dropdownId);
    } else {
      dropdown?.onUnselect(value);
      const selectedItems = dropdown.listItems.map((item) => {
        if (item.isSelected) return item.optionValue;
      });
      if (selectedItems.length) {
        this.args.dropdownManager.submitDropdownSelection(
          dropdownId,
          selectedItems as string[],
        );
      } else {
        this.args.dropdownManager.resetDropdownSelection(dropdownId);
      }
    }
  };

  getDropdownLabel = (dropdownId: string) => {
    return (
      this.args.dropdownManager.getDropdownById(dropdownId).label || dropdownId
    );
  };
  getKey = (dropdownId: string, value: string) => {
    return this.args.dropdownManager.getKey(dropdownId, value) || value;
  };

  <template>
    <Flex @direction={{FlexDirection.Column}} data-test-request-filter-bar>
      <HdsButtonSet
        data-test-request-filter-button-set
        class="vi__request-history-filter-bar
          {{if @hideExpandedFilters 'vi__hide-overflow'}}"
      >
        {{#each @dropdownManager.dropdowns as |dropdownConfig|}}
          {{#let
            (fn @dropdownManager.submitDropdownSelection dropdownConfig.id)
            as |curriedSubmitFunction|
          }}
            <HdsDropdown
              data-test-dropdown-filter
              {{! @glint-expect-error }}
              @listPosition={{@listPosition}}
              {{boxPadding "xs 0 2xs xs"}}
              as |dd|
            >
              <dd.ToggleButton
                data-test-dropdown-toggle
                class="vi__dropdown-filter-text
                  {{if
                    dropdownConfig.selectedValues.length
                    'filterbar-filter-selected'
                    'filterbar-filter-unselected'
                  }}"
                @text={{dropdownConfig.displayName}}
                {{hdsTooltip
                  dropdownConfig.searchTextTooltip
                  options=(hash placement="bottom")
                }}
              />
              {{#if (eq dropdownConfig.dropdownType "DATE_RANGE")}}
                <FilterBarDateRangeDropdown
                  @name={{dropdownConfig.id}}
                  {{! @glint-expect-error }}
                  @dd={{dd}}
                  @listItems={{dropdownConfig.listItems}}
                  {{! @glint-expect-error }}
                  @onSubmit={{curriedSubmitFunction}}
                  {{! @glint-expect-error }}
                  @dateRange={{dropdownConfig.dateRange}}
                  @trackInteraction={{@dropdownManager.trackInteraction}}
                />
              {{else if (eq dropdownConfig.dropdownType "MULTISELECT")}}
                <FilterBarMultiSelectDropdown
                  {{! @glint-expect-error }}
                  @dd={{dd}}
                  @listItems={{dropdownConfig.listItems}}
                  @onSubmit={{curriedSubmitFunction}}
                  @selectedValues={{dropdownConfig.selectedValues}}
                  @trackInteraction={{@dropdownManager.trackInteraction}}
                />
              {{else if (eq dropdownConfig.dropdownType "SELECT")}}
                <FilterBarSingleSelectDropdown
                  @name={{dropdownConfig.id}}
                  {{! @glint-expect-error }}
                  @dd={{dd}}
                  @listItems={{dropdownConfig.listItems}}
                  {{! @glint-expect-error }}
                  @onSubmit={{curriedSubmitFunction}}
                  @selectedValues={{dropdownConfig.selectedValues}}
                  @trackInteraction={{@dropdownManager.trackInteraction}}
                  {{! @glint-expect-error }}
                  @excludeUnselectedOption={{dropdownConfig.options.excludeUnselectedOption}}
                />
              {{else}}
                {{#each dropdownConfig.listItems as |listItem|}}
                  {{#if (eq listItem.optionType "DESCRIPTION")}}
                    <dd.Description @text={{listItem.optionLabel}} />
                  {{else if (eq listItem.optionType "TITLE")}}
                    <dd.Title @text={{listItem.optionLabel}} />
                  {{else if (eq listItem.optionType "SEPARATOR")}}
                    <dd.Separator />
                  {{else if (eq listItem.optionType "STRING_SEARCH")}}
                    <dd.Generic>
                      <FilterBarTextSearchDropdown
                        {{! @glint-expect-error }}
                        @dd={{dd}}
                        {{! @glint-expect-error }}
                        @onSubmit={{curriedSubmitFunction}}
                        @textValue={{listItem.optionValue}}
                        {{! @glint-expect-error }}
                        @helperText={{listItem.helperText}}
                        @selectedValues={{dropdownConfig.selectedValues}}
                        @resetDropdown={{fn
                          @dropdownManager.resetDropdownSelection
                          dropdownConfig.id
                        }}
                        @searchHistory={{dropdownConfig.selectionHistory}}
                        @trackInteraction={{@dropdownManager.trackInteraction}}
                        @validator={{dropdownConfig.options.validator}}
                      />
                    </dd.Generic>
                  {{/if}}
                {{/each}}
              {{/if}}
            </HdsDropdown>
          {{/let}}
        {{/each}}
      </HdsButtonSet>
      {{#unless @hideAdditionalActions}}
        <HdsButtonSet class="vi__more-actions" {{boxPadding "2xs 0 2xs md"}}>
          {{#if (and @hasMultipleFilterRows @toggleExpandedFilters)}}
            {{! @glint-expect-error }}
            <Button
              data-test-show-more-button
              type="button"
              class="filterbar-filter-additional-actions"
              {{on "click" @toggleExpandedFilters}}
            >
              <HdsIcon
                @name={{if @hideExpandedFilters "filter-circle" "filter-fill"}}
                @isInline={{true}}
              />
              <div {{boxPadding "0 0 0 2xs"}}>
                {{if
                  @hideExpandedFilters
                  (t
                    "vault-common.components.filter-bar.filter-buttons.show-more"
                  )
                  (t
                    "vault-common.components.filter-bar.filter-buttons.show-less"
                  )
                }}
              </div>
            </Button>
          {{/if}}
        </HdsButtonSet>
        {{#if @dropdownManager.hasActiveSelections}}
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @wrap={{true}}
            {{boxMargin "0 xs"}}
          >
            {{#each this.activeFilters as |filter|}}
              {{#if filter.value.length}}
                <span {{boxMargin "0 0 0 xs"}}>
                  {{this.getDropdownLabel filter.key}}:
                </span>
                {{#each filter.value as |value|}}
                  <HdsTag
                    data-test-filter-tag
                    {{boxMargin "0 0 0 xs"}}
                    @text={{this.getKey filter.key value}}
                    @onDismiss={{fn this.uncheckSingleSelect filter.key value}}
                  />
                {{/each}}
              {{/if}}
            {{/each}}
            <HdsButton
              data-test-reset-all-button
              {{boxMargin "0 0 0 sm"}}
              @text={{t
                "vault-common.components.filter-bar.filter-buttons.clear-all"
              }}
              @color="tertiary"
              @icon="x"
              {{on "click" @dropdownManager.resetAllDropdowns}}
            />
          </Flex>
        {{else}}
          <Flex
            @direction={{FlexDirection.Row}}
            @alignItems={{AlignItem.Center}}
            @gap={{FlexGap.Xs}}
            data-test-no-filters
          >
            <p class="hds-typography-body-200 hds-foreground-faint">
              {{t "vault-common.components.filter-bar.no-filters"}}
            </p>
            <HdsTooltipButton
              aria-label={{t "vault-common.components.filter-bar.tooltip-aria"}}
              @text={{t "vault-common.components.filter-bar.tooltip"}}
            >
              <HdsIcon @name="info" @isInline={{true}} />
            </HdsTooltipButton>
          </Flex>
        {{/if}}
      {{/unless}}
    </Flex>
  </template>
}
