/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105GetOrganizationOwnershipResponseFromJSON, } from '../models';
/**
 *
 */
export class OrganizationOwnershipServiceApi extends runtime.BaseAPI {
    /**
     * Create creates a new organization ownership and business information record.
     */
    async organizationOwnershipServiceCreateRaw(requestParameters) {
        if (requestParameters.organizationOwnershipOrganizationId === null || requestParameters.organizationOwnershipOrganizationId === undefined) {
            throw new runtime.RequiredError('organizationOwnershipOrganizationId', 'Required parameter requestParameters.organizationOwnershipOrganizationId was null or undefined when calling organizationOwnershipServiceCreate.');
        }
        const queryParameters = {};
        if (requestParameters.organizationOwnershipUpdatedByUserId !== undefined) {
            queryParameters['organization_ownership.updated_by_user_id'] = requestParameters.organizationOwnershipUpdatedByUserId;
        }
        if (requestParameters.organizationOwnershipCreatedAt !== undefined) {
            queryParameters['organization_ownership.created_at'] = requestParameters.organizationOwnershipCreatedAt.toISOString();
        }
        if (requestParameters.organizationOwnershipUpdatedAt !== undefined) {
            queryParameters['organization_ownership.updated_at'] = requestParameters.organizationOwnershipUpdatedAt.toISOString();
        }
        if (requestParameters.organizationOwnershipBusinessBusinessName !== undefined) {
            queryParameters['organization_ownership.business.business_name'] = requestParameters.organizationOwnershipBusinessBusinessName;
        }
        if (requestParameters.organizationOwnershipBusinessAddressLine1 !== undefined) {
            queryParameters['organization_ownership.business.address_line1'] = requestParameters.organizationOwnershipBusinessAddressLine1;
        }
        if (requestParameters.organizationOwnershipBusinessAddressLine2 !== undefined) {
            queryParameters['organization_ownership.business.address_line2'] = requestParameters.organizationOwnershipBusinessAddressLine2;
        }
        if (requestParameters.organizationOwnershipBusinessCity !== undefined) {
            queryParameters['organization_ownership.business.city'] = requestParameters.organizationOwnershipBusinessCity;
        }
        if (requestParameters.organizationOwnershipBusinessState !== undefined) {
            queryParameters['organization_ownership.business.state'] = requestParameters.organizationOwnershipBusinessState;
        }
        if (requestParameters.organizationOwnershipBusinessPostalCode !== undefined) {
            queryParameters['organization_ownership.business.postal_code'] = requestParameters.organizationOwnershipBusinessPostalCode;
        }
        if (requestParameters.organizationOwnershipBusinessCountryCode !== undefined) {
            queryParameters['organization_ownership.business.country_code'] = requestParameters.organizationOwnershipBusinessCountryCode;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_ownership.organization_id}/ownership`.replace(`{${"organization_ownership.organization_id"}}`, encodeURIComponent(String(requestParameters.organizationOwnershipOrganizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Create creates a new organization ownership and business information record.
     */
    async organizationOwnershipServiceCreate(organizationOwnershipOrganizationId, organizationOwnershipUpdatedByUserId, organizationOwnershipCreatedAt, organizationOwnershipUpdatedAt, organizationOwnershipBusinessBusinessName, organizationOwnershipBusinessAddressLine1, organizationOwnershipBusinessAddressLine2, organizationOwnershipBusinessCity, organizationOwnershipBusinessState, organizationOwnershipBusinessPostalCode, organizationOwnershipBusinessCountryCode) {
        const response = await this.organizationOwnershipServiceCreateRaw({ organizationOwnershipOrganizationId: organizationOwnershipOrganizationId, organizationOwnershipUpdatedByUserId: organizationOwnershipUpdatedByUserId, organizationOwnershipCreatedAt: organizationOwnershipCreatedAt, organizationOwnershipUpdatedAt: organizationOwnershipUpdatedAt, organizationOwnershipBusinessBusinessName: organizationOwnershipBusinessBusinessName, organizationOwnershipBusinessAddressLine1: organizationOwnershipBusinessAddressLine1, organizationOwnershipBusinessAddressLine2: organizationOwnershipBusinessAddressLine2, organizationOwnershipBusinessCity: organizationOwnershipBusinessCity, organizationOwnershipBusinessState: organizationOwnershipBusinessState, organizationOwnershipBusinessPostalCode: organizationOwnershipBusinessPostalCode, organizationOwnershipBusinessCountryCode: organizationOwnershipBusinessCountryCode });
        return await response.value();
    }
    /**
     * Get retrieve the organization ownership and business information record for an organization.
     */
    async organizationOwnershipServiceGetRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling organizationOwnershipServiceGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/ownership`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetOrganizationOwnershipResponseFromJSON(jsonValue));
    }
    /**
     * Get retrieve the organization ownership and business information record for an organization.
     */
    async organizationOwnershipServiceGet(organizationId) {
        const response = await this.organizationOwnershipServiceGetRaw({ organizationId: organizationId });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum;
(function (OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum) {
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UNSET"] = "UNSET";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AF"] = "AF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AL"] = "AL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AQ"] = "AQ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DZ"] = "DZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AS"] = "AS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AD"] = "AD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AO"] = "AO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AG"] = "AG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AZ"] = "AZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AR"] = "AR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AU"] = "AU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AT"] = "AT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BS"] = "BS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BH"] = "BH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BD"] = "BD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AM"] = "AM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BB"] = "BB";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BE"] = "BE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BM"] = "BM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BT"] = "BT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BO"] = "BO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BA"] = "BA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BW"] = "BW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BV"] = "BV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BR"] = "BR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BZ"] = "BZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IO"] = "IO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SB"] = "SB";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VG"] = "VG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BN"] = "BN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BG"] = "BG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MM"] = "MM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BI"] = "BI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BY"] = "BY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KH"] = "KH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CM"] = "CM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CA"] = "CA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CV"] = "CV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KY"] = "KY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CF"] = "CF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LK"] = "LK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TD"] = "TD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CL"] = "CL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CN"] = "CN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TW"] = "TW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CX"] = "CX";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CC"] = "CC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CO"] = "CO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KM"] = "KM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["YT"] = "YT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CG"] = "CG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CD"] = "CD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CK"] = "CK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CR"] = "CR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HR"] = "HR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CU"] = "CU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CY"] = "CY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CZ"] = "CZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BJ"] = "BJ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DK"] = "DK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DM"] = "DM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DO"] = "DO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["EC"] = "EC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SV"] = "SV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GQ"] = "GQ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ET"] = "ET";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ER"] = "ER";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["EE"] = "EE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FO"] = "FO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FK"] = "FK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GS"] = "GS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FJ"] = "FJ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FI"] = "FI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AX"] = "AX";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FR"] = "FR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GF"] = "GF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PF"] = "PF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TF"] = "TF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DJ"] = "DJ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GA"] = "GA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GE"] = "GE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GM"] = "GM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PS"] = "PS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["DE"] = "DE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GH"] = "GH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GI"] = "GI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KI"] = "KI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GR"] = "GR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GL"] = "GL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GD"] = "GD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GP"] = "GP";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GU"] = "GU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GT"] = "GT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GN"] = "GN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GY"] = "GY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HT"] = "HT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HM"] = "HM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VA"] = "VA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HN"] = "HN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HK"] = "HK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["HU"] = "HU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IS"] = "IS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IN"] = "IN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ID"] = "ID";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IR"] = "IR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IQ"] = "IQ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IE"] = "IE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IL"] = "IL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IT"] = "IT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CI"] = "CI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["JM"] = "JM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["JP"] = "JP";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KZ"] = "KZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["JO"] = "JO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KE"] = "KE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KP"] = "KP";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KR"] = "KR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KW"] = "KW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KG"] = "KG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LA"] = "LA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LB"] = "LB";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LS"] = "LS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LV"] = "LV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LR"] = "LR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LY"] = "LY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LI"] = "LI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LT"] = "LT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LU"] = "LU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MO"] = "MO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MG"] = "MG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MW"] = "MW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MY"] = "MY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MV"] = "MV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ML"] = "ML";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MT"] = "MT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MQ"] = "MQ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MR"] = "MR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MU"] = "MU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MX"] = "MX";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MC"] = "MC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MN"] = "MN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MD"] = "MD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ME"] = "ME";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MS"] = "MS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MA"] = "MA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MZ"] = "MZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["OM"] = "OM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NA"] = "NA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NR"] = "NR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NP"] = "NP";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NL"] = "NL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CW"] = "CW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AW"] = "AW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SX"] = "SX";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BQ"] = "BQ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NC"] = "NC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VU"] = "VU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NZ"] = "NZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NI"] = "NI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NE"] = "NE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NG"] = "NG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NU"] = "NU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NF"] = "NF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["NO"] = "NO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MP"] = "MP";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UM"] = "UM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["FM"] = "FM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MH"] = "MH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PW"] = "PW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PK"] = "PK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PA"] = "PA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PG"] = "PG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PY"] = "PY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PE"] = "PE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PH"] = "PH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PN"] = "PN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PL"] = "PL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PT"] = "PT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GW"] = "GW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TL"] = "TL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PR"] = "PR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["QA"] = "QA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["RE"] = "RE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["RO"] = "RO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["RU"] = "RU";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["RW"] = "RW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BL"] = "BL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SH"] = "SH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["KN"] = "KN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AI"] = "AI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["LC"] = "LC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MF"] = "MF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["PM"] = "PM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VC"] = "VC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SM"] = "SM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ST"] = "ST";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SA"] = "SA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SN"] = "SN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["RS"] = "RS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SC"] = "SC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SL"] = "SL";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SG"] = "SG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SK"] = "SK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VN"] = "VN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SI"] = "SI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SO"] = "SO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ZA"] = "ZA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ZW"] = "ZW";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ES"] = "ES";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SS"] = "SS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SD"] = "SD";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["EH"] = "EH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SR"] = "SR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SJ"] = "SJ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SZ"] = "SZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SE"] = "SE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["CH"] = "CH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["SY"] = "SY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TJ"] = "TJ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TH"] = "TH";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TG"] = "TG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TK"] = "TK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TO"] = "TO";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TT"] = "TT";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["AE"] = "AE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TN"] = "TN";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TR"] = "TR";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TM"] = "TM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TC"] = "TC";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TV"] = "TV";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UG"] = "UG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UA"] = "UA";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["MK"] = "MK";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["EG"] = "EG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GB"] = "GB";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["GG"] = "GG";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["JE"] = "JE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["IM"] = "IM";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["TZ"] = "TZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["US"] = "US";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VI"] = "VI";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["BF"] = "BF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UY"] = "UY";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["UZ"] = "UZ";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["VE"] = "VE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["WF"] = "WF";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["WS"] = "WS";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["YE"] = "YE";
    OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum["ZM"] = "ZM";
})(OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum || (OrganizationOwnershipServiceCreateOrganizationOwnershipBusinessCountryCodeEnum = {}));
