import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import Actions from './footer/actions.gts';
import Cancel from './footer/cancel.gts';

import type { ModalDialogFooterActionsSignature } from './footer/actions.gts';
import type { WithBoundArgs, ComponentLike } from '@glint/template';

export interface ModalDialogFooterSignature {
  Args: {
    closeModalDialog?: () => void;
  };
  Blocks: {
    default: [
      {
        Actions: ComponentLike<{
          Element: ModalDialogFooterActionsSignature['Element'];
          Blocks: ModalDialogFooterActionsSignature['Blocks'];
          Args: ModalDialogFooterActionsSignature['Args'];
        }>;
        Cancel: WithBoundArgs<typeof Cancel, 'closeModalDialog' | 'text'>;
      },
    ];
  };
  Element: HTMLDivElement;
}

export default class ModalDialogFooter extends Component<ModalDialogFooterSignature> {
  <template>
    <footer data-test-modal-dialog-footer-container>
      {{yield
        (hash
          Actions=(component Actions)
          Cancel=(component Cancel closeModalDialog=@closeModalDialog)
        )
      }}
    </footer>
  </template>
}
