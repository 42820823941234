/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointTFCConfigFromJSON(json) {
    return HashicorpCloudWaypointTFCConfigFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTFCConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationName': !exists(json, 'organization_name') ? undefined : json['organization_name'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}
export function HashicorpCloudWaypointTFCConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_name': value.organizationName,
        'token': value.token,
    };
}
