import Component from '@glimmer/component';

interface VerticalRuleSignature {
  // Args: {};
}

/**
 *
 * `VerticalRule` renders a vertical rule visual element.
 *
 *
 * ```
 * <VerticalRule />
 * ```
 *
 * @class VerticalRule
 *
 */

export default class VerticalRule extends Component<VerticalRuleSignature> {
  <template>
    <div class="verticalRule" data-test-vertical-rule></div>
  </template>
}
