import Component from '@glimmer/component';

interface DocumentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class Document extends Component<DocumentSignature> {
  <template>
    <article class="document" data-test-document ...attributes>
      {{yield}}
    </article>
  </template>
}
