import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import Disclosure from './disclosure.gts';

interface DisclosureGuideSignature {
  Args: {
    content: [
      {
        helpText: string;
        imgSrc: string;
        imgAlt: string;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `DisclosureGuide` renders  `<Disclosure />` styled with an icon instead of
 *  the default indicator. It also will change the summary text based on whether
 *  the element is open. Additionally, it renders `<Hds::Alert />` followed by
 *  an `<img />` element. This component was created to provide users with a
 *  guide with imagery while completing various input fields within HCP. This
 *  component can render multiple `<Hds::Alert />` and a corresponding `<img />`.
 *
 *
 * ## Example usage
 *
 * ```
 * <DisclosureGuide
 *   @content={{this.disclosureGuides.accountId}}
 * />
 * ```
 *
 * @class DisclosureGuide
 *
 */

export default class DisclosureGuide extends Component<DisclosureGuideSignature> {
  /**
   * `@content` is an array of objects that contains the helpText, imgSrc, and
   *  imgAlt that is rendered in `<DisclosureGuide />`.
   *
   * @argument content
   * @type {object}
   * @required
   */

  <template>
    <Disclosure
      ...attributes
      data-test-disclosure-guide
      class="disclosure-guide"
      @label={{t "components.disclosure-guide.where-can-i-find-this"}}
      @openLabel={{t "components.disclosure-guide.where-can-i-find-this"}}
    >
      {{#each @content as |item|}}
        <p>
          {{#if item.helpText}}
            <HdsAlert @type="compact" @color="neutral" as |A|>
              <A.Description data-test-help-text>
                {{t item.helpText htmlSafe=true}}
              </A.Description>
            </HdsAlert>
          {{/if}}
          <img
            data-test-image
            src={{item.imgSrc}}
            alt={{t item.imgAlt htmlSafe=true}}
          />
        </p>
      {{/each}}
    </Disclosure>
  </template>
}
