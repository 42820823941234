import { BILLING_ON_DEMAND_STATUS } from '../constants/status.js';

export default class OnDemandStatus {
  #onDemandStatus;

  constructor(onDemandStatus) {
    this.#onDemandStatus = onDemandStatus;
  }

  get isOnDemandActive() {
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.ACTIVE;
  }

  get isRemovingCreditCard() {
    // An account will be set to 'trial' in some cases when a credit card is
    // in a long async process of being removed. During this phase, the credit
    // card is still set.
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.DELETING;
  }

  get isOnDemandUnset() {
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.UNSET;
  }

  valueOf() {
    return this.#onDemandStatus;
  }
}
