import { hash } from '@ember/helper';
import Component from '@glimmer/component';

import ChartsPercentageBarChart from './percentage-bar/chart.gts';
import ChartsPercentageBarSummary from './percentage-bar/summary.gts';
import ChartsPercentageBarSubtitle from './percentage-bar/subtitle.gts';
import FlexGrid from '../flex-grid.gts';
import Typography from '../typography.gts';
import type { WithBoundArgs } from '@glint/template';

interface ChartsPercentageBarSignature {
  Args: {
    getState?: (value: number) => 'ok' | 'warning' | 'danger';
    title?: string;
    value: number;
  };
  Blocks: {
    default?: [
      {
        Chart: WithBoundArgs<
          typeof ChartsPercentageBarChart,
          'state' | 'value'
        >;
        Summary: WithBoundArgs<typeof ChartsPercentageBarSummary, 'value'>;
        Subtitle: WithBoundArgs<typeof ChartsPercentageBarSubtitle, 'state'>;
        value: number;
        state: 'ok' | 'warning' | 'danger';
      },
    ];
  };
  Element: HTMLDivElement | HTMLElement;
}

// TODO: enable when this is fixed: https://github.com/thgh/rollup-plugin-scss/issues/70
// import './index.scss';

/**
 *
 * `PercentageBar` renders a horizontal bar chart that represents the percentage of something. \
 *  - it includes space for a title, a subtitle with an optional state, and a summary (showing X%)
 *  - it also yields its components (Chart, Summary, and Subtitle) as well as the API (state, value)
 *
 *  * ```
 * <PercentageBar
 *   @title={{"A very interesting metric"}}
 *   @value={{35}}
 * />
 * ```
 *
 * @class PercentageBar
 */

export default class ChartsPercentageBar extends Component<ChartsPercentageBarSignature> {
  /**
   * A title for the card, if omitted the section is not rendered
   * @argument title
   * @type {?string}
   */

  /**
   * the value (0-100 intended as a percentage) that bar will display
   * @argument value
   * @type {number}
   */

  /**
   * getState is an optional function that takes the value passed to the chart and returns one of the "state" enum values
   *  - it's intended to allow usersto customize the ok/warn/danger thresholds away from the defaults
   *
   *  ```
   *    // component.js
   *    ...
   *
   *    getState(value) {
   *      if (value === 90) return 'danger'
   *
   *      return  'ok';
   *    }
   *
   *    ...
   *
   *    // template.hbs
   *    <PercentageBar
   *      @title='A very specific danger'
   *      @value={{this.weirdCPU.level}}
   *      @getState={{this.getState}}
   *    />
   * ```
   * ^ would yield a chart that ONLY shows danger at 90 (for some reason...)
   *
   * @argument getState
   * @type {(value: number) => enum{'ok'|'warning'|'danger'}
   */

  /**
   * default state calculation
   */
  _getState(value: number) {
    if (value >= 100) {
      return 'danger';
    } else if (value >= 75) {
      return 'warning';
    } else {
      return 'ok';
    }
  }

  /**
   * returns the passed in value (as a number) or zero
   * @method PercentageBar#value
   * @return {number}
   */
  get value() {
    return Number(this.args.value) || 0;
  }

  /**
   * uses the value to determine the state of the bar
   * @method PercentageBar#state
   * @return {enum{'ok'|'warning'|'danger'}}
   */
  get state() {
    const { getState } = this.args;
    const { _getState, value } = this;

    const stateFunction = getState ? getState : _getState;

    return stateFunction(value);
  }

  <template>
    {{#if (has-block)}}
      <div class="percentage-bar" data-test-percentage-bar ...attributes>
        {{yield
          (hash
            value=this.value
            state=this.state
            Chart=(component
              ChartsPercentageBarChart state=this.state value=this.value
            )
            Summary=(component ChartsPercentageBarSummary value=this.value)
            Subtitle=(component ChartsPercentageBarSubtitle state=this.state)
          )
        }}
      </div>
    {{else}}
      <FlexGrid
        class="percentage-bar"
        data-test-percentage-bar
        data-test-chart-state={{this.state}}
        data-test-chart-value={{this.value}}
        ...attributes
        as |G|
      >
        {{#if @title}}
          <G.Item @xs="12">
            <Typography data-test-chart-title @variant="h3">
              {{@title}}
            </Typography>
          </G.Item>
        {{/if}}

        <G.Item @xs="12" @sm="11">
          <ChartsPercentageBarChart
            @state={{this.state}}
            @value={{this.value}}
          />
        </G.Item>
        <G.Item class="end-xs" @xs="12" @sm="1">
          <ChartsPercentageBarSummary @value={{this.value}} />
        </G.Item>

        <G.Item @xs="12">
          <ChartsPercentageBarSubtitle @state={{this.state}} />
        </G.Item>
      </FlexGrid>
    {{/if}}
  </template>
}
