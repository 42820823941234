import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class consulHasTfQuickstartPermissions extends Helper {
  @service abilities;

  /**
   *
   * Returns true if user has sufficient permissions to advance to Terraform quickstart page.
   *
   * @returns {Boolean}
   */
  compute() {
    return (
      this.abilities.can('create-permissions consul') &&
      this.abilities.can('create-permissions network') &&
      this.abilities.can('create-permissions network-peering') &&
      this.abilities.can('create-root-token-permissions consul') &&
      this.abilities.can('get-client-config-permissions consul')
    );
  }
}
