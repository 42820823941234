import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class ProductForLocationRegion extends Helper {
  @service regions;

  /**
   * Returns a list of strings indicating products that support the provided region.
   * @param {array} args - 0th element of args must be an object.
   * @param {Object} args[0] - a network's location.region with provider and region properties.
   * @returns {Array}
   */
  compute([locationRegion]) {
    return this.regions.productsForRegion(locationRegion);
  }
}
