import Component from '@glimmer/component';

import FlexGridItem from '../../flex-grid/item.gts';
import Box from '../../box.gts';

export interface DetailCardContentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class DetailCardContent extends Component<DetailCardContentSignature> {
  <template>
    <FlexGridItem
      @lg="5"
      class="detailCard__content"
      ...attributes
      data-test-detail-card-content
    >
      <Box @padding="xs 0">
        {{yield}}
      </Box>
    </FlexGridItem>
  </template>
}
