/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointV20241122CreateGitHubSessionResponseFromJSON(json) {
    return HashicorpCloudWaypointV20241122CreateGitHubSessionResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122CreateGitHubSessionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'installUrl': !exists(json, 'install_url') ? undefined : json['install_url'],
        'authUrl': !exists(json, 'auth_url') ? undefined : json['auth_url'],
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
    };
}
export function HashicorpCloudWaypointV20241122CreateGitHubSessionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'install_url': value.installUrl,
        'auth_url': value.authUrl,
        'session_id': value.sessionId,
    };
}
