/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudWaypointUIActionConfigBundleFromJSON, HashicorpCloudWaypointUIActionConfigBundleToJSON, } from './';
export function HashicorpCloudWaypointUIListActionConfigResponseFromJSON(json) {
    return HashicorpCloudWaypointUIListActionConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointUIListActionConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionConfigBundles': !exists(json, 'action_config_bundles') ? undefined : (json['action_config_bundles'].map(HashicorpCloudWaypointUIActionConfigBundleFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudWaypointUIListActionConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_config_bundles': value.actionConfigBundles === undefined ? undefined : (value.actionConfigBundles.map(HashicorpCloudWaypointUIActionConfigBundleToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
