import Component from '@glimmer/component';

interface InfoLabelSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

export default class InfoLabel extends Component<InfoLabelSignature> {
  <template>
    <div class="infoLabel" data-test-info-label>
      {{yield}}
    </div>
  </template>
}
