import Component from '@glimmer/component';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';
import { formatDate, formatRelative } from 'ember-intl';

interface TimeInnerSignature {
  Args: {
    date: Date;
    display: {
      options: {
        showFriendly: boolean;
        showRelative: boolean;
        displayFormat?: {
          month: string;
          day: string;
          year: string;
          hour: string;
          minute: string;
          second: string;
        };
      };
      relative: {
        value: Date;
        unit: string;
      };
    };
    isoUtcString?: string;
    register: () => void;
    unregister: () => void;
    isValid: boolean;
  };
  Element: HTMLTimeElement;
}

export default class TimeInner extends Component<TimeInnerSignature> {
  <template>
    {{#if @isValid}}
      <time
        class="time"
        datetime={{@isoUtcString}}
        data-test-time-container
        ...attributes
        {{didInsert @register}}
        {{willDestroy @unregister}}
      >
        {{#if @display.options.showFriendly}}
          <span data-test-time-friendly>
            {{#if @display.options.displayFormat}}
              {{! @glint-expect-error }}
              {{formatDate
                @date
                month=@display.options.displayFormat.month
                day=@display.options.displayFormat.day
                year=@display.options.displayFormat.year
                hour=@display.options.displayFormat.hour
                minute=@display.options.displayFormat.minute
                second=@display.options.displayFormat.second
              }}
            {{else}}
              {{@isoUtcString}}
            {{/if}}
          </span>
          {{#if @display.options.showRelative}}
            <span data-test-time-relative>
              {{! @glint-expect-error }}
              ({{formatRelative
                @display.relative.value
                unit=@display.relative.unit
              }})
            </span>
          {{/if}}
        {{else}}
          {{#if @display.options.showRelative}}
            <span data-test-time-relative>
              {{! @glint-expect-error }}
              {{formatRelative
                @display.relative.value
                unit=@display.relative.unit
              }}
            </span>
          {{/if}}
        {{/if}}
      </time>
    {{else}}
      <span data-test-time-invalid>--</span>
    {{/if}}
  </template>
}
