import Component from '@glimmer/component';
import { and, not } from 'ember-truth-helpers';
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import classNames from '../../helpers/class-names.ts';
import Flex from '../flex.gts';
import { AlignItem, FlexDirection, FlexGap } from '../../utils/flex.ts';

interface TableSortBySignature {
  Args: {
    currentField: unknown;
    field: string;
    sortDescending: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLTableCellElement;
}

export default class TableSortBy extends Component<TableSortBySignature> {
  // Is the table sorted by the property this SortBy controls?
  get isActive() {
    return this.args.currentField === this.args.field;
  }

  get sortDescending() {
    if (this.args.sortDescending === 'false') return false;
    return !!this.args.sortDescending;
  }

  <template>
    <th
      class={{classNames
        "sortable"
        (if this.isActive "is-active")
        (if (and this.isActive this.sortDescending) "sort-descending")
        (if (and this.isActive (not this.sortDescending)) "sort-ascending")
      }}
      ...attributes
      data-test-sort-by={{@field}}
    >
      <LinkTo
        @query={{hash
          sortby=@field
          sortdescending=(if this.isActive (not this.sortDescending) true)
        }}
      >
        <Flex
          @alignItems={{AlignItem.Center}}
          @direction={{FlexDirection.Row}}
          @gap={{FlexGap.Xs}}
        >
          <span class="sortby-label">
            {{yield}}
          </span>
          {{#if this.isActive}}
            <HdsIcon @name={{if this.sortDescending "arrow-down" "arrow-up"}} />
          {{/if}}
        </Flex>
      </LinkTo>
    </th>
  </template>
}
