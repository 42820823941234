import { t, formatNumber } from 'ember-intl';

import BillingCostEstimatorFooter from './footer.gjs';
import BillingCostEstimatorLabel from './label.gjs';
import BillingCostEstimatorAmount from './amount.gjs';
import BillingCostEstimatorPrice from './price.gjs';
import BillingCostEstimatorUnit from './unit.gjs';

<template>
  <BillingCostEstimatorFooter
    @error={{@error}}
    ...attributes
    data-test-cost-estimator-create-footer-container
  >
    <:controls>
      {{yield to="controls"}}
    </:controls>
    <:message>
      {{yield to="message"}}
    </:message>
    <:left>
      <BillingCostEstimatorLabel>
        {{t "billing-common.cost-estimator.footer.hourly"}}
      </BillingCostEstimatorLabel>
      <BillingCostEstimatorAmount>
        {{#if @estimate.totalHourlyPrice}}
          <BillingCostEstimatorPrice>
            {{formatNumber
              @estimate.totalHourlyPrice
              currency="USD"
              style="currency"
              maximumFractionDigits="5"
            }}
          </BillingCostEstimatorPrice>
        {{/if}}
        <BillingCostEstimatorUnit>
          {{t "billing-common.cost-estimator.footer.per-hour"}}
        </BillingCostEstimatorUnit>
      </BillingCostEstimatorAmount>
    </:left>
    <:right>
      <BillingCostEstimatorLabel>
        {{t "billing-common.cost-estimator.footer.monthly"}}
      </BillingCostEstimatorLabel>
      <BillingCostEstimatorAmount>
        {{#if @estimate.totalMonthlyPrice}}
          <BillingCostEstimatorPrice>
            {{formatNumber
              @estimate.totalMonthlyPrice
              currency="USD"
              style="currency"
            }}
          </BillingCostEstimatorPrice>
        {{/if}}
        <BillingCostEstimatorUnit>
          {{t "billing-common.cost-estimator.footer.per-month"}}
        </BillingCostEstimatorUnit>
      </BillingCostEstimatorAmount>
    </:right>
  </BillingCostEstimatorFooter>
</template>
