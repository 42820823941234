import Component from '@glimmer/component';

interface DefinitionListKeySignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `DefinitionValue` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <DefinitionList::Key>
 *   Some Key
 * </DefinitionList::Key>
 * ```
 *
 * @class DefinitionListKey
 *
 */

export default class DefinitionListKey extends Component<DefinitionListKeySignature> {
  <template>
    <dt
      class="hds-typography-body-200 hds-font-weight-semibold hds-foreground-primary"
      data-test-definition-list-key
      ...attributes
    >
      {{yield}}
    </dt>
  </template>
}
