import Component from '@glimmer/component';

import { SLIDING_WINDOW_COLUMN_WIDTHS } from '../../utils/consts/sliding-window.ts';
import FlexGrid from '../flex-grid.gts';

interface LayoutSlidingWindowSignature {
  Args: {
    leftDimension: number;
    rightDimension?: number;
    totalDimension: number;
  };
  Blocks: {
    left: [];
    right: [];
  };
}

/**
 * Constrains widths of adjacent "left" and "right" sets of card components.
 * Like a sliding window, as the left dimension opens, the right dimension closes.
 *
 * NOTE: The UI design this implements has a maximum total of 4 elements horizontally,
 *       don't use this component when (left+right > 4) ... or 💥 !!!
 *
 * ```
 * Left    Right
 *  ↓         ↓
 * ┌──────────┬┐
 * │     4    ││
 * └──────────┴┘
 * ┌───────┬───┐
 * │   3   │ 1 │
 * └───────┴───┘
 * ┌─────┬─────┐
 * │  2  │  2  │
 * └─────┴─────┘
 * ┌───┬───────┐
 * │ 1 │   3   │
 * └───┴───────┘
 * ┌┬──────────┐
 * ││    4     │
 * └┴──────────┘
 * ```
 *
 * @class LayoutSlidingWindowComponent
 *
 */
export default class LayoutSlidingWindow extends Component<LayoutSlidingWindowSignature> {
  // TODO: Document args (mandatory VS optional matter)

  get leftLayout() {
    const n = this.args.leftDimension;

    return SLIDING_WINDOW_COLUMN_WIDTHS[
      n as keyof typeof SLIDING_WINDOW_COLUMN_WIDTHS
    ];
  }

  get rightLayout() {
    const n =
      this.args.rightDimension !== undefined
        ? this.args.rightDimension
        : this.args.totalDimension - this.args.leftDimension;

    return SLIDING_WINDOW_COLUMN_WIDTHS[
      n as keyof typeof SLIDING_WINDOW_COLUMN_WIDTHS
    ];
  }

  <template>
    <div class="layout--slidingWindow">
      <FlexGrid as |FG|>
        {{#if this.leftLayout}}
          <FG.Item
            @xs={{this.leftLayout.xs}}
            @sm={{this.leftLayout.sm}}
            @md={{this.leftLayout.md}}
            @lg={{this.leftLayout.lg}}
          >
            {{yield to="left"}}
          </FG.Item>
        {{/if}}

        {{#if this.rightLayout}}
          <FG.Item
            @xs={{this.rightLayout.xs}}
            @sm={{this.rightLayout.sm}}
            @md={{this.rightLayout.md}}
            @lg={{this.rightLayout.lg}}
          >
            {{yield to="right"}}
          </FG.Item>
        {{/if}}
      </FlexGrid>
    </div>
  </template>
}
