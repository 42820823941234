import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { HdsFormTextInputField } from '@hashicorp/design-system-components/components';

interface SlugIdSignature {
  Args: {
    disabled: boolean;
    invalid: boolean;
    onChange: (event: Event) => void;
    value: string;
  };
  Blocks: {
    'slug-id-help-text': [];
    'slug-id-label': [];
  };
  Element: HTMLElement;
}

/**
 *
 * Simple text input, meant to be used to collect a slug ID.
 *
 *
 * ```
 * <FormInputs::SlugId
 *   @invalid={{invalid}}
 *   @disabled={{disabled}}
 *   @value={{value}}
 *   @onChange={{onChange}}
 * />
 * ```
 *
 * @class FormInputsSlugId
 *
 */

export default class FormInputsSlugId extends Component<SlugIdSignature> {
  /**
   * @argument invalid
   * @type {Boolean}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */
  /**
   * @argument value
   * @type {String}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */

  <template>
    <span data-test-form-inputs-slug-id>
      <HdsFormTextInputField
        @type="text"
        @isInvalid={{@invalid}}
        disabled={{@disabled}}
        @value={{@value}}
        ...attributes
        {{on "change" @onChange}}
        as |F|
      >
        {{#if (has-block "slug-id-label")}}
          <F.Label>
            {{yield to="slug-id-label"}}
          </F.Label>
        {{/if}}

        {{#if (has-block "slug-id-help-text")}}
          <F.HelperText>
            {{yield to="slug-id-help-text"}}
          </F.HelperText>
        {{/if}}
      </HdsFormTextInputField>
    </span>
  </template>
}
