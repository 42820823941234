import Component from '@glimmer/component';
import BasicDropdownWormhole from 'ember-basic-dropdown/components/basic-dropdown-wormhole';
import HcpAppFrame from './hcp-app-frame.gts';

interface TestAppFrameSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

// This component is just for engines to use for tests to render modals, notfications, etc. properly.
export default class TestAppFrame extends Component<TestAppFrameSignature> {
  <template>
    <HcpAppFrame @showStatusPageLink={{false}} @includeHomeLink={{false}}>
      {{yield}}
    </HcpAppFrame>

    <BasicDropdownWormhole />
  </template>
}
