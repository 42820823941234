import Component from '@glimmer/component';
import { HdsIcon } from '@hashicorp/design-system-components/components';

interface LogoSignature {
  // Args: {};
}

export default class Logo extends Component<LogoSignature> {
  <template>
    <div class="logo">
      <HdsIcon @name="hashicorp" @size="24" />
    </div>
  </template>
}
