/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointTFModuleVariableFromJSON, HashicorpCloudWaypointTFModuleVariableToJSON, } from './';
export function HashicorpCloudWaypointTFModuleDetailsFromJSON(json) {
    return HashicorpCloudWaypointTFModuleDetailsFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTFModuleDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'readme': !exists(json, 'readme') ? undefined : json['readme'],
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointTFModuleVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointTFModuleDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'readme': value.readme,
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointTFModuleVariableToJSON)),
    };
}
