import { helper } from '@ember/component/helper';
import {
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_PACKER,
  TYPE_PACKER_BUCKET,
  TYPE_TERRAFORM,
  TYPE_TERRAFORM_ORGANIZATION,
  TYPE_VAULT,
  TYPE_VAULT_RADAR_PROJECT,
  TYPE_VAULT_SECRETS_PROJECT,
  TYPE_WEBHOOK,
} from 'common/utils/cloud-resource-types';
import { variation } from 'ember-launch-darkly';

const ICON_MAPPING = {
  [TYPE_BOUNDARY]: 'boundary-color',
  [TYPE_CONSUL]: 'consul-color',
  [TYPE_PACKER]: 'packer-color',
  [TYPE_PACKER_BUCKET]: 'packer-color',
  [TYPE_TERRAFORM]: 'terraform-color',
  [TYPE_TERRAFORM_ORGANIZATION]: 'terraform-color',
  [TYPE_VAULT]: 'vault-color',
  [TYPE_VAULT_SECRETS_PROJECT]: 'vault-secrets-color',
  [TYPE_VAULT_RADAR_PROJECT]: 'vault-radar-color',
  [TYPE_WEBHOOK]: 'webhook',
};

export function optionForLocationLinkIcon([resourceLink = {}]) {
  let { id, type, uuid } = resourceLink;
  let icon = ICON_MAPPING[type];

  const options = { icon, label: '' };

  switch (type) {
    case TYPE_PACKER:
      options.label = 'Packer';
      break;
    case TYPE_PACKER_BUCKET:
      options.label = 'Packer Bucket';
      break;
    case TYPE_TERRAFORM:
    case TYPE_TERRAFORM_ORGANIZATION:
      options.label = 'Terraform';
      break;
    case TYPE_CONSUL:
      options.label = 'Consul';
      break;
    case TYPE_VAULT:
      options.label = variation('hcp-rebrand-milestone-0')
        ? 'Vault Dedicated'
        : 'Vault';
      break;
    case TYPE_VAULT_SECRETS_PROJECT:
      options.label = 'Vault Secrets';
      break;
    case TYPE_VAULT_RADAR_PROJECT:
      options.label = 'Vault Radar';
      break;
    case TYPE_BOUNDARY:
      options.label = 'Boundary';
      break;
    case TYPE_WEBHOOK:
      options.label = 'Webhook';
      break;
    default:
      options.label = id || uuid;
      break;
  }

  return options;
}

export default helper(optionForLocationLinkIcon);
