import Component from '@glimmer/component';

export interface ChartsPercentageBarChartSignature {
  Args: {
    state: 'ok' | 'warning' | 'danger';
    value: number;
  };
  Element: SVGElement;
}

export default class ChartsPercentageBarChart extends Component<ChartsPercentageBarChartSignature> {
  // height of the SVG element aka the "chart" area
  height = 12;

  // Arguments
  /**
   * Current value the chart is representing
   * @argument value
   * @type {number}
   *
   */

  /**
   * used to determine the color of the filledBar (done through css class)
   * @argument state
   * @type {enum{'ok'|'warning'|'danger'}}
   *
   */

  /**
   * Given a value and the size of the chart area, calculates the appropriate width of the filled bar
   * @method PercentageBar::Chart#barWidth
   * @return {number}
   */
  get barWidth() {
    const { value } = this.args;

    //if no % value then return the height to make a circle
    if (value <= 0 || !value) return this.height;

    return value.toString() + '%';
  }

  <template>
    <div aria-hidden="true">
      <svg
        data-test-chart-area
        class="center-xs percentage-bar__chart"
        height={{this.height}}
        ...attributes
      >
        <rect
          class="percentage-bar__background"
          data-test-chart-background
          x="0"
          width="100%"
          height={{this.height}}
          rx="5"
        ></rect>
        <rect
          class="percentage-bar__filled {{@state}}"
          data-test-chart-filled
          x="0"
          rx="5"
          width={{this.barWidth}}
          height={{this.height}}
        ></rect>
      </svg>
    </div>
  </template>
}
