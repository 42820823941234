import Component from '@glimmer/component';

// Components
import Flex from 'core/components/flex';
import {
  HdsTable,
  HdsTextBody,
  HdsIcon,
} from '@hashicorp/design-system-components/components';

// Utils
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';

// Helpers
import { t } from 'ember-intl';
import { concat } from '@ember/helper';

// Modifiers
import boxMargin from 'core/modifiers/box-margin';

import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';
import type { CategoryAction } from '../../../types/role-definitions-flyout';

export interface RoleComparisonTableSignature {
  Args: {
    roles: Array<HashicorpCloudResourcemanagerRole>;
    actions: Array<CategoryAction>;
  };
  Element: HTMLDivElement;
}

export default class RoleComparisonTableComponent extends Component<RoleComparisonTableSignature> {
  <template>
    <HdsTable data-test-role-comparison-table>
      <:head as |H|>
        <H.Tr>
          <H.Th data-test-role-comparison-table-ability-header>{{t
              "manage-access.components.table.role-comparison.header.ability"
            }}</H.Th>
          {{#each @roles as |column|}}
            <H.Th
              data-test-role-comparison-table-role-header
            >{{column.title}}</H.Th>
          {{/each}}
        </H.Tr>
      </:head>
      <:body as |B|>
        {{#each @actions as |row|}}
          <B.Tr>
            <B.Td data-test-role-comparison-table-action-header>
              {{#if row.icon}}
                <Flex
                  @alignItems={{AlignItem.Start}}
                  @direction={{FlexDirection.Row}}
                  @gap={{FlexGap.Xs}}
                >
                  <span {{boxMargin "3xs 0 0 0"}}>
                    <HdsIcon
                      {{! @glint-expect-error: fix incoming icon type }}
                      @name={{row.icon}}
                      @isInline={{true}}
                      data-test-role-comparison-table-action-icon
                    />
                  </span>
                  <HdsTextBody @weight="regular">
                    {{row.title}}
                  </HdsTextBody>
                </Flex>
              {{else}}
                {{row.title}}
              {{/if}}
            </B.Td>
            {{#each @roles as |column|}}
              <B.Td
                data-test-role-comparison-table-cell={{concat
                  column.title
                  "-"
                  row.title
                }}
              >
                {{#let (iamPermissionIsIncluded row column) as |hasPermission|}}
                  <HdsIcon
                    @color={{if hasPermission "green" "red"}}
                    @name={{if hasPermission "check" "skip"}}
                    @isInline={{true}}
                    aria-label={{t
                      "manage-access.components.table.role-comparison.aria-label"
                      hasPermission=hasPermission
                    }}
                  />
                {{/let}}
              </B.Td>
            {{/each}}
          </B.Tr>
        {{/each}}
      </:body>
    </HdsTable>
  </template>
}

const ALWAYS_ALLOWED_PERMISSIONS = ['iam.users.list', 'iam.groups.list'];

function iamPermissionIsIncluded(
  action: CategoryAction,
  column: HashicorpCloudResourcemanagerRole,
): boolean {
  const { overrideColumnPermissionCheck, permissions: requiredPermissions } =
    action;
  const { permissions: rolePermissions = [] } = column;

  if (
    overrideColumnPermissionCheck &&
    typeof overrideColumnPermissionCheck === 'function'
  ) {
    return overrideColumnPermissionCheck(column);
  }

  return requiredPermissions.every(
    (permission) =>
      rolePermissions.includes(permission) ||
      ALWAYS_ALLOWED_PERMISSIONS.includes(permission),
  );
}
