import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import FlexGridItem from './flex-grid/item.gts';
import classNames from '../helpers/class-names.ts';

import type { ComponentLike } from '@glint/template';
import type { FlexGridItemSignature } from './flex-grid/item.gts';

interface FlexGridSignature {
  Args: {
    inside?: unknown;
    reverse?: unknown;
  };
  Blocks: {
    default: [
      {
        Item: ComponentLike<{
          Args: FlexGridItemSignature['Args'];
          Element: FlexGridItemSignature['Element'];
          Blocks: FlexGridItemSignature['Blocks'];
        }>;
      },
    ];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `FlexGrid` wraps FlexGrid::Item to provide a 12-column grid system.
 *
 *
 * ```
 * <FlexGrid @reverse={{true}}></FlexGrid>
 * ```
 *
 * @class FlexGrid
 *
 */

export default class FlexGrid extends Component<FlexGridSignature> {
  /**
   * Reverses the order of the items in this flex grid.
   * @argument reverse
   * @type {boolean}
   */
  /**
   * Removes inner negative margin
   * @argument inside
   * @type {boolean}
   */

  <template>
    <div class="flexGrid">
      <div
        class={{classNames "row" (if @reverse "reverse") (if @inside "inside")}}
        ...attributes
        data-test-flex-grid-container
      >
        {{yield (hash Item=(component FlexGridItem))}}
      </div>
    </div>
  </template>
}
