import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import LayoutFullScreenMain from './full-screen/main.gts';
import LayoutFullScreenAside from './full-screen/aside.gts';

interface LayoutFullScreenSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

export default class LayoutFullScreen extends Component<LayoutFullScreenSignature> {
  <template>
    <div data-test-layout-full-screen class="full-screen" ...attributes>
      {{yield
        (hash
          Main=(component LayoutFullScreenMain)
          Aside=(component LayoutFullScreenAside)
        )
      }}
    </div>
  </template>
}
