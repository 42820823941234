/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointInputVariableFromJSON, HashicorpCloudWaypointInputVariableToJSON, HashicorpCloudWaypointTerraformTFRunStateFromJSON, HashicorpCloudWaypointTerraformTFRunStateToJSON, } from './';
export function HashicorpCloudWaypointGetTFRunStatusResponseFromJSON(json) {
    return HashicorpCloudWaypointGetTFRunStatusResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointGetTFRunStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudWaypointTerraformTFRunStateFromJSON(json['state']),
        'inputVariables': !exists(json, 'input_variables') ? undefined : (json['input_variables'].map(HashicorpCloudWaypointInputVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointGetTFRunStatusResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'state': HashicorpCloudWaypointTerraformTFRunStateToJSON(value.state),
        'input_variables': value.inputVariables === undefined ? undefined : (value.inputVariables.map(HashicorpCloudWaypointInputVariableToJSON)),
    };
}
