import { inject as service } from '@ember/service';
import BaseAbility from './base-ability';
import {
  ACTION_ASSIGN_PROJECT,
  ACTION_DELETE,
  ACTION_GET,
  ACTION_LIST,
  ACTION_TOKENIZE_PAYMENT_DETAILS,
  ACTION_UNASSIGN_PROJECT,
  PREFIX_BILLING_ACCOUNTS,
} from '../utils/permission-types/index';
import { PermissionScope } from '../services/permissions';

export default class BillingAccountAbility extends BaseAbility {
  @service billing;
  @service userContext;
  @service currentUser;

  prefix = PREFIX_BILLING_ACCOUNTS;

  /** @return {boolean} */
  get canAssignProject() {
    return this.permissions.has(
      this.generate(ACTION_ASSIGN_PROJECT),
      PermissionScope.ORGANIZATION,
    );
  }

  /**
   * @description Returns if this billing account is enabled for creating
   *     additional resources.
   * @returns {boolean}
   */
  get canBill() {
    let { billing } = this.userContext;
    return !billing?.onDemand?.status?.isRemovingCreditCard;
  }

  /** @return {boolean} */
  get canDelete() {
    let { billing } = this.userContext;
    return (
      this.permissions.has(
        this.generate(ACTION_DELETE),
        PermissionScope.ORGANIZATION,
      ) &&
      billing?.status?.isActive &&
      billing?.onDemand?.status?.isOnDemandActive
    );
  }

  /** @return {boolean} */
  get canEdit() {
    return (
      this.canBill &&
      this.permissions.has(
        this.generate(ACTION_TOKENIZE_PAYMENT_DETAILS),
        PermissionScope.ORGANIZATION,
      ) &&
      this.canUpdate
    );
  }

  get canGet() {
    return this.permissions.has(
      this.generate(ACTION_GET),
      PermissionScope.ORGANIZATION,
    );
  }

  get canList() {
    return this.permissions.has(
      this.generate(ACTION_LIST),
      PermissionScope.ORGANIZATION,
    );
  }

  /** @return {boolean} */
  get canUnassignProject() {
    return this.permissions.has(
      this.generate(ACTION_UNASSIGN_PROJECT),
      PermissionScope.ORGANIZATION,
    );
  }

  /** @return {boolean} */
  get canView() {
    return this.canGet && this.canList && !this.currentUser.hasNoOrgRole;
  }
}
