
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("ember/index", function(){ return i("ember-source/ember/index.js");});
d("billing/routes", function(){ return i("billing/routes.js");});
d("monitoring/routes", function(){ return i("monitoring/routes.js");});
d("boundary/routes", function(){ return i("boundary/routes.js");});
d("snapshots/routes", function(){ return i("snapshots/routes.js");});
d("consul/routes", function(){ return i("consul/routes.js");});
d("packer/routes", function(){ return i("packer/routes.js");});
d("role-assignments/routes", function(){ return i("role-assignments/routes.js");});
d("secrets/routes", function(){ return i("secrets/routes.js");});
d("vagrant/routes", function(){ return i("vagrant/routes.js");});
d("vault/routes", function(){ return i("vault/routes.js");});
d("waypoint/routes", function(){ return i("waypoint/routes.js");});
d("webhooks/routes", function(){ return i("webhooks/routes.js");});
import "@hashicorp/design-system-components/-embroider-implicit-modules.js";
import "api/-embroider-implicit-modules.js";
import "authz/-embroider-implicit-modules.js";
import "billing-common/-embroider-implicit-modules.js";
import "common/-embroider-implicit-modules.js";
import "consul-ui-toolkit/-embroider-implicit-modules.js";
import "core/-embroider-implicit-modules.js";
import "manage-access/-embroider-implicit-modules.js";
import "networks-common/-embroider-implicit-modules.js";
import "resource-catalog/-embroider-implicit-modules.js";
import "vault-common/-embroider-implicit-modules.js";
import "@embroider/router/-embroider-implicit-modules.js";
import "@nullvoxpopuli/ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-can/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-bundle-analyzer/-embroider-implicit-modules.js";
import "ember-cli-flash/-embroider-implicit-modules.js";
import "ember-cli-page-object/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-engines-router-service/-embroider-implicit-modules.js";
import "ember-file-upload/-embroider-implicit-modules.js";
import "ember-focus-trap/-embroider-implicit-modules.js";
import "ember-launch-darkly/-embroider-implicit-modules.js";
import "ember-mirage/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-power-select/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-router-helpers/-embroider-implicit-modules.js";
import "ember-set-helper/-embroider-implicit-modules.js";
import "ember-simple-auth/-embroider-implicit-modules.js";
import "ember-stargate/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-content-security-policy/-embroider-implicit-modules.js";
import "local-dev-cookie-middleware/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "@sentry/ember/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-sass/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-cli-string-helpers/-embroider-implicit-modules.js";
import "ember-engines/-embroider-implicit-modules.js";
import "ember-exam/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-qunit-nice-errors/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "@bagaar/ember-breadcrumbs/-embroider-implicit-modules.js";
import "multi-html/-embroider-implicit-modules.js";
