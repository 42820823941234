import Component from '@glimmer/component';
import { and, eq } from 'ember-truth-helpers';
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import boxPadding from '../modifiers/box-padding.ts';

interface ZeroConnectionsBannerSignature {
  Args: {
    model: {
      peerings: unknown[];
      tgwAttachments: unknown[];
    };
    network: {
      id: string;
      location: {
        organizationId: string;
        projectId: string;
      };
    };
    route?: string;
    useLinkToExternal?: boolean;
  };
}

export default class ZeroConnectionsBanner extends Component<ZeroConnectionsBannerSignature> {
  <template>
    {{#if
      (and (eq @model.peerings.length 0) (eq @model.tgwAttachments.length 0))
    }}
      <div {{boxPadding "0 0 lg"}}>
        <HdsAlert
          @type="compact"
          @color="warning"
          data-test-zero-connections-banner
          as |A|
        >
          <A.Title data-test-zero-connections-banner-title>
            {{t "vault.components.page.create.network-quota-limit"}}
          </A.Title>
          <A.Description data-test-zero-connections-banner-connections-message>
            {{t
              "components.zero-connections-banner.message.connections"
              htmlSafe=true
              networkID=@network.id
            }}
          </A.Description>
          <A.LinkStandalone
            @icon="arrow-right"
            @iconPosition="trailing"
            @isRouteExternal={{@useLinkToExternal}}
            @models={{array
              @network.location.organizationId
              @network.location.projectId
              @network.id
            }}
            @route={{@route}}
            @text={{t "components.zero-connections-banner.link"}}
            data-test-zero-connections-banner-network-link
          />
        </HdsAlert>
      </div>
    {{/if}}
  </template>
}
