import Component from '@glimmer/component';

import Tooltip from './tooltip.gts';
import type { TooltipSignature } from './tooltip.gts';

interface TooltipConditionalSignature {
  Args: {
    tooltipOptions: TooltipSignature['Args']['tooltipOptions'];
    useTooltip: boolean;
  };
  Blocks: {
    anchor: [];
    tooltip: [];
  };
}

// /**
//  *
//  * The TooltipConditional component yields the tooltip sub-elements to the
//  * Tooltip component if the provided property evaluates to true.
//  * This is necessary because the Tooltip component renders an empty div
//  * with styling when there is no content in the :anchor.
//  *
//  *
//  * ```
//  * <TooltipConditional @useTooltip={{true}}>
//  *  <:tooltip>
//       Content
//     </:tooltip>
//     <:anchor>
//       Anchor
//     </:anchor>
//  * </TooltipConditional>
//  * ```
//  *
//  * @class TooltipConditional
//  */
export default class TooltipConditional extends Component<TooltipConditionalSignature> {
  <template>
    {{#if @useTooltip}}
      <Tooltip @tooltipOptions={{@tooltipOptions}}>
        <:anchor>
          {{yield to="anchor"}}
        </:anchor>
        <:tooltip>
          {{yield to="tooltip"}}
        </:tooltip>
      </Tooltip>
    {{else}}
      {{yield to="anchor"}}
    {{/if}}
  </template>
}
