import Component from '@glimmer/component';

import FlexGridItem from '../../flex-grid/item.gts';

export interface DetailCardHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class DetailCardHeader extends Component<DetailCardHeaderSignature> {
  <template>
    <FlexGridItem
      class="detailCard__header"
      ...attributes
      data-test-detail-card-header
    >
      {{yield}}
    </FlexGridItem>
  </template>
}
