/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointInputVariableFromJSON, HashicorpCloudWaypointInputVariableToJSON, HashicorpCloudWaypointRefApplicationTemplateFromJSON, HashicorpCloudWaypointRefApplicationTemplateToJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationFromTemplateBodyFromJSON(json) {
    return HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationFromTemplateBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationFromTemplateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointRefApplicationTemplateFromJSON(json['application_template']),
        'actionCfgRefs': !exists(json, 'action_cfg_refs') ? undefined : (json['action_cfg_refs'].map(HashicorpCloudWaypointActionCfgRefFromJSON)),
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointInputVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointV20241122WaypointServiceCreateApplicationFromTemplateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'name': value.name,
        'application_template': HashicorpCloudWaypointRefApplicationTemplateToJSON(value.applicationTemplate),
        'action_cfg_refs': value.actionCfgRefs === undefined ? undefined : (value.actionCfgRefs.map(HashicorpCloudWaypointActionCfgRefToJSON)),
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointInputVariableToJSON)),
    };
}
