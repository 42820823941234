import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { and } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { HdsAlert } from '@hashicorp/design-system-components/components';
import type { HdsAlertSignature } from '@hashicorp/design-system-components/components/hds/alert/index';

import { supportTicketPrefillHref } from '../../utils/support-ticket.js';

import type UserContextService from '../../services/user-context.ts';

interface SystemNotificationsAccessRestrictedSignature {
  Args: {
    flash: {
      id: string;
      color: HdsAlertSignature['Args']['color'];
      icon: HdsAlertSignature['Args']['icon'];
      message: string;
      content: string;
      context: {
        name: string;
      };
      actionText: string;
      actionIcon: string;
      onAction: () => void;
      linkText: string;
      linkIcon: string;
    };
  };
}

export default class SystemNotificationsAccessRestricted extends Component<SystemNotificationsAccessRestrictedSignature> {
  @service declare readonly userContext: UserContextService;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  <template>
    <HdsAlert
      @type="page"
      @color={{@flash.color}}
      @icon={{@flash.icon}}
      data-test-system-notification={{@flash.id}}
      as |A|
    >
      <A.Title>
        {{t @flash.message}}
      </A.Title>
      <A.Description>
        {{t @flash.content name=@flash.context.name htmlSafe=true}}
      </A.Description>
      {{#if (and @flash.actionText @flash.onAction)}}
        <A.Button
          data-test-notification-action={{@flash.id}}
          @color="secondary"
          @size="small"
          {{! @glint-expect-error }}
          @icon={{@flash.actionIcon}}
          @text={{t @flash.actionText}}
          {{on "click" @flash.onAction}}
        />
      {{/if}}
      <A.LinkStandalone
        {{! @glint-expect-error }}
        @icon={{@flash.linkIcon}}
        @text={{t @flash.linkText}}
        @href={{this.supportTicketHref}}
        data-test-notification-link={{@flash.id}}
      />
    </HdsAlert>
  </template>
}
