import Component from '@glimmer/component';

export interface ZeroStateHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `ZeroStateHeader` displays the header element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Header>Heading</ZeroState::Header>
 * ```
 *
 * @class ZeroStateHeader
 *
 */

export default class ZeroStateHeader extends Component<ZeroStateHeaderSignature> {
  <template>
    <header
      class="zero-state__header"
      data-test-zero-state-header-container
      ...attributes
    >
      {{yield}}
    </header>
  </template>
}
