/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * The various status we allow a job to be in.
 *
 *  - STATUS_UNSPECIFIED: default/zero value; we have no job status
 *  - STATUS_UNKNOWN: we don't know the status of the job!
 *  - STATUS_RUNNING: the job was marked as running
 *  - STATUS_HALTED: the job was halted/failed to be launched
 *  - STATUS_SUCCESS: the job was successful
 *  - STATUS_ERRORED: the job ran, but errored out
 *  - STATUS_QUEUED: the job is queued to run, used by agents
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointJobStatus;
(function (HashicorpCloudWaypointJobStatus) {
    HashicorpCloudWaypointJobStatus["UNSPECIFIED"] = "STATUS_UNSPECIFIED";
    HashicorpCloudWaypointJobStatus["UNKNOWN"] = "STATUS_UNKNOWN";
    HashicorpCloudWaypointJobStatus["RUNNING"] = "STATUS_RUNNING";
    HashicorpCloudWaypointJobStatus["HALTED"] = "STATUS_HALTED";
    HashicorpCloudWaypointJobStatus["SUCCESS"] = "STATUS_SUCCESS";
    HashicorpCloudWaypointJobStatus["ERRORED"] = "STATUS_ERRORED";
    HashicorpCloudWaypointJobStatus["QUEUED"] = "STATUS_QUEUED";
})(HashicorpCloudWaypointJobStatus || (HashicorpCloudWaypointJobStatus = {}));
export function HashicorpCloudWaypointJobStatusFromJSON(json) {
    return HashicorpCloudWaypointJobStatusFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointJobStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointJobStatusToJSON(value) {
    return value;
}
