import Component from '@glimmer/component';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import optionForLocationLinkIcon from '../helpers/option-for-location-link-icon.js';
import Flex from './flex.gts';
import { FlexDirection, AlignItem } from '../utils/flex.ts';

import type { LocationLink } from '@clients/cloud-billing';
import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

interface LocationLinkIconSignature {
  Args: {
    location: LocationLink;
    size: HdsIconSignature['Args']['size'];
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLSpanElement;
}

export default class LocationLinkIcon extends Component<LocationLinkIconSignature> {
  <template>
    {{#let (optionForLocationLinkIcon @location) as |option|}}
      <span class="locationLinkIcon" ...attributes>
        <Flex @direction={{FlexDirection.Row}} @alignItems={{AlignItem.Center}}>
          {{#if option.icon}}
            <HdsIcon @name={{option.icon}} @size={{@size}} />
          {{/if}}
          {{yield option.label}}
        </Flex>
      </span>
    {{/let}}
  </template>
}
