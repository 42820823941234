import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import CoreCardSummaryKey from './key.gts';
import CoreCardSummaryValue from './value.gts';

import type { ComponentLike } from '@glint/template';
import type { CoreCardSummaryKeySignature } from './key.ts';
import type { CoreCardSummaryValueSignature } from './value.ts';

interface CoreCardSummaryPairSignature {
  Args: {
    keyIcon?: CoreCardSummaryKeySignature['Args']['icon'];
  };
  Blocks: {
    default: [
      {
        Key: ComponentLike<{
          Args: CoreCardSummaryKeySignature['Args'];
          Blocks: CoreCardSummaryKeySignature['Blocks'];
          Element: CoreCardSummaryKeySignature['Element'];
        }>;
        Value: ComponentLike<{
          Args: CoreCardSummaryValueSignature['Args'];
          Blocks: CoreCardSummaryValueSignature['Blocks'];
          Element: CoreCardSummaryValueSignature['Element'];
        }>;
      },
    ];
  };
}

/**
 *
 * `CoreCardSummaryValueComponent` provides a container to display both the key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Pair @keyIcon="help" as |P|>
 *   <P.Key><P.Key>
 *   <P.Value><P.Value>
 * </Core::Card::Summary::Pair>
 * ```
 *
 * @class CoreCardSummaryValueComponent
 *
 */

export default class CoreCardSummaryPair extends Component<CoreCardSummaryPairSignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryValueComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const classes = [];

    if (this.args.keyIcon) {
      classes.push(`core-card-summary--with-key-icon`);
    }

    return classes.join(' ');
  }

  <template>
    {{yield
      (hash
        Key=(component
          CoreCardSummaryKey
          icon=@keyIcon
          contextualClass=(if @keyIcon "core-card-summary-key--with-icon")
        )
        Value=(component
          CoreCardSummaryValue
          contextualClass=(if @keyIcon "core-card-summary-value--with-icon")
        )
      )
    }}
  </template>
}
