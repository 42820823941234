import Component from '@glimmer/component';

import CodeMirror from '../../modifiers/code-mirror.ts';

interface CodeEditorFieldEditorSignature {
  Args: {
    onInput: unknown;
    onBlur: unknown;
    options: unknown;
    value: unknown;
  };
  Element: HTMLDivElement;
}

/**
 *
 * `CodeEditorFieldEditor` displays code with syntax highlighting.
 * The FieldEditor provides default configuration, notably the default 'mode' is 'hcl'.
 *
 * The supported modes for the editor are currently: hcl, shell, go, javascript
 *
 * To use the editor with default configuration values
 * ```
 * <CodeEditor::FieldEditor
 *   @value={{this.myHclSnippet}}
 * />
 * ```
 *
 * To use the editor with custom configuration (example: different mode)
 * ```
 * <CodeEditor::FieldEditor
 *   @value={{this.myShellSnippet}}
 *   @options={{shellOptions}}
 * />
 * ```
 * Where `shellOptions` could include:
 * ```
 * let shellOptions = {
 *   mode: 'shell'
 * }
 * ```
 *
 * To make the editor read only, add the `readOnly` property to its options:
 * ```
 * <CodeEditor::FieldEditor
 *   @value={{this.myCodeSnippet}}
 *   @options={{options}}
 * />
 * ```
 * Where `options` includes:
 * ```
 * let options = {
 *   readOnly: true
 * }
 * ```
 *
 * By default the editor will be 300px tall. To make the editor size to content,
 * add `class='autosize'` to the editor and `viewportMargin: Infinity` to the options (https://codemirror.net/demo/resize.html):
 * ```
 * <CodeEditor::FieldEditor
 *   class='autosize'
 *   @value={{this.myCodeSnippet}}
 *   @options={{options}}
 * />
 * ```
 * Where `options` includes:
 * ```
 * let options = {
 *   viewportMargin: Infinity
 * }
 * ```
 *
 * @class CodeEditorFieldEditor
 *
 */
export default class CodeEditorFieldEditor extends Component<CodeEditorFieldEditorSignature> {
  /**
   *
   * `value` is the code contents to display in the editor.
   * @argument value;
   * @type {String}
   *
   */
  /**
   *
   * `onInput` is a called when the editor contents change.
   * @argument onInput;
   * @type {?Function}
   *
   */
  /**
   *
   * `onBlur` is a called when the editor loses focus.
   * @argument onBlur;
   * @type {?Function}
   *
   */
  /**
   *
   * `options` can be used to specify additional configuration properties or default overrides for the editor.
   * These should follow the codemirror configuration types: https://codemirror.net/doc/manual.html#config
   * @argument options;
   * @type {?Object}
   *
   */

  <template>
    <div
      data-test-code-editor-field-editor
      ...attributes
      class="codemirror-container"
      {{! @glint-expect-error: }}
      {{CodeMirror
        value=@value
        onInput=@onInput
        onBlur=@onBlur
        options=@options
      }}
    ></div>
  </template>
}
