import Component from '@glimmer/component';

interface ToolbarFiltersSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class ToolbarFilters extends Component<ToolbarFiltersSignature> {
  <template>
    <div class="toolbar__filters" ...attributes data-test-toolbar-filters>
      {{yield}}
    </div>
  </template>
}
