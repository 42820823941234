import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import { HdsFormSelectField } from '@hashicorp/design-system-components/components';

interface VersionSelectSignature {
  Args: {
    disabled: boolean;
    onChange: (event: Event) => void;
    selectedVersion: string;
    versions: {
      version: string;
      status: string;
    }[];
  };
}

/**
 *
 * Simple select box input, formats & localizes version statuse in options, example: "v1.2.3 (Recommended)".
 *
 *
 * ```
 * <FormsInputs::VersionSelect
 *   @versions={{versions}}
 *   @selectedVersion={{selectedVersion}}
 *   @onChange={{onChange}}
 *   @disabled={{disabled}}
 * />
 * ```
 *
 * @class FormInputsVersionSelect
 *
 */
export default class FormInputsVersionSelect extends Component<VersionSelectSignature> {
  /**
   * @argument versions - the list of verstions from API data
   * @type {Array.<Object>}
   */
  /**
   * @argument selectedVersion - the selected version number string (example: "v.1.2.3")
   * @type {String}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */

  <template>
    <span data-test-form-inputs-version-select>
      <HdsFormSelectField
        id="version"
        name="version"
        aria-describedby="versionHelp"
        disabled={{@disabled}}
        data-test-version
        {{on "change" @onChange}}
        as |F|
      >
        <F.Options>
          {{#each @versions key="version" as |version|}}
            <option
              value={{version.version}}
              selected={{eq @selectedVersion version.version}}
            >
              {{t
                "components.form-inputs.version-select.option"
                version=version.version
                status=version.status
              }}
            </option>
          {{/each}}
        </F.Options>
      </HdsFormSelectField>
    </span>
  </template>
}
