import Component from '@glimmer/component';

interface LoadingMessageSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `LoadingMessage` displays the message element of the Loading screen.
 *
 *
 * ```
 * <Loading::Message>Message</Loading::Message>
 * ```
 *
 * @class LoadingMessage
 *
 */

export default class LoadingMessage extends Component<LoadingMessageSignature> {
  <template>
    <div class="loading-state__message" data-test-loading-message-container>
      {{yield}}
    </div>
  </template>
}
