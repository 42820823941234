import { hash } from '@ember/helper';
import Component from '@glimmer/component';

import StepperStep from './stepper/step.gts';

interface StepperSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `Stepper` displays sequential steps in a vertical layout.
 * Consumers of this component determine which icon, label, and content should
 * be shown for each step.
 *
 *
 * ```
 * <Stepper as |SR|>
 *   <SR.Step as |S|>
 *     <S.StepIcon>
 *       <HexagonStep @icon='loading' />
 *       <span class='hcp-visually-hidden>
 *         Pending
 *       </span>
 *     </S.StepIcon>
 *     <S.StepLabel>
 *       Label
 *     </S.StepLabel>
 *     <S.StepContent>
 *       Content
 *     </S.StepContent>
 *     <S.StepContent>
 *       <Button>Action</Button>
 *     </S.StepContent>
 *   </SR.Step>
 *   <SR.Step as |S|>
 *     <S.StepIcon>
 *       <HexagonStep @text='2' />
 *       <span class='hcp-visually-hidden>
 *         Incomplete
 *       </span>
 *     </S.StepIcon>
 *     <S.StepLabel>
 *       Label
 *     </S.StepLabel>
 *     <S.StepContent>
 *       Content
 *     </S.StepContent>
 *   </SR.Step>
 * </Stepper>
 * ```
 *
 * @class Stepper
 * @yield {StepperStep} Step `Stepper::Step` component
 *
 */

export default class Stepper extends Component<StepperSignature> {
  <template>
    <div class="stepper" ...attributes data-test-stepper-container>
      {{yield (hash Step=(component StepperStep))}}
    </div>
  </template>
}
