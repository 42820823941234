import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class UrlHelper extends Helper {
  @service router;

  /**
   * Returns a url for this route.
   * @param {array} args - The same arguments for router's urlFor.
   * @param {String} args[0] - The route name.
   * @returns {Array}
   * @see https://api.emberjs.com/ember/release/classes/RouterService/methods/transitionTo?anchor=urlFor
   */
  compute([routeName, ...args]) {
    return this.router.urlFor(routeName, ...args);
  }
}
