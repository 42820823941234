import Component from '@glimmer/component';
import { t } from 'ember-intl';
import {
  HdsCardContainer,
  HdsButton,
} from '@hashicorp/design-system-components/components';

import ActionCard from './action-card.gts';
import boxPadding from '../modifiers/box-padding.ts';
import Typography from './typography.gts';

interface DownloadCardSignature {
  Args: {
    title: string;
    url: string;
  };
  Blocks: {
    description: [];
  };
}

/**
 *
 * `DownloadCard` is a card with a description, a label, and a link that opens the download url in a new tab.
 *
 * ```
 * <DownloadCard
 *   @title={{'Audit Logs'}}
 *   @url={{'download-url'}}
 * />
 *   <:description>{{'December 12, 2020 to December 13, 2020'}}</:description>
 * </DownloadCard>
 * ```
 *
 * @class DownloadCard
 *
 */

export default class DownloadCard extends Component<DownloadCardSignature> {
  <template>
    <HdsCardContainer
      {{! @glint-expect-error }}
      @background="neutral-primary"
      @hasBorder={{true}}
      {{! @glint-expect-error }}
      @level="mid"
      {{! @glint-expect-error }}
      @overflow="visible"
      {{boxPadding "md"}}
    >
      <ActionCard>
        <:header>
          <Typography
            @variant="h4"
            class="hds-typography-display-200 hds-font-weight-medium"
            data-test-download-card-title
          >
            {{@title}}
          </Typography>
        </:header>
        <:description>
          <Typography
            class="hds-typography-body-100 hds-font-weight-regular hds-form-helper-text fs-exclude"
            data-test-download-card-description
          >
            {{yield to="description"}}
          </Typography>
        </:description>
        <:button>
          <HdsButton
            @color="secondary"
            @href={{@url}}
            @icon="download"
            @isIconOnly={{true}}
            @isHrefExternal={{true}}
            @text={{t
              "vault.components.modals.download-audit-logs-modal.title"
            }}
            data-test-download-button
          />
        </:button>
      </ActionCard>
    </HdsCardContainer>
  </template>
}
