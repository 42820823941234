import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { HdsBreadcrumbItem } from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

import type BreadcrumbsService from '@bagaar/ember-breadcrumbs/services/breadcrumbs';
import type { HdsBreadcrumbItemSignature } from '@hashicorp/design-system-components/components/hds/breadcrumb/item';

interface BreadcrumbsItemSignature {
  Args: HdsBreadcrumbItemSignature['Args'];
  Blocks: {
    extra: [];
  };
  Element: HTMLElement;
}

/**
 * `route` is the dot-notation path of the route for the breadcrumb. This
 * argument is equivalent to the `route` arg in `Hds::Breadcrumb::Item`.
 *
 * @argument route;
 * @type {String}
 * @see https://design-system-components-hashicorp.vercel.app/components/breadcrumb#how-to-use
 *
 */

/**
 * `text` is the rendered string that is displayed for the breadcrumb. This
 * argument is equivalent to the `text` arg in `Hds::Breadcrumb::Item`.
 *
 * @argument text;
 * @type {String}
 * @see https://design-system-components-hashicorp.vercel.app/components/breadcrumb#how-to-use
 *
 */

/**
 * `Breadcrumbs::Item` renders an individual breadcrumb which gets portal'd to
 * the `Breadcrumbs::Container`. Each `Item` passes through `route` and `text`
 * to an underlying HDS component. A built-in `LinkTo` will be rendered
 * in the context it needs to link to and will be able to infer the implicit
 * models of each route that are required. This means that only `route` and
 * `text` should be necessary arguments. We don't render the HDS component as
 * a child here because we want to control the api of that component in a single
 * place.
 *
 * ```
 * <Breadcrumbs::Item
 *  @route='my.route.name'
 *  @text={{t 'my.translation.string'}}
 * />
 * ```
 *
 * @class BreadcrumbsItemComponent
 *
 */

export default class BreadcrumbsItem extends Component<BreadcrumbsItemSignature> {
  @service('breadcrumbs')
  declare readonly breadcrumbsService: BreadcrumbsService;

  // @ts-expect-error
  @tracked element = null;

  registerSelf = (element: Element) => {
    // @ts-expect-error
    this.element = element;
  };

  get current() {
    const crumbs = this.breadcrumbsService?.containers[0]?.element.children;
    const lastCrumb = crumbs?.[crumbs.length - 1];
    return lastCrumb?.isEqualNode(this.element);
  }

  <template>
    {{#each this.breadcrumbsService.containers as |container|}}
      {{#in-element container.element insertBefore=null}}
        {{! TODO! add all the other LinkTo parameters as in the LinkStandalone }}
        <HdsBreadcrumbItem
          @text={{@text}}
          @icon={{@icon}}
          @route={{@route}}
          @models={{@models}}
          @query={{@query}}
          @model={{@model}}
          @current={{this.current}}
          ...attributes
          {{didInsert this.registerSelf}}
          data-test-breadcrumbs-item
        >
          {{! @glint-expect-error: not typed }}
          <:extra>
            {{yield to="extra"}}
          </:extra>
        </HdsBreadcrumbItem>
      {{/in-element}}
    {{/each}}
  </template>
}
