import { DateTime } from 'luxon';

// hours in day * minutes in hour * seconds in minute * ms in seconds
const millisecondsInDay = 24 * 60 * 60 * 1000;

export const sevenDays = 7 * millisecondsInDay;
export const thirtyDays = 30 * millisecondsInDay;

export function getTimeUntil(date) {
  let until = DateTime.fromJSDate(date, { zone: 'utc' });
  return until.diff(DateTime.utc()).toObject();
}

export function getTimeSince(date) {
  let from = DateTime.fromJSDate(date, { zone: 'utc' });
  return DateTime.utc().diff(from).toObject();
}
