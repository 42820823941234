import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import Typography from '../typography.gts';
import DetailSectionZeroState from './section/zero-state.gts';

interface DetailSectionSignature {
  Args: {
    title: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `DetailSection` wraps content for display on detail pages.
 *
 *
 * ```
 * <Detail::Section @title="Title">
 *   Content
 * </Detail::Section>
 * ```
 *
 * @class DetailSection
 *
 */

export default class DetailSection extends Component<DetailSectionSignature> {
  /**
   *
   * The title of the section to render as a Typography h3.
   * @argument title
   * @type string
   *
   */

  <template>
    <section
      class="detailSection"
      data-test-detail-section-container
      ...attributes
    >
      <header class="detailSection__header" data-test-detail-section-header>
        <Typography @variant="h2" data-test-detail-section-header-title>
          {{@title}}
        </Typography>
      </header>
      {{yield (hash ZeroState=(component DetailSectionZeroState))}}
    </section>
  </template>
}
