/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import {
  HdsBadge,
  HdsIcon,
} from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

import boxMargin from '../modifiers/box-margin.ts';

import type Owner from '@ember/owner';
import type { HdsBadgeSignature } from '@hashicorp/design-system-components/components/hds/badge/index';

interface DisclosureSignature {
  Args: {
    badge?: HdsBadgeSignature['Args'];
    label?: string;
    openLabel?: string;
    isInitiallyOpen?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 * `label` is the text to show in the default state
 * @argument label
 * @type {string}
 * @required
 */

/**
 * `openLabel` is the text to show in the open state. When not provided,
 * @label is used instead.
 * @argument openLabel
 * @type {string}
 * @optional
 */

/**
 * `isInitiallyOpen` can be optionally set to `true` if the disclosure should be
 * open on initial render.
 * @argument isInitiallyOpen
 * @type {boolean}
 * @optional
 */

/**
 * `badge` can be optionally set to an object containing args for the Hds::Badge
 * component. It's presence also acts as boolean for its rendering in the <summary>
 * element.
 * @argument badge
 * @type {Object}
 * @optional
 */

export default class Disclosure extends Component<DisclosureSignature> {
  // This property only tracks the `<details>` `open` attribute, allowing us to
  // re-render when that changes.
  @tracked isOpen;

  constructor(owner: Owner, args: DisclosureSignature['Args']) {
    super(owner, args);
    this.isOpen = args.isInitiallyOpen || false;
  }

  setOpen = (ctx: { target: { open: any }; open: any }) => {
    // `ctx` is either an event from toggling detail or the details element
    // itself passed in the did-insert render modifier to set the initial state.
    this.isOpen = ctx?.target?.open ?? ctx.open;
  };

  <template>
    <details
      class="disclosure"
      open={{this.isOpen}}
      ...attributes
      {{! @glint-expect-error: not typesafe yet }}
      {{on "toggle" this.setOpen}}
      {{! @glint-expect-error: not typesafe yet }}
      {{didInsert this.setOpen}}
    >
      <summary>
        <HdsIcon
          @name={{if this.isOpen "chevron-up" "chevron-down"}}
          @size="16"
          class="disclosure__icon"
        />
        <span class="disclosure__label">
          {{~if this.isOpen (or @openLabel @label) @label}}
        </span>
        {{#if @badge}}
          <span {{boxMargin "0 0 0 sm"}}>
            <HdsBadge
              @text={{@badge.text}}
              @color={{@badge.color}}
              @icon={{@badge.icon}}
              @size={{@badge.size}}
            />
          </span>
        {{/if}}
      </summary>
      {{yield}}
    </details>
  </template>
}
