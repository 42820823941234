import Component from '@glimmer/component';
import { service } from '@ember/service';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsApplicationState } from '@hashicorp/design-system-components/components';

import type ConfigService from 'api/services/config';

import optionForHttpError from '../helpers/option-for-http-error.js';
import Flex from './flex.gts';
import {
  AlignItem,
  FlexBasis,
  FlexDirection,
  JustifyContent,
} from '../utils/flex.ts';

interface HttpErrorSignature {
  Args: {
    code: string;
    message?: string;
    title?: string;
    previousRoute?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `HttpError` wraps an Error component to help with rendering HTTP Errors.
 *
 *
 * ```
 * <HttpError
 *   @code="404"
 * />
 * ```
 *
 * @class HttpError
 *
 */

export default class HttpError extends Component<HttpErrorSignature> {
  @service declare readonly config: ConfigService;

  /**
   * A route path for the Go back link.
   * @argument previousRoute
   * @type {?string}
   */

  /**
   * A string to override the default title for the error code.
   * @argument title
   * @type {?string}
   */

  /**
   * A string to override the default message for the error code.
   * @argument message
   * @type {?string}
   */

  /**
   * The HTTP Error code to use when mapping error messaging.
   * @argument code
   * @type {string}
   */

  get previousRoute() {
    return this.args.previousRoute || this.config?.app?.defaultRoute;
  }

  <template>
    {{#let (optionForHttpError @code) as |option|}}
      <Flex
        class="http-error"
        @alignItems={{AlignItem.Center}}
        @basis={{FlexBasis.ZeroPercent}}
        @direction={{FlexDirection.Column}}
        @grow={{1}}
        @shrink={{1}}
        @justifyContent={{JustifyContent.Center}}
        data-test-http-error-container
        ...attributes
      >
        <HdsApplicationState as |A|>
          <A.Header
            @title={{or @title (t option.label)}}
            @icon={{option.icon}}
            @errorCode={{@code}}
            data-test-http-error-state-title
          />
          <A.Body
            @text={{or @message (t option.message)}}
            data-test-http-error-state-content
          />
          <A.Footer data-test-http-error-state-footer as |F|>
            {{yield F}}
          </A.Footer>
        </HdsApplicationState>
      </Flex>
    {{/let}}
  </template>
}
