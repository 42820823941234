import Component from '@glimmer/component';

import Flex from '../../../flex.gts';
import { FlexDirection, FlexGap } from '../../../../utils/flex.ts';

export interface CoreCardSummaryValueSignature {
  Args: {
    contextualClass?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `CoreCardSummaryValueComponent` provides a container to display a value in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Value>
 *   Some Value
 * </Core::Card::Summary::Value>
 * ```
 *
 * @class CoreCardSummaryValueComponent
 *
 */

export default class CoreCardSummaryValue extends Component<CoreCardSummaryValueSignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryValueComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const { contextualClass } = this.args;

    const classes = [
      'core-card-summary-value',
      'hds-typography-body-100',
      'hds-font-weight-regular',
      'hds-foreground-primary',
    ];

    if (contextualClass) {
      classes.push(contextualClass);
    }

    return classes.join(' ');
  }

  <template>
    <dd
      class={{this.classNames}}
      data-test-core-card-value-container
      ...attributes
    >
      <Flex @direction={{FlexDirection.Row}} @gap={{FlexGap.Xxs}}>
        <div
          class="core-card-summary__icon-container"
          data-test-core-card-value-icon
        ></div>

        <div data-test-core-card-value-yield>
          {{yield}}
        </div>
      </Flex>
    </dd>
  </template>
}
