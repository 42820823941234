import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import StepperStepIcon from './step-icon.gts';
import StepperStepLabel from './step-label.gts';
import StepperStepContent from './step-content.gts';

interface StepperStepSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `StepperStep` renders a single step that should be used within the Stepper component.
 * Each step must provide a StepLabel and StepIcon, with optional StepContent.
 * `StepperStep` supports rich step content - the user may provide either text or markup within the StepContent blocks.
 * The StepContent yielded component provides OOTB spacing and multiple StepContent components can be used within a single
 * step if the user wants to have consistent spacing between sections of content (text, buttons, links, banners, etc).
 *
 *
 * ```
 * <Stepper::Step
 *   as |S|
 * >
 *   <S.StepIcon>
 *     <HexagonStep @icon='loading' />
 *     <span class='hcp-visually-hidden>
 *       Pending
 *     </span>
 *   </S.StepIcon>
 *   <S.StepLabel>
 *     First step
 *   </S.StepLabel>
 *   <S.StepContent>
 *     This is what you need to do to complete step 1.
 *   </S.StepContent>
 *   <S.StepContent>
 *     You can use multiple StepContent blocks in each step if you want to take
 *     advantage of consistent spacing between sections of content.
 *   </S.StepContent>
 *   <S.StepContent>
 *     <Button>Do something</Button>
 *   </S.StepContent>
 * </Stepper::Step>
 * ```
 *
 * @class StepperStep
 * @yield {StepperStepIcon} StepIcon `Stepper::StepIcon` component
 * @yield {StepperStepLabel} StepLabel `Stepper::StepLabel` component
 * @yield {StepperStepContent} StepContent `Stepper::StepContent` component
 *
 */
export default class StepperStep extends Component<StepperStepSignature> {
  <template>
    <div class="stepper_step" ...attributes data-test-stepper-step-container>
      {{! Grid layout - top left (step icon) }}
      <div class="stepper_stepLabelIcon">
        {{yield (hash StepIcon=(component StepperStepIcon))}}
      </div>

      {{! Grid layout - top right (step label) }}
      <div class="stepper_stepLabelWrapper" data-test-stepper-step-label>
        {{yield (hash StepLabel=(component StepperStepLabel))}}
      </div>

      {{! Grid layout - bottom left (vertical line) }}
      <div class="stepper_stepConnector"></div>

      {{! Grid layout - Bottom right (step content) }}
      <div class="stepper_stepContent" data-test-stepper-step-content-container>
        {{yield (hash StepContent=(component StepperStepContent))}}
      </div>
    </div>
  </template>
}
