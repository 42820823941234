import Component from '@glimmer/component';
import { service } from '@ember/service';
import SystemNotificationColors from 'common/utils/system-notification-colors';
import { t } from 'ember-intl';
import { hash, concat } from '@ember/helper';
import { formatNumber } from 'ember-intl';
import { or } from 'ember-truth-helpers';
import { HdsAlert } from '@hashicorp/design-system-components/components';

import './payment-banner.scss';

const PAYMENT_BANNER = Object.freeze({
  ADD: {
    bannerColor: SystemNotificationColors.NEUTRAL,
    intlKey: 'add',
  },
  ERROR: {
    bannerColor: SystemNotificationColors.CRITICAL,
    intlKey: 'error',
  },
  LOW_CREDITS: {
    bannerColor: SystemNotificationColors.WARNING,
    intlKey: 'low-credits',
  },
  ZERO_CREDITS: {
    bannerColor: SystemNotificationColors.CRITICAL,
    intlKey: 'zero-credits',
  },
  ADD_ON_CONTRACT: {
    bannerColor: SystemNotificationColors.NEUTRAL,
    intlKey: 'add-on-contract',
  },
});

const DEFAULT_TRANSLATION_PREFIX = 'billing-common.overview';

export default class PaymentBanner extends Component {
  @service userContext;
  @service abilities;

  get canEditBilling() {
    return this.abilities.can('edit billing-account');
  }

  get isTrialOrIncomplete() {
    let status = this.args.accountStatus;
    return (
      !this.args.isRemovingCreditCard &&
      (status?.isTrial || status?.isIncomplete)
    );
  }

  get paymentBanner() {
    // If the user is not on Trial and has a card on file, we ensure the credit card
    // is valid and not expired. If not, display a payment error banner.
    if (!this.isTrialOrIncomplete && this.args.cardDetails) {
      return this.args.hasCardError ? PAYMENT_BANNER.ERROR : undefined;
    }

    if (!this.args.cardDetails) {
      if (this.isTrialOrIncomplete) {
        if (this.args.credits?.isLow) {
          // If they are a trial account and have low credits
          return PAYMENT_BANNER.LOW_CREDITS;
        }

        if (this.args?.credits?.isZero) {
          // If they are an incomplete account show zero credits
          return PAYMENT_BANNER.ZERO_CREDITS;
        }
      }

      // if Flex or Entitlement contract
      if (this.args.isOnContract) {
        // if they are on contract, we inform the user that the card will
        // only be used if their contract ends and they transition to On Demand
        return PAYMENT_BANNER.ADD_ON_CONTRACT;
      }

      return PAYMENT_BANNER.ADD;
    }

    return undefined;
  }

  get translationPrefix() {
    let { translationPrefix = DEFAULT_TRANSLATION_PREFIX } = this.args;
    return translationPrefix;
  }

  <template>
    {{#let this.paymentBanner as |paymentBanner|}}
      <div ...attributes>
        {{#if paymentBanner}}
          <div class="billing-payment-banner">
            <HdsAlert
              @type="inline"
              @color={{paymentBanner.bannerColor}}
              as |A|
            >
              <A.Title>
                {{t
                  (concat
                    this.translationPrefix
                    ".payment-method.alert."
                    paymentBanner.intlKey
                    ".title"
                  )
                }}
              </A.Title>
              <A.Description>
                {{t
                  (concat
                    this.translationPrefix
                    ".payment-method.alert."
                    paymentBanner.intlKey
                    ".content"
                    (unless this.canEditBilling "-contributor")
                  )
                  credits=(formatNumber
                    (or @credits 0) currency="USD" style="currency"
                  )
                }}
              </A.Description>
              {{#if this.canEditBilling}}
                <A.Button
                  @model={{@accountId}}
                  @color="secondary"
                  @size="small"
                  @text={{t
                    (concat
                      this.translationPrefix
                      ".payment-method.alert."
                      paymentBanner.intlKey
                      ".action"
                    )
                  }}
                  @route={{or
                    @editPaymentRoute
                    "accounts.account.payments.credit-card"
                  }}
                  @query={{hash org_id=this.userContext.organization.id}}
                  data-test-payment-button
                />
              {{/if}}
            </HdsAlert>
          </div>
        {{else if (has-block "or")}}
          {{yield to="or"}}
        {{/if}}
      </div>
    {{/let}}
  </template>
}
