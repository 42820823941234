/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefIntegrationFromJSON, HashicorpCloudWaypointRefIntegrationToJSON, HashicorpCloudWaypointV20241122GetGitHubReposRequestSessionTargetFromJSON, HashicorpCloudWaypointV20241122GetGitHubReposRequestSessionTargetToJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON, HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON, } from './';
export function HashicorpCloudWaypointV20241122WaypointServiceGetGitHubReposBodyFromJSON(json) {
    return HashicorpCloudWaypointV20241122WaypointServiceGetGitHubReposBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointV20241122WaypointServiceGetGitHubReposBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceFromJSON(json['namespace']),
        'session': !exists(json, 'session') ? undefined : HashicorpCloudWaypointV20241122GetGitHubReposRequestSessionTargetFromJSON(json['session']),
        'integration': !exists(json, 'integration') ? undefined : HashicorpCloudWaypointRefIntegrationFromJSON(json['integration']),
    };
}
export function HashicorpCloudWaypointV20241122WaypointServiceGetGitHubReposBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': HashicorpCloudWaypointV20241122WaypointServiceCreateAddOnBodyNamespaceToJSON(value.namespace),
        'session': HashicorpCloudWaypointV20241122GetGitHubReposRequestSessionTargetToJSON(value.session),
        'integration': HashicorpCloudWaypointRefIntegrationToJSON(value.integration),
    };
}
