import Component from '@glimmer/component';

interface DefinitionListValueSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `DefinitionValue` provides a container to display a value in a key/value pair.
 *
 *
 * ```
 * <Definition::Value>
 *   Some Value
 * </Definition::Value>
 * ```
 *
 * @class DefinitionValue
 *
 */

export default class DefinitionListValue extends Component<DefinitionListValueSignature> {
  <template>
    <dd
      class="hds-typography-body-200 hds-font-weight-regular hds-foreground-primary"
      data-test-definition-list-value
      ...attributes
    >
      {{yield}}
    </dd>
  </template>
}
