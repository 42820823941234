import Component from '@glimmer/component';
import Flex from './flex.gts';
import {
  AlignItem,
  FlexDirection,
  JustifyContent,
  FlexGap,
  FlexBasis,
} from '../utils/flex.ts';

interface ActionCardSignature {
  // Args: {};
  Blocks: {
    button: [];
    description: [];
    header: [];
    icon: [];
  };
}

/**
 *
 * `ActionCard` is a skeletal component for higher-order layouts. It's built
 * with the `Flex` component to stack a title and/or a label, center them
 * vertically, and allow for a space to render a button. This is powered by
 * any of the following combinations of the named blocks ":icon," ":header,"
 * ":description," and ":button".
 *
 * ```
 * <ActionCard>
 *   <:header>header</:header>
 *   <:description>description</:description>
 *   <:button>
 *     <button type='button'>Action</button>
 *   </:button>
 * </ActionCard>
 * ```
 *
 * @class ActionCard
 *
 */

export default class ActionCard extends Component<ActionCardSignature> {
  <template>
    <Flex
      @alignItems={{AlignItem.Center}}
      @direction={{FlexDirection.Row}}
      @gap={{FlexGap.Md}}
      @justifyContent={{JustifyContent.SpaceBetween}}
      @wrap={{false}}
      data-test-action-card-container
    >
      {{#if (has-block "icon")}}
        <Flex @alignItems={{AlignItem.Center}} data-test-action-card-icon>
          {{yield to="icon"}}
        </Flex>
      {{/if}}
      <Flex
        @alignItems={{AlignItem.Start}}
        @basis={{FlexBasis.MinContent}}
        @direction={{FlexDirection.Column}}
        @grow={{1}}
        class="action-card-content"
        data-test-action-card-content
      >
        {{#if (has-block "header")}}
          <header data-test-action-card-header>
            {{yield to="header"}}
          </header>
        {{/if}}
        {{#if (has-block "description")}}
          <div
            class="action-card-description"
            data-test-action-card-description
          >
            {{yield to="description"}}
          </div>
        {{/if}}
      </Flex>
      {{#if (has-block "button")}}
        <Flex @alignItems={{AlignItem.Center}} data-test-action-card-button>
          {{yield to="button"}}
        </Flex>
      {{/if}}
    </Flex>
  </template>
}
