import Component from '@glimmer/component';
import { capitalize } from '@ember/string';
import { fn } from '@ember/helper';
import { and, not } from 'ember-truth-helpers';
import { HdsAlert } from '@hashicorp/design-system-components/components';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';

import boxMargin from '../../modifiers/box-margin.ts';

import type { FieldViolation } from '../../utils/consts/with-errors.ts';

interface WithErrorsFieldErrorSignature {
  Args: {
    id?: string;
    field: string;
    fieldViolations?: FieldViolation[];
    onInsert: (field: string) => void;
    hdsDelegatedError?: boolean;
  };
  Blocks: {
    default: [FieldViolation | undefined];
  };

  Element: HTMLElement;
}

export default class WithErrorsFieldError extends Component<WithErrorsFieldErrorSignature> {
  /**
   * A string that will match the `field` value of an object in an error.details[].field_violation. This will be used to determine if there is an error corresponding to the passed field.
   * @argument field
   * @type {string}
   *
   */

  /**
   * An array extracted from rolling up all error.details[].field_violations from the parent WithErrors component.
   * @argument fieldViolations
   * @type {array}
   *
   */

  /**
   * An ID to use for the HTML id attribute (for a11y) on the error message.
   * @argument id
   * @type {string}
   *
   */

  // an error matching the specified field from `@field`
  get fieldError() {
    const fieldViolation = this.args.fieldViolations?.find((e) => {
      return e.field === this.args.field;
    });

    if (fieldViolation?.description) {
      const lastCharacter =
        fieldViolation.description[fieldViolation.description.length - 1];

      const punctuationList = ['.', '?', '!'];

      if (lastCharacter && !punctuationList.includes(lastCharacter)) {
        fieldViolation.description = fieldViolation.description + '.';
      }

      fieldViolation.description = capitalize(fieldViolation.description);
    }

    return fieldViolation;
  }

  <template>
    <noscript {{didInsert (fn @onInsert @field)}}></noscript>
    {{yield this.fieldError}}

    {{! here we render field-specific error }}
    {{#if (and this.fieldError (not @hdsDelegatedError))}}
      <HdsAlert
        data-test-with-errors-field-error
        id={{@id}}
        @type="compact"
        @color="critical"
        @icon="alert-diamond-fill"
        {{boxMargin "0 0 lg"}}
        as |A|
      >
        <A.Description>
          {{this.fieldError.description}}
        </A.Description>
      </HdsAlert>
    {{/if}}
  </template>
}
