import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import { sortBy } from '@nullvoxpopuli/ember-composable-helpers';
import { HdsFormSelectField } from '@hashicorp/design-system-components/components';

import optionsForRegions from '../../helpers/options-for-regions.js';

interface RegionSelectSignature {
  Args: {
    disabled: boolean;
    error: { description: string } | null;
    id: string;
    onChange: (event: Event) => void;
    provider: string;
    regions: [];
    selectedRegion: string;
  };
  Blocks: {
    'helper-text': [];
    label: [];
  };
  Element: HTMLElement;
}

/**
 *
 * Simple select box input, formats & localizes AWS region name options.
 *
 *
 * ```
 * <FormInputs::RegionSelect
 *   @regions={{regions}}
 *   @selectedRegion={{selectedRegion}}
 *   @onChange={{onChange}}
 *   @disabled={{disabled}}
 * />
 * ```
 *
 * @class FormInputsRegionSelect
 *
 */
export default class FormInputsRegionSelect extends Component<RegionSelectSignature> {
  /**
   * @argument regions - list of all AWS regions (Example: ["us-east-1", "eu-west-1"])
   * @type {Array.<String>}
   */
  /**
   * @argument selectedRegion
   * @type {String}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */

  <template>
    <span data-test-form-inputs-region-select>
      <HdsFormSelectField
        @isInvalid={{if @error true false}}
        @id={{if @id @id "region"}}
        name="region"
        aria-describedby="region-help"
        disabled={{@disabled}}
        ...attributes
        {{on "change" @onChange}}
        as |F|
      >
        <F.Label>
          {{yield to="label"}}
        </F.Label>
        <F.HelperText>
          {{yield to="helper-text"}}
        </F.HelperText>
        <F.Options>
          {{#each
            (sortBy "name" (optionsForRegions @regions provider=@provider))
            as |continent|
          }}
            <optgroup
              {{! @glint-expect-error: not typed }}
              label={{t continent.name}}
              {{! @glint-expect-error: not typed }}
              data-test-region-select-continent-optgroup={{continent.id}}
            >
              {{! @glint-expect-error: not typed }}
              {{#each (sortBy "id" continent.regions) as |region|}}
                <option
                  {{! @glint-expect-error: not typed }}
                  value={{region.id}}
                  {{! @glint-expect-error: not typed }}
                  selected={{eq @selectedRegion region.id}}
                  {{! @glint-expect-error: not typed }}
                  data-test-region-select-region-option={{region.id}}
                >
                  {{! @glint-expect-error: not typed }}
                  {{t region.name}}
                  {{! @glint-expect-error: not typed }}
                  ({{region.id}})
                </option>
              {{/each}}
            </optgroup>
          {{/each}}
        </F.Options>

        {{#if @error}}
          <F.Error>
            {{@error.description}}
          </F.Error>
        {{/if}}
      </HdsFormSelectField>
    </span>
  </template>
}
