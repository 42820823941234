import Component from '@glimmer/component';
import {
  AlignItem,
  Display,
  FlexBasis,
  FlexDirection,
  FlexGap,
  FlexGrow,
  FlexShrink,
  JustifyContent,
} from '../utils/flex.ts';
import classNames from '../helpers/class-names.ts';

interface FlexSignature {
  Args: {
    alignItems?: AlignItem;
    basis?: FlexBasis;
    direction?: FlexDirection;
    display?: Display;
    /**
     * These 'tshirt size' values map to the same space tokens as the `box-padding`
     * modifier uses.
     */
    gap?: FlexGap;
    grow?: number | string;
    justifyContent?: JustifyContent;
    shrink?: number | string;
    wrap?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `Flex` is a convenience component to create a flex container without writing
 * bespoke CSS.
 *
 * It supports some (but not all) flex attributes
 * - `align-items`
 * - `display`
 * - `flex-basis`
 * - `flex-direction`
 * - `flex-grow`
 * - `flex-shrink`
 * - `flex-wrap`
 * - `gap`
 * - `justify-content`
 *
 * See the argument types for supported values for each attribute.
 *
 * ```
 * <Flex
 *   @alignItems='center'
 *   @basis='content'
 *   @direction='column'
 *   @display='inline'
 *   @gap='xs'
 *   @grow={{1}}
 *   @justifyContent='space-between'
 *   @shrink={{1}}
 *   @wrap={{false}}
 * >
 *   My flex container content
 * </Flex>
 * ```
 *
 * @class Flex
 *
 */
export default class Flex extends Component<FlexSignature> {
  get alignClass() {
    const { alignItems } = this.args;
    if (!alignItems) return undefined;

    switch (alignItems) {
      case AlignItem.Center:
      case AlignItem.End:
      case AlignItem.Start:
        return `hcp-flex-align-items-${alignItems}`;
      default:
        return undefined;
    }
  }

  get basisClass() {
    const { basis } = this.args;

    switch (basis) {
      case FlexBasis.Content:
      case FlexBasis.FitContent:
      case FlexBasis.Inherit:
      case FlexBasis.Initial:
      case FlexBasis.MaxContent:
      case FlexBasis.MinContent:
      case FlexBasis.Revert:
      case FlexBasis.RevertLayer:
      case FlexBasis.Unset:
      case FlexBasis.ZeroPercent:
        return `hcp-flex-basis-${basis}`;
      default:
        return undefined;
    }
  }

  get directionClass() {
    const { direction } = this.args;
    if (!direction) return undefined;

    switch (direction) {
      case FlexDirection.Column:
      case FlexDirection.Row:
        return `hcp-flex-direction-${direction}`;
      default:
        return undefined;
    }
  }

  get displayClass() {
    const { display } = this.args;
    if (!display) return undefined;

    switch (display) {
      case Display.Inline:
        return `hcp-flex-display-${display}`;
      default:
        return undefined;
    }
  }

  get gapClass() {
    const { gap } = this.args;

    switch (gap) {
      case FlexGap.Xxs:
      case FlexGap.Xs:
      case FlexGap.Sm:
      case FlexGap.Md:
      case FlexGap.Lg:
      case FlexGap.Llg:
      case FlexGap.Xl:
      case FlexGap.Xxl:
        return `hcp-flex-gap-${gap}`;
      default:
        return undefined;
    }
  }

  get growClass() {
    const { grow } = this.args;

    switch (grow) {
      case FlexGrow.Zero:
      case FlexGrow.One:
      case FlexGrow.Two:
        return `hcp-flex-grow-${grow}`;
      default:
        return undefined;
    }
  }

  get justifyClass() {
    const { justifyContent } = this.args;
    if (!justifyContent) return undefined;

    switch (justifyContent) {
      case JustifyContent.Center:
      case JustifyContent.End:
      case JustifyContent.Start:
      case JustifyContent.SpaceBetween:
      case JustifyContent.SpaceAround:
      case JustifyContent.Stretch:
        return `hcp-flex-justify-content-${justifyContent}`;
      default:
        return undefined;
    }
  }

  get shrinkClass() {
    const { shrink } = this.args;

    switch (shrink) {
      case FlexShrink.Zero:
      case FlexShrink.One:
      case FlexShrink.Two:
        return `hcp-flex-shrink-${shrink}`;
      default:
        return undefined;
    }
  }

  get wrapClass() {
    const { wrap } = this.args;

    switch (wrap) {
      case true:
      case false:
        return `hcp-flex-wrap-${wrap}`;
      default:
        return undefined;
    }
  }

  <template>
    <div
      class={{classNames
        "hcp-flex"
        (if this.alignClass this.alignClass)
        (if this.basisClass this.basisClass)
        (if this.directionClass this.directionClass)
        (if this.displayClass this.displayClass)
        (if this.gapClass this.gapClass)
        (if this.growClass this.growClass)
        (if this.justifyClass this.justifyClass)
        (if this.shrinkClass this.shrinkClass)
        (if this.wrapClass this.wrapClass)
      }}
      ...attributes
    >
      {{yield}}
    </div>
  </template>
}
