/**
 * FilterBar component is responsible for managing and applying filters
 * for the manage-access feature. It provides a UI for users to input
 * search terms and select various filter options such as scope types,
 * service IDs, and scope IDs. The component also handles the application
 * and clearing of these filters, updating the query parameters in the URL
 * accordingly.
 *
 * @component
 * @extends Component
 *
 * @service {RouterService} router - Ember's router service for handling transitions.
 *
 * @tracked {Filters} filters - The current set of filters applied by the user.
 *
 * @action handleOnApplyFilters - Applies the current filters by updating the URL query parameters.
 * @action handleOnClearFilters - Clears all filters and applies the empty filter set.
 * @action handleOnFiltersArgsChanged - Updates the internal filters state when the component's arguments change.
 * @action handleOnSearchInputChanged - Updates the search filter when the user types in the search input.
 * @action toggleFilterValue - Toggles a filter value in the specified filter category.
 * @action handleDropdown - Handles changes in dropdown filter selections.
 *
 * @task handleOnSubmit - Handles the form submission event to apply filters.
 *
 * @template
 * The template includes a form with search input and dropdowns for scope types and service IDs.
 * It also displays applied filters as tags and provides a button to clear all filters.
 *
 * @param {FilterBarArgs} args - The arguments passed to the component.
 * @param {HTMLElement} Element - The HTML element associated with the component.
 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

// Types
import type RouterService from '@ember/routing/router-service';

// Components
import {
  HdsButton,
  HdsIcon,
  HdsSegmentedGroup,
  HdsTag,
  HdsTextBody,
  HdsTooltipButton,
} from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';
import {
  AlignItem,
  FlexDirection,
  FlexGap,
  JustifyContent,
} from 'core/utils/flex';

// Helpers
import { t } from 'ember-intl';
import { concat, fn } from '@ember/helper';
import {
  entries,
  includes,
  findBy,
  queue,
} from '@nullvoxpopuli/ember-composable-helpers';
import set from 'ember-set-helper/helpers/set';
import { eq, or } from 'ember-truth-helpers';
import optionForSourceState from 'core/helpers/option-for-source-state';
import iamGetLabelForService from '../../../helpers/iam-get-label-for-service.ts';

// Modifiers
import { on } from '@ember/modifier';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import boxPadding from 'core/modifiers/box-padding';

// Constants
import { BASIC_ROLE_KEY_UI_ONLY } from '../../../utils/parse-role-id.ts';

interface FilterBarArgs {
  filterOptions: {
    scopeIds: { [key: string]: number };
    scopeTypes: { [key: string]: number };
    serviceIds: { [key: string]: number };
  };
  filters: {
    search: string;
    scopeIds: string[];
    scopeTypes: string[];
    serviceIds: string[];
  };
  scopeLinks: Array<{ id: string; name: string }>;
  currentScopeLink: { type: string };
  setClearFiltersAction: (action: () => Promise<void>) => void;
}

interface FilterBarSignature {
  Element: HTMLElement;
  Args: FilterBarArgs;
}

interface Filters {
  search?: string;
  scope_ids?: string[];
  scope_types?: string[];
  service_ids?: string[];
  [key: string]: string | string[] | undefined;
}

export default class FilterBar extends Component<FilterBarSignature> {
  constructor() {
    // This is a workaround to pass the clear filters action to the parent component.
    // As part of HCPF-2611 we will be adding the role assignments table to the manage-access
    // package and we will contextual components as use a more elegant solution
    // to pass the clear filters action.
    // @ts-expect-error
    super(...arguments);
    this.args.setClearFiltersAction(this.handleOnClearFilters);
  }

  @service declare readonly router: RouterService;

  @tracked filters: Filters = {
    search: '',
    service_ids: [],
    scope_ids: [],
    scope_types: [],
  };

  @action
  async handleOnApplyFilters() {
    const routeQueryParams = this.router.currentRoute?.queryParams ?? {};
    const queryParams = {
      ...routeQueryParams,
      ...this.filters,
      roles_current_page: 1,
    };

    if (this.router.currentRouteName) {
      await this.router.transitionTo(this.router.currentRouteName, {
        queryParams,
      });
    }
  }

  @action
  async handleOnClearFilters() {
    this.filters = {
      search: '',
      service_ids: [],
      scope_ids: [],
      scope_types: [],
    };
    await this.handleOnApplyFilters();
  }

  @action
  handleOnFiltersArgsChanged() {
    const { search, serviceIds, scopeIds, scopeTypes } = this.args.filters;

    this.filters = {
      search: search ?? '',
      service_ids: serviceIds ?? [],
      scope_ids: scopeIds ?? [],
      scope_types: scopeTypes ?? [],
    };
  }

  @action
  handleOnSearchInputChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    this.filters.search = input.value;
    if (!input.value) {
      this.handleOnApplyFilters();
    }
  }

  @action
  toggleFilterValue(name: keyof typeof this.filters, value: string) {
    if (!name || !value) {
      return;
    }

    if (!this.filters[name]) {
      this.filters[name] = [];
    }

    if (Array.isArray(this.filters[name])) {
      const values = this.filters[name] as Array<string>;
      if (values.includes(value)) {
        this.filters[name] = values.filter((item) => item !== value);
      } else {
        this.filters[name] = [...values, value];
      }
    }
  }

  @action
  handleDropdown(event: Event) {
    const input = event.target as HTMLInputElement;
    this.toggleFilterValue(
      input.name as keyof typeof this.filters,
      input.value,
    );
    this.handleOnApplyFilters();
  }

  handleOnSubmit = task(async (event: Event) => {
    event.preventDefault();
    await this.handleOnApplyFilters();
  });

  <template>
    {{#let
      (optionForSourceState "resource-type" @currentScopeLink.type)
      (or
        @filters.search
        @filters.scopeIds.length
        @filters.scopeTypes.length
        @filters.serviceIds.length
      )
      as |currentScopeOption isFiltered|
    }}
      <form
        class="hcp-hds-form"
        {{didInsert this.handleOnFiltersArgsChanged}}
        {{didUpdate this.handleOnFiltersArgsChanged @filters}}
        {{on "submit" this.handleOnSubmit.perform}}
        ...attributes
      >
        <HdsSegmentedGroup as |S|>
          <S.TextInput
            @type="search"
            @value={{this.filters.search}}
            @width="300px"
            placeholder={{t
              "manage-access.components.form.filter-bar.search-roles"
            }}
            aria-label={{t
              "manage-access.components.form.filter-bar.search-roles"
            }}
            {{on "input" this.handleOnSearchInputChanged}}
          />
          {{#let (entries @filterOptions.scopeTypes) as |scopeTypes|}}
            {{#if scopeTypes.length}}
              <S.Dropdown as |DD|>
                <DD.ToggleButton
                  @color="secondary"
                  @text={{t
                    "manage-access.components.form.filter-bar.granted-at"
                  }}
                  @count={{@filters.scopeTypes.length}}
                  data-test-scope-types-dropdown
                />
                {{#each scopeTypes as |scopeType|}}
                  {{#let
                    (optionForSourceState "resource-type" (get scopeType 0))
                    as |option|
                  }}
                    <DD.Checkbox
                      checked={{includes (get scopeType 0) @filters.scopeTypes}}
                      name="scope_types"
                      @value={{get scopeType 0}}
                      {{on "change" this.handleDropdown}}
                    >
                      {{t option.text}}
                    </DD.Checkbox>
                  {{/let}}
                {{/each}}
              </S.Dropdown>
            {{/if}}
          {{/let}}
          {{#let (entries @filterOptions.serviceIds) as |serviceIds|}}
            {{#if serviceIds.length}}
              <S.Dropdown as |DD|>
                <DD.ToggleButton
                  @color="secondary"
                  @text={{t "manage-access.components.form.filter-bar.service"}}
                  @count={{@filters.serviceIds.length}}
                  data-test-service-ids-dropdown
                />
                {{#each serviceIds as |serviceId|}}
                  {{#let (get serviceId 0) as |service|}}
                    <DD.Checkbox
                      checked={{includes service @filters.serviceIds}}
                      name="service_ids"
                      @value={{service}}
                      {{on "change" this.handleDropdown}}
                    >
                      {{iamGetLabelForService service}}
                      {{#if (eq service BASIC_ROLE_KEY_UI_ONLY)}}
                        ({{t currentScopeOption.text}})
                      {{/if}}
                    </DD.Checkbox>
                  {{/let}}
                {{/each}}
              </S.Dropdown>
            {{/if}}
          {{/let}}
        </HdsSegmentedGroup>
      </form>

      <Flex
        @alignItems={{AlignItem.Center}}
        @direction={{FlexDirection.Row}}
        @gap={{FlexGap.Xs}}
        @justifyContent={{JustifyContent.Start}}
        @wrap={{true}}
      >
        {{#if isFiltered}}
          <HdsTextBody @color="faint" @size="200" @weight="regular">
            {{t "manage-access.components.form.filter-bar.filters-applied"}}
          </HdsTextBody>
          <span aria-hidden="true" data-test-gap-spacer></span>
          {{#if @filters.search}}
            <HdsTag
              @text={{t
                "manage-access.components.form.filter-bar.filter-search"
                search=@filters.search
              }}
              {{! @glint-ignore }}
              @onDismiss={{queue
                (set this.filters "search" undefined)
                this.handleOnApplyFilters
              }}
            />
          {{/if}}

          {{#if @filters.scopeIds.length}}
            {{#each @filters.scopeIds as |scopeId|}}
              {{#let (findBy "id" scopeId @scopeLinks) as |scope|}}
                <HdsTag
                  @text={{t
                    "manage-access.components.form.filter-bar.filter-scope-name"
                    scopeName=scope.name
                  }}
                  {{! @glint-ignore }}
                  @onDismiss={{queue
                    (fn this.toggleFilterValue "scope_ids" scopeId)
                    this.handleOnApplyFilters
                  }}
                />
              {{/let}}
            {{/each}}
          {{/if}}

          {{#if @filters.scopeTypes.length}}
            {{#each @filters.scopeTypes as |scopeType|}}
              {{#let
                (optionForSourceState "resource-type" scopeType)
                as |option|
              }}
                <HdsTag
                  @text={{t
                    "manage-access.components.form.filter-bar.filter-scope-type"
                    scopeType=(t option.text)
                  }}
                  {{! @glint-ignore }}
                  @onDismiss={{queue
                    (fn this.toggleFilterValue "scope_types" scopeType)
                    this.handleOnApplyFilters
                  }}
                />
              {{/let}}
            {{/each}}
          {{/if}}

          {{#if @filters.serviceIds.length}}
            {{#each @filters.serviceIds as |serviceId|}}
              <HdsTag
                @text={{t
                  "manage-access.components.form.filter-bar.filter-service"
                  service=(concat
                    (iamGetLabelForService serviceId)
                    (if
                      (eq serviceId BASIC_ROLE_KEY_UI_ONLY)
                      (concat " (" (t currentScopeOption.text) ") ")
                    )
                    ""
                  )
                }}
                {{! @glint-ignore }}
                @onDismiss={{queue
                  (fn this.toggleFilterValue "service_ids" serviceId)
                  this.handleOnApplyFilters
                }}
              />
            {{/each}}
          {{/if}}

          <span aria-hidden="true" data-test-gap-spacer></span>
          <HdsButton
            @color="tertiary"
            @icon="x"
            @iconPosition="leading"
            @size="small"
            @text={{t "manage-access.components.form.filter-bar.clear-filters"}}
            {{on "click" this.handleOnClearFilters}}
            data-test-clear-filters
          />
        {{else}}
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @gap={{FlexGap.Xs}}
            {{boxPadding "2xs 0"}}
          >
            <HdsTextBody @color="faint" @size="200">
              {{t
                "manage-access.components.form.filter-bar.no-filters-applied"
              }}
            </HdsTextBody>
            <HdsTooltipButton
              @text={{t
                "manage-access.components.form.filter-bar.apply-filters-using-the-filter-bar"
              }}
              aria-label={{t
                "manage-access.components.form.filter-bar.apply-filters-using-the-filter-bar"
              }}
            >
              <HdsIcon
                @name="alert-circle"
                @color="var(--token-color-foreground-faint)"
                @isInline={{true}}
              />
            </HdsTooltipButton>
          </Flex>
        {{/if}}
      </Flex>
    {{/let}}
  </template>
}
