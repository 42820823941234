import Component from '@glimmer/component';
import { service } from '@ember/service';
import {
  macroCondition,
  importSync,
  getConfig,
  isTesting,
  isDevelopingApp,
} from '@embroider/macros';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import {
  HdsAppFrame,
  HdsSideNav,
  HdsSideNavHeader,
  HdsSideNavPortalTarget,
  HdsSideNavHeaderHomeLink,
  HdsAppFooter,
  HdsIcon,
} from '@hashicorp/design-system-components/components';

import HelpMenu from './help-menu.gts';
import UserMenu from './user-menu.gts';
import ProjectPicker from './project-picker.gts';
import OrganizationPicker from './organization-picker.gts';
import Flex from './flex.gts';
import { FlexBasis, FlexDirection } from '../utils/flex.ts';
import SystemNotifications from './system-notifications.gts';
import Notifications from './notifications.gts';

import type Owner from '@ember/owner';
import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type CurrentUserService from '../services/current-user.ts';
import type UserContextService from '../services/user-context.ts';
import type { HashicorpCloudResourcemanagerOrganization } from '@clients/cloud-resource-manager';

interface HcpAppFrameSignature {
  Args: {
    includeHomeLink: unknown;
    showStatusPageLink: unknown;
  };
  Blocks: {
    default: [];
  };
}

/**
 *
 * `HcpAppFrame` uses HDS HTML layout structure to wrap content.
 *
 *
 * ```
 * <HcpAppFrame>{{outlet}}</HcpAppFrame>
 * ```
 *
 * @class HcpAppFrame
 *
 */

export default class HcpAppFrame extends Component<HcpAppFrameSignature> {
  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly router: EnginesRouterService;
  @service declare readonly userContext: UserContextService;
  // @ts-expect-error no types for systemStatus
  @service declare readonly systemStatus;

  year = new Date().getFullYear();

  devComponents: Component[] = [];

  constructor(owner: Owner, args: HcpAppFrameSignature['Args']) {
    super(owner, args);
    if (
      macroCondition(
        isDevelopingApp() &&
          !isTesting() &&
          // @ts-expect-error getConfig shape is unknown
          !getConfig('api').isMirageEnabled
      )
    ) {
      // @ts-expect-error getConfig shape is unknown
      const { default: DevTools } = importSync('./dev-tools');
      this.devComponents.push(DevTools);
    }
    if (
      macroCondition(
        !isTesting() &&
          // @ts-expect-error getConfig shape is unknown
          getConfig('api').isMirageEnabled
      )
    ) {
      // @ts-expect-error getConfig shape is unknown
      const { default: MirageDevTools } = importSync('./mirage-dev-tools');
      this.devComponents.push(MirageDevTools);
    }
  }

  get includeHomeLink() {
    return this.args.includeHomeLink ?? true;
  }

  get currentOrg() {
    return this.userContext
      ?.organization as HashicorpCloudResourcemanagerOrganization;
  }

  get currentProject() {
    return this.userContext?.project;
  }

  get organizations() {
    return this?.currentUser?.organizations ?? [];
  }

  get projects() {
    return this?.userContext?.projects ?? [];
  }

  get projectsNextPageToken() {
    return this?.userContext?._projectsNextPageToken;
  }

  get hideUserNav() {
    if (this.router.currentRouteName === 'cloud.orgs.create.index') {
      return this.organizations.length <= 0;
    }

    if (
      this.router.currentRouteName === 'cloud.orgs.detail.hcp-with-terraform'
    ) {
      return true;
    }

    return false;
  }

  get statusPageUrl() {
    return this.systemStatus.statusPageUrl;
  }

  get hcpStatus() {
    return this.systemStatus.hcp;
  }

  <template>
    <HdsAppFrame
      @hasHeader={{false}}
      @hasSidebar={{not this.hideUserNav}}
      id="hcp-app-frame"
      as |Frame|
    >
      <Frame.Sidebar>
        <HdsSideNav
          @isResponsive={{true}}
          @hasA11yRefocus={{true}}
          @a11yRefocusSkipTo="app-main-content"
          @isCollapsible={{true}}
        >
          <:header>
            <HdsSideNavHeader>
              <:logo>
                {{#if this.includeHomeLink}}
                  <HdsSideNavHeaderHomeLink
                    @icon="hashicorp"
                    @ariaLabel={{t "nav.logo-aria-label"}}
                    @route="cloud.orgs"
                    data-test-company-logo
                  />
                {{else}}
                  <HdsIcon
                    @name="hashicorp"
                    @stretched={{true}}
                    data-test-company-logo
                  />
                {{/if}}
              </:logo>
              <:actions>
                <HelpMenu />
                <UserMenu />
              </:actions>
            </HdsSideNavHeader>
          </:header>
          <:body>
            {{! @glint-expect-error: not typed }}
            <HdsSideNavPortalTarget data-test-sidebar-nav-content />
            <div class="hds-side-nav-hide-when-minimized">
              {{#each this.devComponents as |DevComponent|}}
                {{! @glint-expect-error: not typed }}
                <DevComponent />
              {{/each}}
            </div>
          </:body>
          <:footer>
            {{#if this.currentOrg}}
              <ProjectPicker
                @currentOrg={{this.currentOrg}}
                @currentProject={{this.currentProject}}
                @organizations={{this.organizations}}
                @projects={{this.projects}}
                @projectsNextPageToken={{this.projectsNextPageToken}}
                class="hds-side-nav-hide-when-minimized"
              />
            {{else}}
              <OrganizationPicker
                @currentOrg={{this.currentOrg}}
                @organizations={{this.organizations}}
                class="hds-side-nav-hide-when-minimized"
              />
            {{/if}}
          </:footer>
        </HdsSideNav>
      </Frame.Sidebar>
      <Frame.Modals id="hcp-app-frame-modals" data-test-modals-container />
      <Frame.Main id="app-main-content" data-test-main>
        <Flex
          @basis={{FlexBasis.ZeroPercent}}
          @direction={{FlexDirection.Column}}
          @grow={{1}}
          @shrink={{1}}
          class="hcp-min-height-100-percent"
        >
          <SystemNotifications />
          <Flex
            @basis={{FlexBasis.ZeroPercent}}
            @direction={{FlexDirection.Column}}
            @grow={{1}}
            @shrink={{1}}
          >
            {{yield}}
          </Flex>
        </Flex>
      </Frame.Main>
      <Frame.Footer>
        <HdsAppFooter data-test-layout-footer as |AF|>
          {{#if @showStatusPageLink}}
            {{#if this.hcpStatus}}
              <AF.StatusLink
                @status={{this.hcpStatus}}
                href={{this.statusPageUrl}}
                @text={{t
                  "components.hcp.status-page-indicator-link"
                  status=this.hcpStatus
                }}
              />
            {{else}}
              <AF.StatusLink
                @text={{t "components.hcp.status-page-link"}}
                href={{this.statusPageUrl}}
              />
            {{/if}}
          {{/if}}
          <AF.Link @href="https://cloud.hashicorp.com/docs/changelog">{{t
              "components.layout.footer.changelog"
            }}</AF.Link>
          <AF.LegalLinks />
        </HdsAppFooter>
      </Frame.Footer>
    </HdsAppFrame>
    <Notifications />
  </template>
}
