import Component from '@glimmer/component';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { optional } from '@nullvoxpopuli/ember-composable-helpers';
import { HdsIcon } from '@hashicorp/design-system-components/components';

interface StepperFormTabSignature {
  Args: {
    available: boolean;
    complete: boolean;
    currentStep: number;
    onTabChange: () => void;
    step: number;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLButtonElement;
}

/**
 * `StepperForm::Tab is meant to be used as a contextual component yielded
 * by `StepperForm`. It is the navigation element of the form and the parent
 * `StepperForm` manages as much of the `Tab` state as possible.
 *
 * @class StepperForm::Tab
 */
export default class StepperFormTab extends Component<StepperFormTabSignature> {
  /**
   * `@step` The numeric placement of this step in the stepper form.
   *
   * @argument @step
   * @type {Number}
   */

  /** `@currentStep` The current step the stepper form is on (typically set in
   * StepperForm on the contextual component)
   *
   * @argument @currentStep
   * @type {Number}
   */

  /** `@complete` Whether or not the step in the form has been completed.
   *
   * @argument @complete
   * @type {Boolean}
   * @default false
   */

  /** `@available` Whether or not the step can be visited directly from the tab.
   *
   * @argument @complete
   * @type {Boolean}
   * @default false
   */

  get isActive() {
    return +this.args.step === +this.args.currentStep;
  }

  get icon() {
    if (this.isActive) {
      return this.args.complete ? 'check-hexagon-fill' : 'hexagon-fill';
    }
    return this.args.complete ? 'check-hexagon' : 'hexagon';
  }

  get step() {
    return this.args.step ?? parseInt(this.args.step, 10);
  }

  <template>
    <li
      role="presentation"
      class="{{if this.isActive 'is-active'}} {{if @complete 'is-complete'}}"
      data-test-stepper-form-tab
    >
      {{!
        ember-template-lint detects <HdsIcon> as a semantic descendant.
        When https://github.com/ember-template-lint/ember-template-lint/pull/3089
        is merged we should be able to remove the template-lint-disable below.
      }}
      {{! template-lint-disable require-presentational-children }}
      <button
        type="button"
        role="tab"
        disabled={{not @available}}
        ...attributes
        {{on "click" (fn (optional @onTabChange) this.step)}}
      >
        <HdsIcon @name={{this.icon}} @size="24" />
        {{yield}}
      </button>
      {{#if this.isActive}}
        <span class="hcp-visually-hidden">
          {{t "components.stepper-form.tabs.in-progress"}}
        </span>
      {{else if @complete}}
        <span class="hcp-visually-hidden">
          {{t "components.stepper-form.tabs.complete"}}
        </span>
      {{/if}}
    </li>
  </template>
}
