import Component from '@glimmer/component';
import { service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { t } from 'ember-intl';
import {
  HdsDropdown,
  HdsLinkStandalone,
} from '@hashicorp/design-system-components/components';
import { boxPadding } from '../modifiers.ts';

import type SessionService from 'ember-simple-auth/services/session';
import type CurrentUserService from '../services/current-user.ts';

interface UserMenuSignature {
  Args: {
    isExternal?: boolean;
  };
  Element: HTMLElement;
}

/**
 *
 * `UserMenu` renders a `Menu` with information about the current user which is pulled from the `currentUser` service.
 *
 *
 * ```
 * <UserMenu />
 * ```
 *
 * @class UserMenu
 *
 */

export default class UserMenu extends Component<UserMenuSignature> {
  /**
   *
   * `isExternal` when true, marks links as @isRouteExternal={{true}}.
   *              This is false by default and should be true when used in an engine.
   * @argument isExternal
   * @type {boolean}
   *
   */

  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly session: SessionService;

  get accessToken() {
    if (variation('hcp-ui-copy-access-token')) {
      return 'hcp auth print-access-token';
    }
    return '';
  }

  <template>
    {{#let this.currentUser.user as |u|}}
      <HdsDropdown
        @listPosition="bottom-left"
        @width={{if (variation "hcp-ui-copy-access-token") "280px" "240px"}}
        ...attributes
        class="hds-side-nav__dropdown"
        data-test-user-menu
        as |dd|
      >
        {{#if u.picture}}
          <dd.ToggleIcon
            @imageSrc={{u.picture}}
            @text={{t "components.nav.user-menu.label"}}
            data-test-user-menu-toggle
            data-test-user-menu-avatar
          />
        {{else}}
          <dd.ToggleIcon
            @icon="user"
            @text={{t "components.nav.user-menu.label"}}
            data-test-user-menu-toggle
            data-test-user-menu-icon
          />
        {{/if}}
        {{#if u}}
          <dd.Description
            @text={{t
              "components.nav.user-menu.sign-in-method"
              email=u.email
              htmlSafe=true
              identityType=u.identityType
            }}
            data-test-user-menu-email
            class="user-menu-email"
          />
          {{#if @isExternal}}
            <dd.Interactive
              @isRouteExternal={{true}}
              @route="cloudAccountSettings"
              @text={{t "components.page.account-settings.title"}}
            />
            <dd.Interactive
              @isRouteExternal={{true}}
              @route="signOut"
              @text={{t "auth.ctas.sign-out"}}
            />
          {{else}}
            <dd.Interactive
              @route="cloud.account-settings"
              @text={{t "components.page.account-settings.title"}}
            />
            <dd.Interactive
              @route="sign-out"
              @text={{t "auth.ctas.sign-out"}}
            />
          {{/if}}
          {{#if (variation "hcp-ui-copy-access-token")}}
            <dd.Separator />
            <dd.Title
              @text={{t "components.nav.user-menu.access-token-copy"}}
            />
            <dd.CopyItem
              @text={{this.accessToken}}
              @isTruncated={{false}}
              class="user-menu-copy-item"
              data-test-access-token-copy-item
            />
            <dd.Generic data-test-access-token-docs-link>
              <div {{boxPadding "xs 0"}}>
                <HdsLinkStandalone
                  @href="https://developer.hashicorp.com/hcp/docs/cli/commands/auth/print-access-token"
                  @icon="docs-link"
                  @text={{t "components.nav.user-menu.access-token-docs"}}
                />
              </div>
            </dd.Generic>
          {{/if}}
        {{else}}
          {{#if @isExternal}}
            <dd.Interactive
              @isRouteExternal={{true}}
              @route="signIn"
              @text={{t "auth.ctas.sign-in"}}
              data-test-link-sign-in
            />
            <dd.Interactive
              @isRouteExternal={{true}}
              @route="signUp"
              @text={{t "components.page.sign-up.create-an-account"}}
              data-test-link-sign-up
            />
          {{else}}
            <dd.Interactive
              @route="sign-in"
              @text={{t "auth.ctas.sign-in"}}
              data-test-link-sign-in
            />
            <dd.Interactive
              @route="sign-up"
              @text={{t "components.page.sign-up.create-an-account"}}
              data-test-link-sign-up
            />
          {{/if}}
        {{/if}}
      </HdsDropdown>
    {{/let}}
  </template>
}
