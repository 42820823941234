import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

const defaultDeniedRoles: Array<HashicorpCloudResourcemanagerRole['id']> = [
  'roles/some-role*',
];

export function filterDeniedRoles(
  roles: Array<HashicorpCloudResourcemanagerRole> = [],
  deniedRoles: Array<
    HashicorpCloudResourcemanagerRole['id']
  > = defaultDeniedRoles,
): Array<HashicorpCloudResourcemanagerRole> {
  return roles.filter((role) => {
    return !deniedRoles.some((deniedRole) => {
      if (deniedRole?.endsWith('*')) {
        // If the deniedRole ends with '*', check for a prefix match.
        // Remove the '*' before comparison.
        const prefix = deniedRole.slice(0, -1);
        return role?.id?.startsWith(prefix);
      } else {
        // Exact match check.
        return role.id === deniedRole;
      }
    });
  });
}

export default filterDeniedRoles;
