/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105CountryFromJSON, Billing20201105CountryToJSON, Billing20201105OnDemandBillingMethodFromJSON, Billing20201105OnDemandBillingMethodToJSON, } from './';
export function Billing20201105BillingAccountServiceCreateBodyFromJSON(json) {
    return Billing20201105BillingAccountServiceCreateBodyFromJSONTyped(json, false);
}
export function Billing20201105BillingAccountServiceCreateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'projectIds': !exists(json, 'project_ids') ? undefined : json['project_ids'],
        'onDemandBillingMethod': !exists(json, 'on_demand_billing_method') ? undefined : Billing20201105OnDemandBillingMethodFromJSON(json['on_demand_billing_method']),
        'country': !exists(json, 'country') ? undefined : Billing20201105CountryFromJSON(json['country']),
    };
}
export function Billing20201105BillingAccountServiceCreateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'project_ids': value.projectIds,
        'on_demand_billing_method': Billing20201105OnDemandBillingMethodToJSON(value.onDemandBillingMethod),
        'country': Billing20201105CountryToJSON(value.country),
    };
}
