import Component from '@glimmer/component';

interface LoadingHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `LoadingHeader` displays the header element of the Loading screen.
 *
 *
 * ```
 * <Loading::Header>Heading</Loading::Header>
 * ```
 *
 * @class LoadingHeader
 *
 */

export default class LoadingHeader extends Component<LoadingHeaderSignature> {
  <template>
    <header class="loading-state__header" data-test-loading-header-container>
      {{yield}}
    </header>
  </template>
}
