import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { concat } from '@ember/helper';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import FlexGrid from '../flex-grid.gts';

import type { HdsIconSignature } from '@hashicorp/design-system-components/components/hds/icon/index';

export interface ModalDialogHeaderSignature {
  Args: {
    closeModalDialog?: () => void;
    dismissible?: unknown;
    headerIconType?: HdsIconSignature['Args']['name'];
    id?: string;
    variant?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class ModalDialogHeader extends Component<ModalDialogHeaderSignature> {
  get dismissible() {
    return this.args.dismissible ?? true;
  }

  <template>
    <header
      data-test-modal-dialog-header-container
      tabindex="-1"
      class="pdsModalDialog__header
        {{if @variant (concat 'pdsModalDialog__header--' @variant)}}"
      ...attributes
    >
      <FlexGrid as |G|>
        <G.Item @xs={{11}} @sm={{11}} @md={{11}} @lg={{11}}>
          {{#if @headerIconType}}
            <HdsIcon
              @name={{@headerIconType}}
              @size="16"
              class="pdsModalDialog__headerIcon"
              data-test-modal-dialog-header-icon
            />
          {{/if}}
          <div class="pdsModalDialog__headerLabel" id={{@id}}>
            {{yield}}
          </div>
        </G.Item>
        {{#if this.dismissible}}
          <G.Item class="end-xs" @xs={{1}}>
            <button
              aria-label={{t "components.modals.close.label"}}
              class="pdsModalDialog__closeButton"
              type="button"
              data-test-modal-dialog-close-button-container
              {{! @glint-expect-error: missing modifier type }}
              {{on "click" @closeModalDialog}}
            >
              <HdsIcon @name="x" />
            </button>
          </G.Item>
        {{/if}}
      </FlexGrid>
    </header>
  </template>
}
