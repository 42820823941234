import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { HdsAlert } from '@hashicorp/design-system-components/components';
// @ts-expect-error: not typed
import titleize from 'ember-cli-string-helpers/helpers/titleize';

import failureForResource from '../../helpers/failure-for-resource.js';
import isDataplaneAccountError from '../../helpers/is-dataplane-account-error.js';
import boxPadding from '../../modifiers/box-padding.ts';

interface OperationFailureForResourceSignature {
  Args: {
    resource: unknown;
    resourceType: unknown;
  };
}

/**
 *
 * Error messages related to failed operations from 'FAILED' or
 * busy clusters will be rendered in an alert component.
 *
 * ```
 * <Operation::FailureForResource
 *   @resource={{@model.cluster}}
 *   @resourceType={{resource-type-for 'network'}}
 * />
 * ```
 *
 * @class OperationFailureForResource
 *
 */

export default class OperationFailureForResource extends Component<OperationFailureForResourceSignature> {
  /**
   * The `resource` is the object that will be used to find a related operation
   * failure. It needs to have `state` and `id` attributes.
   * @argument resource
   * @type {object}
   */
  /**
   * The resource resource type so we can check if the resource's state is
   * FAILED or busy.
   * @example 'hashicorp.network.hvn'
   * @argument resourceType
   * @type {string}
   */

  <template>
    {{#let
      (failureForResource @resource @resourceType)
      as |failedOperationError|
    }}
      {{#if failedOperationError}}
        <div {{boxPadding "0 0 xl"}}>
          {{#if (isDataplaneAccountError failedOperationError.message)}}
            <HdsAlert
              @type="inline"
              @color="critical"
              data-test-operation-failure-data-plane-account
              as |A|
            >
              <A.Title>
                {{t
                  "components.operation.failure-for-resource.data-plane-error.title"
                }}
              </A.Title>
              <A.Description>
                {{t
                  "components.operation.failure-for-resource.data-plane-error.content"
                }}
              </A.Description>
            </HdsAlert>
          {{else}}
            <HdsAlert
              @type="inline"
              @color="critical"
              data-test-operation-failure
              as |A|
            >
              <A.Title>
                {{! @glint-expect-error }}
                {{titleize @resource.state}}
              </A.Title>
              <A.Description>
                {{failedOperationError.message}}
              </A.Description>
            </HdsAlert>
          {{/if}}
        </div>
      {{/if}}
    {{/let}}
  </template>
}
