import Component from '@glimmer/component';

interface BadgeListSignature {
  Args: {
    model: unknown[] | undefined;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLUListElement;
}

export default class BadgeList extends Component<BadgeListSignature> {
  <template>
    {{#if @model}}
      <ul class="badge-list" data-test-badge-list ...attributes>
        {{#each @model as |item|}}
          <li data-test-badge-list-badge-container>
            {{yield item}}
          </li>
        {{/each}}
      </ul>
    {{/if}}
  </template>
}
