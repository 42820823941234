import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { hash } from '@ember/helper';

import {
  DEFAULT_VARIANT,
  DEFAULT_VARIANT_MAPPING,
  VARIANT_SCALE,
} from '../../utils/consts/page-header.ts';
import FlexGrid from '../flex-grid.gts';
import LayoutPageHeaderBreadcrumbs from './page-header/breadcrumbs.gts';
import LayoutPageHeaderActions from './page-header/actions.gts';
import LayoutPageHeaderTabs from './page-header/tabs.gts';
import LayoutPageHeaderTitle from './page-header/title.gts';

interface LayoutPageHeaderSignature {
  Args: {
    variant?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `PageHeader` renders a `<header>` element that yields `Title`, and `Actions` which are <a href="https://embermap.com/topics/contextual-components/flexible-interfaces">Contextual Components</a>.<br /><br />
 * `Title` has a set class `pageHeader__title` but can accept dynamic classes since this template contains <a href="https://embermap.com/topics/what-s-new-in-ember/forwarding-element-modifiers-with-attributes-3-11">splattributes</a>.  This template is intended to (but not limited to) display the page title and description text through the use of `<Typography>` component.<br /><br />
 * `Actions` has a set class `pageHeader__actions` but can accept dynamic classes as this template also contains <a href="https://embermap.com/topics/what-s-new-in-ember/forwarding-element-modifiers-with-attributes-3-11">splattributes</a>.  This template is intended to display (but not limited to) any actionable elements such as `<button>`, `<LinkTo>`.<br /><br />
 *
 *
 * ## Example usage
 *
 * ```
 * <Layout::PageHeader @variant='filled' as |P|>
 *   <P.Breadcrumbs>
 *     <Breadcrumbs::Container />
 *   </P.Breadcrumbs>
 *   <P.Title>
 *     <Typography @variant='h1'>
 *       {{t 'components.page.hvns.create.title'}}
 *     </Typography>
 *     <Typography @variant='body1'>
 *       {{t 'components.page.hvns.create.description'}}
 *     </Typography>
 *   </P.Title>
 * </Layout::PageHeader>
 * ```
 * @class LayoutPageHeader
 */

export default class LayoutPageHeader extends Component<LayoutPageHeaderSignature> {
  /**
   * Changes the fill and border styles of the Header.
   * Can be one of the following: 'root' (default) or 'filled'.
   * @argument variant
   * @type {?string}
   */

  /**
   * Gets the variant arg or falls back to the default.
   * @method LayoutPageHeader#variant
   * @return {string}
   */
  get variant() {
    const { variant = DEFAULT_VARIANT } = this.args;

    if (variant) {
      assert(
        `@variant for ${
          this.constructor.name
        } must be one of the following: ${VARIANT_SCALE.join(
          ', '
        )}, receieved: ${variant}`,
        VARIANT_SCALE.includes(variant)
      );
    }

    return variant;
  }

  /**
   * Get a class to apply to the header based on the variant argument.
   * @method LayoutPageHeader#variantClass
   * @return {string} The css class to apply to the container
   */
  get variantClass() {
    return (
      DEFAULT_VARIANT_MAPPING[this.variant] ||
      DEFAULT_VARIANT_MAPPING[DEFAULT_VARIANT]
    );
  }

  <template>
    <header
      class="pageHeader {{this.variantClass}}"
      data-test-page-header-container
      ...attributes
    >
      <FlexGrid>
        {{yield (hash Breadcrumbs=(component LayoutPageHeaderBreadcrumbs))}}
      </FlexGrid>

      <FlexGrid class="between-sm">
        {{yield
          (hash
            Actions=(component LayoutPageHeaderActions)
            Title=(component LayoutPageHeaderTitle)
          )
        }}
      </FlexGrid>

      <FlexGrid>
        {{yield (hash Tabs=(component LayoutPageHeaderTabs))}}
      </FlexGrid>
    </header>
  </template>
}
