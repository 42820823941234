import Component from '@glimmer/component';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';

import Typography from '../typography.gts';

interface LoadingElapsedSignature {
  Args: {
    startTime?: number;
  };
}

/**
 *
 * `LoadingElapsed` displays the time since being rendered on screen.
 *
 *
 * ```
 * <Loading::Elapsed></Loading::Elapsed>
 * ```
 *
 * @class LoadingElapsed
 *
 */

export default class LoadingElapsed extends Component<LoadingElapsedSignature> {
  /**
   *
   * An optional timestamp that will be used to calculate elapsed time since.
   * @argument startTime;
   * @type {?number}
   *
   */
  timeout: number | undefined = undefined;
  renderTime = new Date().getTime();

  @tracked elapsed = '--:--';

  startTimer = () => {
    this.timeout = this.poll();
  };

  destroyTimer = () => {
    clearTimeout(this.timeout);
  };

  poll() {
    return setTimeout(() => {
      // eslint-disable-next-line ember/no-runloop
      run(() => {
        this.elapsed = this.getElapsed();
        this.timeout = this.poll();
      });
    }, 1000);
  }

  get startTime() {
    return this.args.startTime || this.renderTime;
  }

  /**
   * Get the elapsed time since the argument "startTime" or since render time.
   * @method Typography#getElapsed
   * @return {string} The elapsed time string.
   */
  getElapsed() {
    const updatedTime = new Date().getTime();
    const difference = updatedTime - this.startTime;
    let hours: string | number = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes: string | number = Math.floor(
      (difference % (1000 * 60 * 60)) / (1000 * 60)
    );
    let seconds: string | number = Math.floor(
      (difference % (1000 * 60)) / 1000
    );
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if (hours == 0) {
      return `${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }

  <template>
    <div
      class="loading-state__elapsed"
      data-test-loading-elapsed-container
      {{didInsert this.startTimer}}
      {{willDestroy this.destroyTimer}}
    >
      <Typography>
        {{t "components.loading.time-elapsed" elapsed=this.elapsed}}
      </Typography>
    </div>
  </template>
}
