import Component from '@glimmer/component';

interface LayoutFullScreenAsideSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class LayoutFullScreenAside extends Component<LayoutFullScreenAsideSignature> {
  <template>
    <aside data-test-full-screen-aside class="full-screen__aside" ...attributes>
      {{yield}}
    </aside>
  </template>
}
