/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * :warning: Version 2023-08-18 of the Waypoint service will be retired Febuary 2025. Service for managing applications.
 *
 * The version of the OpenAPI document: 2024-11-22
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudWaypointAddOnDefinitionFromJSON, HashicorpCloudWaypointAddOnDefinitionToJSON, } from './';
export function HashicorpCloudWaypointListAddOnDefinitionsResponseFromJSON(json) {
    return HashicorpCloudWaypointListAddOnDefinitionsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListAddOnDefinitionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addOnDefinitions': !exists(json, 'add_on_definitions') ? undefined : (json['add_on_definitions'].map(HashicorpCloudWaypointAddOnDefinitionFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
    };
}
export function HashicorpCloudWaypointListAddOnDefinitionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'add_on_definitions': value.addOnDefinitions === undefined ? undefined : (value.addOnDefinitions.map(HashicorpCloudWaypointAddOnDefinitionToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'total_count': value.totalCount,
    };
}
