import Component from '@glimmer/component';
import { or } from 'ember-truth-helpers';

import CoreLayoutMainHorizontalViewport from './main/horizontal-viewport.gts';

interface CoreLayoutMainSignature {
  // Args: {};
  Blocks: {
    alert: [];
    body: [];
    generic: [];
    header: [];
  };
  Element: HTMLDivElement;
}

export default class CoreLayoutMain extends Component<CoreLayoutMainSignature> {
  <template>
    <div class="core-layout-main" data-test-core-layout-main-container>
      {{#if (or (has-block "header") (has-block "alert"))}}
        <CoreLayoutMainHorizontalViewport
          class="core-layout-main__page-header-container"
          data-test-core-layout-main-page-header-container
        >
          {{#if (has-block "header")}}
            <div
              class="core-layout-main__page-header"
              data-test-core-layout-main-page-header
            >
              {{yield to="header"}}
            </div>
          {{/if}}

          {{#if (has-block "alert")}}
            {{yield to="alert"}}
          {{/if}}
        </CoreLayoutMainHorizontalViewport>
      {{/if}}

      {{#if (has-block "body")}}
        <CoreLayoutMainHorizontalViewport
          class="core-layout-main__page-body-container"
          data-test-core-layout-main-page-body-container
          ...attributes
        >
          {{yield to="body"}}
        </CoreLayoutMainHorizontalViewport>
      {{/if}}

      {{#if (has-block "generic")}}
        {{yield to="generic"}}
      {{/if}}
    </div>
  </template>
}
