import { chunk, flattenDeep } from 'lodash-es';

const HashicorpResourceNameSeparator = '.';
const HashicorpTypePrefix = 'hashicorp';
const ResourceManagerNamespace = 'resource-manager';
const OrganizationTypePart = 'organization';
const ProjectTypePart = 'project';

export type Segment = string;
export type Part = [Segment, Segment];
export type ParsedResourceName = {
  id?: string;
  namespace: string;
  parts: Array<Part>;
  type: string;
};
export type Options = { part: string };
export type PartRecord = { part: string; id: string };

// ParseResourceName will parse a resource name into its namespace and
// type-name-tuples.
export function ParseResourceName(
  resourceName: string
): ParsedResourceName | undefined {
  const [namespace, ...segments] = resourceName.split('/');

  if (!namespace) {
    return;
  }

  // Handle organization and project resource names (which consist of two segments)
  if (
    segments.length === 1 &&
    typeof segments[0] === 'string' &&
    [ProjectTypePart, OrganizationTypePart].includes(namespace)
  ) {
    return {
      id: segments[0],
      namespace: ResourceManagerNamespace,
      parts: [[namespace, segments[0]]],
      type: [HashicorpTypePrefix, ResourceManagerNamespace, namespace].join(
        HashicorpResourceNameSeparator
      ),
    };
  }

  if (segments.length === 0 || segments.length % 2 !== 0) {
    return;
  }

  // Construct a real hashicorp resource type.
  const type = [
    HashicorpTypePrefix,
    namespace,
    segments[segments.length - 2],
  ].join(HashicorpResourceNameSeparator);

  // The last item in the segments is always the id of the resource itself.
  const id = segments[segments.length - 1];

  return { id, namespace, parts: chunk(segments, 2) as Array<Part>, type };
}

// CollapseResourceName can be used to collapse a resource name to a shorter
// version.
export function CollapseResourceName(
  resourceName: string,
  context?: string,
  collapseType?: boolean
) {
  let formattedResourceName: string | undefined = resourceName;
  let formattedContext: string | undefined = context;

  // Parse resource name to validate it
  if (!ParseResourceName(formattedResourceName)) {
    return;
  }

  // Replace type parts with "..." if the type should be collapsed
  if (collapseType) {
    formattedResourceName = replaceTypesWithEllipses(formattedResourceName);

    if (!formattedResourceName) {
      return;
    }
  }

  // Replace context with "..." if context is provided
  if (formattedContext) {
    // Parse context to validate it
    if (!ParseResourceName(formattedContext)) {
      return;
    }

    // If the type should be collapsed also collapse the type on the context to
    // ensure it matches the collapsed resource name.
    if (collapseType) {
      formattedContext = replaceTypesWithEllipses(formattedContext);

      if (!formattedContext) {
        return;
      }
    }

    // Replace the context in the resource name
    formattedResourceName = formattedResourceName.replace(
      formattedContext,
      '...'
    );
  }

  // Replace all doubled up ellipses
  formattedResourceName = formattedResourceName.replaceAll('.../...', '...');

  // Check if the only remaining part is the last name (e.g. .../my-cluster) and
  // return that part only if it is
  const parts = formattedResourceName.split('/');

  if (parts.length === 2 && parts[0] === '...') {
    return parts[1];
  }

  return formattedResourceName;
}

// replaceTypesWithEllipses will replace any type parts in a resource name with
// ellipsis. E.g. vault/project/a70fffe7-4c14-4f4f-bbc7-18aa9e3a221c/cluster/my-cluster
// -> .../.../a70fffe7-4c14-4f4f-bbc7-18aa9e3a221c/.../my-cluster
function replaceTypesWithEllipses(resourceName: string) {
  const parsed = ParseResourceName(resourceName);
  if (!parsed) {
    return;
  }

  parsed.namespace = '...';
  parsed.parts = parsed.parts.map((part) => ['...', part[1]]);

  return flattenDeep([parsed.namespace, parsed.parts]).join('/');
}

export function IamFindResourceNamePart(
  parsedResourceName: ParsedResourceName,
  { part }: { part: string }
) {
  const { parts } = parsedResourceName;

  if (!parts || !parts.length) {
    return;
  }

  const foundPart = parts?.find(([partType]) => {
    return partType == part;
  });

  if (!foundPart) {
    return;
  }

  return { part, id: foundPart[1] };
}

// WorkspaceWithDisplayName will extend a workspace object with its display name.
export function WorkspaceWithDisplayName(resource: { resourceName: string }) {
  const parsedResourceName = ParseResourceName(resource.resourceName);
  return { displayName: parsedResourceName?.id, ...resource };
}
