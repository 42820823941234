import Component from '@glimmer/component';

interface ToolbarActionsSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class ToolbarActions extends Component<ToolbarActionsSignature> {
  <template>
    <div class="toolbar__actions" ...attributes data-test-toolbar-actions>
      {{yield}}
    </div>
  </template>
}
