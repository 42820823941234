import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import { HdsButton } from '@hashicorp/design-system-components/components';

interface ModalDialogFooterCancelSignature {
  Args: {
    closeModalDialog?: () => void;
    text?: string;
  };
  Element: HTMLElement;
}

export default class ModalDialogFooterCancel extends Component<ModalDialogFooterCancelSignature> {
  <template>
    <div class="modal-dialog-cancel-button-container">
      <HdsButton
        data-test-modal-dialog-cancel-button-container
        aria-label={{t "components.modals.close.label"}}
        @color="secondary"
        @text={{or @text (t "components.form.cancel")}}
        ...attributes
        {{! @glint-expect-error:  }}
        {{on "click" @closeModalDialog}}
      />
    </div>
  </template>
}
