import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

import type Owner from '@ember/owner';

export const ALERTS_STATE_IDENTIFIER = 'alerts';

interface MetadataAdapter {
  getMetadata(): Promise<MetadataResponse>;
  setMetadata(record: MetadataRecord): Promise<void>;
}

interface MetadataResponse {
  metadata?: MetadataObject;
}

interface MetadataObject {
  [ALERTS_STATE_IDENTIFIER]?: {
    [key: string]: AlertState;
  };
  [key: string]: unknown;
}

interface AlertState {
  isDismissed: boolean;
  createdAt: string;
}

interface Alert extends AlertState {
  id: string;
}

interface MetadataRecord {
  scope: 'user';
  metadata: MetadataObject;
}

export default class AlertStatesService extends Service {
  @service('metadata.local-storage')
  declare localStorage: MetadataAdapter;

  adapter: MetadataAdapter | null = null;

  constructor(owner: Owner) {
    super(owner);
    this.configure('localStorage');
  }

  configure(adapterName: keyof this): void {
    const adapter = this[adapterName];
    assert('Incompatible metadata persistence adapter: not found', adapter);
    // First cast to unknown, then to MetadataAdapter to satisfy TypeScript
    this.adapter = adapter as unknown as MetadataAdapter;
  }

  async getAlert(id: string): Promise<Alert | undefined> {
    const { metadata = {} } = await this.getMetadata();
    if (
      metadata[ALERTS_STATE_IDENTIFIER] &&
      metadata[ALERTS_STATE_IDENTIFIER][id]
    ) {
      return { id, ...metadata[ALERTS_STATE_IDENTIFIER][id] };
    }
  }

  async setAlert({
    id,
    isDismissed,
  }: {
    id: string;
    isDismissed: boolean;
  }): Promise<void> {
    await this.setMetadata({ id, isDismissed });
  }

  async getMetadata(): Promise<MetadataResponse> {
    assert(
      'Incompatible metadata persistence adapter: no implemented getMetadata',
      this.adapter?.getMetadata
    );
    const metadata = await this.adapter!.getMetadata();
    return metadata;
  }

  async setMetadata({
    id,
    isDismissed = false,
  }: {
    id: string;
    isDismissed: boolean;
  }): Promise<void> {
    assert(
      'Incompatible metadata persistence adapter: no implemented setMetadata',
      this.adapter?.setMetadata
    );
    const { metadata } = await this.getMetadata();
    const identifier = ALERTS_STATE_IDENTIFIER;

    let newMetadata: MetadataObject = {};

    if (metadata) {
      newMetadata = {
        ...metadata,
      };
    }

    if (id) {
      newMetadata[identifier] = {
        ...(newMetadata[identifier] ?? {}),
        [id]: {
          isDismissed,
          createdAt: new Date().toISOString(),
        },
      };
    }

    const record: MetadataRecord = { scope: 'user', metadata: newMetadata };

    await this.adapter!.setMetadata(record);
  }
}
