import Component from '@glimmer/component';
import { concat } from '@ember/helper';

import classNames from '../../helpers/class-names.ts';

export interface FlexGridItemSignature {
  Args: {
    xl?: string | number;
    xlOffset?: string | number;
    lg?: string | number;
    lgOffset?: string | number;
    md?: string | number;
    mdOffset?: string | number;
    sm?: string | number;
    smOffset?: string | number;
    xs?: string | number;
    xsOffset?: string | number;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `FlexGridItem` applies a class on a container to control the width of an item
 * within a `Grid`.
 *
 *
 * ```
 * <FlexGrid::Item
 *   @xs="6"
 *   @sm="6"
 *   @md="6"
 *   @lg="6"
 *   @xl="6"
 * >
 *   content
 * </FlexGrid::Item>
 * ```
 *
 * @class FlexGridItem
 *
 */

export default class FlexGridItem extends Component<FlexGridItemSignature> {
  /**
   * `xs` applies the extra small class for this item.
   * @argument xs
   * @type {string}
   */
  /**
   * `xsOffset` applies the extra small offset class for this item.
   * @argument xsOffset
   * @type {string}
   */
  /**
   * `sm` applies the small class for this item.
   * @argument sm
   * @type {string}
   */
  /**
   * `smOffset` applies the small offset class for this item.
   * @argument smOffset
   * @type {string}
   */
  /**
   * `md` applies the medium class for this item.
   * @argument md
   * @type {string}
   */
  /**
   * `mdOffset` applies the medium offset class for this item.
   * @argument mdOffset
   * @type {string}
   */
  /**
   * `lg` applies the large class for this item.
   * @argument lg
   * @type {string}
   */
  /**
   * `lgOffset` applies the large offset class for this item.
   * @argument lgOffset
   * @type {string}
   */
  /**
   * `xl` applies the x-large class for this item.
   * @argument xl
   * @type {string}
   */
  /**
   * `xlOffset` applies the x-large offset class for this item.
   * @argument xlOffset
   * @type {string}
   */

  <template>
    <div
      class={{classNames
        "flexGrid__item"
        (if @xs (concat "col-xs-" @xs))
        (if @xsOffset (concat "col-xs-offset-" @xsOffset))
        (if @sm (concat "col-sm-" @sm))
        (if @smOffset (concat "col-sm-offset-" @smOffset))
        (if @md (concat "col-md-" @md))
        (if @mdOffset (concat "col-md-offset-" @mdOffset))
        (if @lg (concat "col-lg-" @lg))
        (if @lgOffset (concat "col-lg-offset-" @lgOffset))
        (if @xl (concat "col-xl-" @xl))
        (if @xlOffset (concat "col-xl-offset-" @xlOffset))
      }}
      ...attributes
      data-test-flex-grid-item-container
    >
      {{yield}}
    </div>
  </template>
}
