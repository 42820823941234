import Component from '@glimmer/component';
import { service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { isTesting } from '@embroider/macros';
import { array } from '@ember/helper';
import { hash } from '@ember/helper';
import { t } from 'ember-intl';
import { HdsSideNavPortal } from '@hashicorp/design-system-components/components';
import can from 'ember-can/helpers/can';

import { supportTicketPrefillHref } from '../../../utils/support-ticket.js';
import CoreProjectLinks from '../../core/project-links.gts';

import type Owner from '@ember/owner';
import type { IntlService } from 'ember-intl';
import type UserContextService from '../../../services/user-context.ts';

export interface LayoutSidebarMainNavSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};

  Blocks: {
    default: [];
  };

  Element: HTMLElement;
}

interface TenantData {
  state?: string;
  type?: string;
}
interface VaultRadarTenantService {
  tenantData: TenantData;
  fetch(): Promise<TenantData>;
  loading: boolean;
}
interface ConfigService {
  app?: {
    vaultRadarDomain?: string;
  };
}

/**
 *
 * `Layout::SideBar::MainNav` wraps sidebar navigation components.
 *
 *
 * ```
 * <Layout::SideBar::MainNav>{{outlet}}</Layout::SideBar::MainNav>
 * ```
 *
 * @class Layout::SideBar::MainNav
 *
 */
export default class LayoutSidebarMainNav extends Component<LayoutSidebarMainNavSignature> {
  @service declare readonly userContext: UserContextService;
  @service('resources.project') declare readonly projects: unknown;
  @service declare readonly intl: IntlService;
  @service declare readonly vaultRadarTenant: VaultRadarTenantService;
  @service declare readonly config: ConfigService;

  constructor(owner: Owner, args: LayoutSidebarMainNavSignature['Args']) {
    super(owner, args);

    this.vaultRadarTenant.fetch();
  }

  get shouldLinkToVaultRadar() {
    const LINKABLE_TENANT_STATES = ['PROVISIONED', 'DELINQUENT'];
    const { state = '' } = this.vaultRadarTenant.tenantData;
    const isProvisioned = state && LINKABLE_TENANT_STATES.includes(state);

    return !this.vaultRadarTenant.loading && isProvisioned;
  }

  get vaultRadarUrl() {
    const { project } = this.userContext;
    const vaultRadarUrl = this.config?.app?.vaultRadarDomain;

    return `${vaultRadarUrl}/projects/${project?.id}`;
  }

  get vaultRadarBadge() {
    const { type } = this.vaultRadarTenant.tenantData || {};
    const isLoading = this.vaultRadarTenant.loading;
    const defaultType = variation('hcp-vault-radar-user-interface-show-billing')
      ? 'TRIAL'
      : 'BETA';
    const currentType = type || defaultType;

    if (currentType === 'BETA' && !isLoading) {
      return this.intl.t('nav.project.radar-beta-badge');
    }

    if (currentType === 'TRIAL' && !isLoading) {
      return this.intl.t('nav.project.radar-trial-badge');
    }

    return undefined;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  get shouldShowNav() {
    const { organization, project } = this.userContext;
    return organization?.id && project?.id;
  }

  get isTesting() {
    return isTesting();
  }

  <template>
    {{! @glint-expect-error: missing types }}
    <HdsSideNavPortal
      @ariaLabel="Primary"
      data-test-sidebar-main-nav-container
      ...attributes
      as |Subnav|
    >
      {{#if this.shouldShowNav}}
        {{! Dashboard }}
        <Subnav.Link
          data-test-sidebar-link-project-overview
          @icon="dashboard"
          @text={{this.userContext.project.name}}
          @route="cloud.orgs.detail.projects.detail.index"
          @models={{array
            this.userContext.organization.id
            this.userContext.project.id
          }}
        />

        {{! ========= Services ========= }}

        <Subnav.Title>
          {{t "nav.headers.services"}}
        </Subnav.Title>

        {{#let
          (can "list boundary-clusters")
          (can "list consul")
          (can "get packer-registry")
          (can "list vagrant-registries")
          (can "list vault")
          (can "view vault-radar")
          (can "list vault-secrets-app")
          (can "read waypoint-application")
          as |canListBoundaryClusters canListConsulClusters canGetPackerRegistry canListVagrantRegistries canListVaultClusters canViewVaultRadar canListVaultSecretsApps canReadWaypointApplications|
        }}

          {{#if canListBoundaryClusters}}
            {{! Boundary }}
            <Subnav.Link
              @icon="boundary"
              @text={{t "nav.project.boundary"}}
              @route="boundary"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-boundary-list
            />
          {{/if}}

          {{#if canListConsulClusters}}
            {{! Consul }}
            <Subnav.Link
              @icon="consul"
              @text={{t "nav.project.consul"}}
              @route="consul.clusters.overview"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-consul-list
            />
          {{/if}}

          {{#if canGetPackerRegistry}}
            {{! Packer }}
            <Subnav.Link
              @icon="packer"
              @text={{t "nav.project.packer"}}
              @route="packer"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-packer-list
            />
          {{/if}}

          {{! Terraform }}
          <Subnav.Link
            @icon="terraform"
            @text={{t "nav.project.terraform.header"}}
            @route="terraform"
            @query={{hash project_id=this.userContext.project.id}}
            data-test-sidebar-link-terraform-list
          />

          {{#if canListVagrantRegistries}}
            {{! Vagrant }}
            <Subnav.Link
              @icon="vagrant"
              @text={{t "nav.project.vagrant"}}
              @route="vagrant"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-vagrant-list
            />
          {{/if}}

          {{#if canListVaultClusters}}
            {{! Vault }}
            <Subnav.Link
              @icon="vault"
              @text={{t
                (if
                  (variation "hcp-rebrand-milestone-0")
                  "nav.project.vault-dedicated"
                  "nav.project.vault"
                )
              }}
              @route="vault.clusters.list"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-vault-list
            />
          {{/if}}

          {{#if canViewVaultRadar}}
            {{! Vault Radar }}
            {{#if this.shouldLinkToVaultRadar}}
              <Subnav.Link
                @icon="vault-radar"
                @isHrefExternal={{false}}
                @text={{t "nav.project.radar"}}
                @href={{this.vaultRadarUrl}}
                @badge={{this.vaultRadarBadge}}
                data-test-sidebar-link-vault-radar-list-direct-link
              />
            {{else}}
              <Subnav.Link
                @icon="vault-radar"
                @text={{t "nav.project.radar"}}
                @route="cloud.services.vault-radar"
                @query={{hash project_id=this.userContext.project.id}}
                @badge={{this.vaultRadarBadge}}
                data-test-sidebar-link-vault-radar-list-overview-link
              />
            {{/if}}
          {{/if}}

          {{#if canListVaultSecretsApps}}
            <Subnav.Link
              @icon="vault-secrets"
              @text={{t "nav.project.secrets"}}
              @route="secrets"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-vault-secrets-list
            />
          {{/if}}

          {{#if canReadWaypointApplications}}
            {{! Waypoint Next }}
            <Subnav.Link
              @icon="waypoint"
              @text={{t "nav.project.waypoint"}}
              @route="waypoint"
              @query={{hash project_id=this.userContext.project.id}}
              data-test-sidebar-link-waypoint-list
            />
          {{/if}}
        {{/let}}

        {{! ---- End of Resources ---- }}

        {{! ========= Manage ========= }}

        <Subnav.Title>
          {{this.userContext.project.name}}
        </Subnav.Title>

        {{#if Subnav.Link}}
          <CoreProjectLinks @component={{Subnav.Link}} />
        {{/if}}
        {{! ---- End of Manage ---- }}
      {{/if}}
    </HdsSideNavPortal>
  </template>
}
