import Component from '@glimmer/component';

import FlexGridItem from '../../flex-grid/item.gts';

interface LayoutPageHeaderBreadcrumbsSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

export default class LayoutPageHeaderBreadcrumbs extends Component<LayoutPageHeaderBreadcrumbsSignature> {
  <template>
    <FlexGridItem
      @xs="12"
      class="pageHeader__breadcrumbs"
      data-test-page-header-breadcrumbs
    >
      {{yield}}
    </FlexGridItem>
  </template>
}
