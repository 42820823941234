import Service from '@ember/service';
import * as Sentry from '@sentry/ember';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import {
  ACTION_ASSIGN_PROJECT,
  ACTION_CREATE,
  ACTION_CREATE_ROOT_TOKEN,
  ACTION_CREATE_TOKEN,
  ACTION_DELETE,
  ACTION_DELETE_TOKEN,
  ACTION_DISABLE,
  ACTION_ENABLE,
  ACTION_GET,
  ACTION_GET_API_INFORMATION,
  ACTION_GET_CLIENT_CONFIG,
  ACTION_GET_IAM_POLICY,
  ACTION_GET_SECRETS,
  ACTION_LIST,
  ACTION_LIST_ROLES,
  ACTION_LIST_TOKENS,
  ACTION_SET_IAM_POLICY,
  ACTION_SET_NAME,
  ACTION_TOKENIZE_PAYMENT_DETAILS,
  ACTION_UNASSIGN_PROJECT,
  ACTION_UPDATE,
  ACTION_UPDATE_MEMBERS,
  ACTION_LIST_MEMBERS,
  PREFIX_BILLING_ACCOUNTS,
  PREFIX_BILLING_ORG_OWNERSHIP,
  PREFIX_BOUNDARY_CLUSTERS,
  PREFIX_CONSUL_CLUSTERS,
  PREFIX_CONSUL_GNM_CLUSTERS,
  PREFIX_CONSUL_GNM_CLUSTERS_SERVERS,
  PREFIX_CONSUL_GNM_PEERING_CONNECTIONS,
  PREFIX_CONSUL_SNAPSHOTS,
  PREFIX_IAM_AUTH_CONNECTION,
  PREFIX_IAM_GROUPS,
  PREFIX_IAM_INVITATIONS,
  PREFIX_IAM_SERVICE_PRINCIPALS,
  PREFIX_IAM_SSO,
  PREFIX_IAM_USERS,
  PREFIX_IDP_SCIM,
  PREFIX_LOG_ENTRIES,
  PREFIX_NETWORK_HVNS,
  PREFIX_NETWORK_PEERINGS,
  PREFIX_NETWORK_ROUTES,
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
  PREFIX_RESOURCE_MANAGER_PROJECTS,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
  PREFIX_TERRAFORM_WORKSPACES,
  PREFIX_PACKER_CHANNELS,
  PREFIX_PACKER_BUCKETS,
  PREFIX_PACKER_VERSIONS,
  PREFIX_PACKER_REGISTRIES,
  PREFIX_VAGRANT_REGISTRY,
  PREFIX_WEBHOOK_WEBHOOKS,

  // Vault Secrets permissions are defined in `utils/permissions-types` and imported here
  SECRETS_ALL_PERMISSIONS,
  TERRAFORM_ALL_PERMISSIONS,
  // Vault permissions are defined in the `utils/permission-types` and imported here
  VAULT_ALL_PERMISSIONS,
  VAULT_RADAR_ALL_PERMISSIONS,
  WAYPOINT_ALL_PERMISSIONS,
  PREFIX_CONSUL_GNM_OBSERVABILITY,
  ACTION_GET_CREDENTIALS,
  PREFIX_LOG_STREAMING,
  ACTION_TEST,
} from '../utils/permission-types/index';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import AuthzBuilder from 'common/utils/authz';

const vagrantAppScope = new AuthzBuilder('vagrant', { Registry: 'registry' });
vagrantAppScope.addActions('registry', ['create', 'read', 'delete', 'list']);

const PERMISSIONS_QUERY = {
  permissions: [
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_ASSIGN_PROJECT}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_DELETE}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_GET}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_LIST}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_TOKENIZE_PAYMENT_DETAILS}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UNASSIGN_PROJECT}`,
    `${PREFIX_BILLING_ACCOUNTS}.${ACTION_UPDATE}`,

    `${PREFIX_BILLING_ORG_OWNERSHIP}.${ACTION_CREATE}`,
    `${PREFIX_BILLING_ORG_OWNERSHIP}.${ACTION_GET}`,

    `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_BOUNDARY_CLUSTERS}.${ACTION_CREATE}`,

    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE_ROOT_TOKEN}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_GET_CLIENT_CONFIG}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_CLUSTERS}.${ACTION_UPDATE}`,

    `${PREFIX_CONSUL_GNM_CLUSTERS_SERVERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_API_INFORMATION}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET_SECRETS}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_GET}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_LIST}`,
    `${PREFIX_CONSUL_GNM_CLUSTERS}.${ACTION_UPDATE}`,
    `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_GNM_PEERING_CONNECTIONS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_GNM_OBSERVABILITY}.${ACTION_GET_CREDENTIALS}`,

    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_CREATE}`,
    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_DELETE}`,
    `${PREFIX_CONSUL_SNAPSHOTS}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_CREATE}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_DELETE}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_GET}`,
    `${PREFIX_IAM_AUTH_CONNECTION}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_INVITATIONS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_INVITATIONS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,

    `${PREFIX_IAM_GROUPS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_GET}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE_MEMBERS}`,
    `${PREFIX_IAM_GROUPS}.${ACTION_LIST_MEMBERS}`,

    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_CREATE}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_GET}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_LIST}`,
    `${PREFIX_IAM_SERVICE_PRINCIPALS}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_SSO}.${ACTION_CREATE}`,
    `${PREFIX_IAM_SSO}.${ACTION_DELETE}`,
    `${PREFIX_IAM_SSO}.${ACTION_GET}`,
    `${PREFIX_IAM_SSO}.${ACTION_LIST}`,
    `${PREFIX_IAM_SSO}.${ACTION_UPDATE}`,

    `${PREFIX_IAM_USERS}.${ACTION_DELETE}`,
    `${PREFIX_IAM_USERS}.${ACTION_GET}`,
    `${PREFIX_IAM_USERS}.${ACTION_LIST}`,

    `${PREFIX_IDP_SCIM}.${ACTION_CREATE_TOKEN}`,
    `${PREFIX_IDP_SCIM}.${ACTION_DELETE_TOKEN}`,
    `${PREFIX_IDP_SCIM}.${ACTION_DISABLE}`,
    `${PREFIX_IDP_SCIM}.${ACTION_ENABLE}`,
    `${PREFIX_IDP_SCIM}.${ACTION_GET}`,
    `${PREFIX_IDP_SCIM}.${ACTION_LIST_TOKENS}`,

    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_LIST_ROLES}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_GET_IAM_POLICY}`,
    `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_IAM_POLICY}`,

    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_CREATE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_DELETE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_LIST}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_UPDATE}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_GET_IAM_POLICY}`,
    `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_SET_IAM_POLICY}`,

    `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST}`,
    `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,

    `${PREFIX_TERRAFORM_WORKSPACES}.${ACTION_LIST}`,

    `${PREFIX_LOG_ENTRIES}.${ACTION_GET}`,

    `${PREFIX_LOG_STREAMING}.${ACTION_GET}`,
    `${PREFIX_LOG_STREAMING}.${ACTION_LIST}`,
    `${PREFIX_LOG_STREAMING}.${ACTION_CREATE}`,
    `${PREFIX_LOG_STREAMING}.${ACTION_DELETE}`,
    `${PREFIX_LOG_STREAMING}.${ACTION_UPDATE}`,
    `${PREFIX_LOG_STREAMING}.${ACTION_TEST}`,

    `${PREFIX_NETWORK_HVNS}.${ACTION_CREATE}`,
    `${PREFIX_NETWORK_HVNS}.${ACTION_DELETE}`,
    `${PREFIX_NETWORK_HVNS}.${ACTION_LIST}`,

    `${PREFIX_NETWORK_PEERINGS}.${ACTION_CREATE}`,

    `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
    `${PREFIX_NETWORK_ROUTES}.${ACTION_DELETE}`,

    `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_CREATE}`,

    `${PREFIX_PACKER_CHANNELS}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_GET}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_LIST}`,
    `${PREFIX_PACKER_CHANNELS}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_BUCKETS}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_BUCKETS}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_BUCKETS}.${ACTION_GET}`,
    `${PREFIX_PACKER_BUCKETS}.${ACTION_LIST}`,
    `${PREFIX_PACKER_BUCKETS}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_VERSIONS}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_VERSIONS}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_VERSIONS}.${ACTION_GET}`,
    `${PREFIX_PACKER_VERSIONS}.${ACTION_LIST}`,
    `${PREFIX_PACKER_VERSIONS}.${ACTION_UPDATE}`,

    `${PREFIX_PACKER_REGISTRIES}.${ACTION_CREATE}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_DELETE}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_GET}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_LIST}`,
    `${PREFIX_PACKER_REGISTRIES}.${ACTION_UPDATE}`,

    `${PREFIX_VAGRANT_REGISTRY}.${ACTION_LIST}`,

    `${PREFIX_WEBHOOK_WEBHOOKS}.${ACTION_LIST}`,

    ...SECRETS_ALL_PERMISSIONS,
    ...TERRAFORM_ALL_PERMISSIONS,
    ...VAULT_RADAR_ALL_PERMISSIONS,
    ...VAULT_ALL_PERMISSIONS,
    ...WAYPOINT_ALL_PERMISSIONS,
    ...vagrantAppScope.list(),
  ],
};

export const PermissionScope = {
  ORGANIZATION: 'organization',
  PROJECT: 'project',
  RESOURCE: 'resource',
  ALL: 'all',
};

export default class PermissionsService extends Service {
  @service api;

  // _testingPermissions is a variable that holds an overriding permissions list for testing
  // this allows tests to write `permissions.permissions = [...]` and set the permissions for a specific test
  // directly setting a value for `permissions.permission` will throw an error in non-testing mode
  // null vs empty array is an important distinction, null means this is "nothing" empty array means the user has no permissions
  @tracked _testingPermissions = null;

  @tracked id = null;
  @tracked organizationPermissions = [];
  @tracked projectPermissions = [];
  @tracked resourcePermissions = [];
  @tracked queriedAt = null;
  @tracked failOpen = false;
  @tracked resourceType = null;

  /**
   * Checks the presence of a RBAC permissions string in the in-memory permissions list.
   * In the case of an API failure, `this.failOpen` will be true, and `has`
   * will always return true. This is a failure mode which may be confusing for
   * users, but will allow the portal to continue to function with their set of
   * allowed permissions.
   *
   * When scoping permissions using the scope variable, the permissions are checked against
   * the scope provided, if a scope isn't provided, or the scope is set to PermissionScope.ALL,
   * the permissions are checked against all permissions using the this.permissions value - which
   * allows for the ease of setting permissions during test.
   * @param {string} permission - A hashicorp.cloud.resource-manager RBAC permission string.
   * @param {PermissionScope} scope - The scope of the permission to check.
   * @return {boolean}
   */
  @action
  has(permission, scope = PermissionScope.ALL) {
    let permissions;
    if (scope === PermissionScope.ALL) {
      permissions = this.permissions;
    } else {
      permissions = this.scopedPermissions[scope];
    }

    return this.failOpen || permissions.includes(permission);
  }

  /**
   * Combines a prefix and an action to create an RBAC permission string dynamically.
   *
   * @param {string} permissionPrefix - A hashicorp.cloud.resource-manager RBAC prefix string.
   * @param {string} permissionAction - A hashicorp.cloud.resource-manager RBAC action string.
   * @return {string}
   */
  generate(permissionPrefix, permissionAction) {
    return `${permissionPrefix}.${permissionAction}`;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and the current
   * resource type (organization, project).
   * Upon querying a resourceType of permissions, we'll reset all other lower permissions
   * To prevent an issue where you might be able to perform an action at the project/resource level,
   * but not the organization level.
   * Since we always query permissions on every page load, and at the highest level first
   * (org, then project, then resource), we can ensure that we'll always have the correct fresh permissions.
   *
   * @param {string} organizationId - A hashicorp.cloud.resource-manager organization id.
   * @param {string} resourceType - Defaults to organization, project is also an accepted/supported type.
   * @return {Object}
   */
  async query(id, resourceType = TYPE_ORGANIZATION) {
    if (resourceType === TYPE_ORGANIZATION) {
      await this.queryOrganization(id);
      this.removeProjectPermissions();
      this.removeResourcePermissions();
    } else if (resourceType === TYPE_PROJECT) {
      await this.queryProject(id);
      this.removeResourcePermissions();
    } else {
      await this.queryResource(id);
    }

    this.id = id;
    this.queriedAt = new Date();
    this.resourceType = resourceType;

    return this;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and an organization.
   *
   * @param {string} organizationId - A hashicorp.cloud.resource-manager organization id.
   * @return {Object}
   */
  async queryOrganization(organizationId) {
    // Query all permissions.
    let permissionResponse;
    try {
      permissionResponse =
        await this.api.resourceManager.org.organizationServiceTestIamPermissions(
          organizationId,
          PERMISSIONS_QUERY,
        );
    } catch (e) {
      // we shouldn't hide anything if there's an error in calling the `projectTestIamPermissions` endpoint
      this.failOpen = true;
      var style = 'color: red; background:#eee; font-size:16px;';
      console.log(
        '%cIAM Permissions check failed, all client-side permission checks will succeed. This may lead to showing UI elements for actions you do not normally see and do not have permission to carry out.',
        style,
      );
      Sentry.captureException(
        new Error('testIamPermissions API request failed'),
      );
      // rethrow so that downstream try/catch blocks still work
      throw e;
    }

    this.organizationPermissions = permissionResponse.allowedPermissions || [];
    return permissionResponse;
  }

  /**
   * Queries resource manager for all RBAC permissions for current user and a project.
   *
   * @param {string} projectId - A hashicorp.cloud.resource-manager project id.
   * @return {Object}
   */
  async queryProject(projectId) {
    // Query all permissions.
    let permissionResponse;
    try {
      permissionResponse =
        await this.api.resourceManager.project.projectServiceTestIamPermissions(
          projectId,
          PERMISSIONS_QUERY,
        );
    } catch (e) {
      // we shouldn't hide anything if there's an error in calling the `testIamPermissions` endpoint
      this.failOpen = true;
      var style = 'color: red; background:#eee; font-size:16px;';
      console.log(
        '%cProject IAM Permissions check failed, all client-side permission checks will succeed. This may lead to showing UI elements for actions you do not normally see and do not have permission to carry out.',
        style,
      );
      Sentry.captureException(
        new Error('projectTestIamPermissions API request failed'),
      );
      // rethrow so that downstream try/catch blocks still work
      throw e;
    }

    this.projectPermissions = permissionResponse.allowedPermissions || [];
    return permissionResponse;
  }

  async queryResource(identifier) {
    // Query all permissions.
    let permissionResponse;

    try {
      permissionResponse =
        await this.api.resourceManager.authorization.authorizationServiceTestIamPermissions(
          {
            ...PERMISSIONS_QUERY,
            resourceName: identifier,
          },
        );
    } catch (e) {
      // we shouldn't hide anything if there's an error in calling the `testIamPermissions` endpoint
      this.failOpen = true;
      var style = 'color: red; background:#eee; font-size:16px;';
      console.log(
        '%cResource IAM Permissions check failed, all client-side permission checks will succeed. This may lead to showing UI elements for actions you do not normally see and do not have permission to carry out.',
        style,
      );
      Sentry.captureException(
        new Error('authorizationServiceTestIamPermissions API request failed'),
      );
      // rethrow so that downstream try/catch blocks still work
      throw e;
    }

    // this code assumes there's only ONE active resource at a time
    // if that changes, this will start working in strange ways.
    this.resourcePermissions = permissionResponse.allowedPermissions || [];

    return permissionResponse;
  }

  removeProjectPermissions() {
    this.projectPermissions = [];
  }

  removeResourcePermissions() {
    this.resourcePermissions = [];
  }

  get scopedPermissions() {
    return {
      [PermissionScope.ORGANIZATION]: [...this.organizationPermissions],
      [PermissionScope.PROJECT]: [...this.projectPermissions],
      [PermissionScope.RESOURCE]: [...this.resourcePermissions],
      // Set is used to deduplicate any repeated permissions
      [PermissionScope.ALL]: Array.from(
        new Set([
          ...this.organizationPermissions,
          ...this.projectPermissions,
          ...this.resourcePermissions,
        ]).values(),
      ),
    };
  }

  /**
   * Return all scoped permissions for the current user.
   * @returns {string[]}
   */
  get permissions() {
    // this line allows tests to override permissions
    // this line should be skipped if the app is not in testing mode
    if (isTesting() && this._testingPermissions)
      return this._testingPermissions;

    return this.scopedPermissions.all;
  }

  set permissions(permissions) {
    // setting permissions directly is not supported outside of testing scenarios
    // if you want to load permissions into the permissions service, use `queryResource` `queryOrganization`, or `queryProject`
    if (!isTesting()) {
      console.error(
        'Setting permissions directly is not allowed in non-testing scenarios. To update/fetch permissions, use the `permissions-service.query` method instead. ',
      );
      return;
    }

    this._testingPermissions = permissions;
  }
}
