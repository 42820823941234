import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import FieldEditor from './code-editor/field-editor.gts';
import Toolbar from './code-editor/toolbar.gts';

interface CodeEditorSignature {
  Args: {
    codeValue: string | undefined;
  };
  Blocks: {
    default: [unknown];
  };
}
/**
 *
 * A string containing the code that should appear in the code editor.
 * @argument codeValue
 * @type {String}
 *
 */

/**
 *
 * `CodeEditor` is a component that yields two presentational components which together provide an IDE-like UX.
 * CodeEditor::FieldEditor is where the provided code is displayed with syntax highlighting.
 * CodeEditor::Toolbar is intended to go on top of the FieldEditor and provides a 'Copy code' button as well as other optional user-provided actions.
 *
 *
 * ```
 * <CodeEditor
 *   @codeValue={{this.codeIWantToSee}}
 *   as |C|
 * >
 *  <C.Toolbar />
 *  <C.FieldEditor />
 * </CodeEditor>
 * ```
 *
 * @class CodeEditor
 *
 */
export default class CodeEditor extends Component<CodeEditorSignature> {
  <template>
    <div data-test-code-editor>
      {{yield
        (hash
          FieldEditor=(component FieldEditor value=@codeValue)
          Toolbar=(component Toolbar copyText=@codeValue)
        )
      }}
    </div>
  </template>
}
