import Component from '@glimmer/component';
import { t } from 'ember-intl';
import {
  HdsCardContainer,
  HdsBadge,
  HdsIcon,
} from '@hashicorp/design-system-components/components';
import Flex from 'core/components/flex';
import FlexGrid from 'core/components/flex-grid';
import Typography from 'core/components/typography';
import ClipboardCopyButton from 'core/components/clipboard-copy-button';
import boxPadding from 'core/modifiers/box-padding';
import optionForSourceState from 'core/helpers/option-for-source-state';

import networksConnectionInfo from '../../../helpers/networks-connection-info.js';
import networksConnectionSourceType from '../../../helpers/networks-connection-source-type.js';

/**
 *
 * `NetworksCardsConnectionDetailsComponent` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::Cards::ConnectionDetails
 *   @connection={{connection}}
 * />
 * ```
 *
 * @class NetworksCardsConnectionDetailsComponent
 *
 */
export default class NetworksCardsConnectionDetails extends Component {
  get provider() {
    let {
      connection: {
        hvn: {
          location: {
            region: { provider },
          },
        },
      },
    } = this.args;
    return provider;
  }

  <template>
    {{#let (networksConnectionInfo @connection) as |destination|}}
      <HdsCardContainer
        data-networks-cards-connection-details
        class="networks-cards-connection-details"
        @level="base"
        @hasBorder={{true}}
        {{boxPadding "md 2xl md lg"}}
      >
        <Flex @direction="column" @gap="md">
          <Flex @alignItems="center" @gap="xl">
            <Flex
              @direction="column"
              @gap="2xs"
              @grow={{1}}
              @justifyContent="start"
            >
              <Typography
                @variant="body1"
                class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
              >
                {{t
                  "components.networks.cards.connection-details.peering-name"
                }}
              </Typography>
              <Flex @alignItems="center" @direction="row" @gap="md">
                <Flex @alignItems="center" @gap="2xs">
                  <HdsIcon @name="hashicorp-color" @isInline={{true}} />
                  <span
                    class="hds-typography-body-200 hds-font-weight-semibold hds-foreground-primary"
                    data-networks-cards-connection-details-name
                  >
                    {{@connection.id}}
                  </span>
                </Flex>
                <Flex @alignItems="center" @direction="row">
                  {{#let
                    (optionForSourceState
                      (networksConnectionSourceType @connection)
                      @connection.state
                    )
                    as |option|
                  }}
                    <HdsBadge
                      @text={{t option.text}}
                      @color={{option.color}}
                      @icon={{option.icon}}
                      @isIconOnly={{option.isIconOnly}}
                      @size={{option.size}}
                      @type={{option.type}}
                      data-networks-cards-connection-details-state
                    />
                  {{/let}}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <hr />
          <FlexGrid as |G|>
            <G.Item @xs="12" @sm="12" @md="3" @lg="3">
              <Flex
                @direction="column"
                @gap="2xs"
                @grow={{1}}
                @justifyContent="start"
              >
                <Typography
                  @variant="body1"
                  class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
                >
                  {{t
                    "components.networks.cards.connection-details.destination.peering-id"
                  }}
                </Typography>
                {{#if @connection.providerPeeringId}}
                  <Flex @gap="2xs">
                    <Flex
                      @justifyContent="center"
                      data-networks-cards-connection-details-data
                    >
                      {{#let
                        (optionForSourceState "region" this.provider)
                        as |option|
                      }}
                        <Flex @alignItems="center" @direction="row" @gap="2xs">
                          <HdsIcon
                            @name={{option.icon}}
                            @isInline={{true}}
                            data-networks-cards-connection-details-state
                          />
                          <span class="hds-typography-body-200">
                            {{@connection.providerPeeringId}}
                          </span>
                        </Flex>
                      {{/let}}
                    </Flex>
                    <Flex @justifyContent="center">
                      <ClipboardCopyButton
                        title={{t
                          "components.networks.cards.connection-details.copy-button-title"
                        }}
                        @clipboardText={{@connection.providerPeeringId}}
                        @variant="no-success-text"
                      />
                    </Flex>
                  </Flex>
                {{/if}}
              </Flex>
            </G.Item>
            <G.Item @xs="12" @sm="12" @md="3" @lg="3">
              <Flex
                @direction="column"
                @gap="2xs"
                @grow={{1}}
                @justifyContent="start"
              >
                <Typography
                  @variant="body1"
                  class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
                >
                  {{t
                    "components.networks.cards.connection-details.destination.vpc"
                  }}
                </Typography>
                {{#if destination.vpcId}}
                  <Flex @gap="2xs">
                    <Flex
                      @justifyContent="center"
                      data-networks-cards-connection-details-data
                    >
                      {{#let
                        (optionForSourceState "region" this.provider)
                        as |option|
                      }}
                        <Flex @alignItems="center" @direction="row" @gap="2xs">
                          <HdsIcon
                            @name={{option.icon}}
                            @isInline={{true}}
                            data-networks-cards-connection-details-state
                          />
                          <span class="hds-typography-body-200">
                            {{destination.vpcId}}
                          </span>
                        </Flex>
                      {{/let}}
                    </Flex>
                    <Flex @justifyContent="center">
                      <ClipboardCopyButton
                        title={{t
                          "components.networks.cards.connection-details.copy-button-title"
                        }}
                        @clipboardText={{destination.vpcId}}
                        @variant="no-success-text"
                      />
                    </Flex>
                  </Flex>
                {{/if}}
              </Flex>
            </G.Item>
            <G.Item @xs="12" @sm="12" @md="3" @lg="3">
              <Flex
                @direction="column"
                @gap="2xs"
                @grow={{1}}
                @justifyContent="start"
              >
                <Typography
                  @variant="body1"
                  class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
                >
                  {{t
                    "components.networks.cards.connection-details.destination.account-id"
                  }}
                </Typography>
                {{#if destination.accountId}}
                  <Flex @gap="2xs">
                    <Flex
                      @justifyContent="center"
                      data-networks-cards-connection-details-data
                    >
                      {{#let
                        (optionForSourceState "region" this.provider)
                        as |option|
                      }}
                        <Flex @alignItems="center" @direction="row" @gap="2xs">
                          <HdsIcon
                            @name={{option.icon}}
                            @isInline={{true}}
                            data-networks-cards-connection-details-state
                          />
                          <span class="hds-typography-body-200">
                            {{destination.accountId}}
                          </span>
                        </Flex>
                      {{/let}}
                    </Flex>
                    <Flex @justifyContent="center">
                      <ClipboardCopyButton
                        title={{t
                          "components.networks.cards.connection-details.copy-button-title"
                        }}
                        @clipboardText={{destination.accountId}}
                        @variant="no-success-text"
                      />
                    </Flex>
                  </Flex>
                {{/if}}
              </Flex>
            </G.Item>
            <G.Item @xs="12" @sm="12" @md="3" @lg="3">
              <Flex
                @direction="column"
                @gap="2xs"
                @grow={{1}}
                @justifyContent="start"
              >
                <Typography
                  @variant="body1"
                  class="hds-typography-body-100 hds-font-weight-regular hds-foreground-faint"
                >
                  {{t
                    "components.networks.cards.connection-details.destination.region"
                  }}
                </Typography>
                {{#if destination.region}}
                  <Flex @gap="2xs">
                    <Flex
                      @justifyContent="center"
                      data-networks-cards-connection-details-data
                    >
                      {{#let
                        (optionForSourceState "region" this.provider)
                        as |option|
                      }}
                        <Flex @alignItems="center" @direction="row" @gap="2xs">
                          <HdsIcon
                            @name={{option.icon}}
                            @isInline={{true}}
                            data-networks-cards-connection-details-state
                          />
                          <span class="hds-typography-body-200">
                            {{destination.region}}
                          </span>
                        </Flex>
                      {{/let}}
                    </Flex>
                    <Flex @justifyContent="center">
                      <ClipboardCopyButton
                        title={{t
                          "components.networks.cards.connection-details.copy-button-title"
                        }}
                        @clipboardText={{destination.region}}
                        @variant="no-success-text"
                      />
                    </Flex>
                  </Flex>
                {{/if}}
              </Flex>
            </G.Item>
          </FlexGrid>
        </Flex>
      </HdsCardContainer>
    {{/let}}
  </template>
}
