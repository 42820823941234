/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221ControllerConfigurationFromJSON, HashicorpCloudBoundary20211221ControllerConfigurationToJSON, } from './';
export function HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseFromJSON(json) {
    return HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'config': !exists(json, 'config') ? undefined : HashicorpCloudBoundary20211221ControllerConfigurationFromJSON(json['config']),
    };
}
export function HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'config': HashicorpCloudBoundary20211221ControllerConfigurationToJSON(value.config),
    };
}
