import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';

import WithErrorsFormError from './with-errors/form-error.gts';
import WithErrorsFieldError from './with-errors/field-error.gts';

import type { Error } from '../utils/consts/with-errors';
import type { WithBoundArgs } from '@glint/template';

interface WithErrorsSignature {
  Args: {
    id?: string;
    error: Error;
  };
  Blocks: {
    default: [
      WithBoundArgs<
        typeof WithErrorsFormError,
        'error' | 'unclaimedFieldErrors'
      >,
      WithBoundArgs<
        typeof WithErrorsFieldError,
        'fieldViolations' | 'id' | 'onInsert'
      >,
      Error,
    ];
  };
}

/**
 *
 * `WithErrors` is a data-handling component that sorts out errors and keeps a
 *    registry of fields and then yields two components, `WithErrors::FormError`
 *    and `WithErrors::FieldError`.
 *
 *
 * `WithErrors::FormError` is a page-level error that renders when other,
 *    field-specific errors are not claimed by other instances.
 *
 *
 * `WithErrors::FieldError` is a field-level error that takes a `@field` argument
 *    and renders an HDS Alert component next to a form field. This
 *    form can be used in block form if you need to render the error state of an
 *    input based on the presence of an error (or even a certain error).
 *
 *
 *  `WithErrors::FieldError` can also be used with `Hds::Form` components, where
 *    the rendering of the error message associated with a field is handled at the
 *    component level. To communicate that the rendering of the error message is
 *    suppressed at the `WithErrors::FieldError` level and is delegated to the
 *    `Hds::Form` component (to prevent duplicated error messages) an
 *    `@hdsDelegatedError` argument is required, as shown in the example below.
 *
 *
 * ```
 * <WithErrors @error={{this.save.last.error}} as |FormError FieldError|>
 *   <FormError />
 *   <FieldError @field='cluster.id' @hdsDelegatedError={{true}} as |err|>
 *     <Hds::Form::TextInput::Field @isInvalid={{err}}>
 *       <C.Label>Network name</C.Label>
 *       <C.Error>{{err.description}}</C.Error>
 *     </Hds::Form::TextInput::Field>
 *   </FieldError>
 * </WithErrors>
 * ```
 *
 *
 * `WithErrors::error` is the internally-parsed json error.
 *
 *
 * The yielded components can be used independently.
 *
 *
 * ```
 * <WithErrors @error={{this.save.last.error}} as |FormError FieldError|>
 *   <FormError />
 *   <FieldError @field='cluster.id' as |err|>
 *     <input class={{if err 'errored'}} value={{@someVal}} {{on 'change' this.update}} />
 *   </FieldError>
 * </WithErrors>
 * ```
 *
 * `WithErrors` handles error responses that take a few forms:
 *
 *
 * with field violations:
 *
 *
 * ```
 *  {
 *   message: 'bad request', error: 'bad request',
 *   details: [
 *     {
 *       field_violations: [
 *         {
 *           field: 'cluster.id',
 *           description: 'cannot be blank',
 *         },
 *         {
 *           field: 'cluster.lol',
 *           description: 'lols cannot be blank',
 *         },
 *       ],
 *     },
 *   ],
 * }
 *  ```
 *
 * with violations:
 *
 *  ```
 *  {
 *   error: 'Consul cluster quota reached',
 *   code: 8,
 *   message: 'Consul cluster quota reached',
 *   details: [
 *     {
 *       '@type': 'type.googleapis.com/google.rpc.QuotaFailure',
 *       violations: [
 *         {
 *           subject: 'organization:11eaf69e-87f8-9c92-a0cb-0242ac120033',
 *           description: 'Limit 1 Consul cluster per organization',
 *         },
 *       ],
 *     },
 *   ],
 * }
 *  ```
 *
 * or more generic errors:
 *
 *  ```
 *  {
 *   "error": "cluster with name \"consul-cluster-two\" already exists in the project",
 *   "code": 6,
 *   "message": "cluster with name \"consul-cluster-two\" already exists in the project",
 *   "details": []
 * }
 *  ```
 *
 * @class WithErrors
 *
 */
export default class WithErrors extends Component<WithErrorsSignature> {
  /**
   * The error object to use to determine what to render.
   * @argument error
   * @type {object}
   *
   */

  // fields keeps track of the value of `@field` across all instances of
  // `FormError`
  @tracked fields = new Set();
  @tracked _error: Error;

  registerField = (field: string) => {
    this.fields.add(field);
  };

  /*
   * `setError` gets called from did-insert and did-update modifiers.
   *    It transforms an error into a standard format and calls `error.json()`
   *    if the error is from a server response.
   *
   *
   */
  setError = async () => {
    let { error } = this.args;
    if (error?.json) {
      const { status, statusText, url } = error;
      error = {
        ...(await error.json()),
        status,
        statusText,
        url,
      };
    }
    this._error = error;
  };

  get error() {
    return this._error;
  }

  // an array extracted from rolling up all error.details[].field_violations[]
  get fieldViolations() {
    return this.error?.details
      ?.flatMap((d) => d?.field_violations)
      .filter(Boolean);
  }

  // this is a list of errors that other instances of FormError have not used
  // as their `@field` value
  get unclaimedFieldErrors() {
    return this.fieldViolations?.filter((e) => {
      return !this.fields.has(e?.field);
    });
  }

  <template>
    <noscript
      data-test-form-error-notifier
      {{didInsert (fn this.setError @error)}}
      {{didUpdate this.setError @error}}
    ></noscript>
    {{yield
      (component
        WithErrorsFormError
        error=this.error
        unclaimedFieldErrors=this.unclaimedFieldErrors
      )
      (component
        WithErrorsFieldError
        onInsert=this.registerField
        fieldViolations=this.fieldViolations
        id=@id
      )
      this.error
    }}
  </template>
}
