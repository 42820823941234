import Component from '@glimmer/component';

interface CoreLayoutMainHorizontalViewportSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class CoreLayoutMainHorizontalViewport extends Component<CoreLayoutMainHorizontalViewportSignature> {
  <template>
    <div
      class="core-layout-main__horizontal-viewport"
      data-test-core-layout-main-horizontal-viewport
      ...attributes
    >
      {{yield}}
    </div>
  </template>
}
