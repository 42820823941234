import Component from '@glimmer/component';
import { t } from 'ember-intl';

import Typography from '../../typography.gts';

interface ChartsStackedBarSummarySignature {
  Args: {
    isOverMax: boolean;
    max?: number;
    total: number;
  };
}

export default class ChartsStackedBarSummary extends Component<ChartsStackedBarSummarySignature> {
  <template>
    <Typography
      class="stacked-bar__summary {{if @isOverMax 'over'}}"
      data-test-chart-summary
      @variant="body1"
    >
      {{#if @max}}
        <span
          aria-label={{t
            "components.stacked-bar.accessibility.summary-with-max"
            max=@max
            total=@total
          }}
        >
          {{@total}}/{{@max}}
        </span>
      {{else}}
        <span
          aria-label={{t
            "components.stacked-bar.accessibility.summary-label"
            max=@max
            total=@total
          }}
        >
          {{@total}}
          {{t "components.charts.stacked-bar.total"}}
        </span>
      {{/if}}
    </Typography>
  </template>
}
