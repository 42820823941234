import Service, { service } from '@ember/service';
import SegmentProvider from '../utils/analytics-providers/segment.js';
import PosthogProvider from '../utils/analytics-providers/posthog.js';
import type RouterService from '@ember/routing/router-service';
import type CurrentUserService from '../services/current-user.ts';
import type UserContextService from '../services/user-context.ts';
import type Owner from '@ember/owner';

const CONTEXT_STORAGE_KEY = 'hcp.analytics.context';

interface Provider {
  name: string;
  anonymousId: string;
  start: (config: Record<string, string | object>) => void;
  trackPageView: (path: string, name: string, referrer: string) => void;
  trackEvent: (
    event: string,
    properties: object,
    options?: object,
    callback?: () => void
  ) => void;
  identifyUser: (userId: string, traits: object, options?: unknown) => void;
}

/**
 * Encapsulates the implementation details of our analytics integration for Cloud UI.
 *
 * @see https://backstage.hashicorp.services/docs/default/component/cloud-ui/analytics/
 *
 */
export default class AnalyticsService extends Service {
  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly userContext: UserContextService;
  @service declare readonly router: RouterService;

  provider: Provider | undefined;
  referrer: string;
  shouldLog: boolean = false;
  constructor(owner: Owner) {
    super(owner);
    this.referrer = window.location.href;
    this.setupRouteChange();
  }

  setupRouteChange() {
    this.router.on('routeDidChange', () => {
      // skip tracking the callback page
      if (this.router.currentRouteName !== 'callback') {
        this.trackPageView(
          this.router.currentURL || '',
          this.router.currentRouteName || '',
          this.referrer
        );
        this.referrer = window.location.href;
      }
      this.identifyUser();
    });
  }

  get anonymousId() {
    return this.provider?.anonymousId;
  }

  set context(context) {
    localStorage.setItem(CONTEXT_STORAGE_KEY, JSON.stringify(context));
  }

  get context() {
    const context = localStorage.getItem(CONTEXT_STORAGE_KEY);
    return context ? JSON.parse(context) : {};
  }

  start(providerType: string, config: Record<string, string | object> = {}) {
    if (!this.provider) {
      if (providerType === 'segment') {
        this.provider = new SegmentProvider() as Provider;
      }
      if (providerType === 'posthog') {
        this.provider = new PosthogProvider() as Provider;
      }
    }
    if (config && config['LOG_EVENT_TRACKING']) {
      this.shouldLog = true;
    }
    this.provider?.start(config);
  }

  clearContext() {
    localStorage.removeItem(CONTEXT_STORAGE_KEY);
  }

  identifyUser() {
    const { user } = this.currentUser;
    const { organization } = this.userContext;

    const isEmployee = user?.email?.endsWith('@hashicorp.com');

    if (user && user.id) {
      this.provider?.identifyUser(user.id, {
        requesting_user_id: user?.id,
        organization: organization?.id,
        is_employee: Boolean(isEmployee),
      });
    }
  }

  log(...args: Array<unknown>) {
    if (this.shouldLog) {
      console.info(`[Analytics: ${this.provider?.name}] `, ...args); // eslint-disable-line no-console
    }
  }

  trackPageView(path: string, routeName: string, referrer: string) {
    this.log('pageView', path, routeName, referrer);
    this.provider?.trackPageView(path, routeName, referrer);
  }

  trackEvent(event: string, attrs?: object, options?: object) {
    const { organization } = this.userContext;
    let properties = attrs || {};
    if (organization && organization.id) {
      properties = { ...properties, organization: organization.id };
    }
    this.log(event, properties, options);
    this.provider?.trackEvent(event, properties, options);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
