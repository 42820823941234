/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudBoundary20211221ClusterUpdateDeadlineGetResponseFromJSON, HashicorpCloudBoundary20211221CreateRequestToJSON, HashicorpCloudBoundary20211221CreateResponseFromJSON, HashicorpCloudBoundary20211221DeleteResponseFromJSON, HashicorpCloudBoundary20211221GetControllerConfigurationResponseFromJSON, HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseFromJSON, HashicorpCloudBoundary20211221GetResponseFromJSON, HashicorpCloudBoundary20211221ListResponseFromJSON, HashicorpCloudBoundary20211221MaintenanceWindowGetResponseFromJSON, HashicorpCloudBoundary20211221MaintenanceWindowUpdateRequestToJSON, HashicorpCloudBoundary20211221SessionsResponseFromJSON, HashicorpCloudBoundary20211221UpdateApplyRequestToJSON, HashicorpCloudBoundary20211221UpdateControllerConfigurationRequestToJSON, HashicorpCloudBoundary20211221UpdateRequestToJSON, } from '../models';
/**
 *
 */
export class BoundaryServiceApi extends runtime.BaseAPI {
    /**
     * ClusterUpdateDeadlineGet is used to get the deadline for a cluster update.
     */
    async boundaryServiceClusterUpdateDeadlineGetRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceClusterUpdateDeadlineGet.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceClusterUpdateDeadlineGet.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceClusterUpdateDeadlineGet.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/update-deadline`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221ClusterUpdateDeadlineGetResponseFromJSON(jsonValue));
    }
    /**
     * ClusterUpdateDeadlineGet is used to get the deadline for a cluster update.
     */
    async boundaryServiceClusterUpdateDeadlineGet(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceClusterUpdateDeadlineGetRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Create creates a new HCP Boundary cluster.
     */
    async boundaryServiceCreateRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceCreate.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceCreate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling boundaryServiceCreate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudBoundary20211221CreateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221CreateResponseFromJSON(jsonValue));
    }
    /**
     * Create creates a new HCP Boundary cluster.
     */
    async boundaryServiceCreate(locationOrganizationId, locationProjectId, body) {
        const response = await this.boundaryServiceCreateRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     * Delete deletes the specified HCP Boundary cluster.
     */
    async boundaryServiceDeleteRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceDelete.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceDelete.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceDelete.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221DeleteResponseFromJSON(jsonValue));
    }
    /**
     * Delete deletes the specified HCP Boundary cluster.
     */
    async boundaryServiceDelete(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceDeleteRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Get returns a single existing HCP Boundary cluster.
     */
    async boundaryServiceGetRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceGet.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceGet.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceGet.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221GetResponseFromJSON(jsonValue));
    }
    /**
     * Get returns a single existing HCP Boundary cluster.
     */
    async boundaryServiceGet(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceGetRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetControllerConfiguration is used to retrieve the controller configuration values of a specified Boundary cluster.
     */
    async boundaryServiceGetControllerConfigurationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceGetControllerConfiguration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceGetControllerConfiguration.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceGetControllerConfiguration.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/controller-configuration`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221GetControllerConfigurationResponseFromJSON(jsonValue));
    }
    /**
     * GetControllerConfiguration is used to retrieve the controller configuration values of a specified Boundary cluster.
     */
    async boundaryServiceGetControllerConfiguration(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceGetControllerConfigurationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetDefaultControllerConfiguration is used to obtain the default controller configuration values used for a Boundary cluster.
     */
    async boundaryServiceGetDefaultControllerConfigurationRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/controller-configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221GetDefaultControllerConfigurationResponseFromJSON(jsonValue));
    }
    /**
     * GetDefaultControllerConfiguration is used to obtain the default controller configuration values used for a Boundary cluster.
     */
    async boundaryServiceGetDefaultControllerConfiguration() {
        const response = await this.boundaryServiceGetDefaultControllerConfigurationRaw();
        return await response.value();
    }
    /**
     * List returns all existing HCP Boundary Clusters.
     */
    async boundaryServiceListRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceList.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceList.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221ListResponseFromJSON(jsonValue));
    }
    /**
     * List returns all existing HCP Boundary Clusters.
     */
    async boundaryServiceList(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.boundaryServiceListRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * MaintenanceWindowGet is used to fetch the type of maintenance for the given cluster. Maintenance can either be automatic or scheduled, if scheduled the response returns the window set by the user.
     */
    async boundaryServiceMaintenanceWindowGetRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceMaintenanceWindowGet.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceMaintenanceWindowGet.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceMaintenanceWindowGet.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/maintenance-window`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221MaintenanceWindowGetResponseFromJSON(jsonValue));
    }
    /**
     * MaintenanceWindowGet is used to fetch the type of maintenance for the given cluster. Maintenance can either be automatic or scheduled, if scheduled the response returns the window set by the user.
     */
    async boundaryServiceMaintenanceWindowGet(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceMaintenanceWindowGetRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * MaintenanceWindowUpdate sets maintenance window for update version
     */
    async boundaryServiceMaintenanceWindowUpdateRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceMaintenanceWindowUpdate.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceMaintenanceWindowUpdate.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceMaintenanceWindowUpdate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling boundaryServiceMaintenanceWindowUpdate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/maintenance-window`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudBoundary20211221MaintenanceWindowUpdateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * MaintenanceWindowUpdate sets maintenance window for update version
     */
    async boundaryServiceMaintenanceWindowUpdate(locationOrganizationId, locationProjectId, clusterId, body) {
        const response = await this.boundaryServiceMaintenanceWindowUpdateRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, body: body });
        return await response.value();
    }
    /**
     * ResetControllerConfiguration is used to reset a cluster\'s controller configuration to its default values.
     */
    async boundaryServiceResetControllerConfigurationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceResetControllerConfiguration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceResetControllerConfiguration.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceResetControllerConfiguration.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/controller-configuration`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * ResetControllerConfiguration is used to reset a cluster\'s controller configuration to its default values.
     */
    async boundaryServiceResetControllerConfiguration(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceResetControllerConfigurationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Sessions returns all session information for existing HCP Boundary Cluster.
     */
    async boundaryServiceSessionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceSessions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceSessions.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceSessions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/sessions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudBoundary20211221SessionsResponseFromJSON(jsonValue));
    }
    /**
     * Sessions returns all session information for existing HCP Boundary Cluster.
     */
    async boundaryServiceSessions(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.boundaryServiceSessionsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Update triggers checks if update for the specific cluster is needed and forces an update.
     */
    async boundaryServiceUpdateRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceUpdate.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceUpdate.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceUpdate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling boundaryServiceUpdate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudBoundary20211221UpdateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Update triggers checks if update for the specific cluster is needed and forces an update.
     */
    async boundaryServiceUpdate(locationOrganizationId, locationProjectId, clusterId, body) {
        const response = await this.boundaryServiceUpdateRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, body: body });
        return await response.value();
    }
    /**
     * UpdateApply is used to manually update a cluster to a specific version.
     */
    async boundaryServiceUpdateApplyRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceUpdateApply.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceUpdateApply.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceUpdateApply.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling boundaryServiceUpdateApply.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/update`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudBoundary20211221UpdateApplyRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateApply is used to manually update a cluster to a specific version.
     */
    async boundaryServiceUpdateApply(locationOrganizationId, locationProjectId, clusterId, body) {
        const response = await this.boundaryServiceUpdateApplyRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, body: body });
        return await response.value();
    }
    /**
     * UpdateControllerConfiguration is used to modify the controller configuration values of a specified Boundary cluster.
     */
    async boundaryServiceUpdateControllerConfigurationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling boundaryServiceUpdateControllerConfiguration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling boundaryServiceUpdateControllerConfiguration.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling boundaryServiceUpdateControllerConfiguration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling boundaryServiceUpdateControllerConfiguration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/boundary/2021-12-21/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/controller-configuration`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudBoundary20211221UpdateControllerConfigurationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateControllerConfiguration is used to modify the controller configuration values of a specified Boundary cluster.
     */
    async boundaryServiceUpdateControllerConfiguration(locationOrganizationId, locationProjectId, clusterId, body) {
        const response = await this.boundaryServiceUpdateControllerConfigurationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, body: body });
        return await response.value();
    }
}
