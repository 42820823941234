import { variation } from 'ember-launch-darkly';
import cookies from 'js-cookie';

export const LOCAL_DEV_COOKIE = 'local_access_token';

const cookieAuthEnabled = (): boolean => {
  // @ts-expect-error global type
  if (!window.__LD__) {
    // if Launch Darkly is not initialized, return the localStorage value...
    return localStorage.getItem('hcp-ui-cookie-auth') === 'true';
  }

  return (
    variation('hcp-ui-cookie-auth') ||
    localStorage.getItem('hcp-ui-cookie-auth') === 'true'
  );
};

export const validateCookieAuth = (): boolean =>
  cookieAuthEnabled() && !!cookies.get(LOCAL_DEV_COOKIE);

export default cookieAuthEnabled;
