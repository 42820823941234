import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class FormatFileSizeHelper extends Helper {
  @service intl;

  compute([bytes]) {
    if (!bytes || isNaN(bytes) || bytes === 0) {
      return `0 ${this.intl.t('helpers.format-file-size.bytes')}`;
    }

    const units = [
      this.intl.t('helpers.format-file-size.bytes'),
      this.intl.t('helpers.format-file-size.kilobytes'),
      this.intl.t('helpers.format-file-size.megabytes'),
      this.intl.t('helpers.format-file-size.gigabytes'),
      this.intl.t('helpers.format-file-size.terabytes'),
      this.intl.t('helpers.format-file-size.petabytes'),
      this.intl.t('helpers.format-file-size.exabytes'),
      this.intl.t('helpers.format-file-size.zettabytes'),
      this.intl.t('helpers.format-file-size.yottabytes'),
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

    return `${this.intl.formatNumber(size)} ${units[i]}`;
  }
}
