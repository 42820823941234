import Component from '@glimmer/component';

interface CoreCardSummaryTitleSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `CoreCardSummaryTitleComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryTitleComponent
 *
 */

export default class CoreCardSummaryTitle extends Component<CoreCardSummaryTitleSignature> {
  <template>
    <div
      class="core-card-summary__title hds-typography-display-200 hds-font-weight-medium hds-foreground-strong"
      data-test-core-card-summary-title
    >
      {{yield}}
    </div>
  </template>
}
