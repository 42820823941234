import Component from '@glimmer/component';

// Components
import Flex from 'core/components/flex';
import { Type } from 'core/utils/consts/card-summary';
import CoreCardSummary from 'core/components/core/card/summary';
import {
  HdsCopySnippet,
  HdsIcon,
} from '@hashicorp/design-system-components/components';

// Helpers
import { or } from 'ember-truth-helpers';
import { array } from '@ember/helper';
import { t } from 'ember-intl';
import optionForSourceState from 'core/helpers/option-for-source-state';

// Utils
import { AlignItem, FlexDirection, FlexGap } from 'core/utils/flex';
import type { HashicorpCloudIamUserPrincipal } from '@clients/cloud-iam';

export interface ManageAccessUsersSummaryCardSignature {
  Element: HTMLElement;
  Args: {
    userPrincipal: HashicorpCloudIamUserPrincipal;
  };
  Blocks: {
    default: [];
  };
}

export default class ManageAccessUsersSummaryCardComponent extends Component<ManageAccessUsersSummaryCardSignature> {
  <template>
    <CoreCardSummary
      @type={{Type.Horizontal}}
      data-test-user-detail-summary-card
      ...attributes
      as |Card|
    >
      <Card.Pair as |P|>
        <P.Key
          data-test-user-detail-summary-card-key
          data-test-user-detail-summary-card-key-type
        >
          {{t "manage-access.components.users.summary-card.principal-type"}}
        </P.Key>
        <P.Value
          data-test-user-detail-summary-card-value
          data-test-user-detail-summary-card-value-type
        >
          {{#let (optionForSourceState "policy-type" "user") as |option|}}
            <Flex
              @alignItems={{AlignItem.Center}}
              @direction={{FlexDirection.Row}}
              @gap={{FlexGap.Xs}}
            >
              <span>
                {{! @glint-expect-error: fix incoming icon type }}
                <HdsIcon @name={{option.icon}} @isInline={{true}} />
              </span>
              <span>
                {{t option.text}}
              </span>
            </Flex>
          {{/let}}
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-user-detail-summary-card-key
          data-test-user-detail-summary-card-key-email-id
        >
          {{t "manage-access.components.users.summary-card.email-id"}}
        </P.Key>
        <P.Value
          data-test-user-detail-summary-card-value
          data-test-user-detail-summary-card-value-email-id
        >
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @gap={{FlexGap.Xs}}
          >
            {{#if @userPrincipal.email}}
              <HdsCopySnippet
                @color="secondary"
                @textToCopy={{@userPrincipal.email}}
              />
            {{else}}
              —
            {{/if}}
          </Flex>
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-user-detail-summary-card-key
          data-test-user-detail-summary-card-key-auth-method
        >
          {{t "manage-access.components.users.summary-card.auth-method"}}
        </P.Key>
        <P.Value
          data-test-user-detail-summary-card-value
          data-test-user-detail-summary-card-value-auth-method
        >
          <Flex
            @alignItems={{AlignItem.Center}}
            @direction={{FlexDirection.Row}}
            @gap={{FlexGap.Md}}
          >
            {{#let
              (or
                @userPrincipal.identityTypes (array @userPrincipal.identityType)
              )
              as |identityTypes|
            }}
              {{#each identityTypes as |type|}}
                {{#let
                  (optionForSourceState "identity_types" type)
                  as |option|
                }}
                  <Flex
                    @alignItems={{AlignItem.Center}}
                    @direction={{FlexDirection.Row}}
                    @gap={{FlexGap.Xxs}}
                    data-test-user-item-identity-type-badge
                  >
                    <HdsIcon
                      @color={{option.iconColor}}
                      {{! @glint-expect-error: fix incoming icon type }}
                      @name={{option.icon}}
                      @isInline={{true}}
                    />
                    <span>
                      {{t option.text}}
                    </span>
                  </Flex>
                {{/let}}
              {{/each}}
            {{/let}}
          </Flex>
        </P.Value>
      </Card.Pair>
      <Card.Pair as |P|>
        <P.Key
          data-test-user-detail-summary-card-key
          data-test-user-detail-summary-card-key-user-id
        >
          {{t "manage-access.components.users.summary-card.user-id"}}
        </P.Key>
        <P.Value
          data-test-user-detail-summary-card-value
          data-test-user-detail-summary-card-value-user-id
        >
          <HdsCopySnippet
            @color="secondary"
            @textToCopy={{@userPrincipal.id}}
          />
        </P.Value>
      </Card.Pair>
    </CoreCardSummary>
  </template>
}
