import Component from '@glimmer/component';
import { hash } from '@ember/helper';

import type { TaskForTaskFunction, TaskFunction } from 'ember-concurrency';

interface SplitListRightPaneSignature<ListItemType, DetailType> {
  Args: {
    onSelectItem: TaskForTaskFunction<TaskFunction<DetailType, [ListItemType]>>;
    selectedItem: DetailType;
    selectedItemIdentifier: string | null;
    selectPending: boolean;
    selectError: unknown;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 *
 * `SplitListRightPane` renders a container for detail content for a single list item.
 *
 * `RightPane` yields three pieces of data:
 * - `SelectItemError`: error returned by the `onSelectItem` function, if applicable
 * - `SelectItemPending`: pending state of the `onSelectItem` function
 * - `SelectedItem`: selected item details as returned by `onSelectItem`
 *
 * The consumer can decide which, if any, of this yielded data to use to render their detail view.
 *
 * By default, the right side of the list view will take up the remainder of the available width after
 * the left side content. You can provide a fixed width or minimum width to your content, which will ensure that
 * the right side scrolls horizontally when the space available to it is less than that width.
 *
 * ```
 * <SplitList
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}} as |SL|
 * >
 *   <SL.RightPane as |RP|>
 *     <div class='split-list-example-detail'>
 *       {{#if RP.SelectItemError}}
 *         Error
 *       {{else if RP.SelectItemPending}}
 *         Loading
 *       {{else if RP.SelectedItem}}
 *         Item detail
 *         <p>
 *           ID: {{RP.SelectedItem.id}}
 *         </p>
 *         <p>
 *           {{RP.SelectedItem.details}}
 *         </p>
 *       {{else}}
 *         Nothing selected
 *       {{/if}}
 *     </div>
 *   </SL.RightPane>
 * </SplitList>
 * ```
 *
 * @class SplitListRightPane
 *
 */

export default class SplitListRightPane<
  ListItemType,
  DetailType,
> extends Component<SplitListRightPaneSignature<ListItemType, DetailType>> {
  <template>
    {{yield
      (hash
        SelectItemPending=@selectPending
        SelectItemError=@selectError
        SelectedItem=@selectedItem
      )
    }}
  </template>
}
