import Component from '@glimmer/component';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import { hash } from '@ember/helper';

import boxPadding from '../modifiers/box-padding.ts';
import Tab from './guided-stepper/tab.gts';

interface GuidedStepperSignature {
  Args: {
    currentStep: number | string;
  };
  Blocks: {
    tabs: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 * `@currentStep` The step the stepper is currently on.
 *
 * @argument @currentStep
 * @type {Number | String}
 */

/**
 * `GuidedStepper` component is used to structure a sequential user flow broken
 * down into phases.
 *
 * Each step must be completed sequentially and marked as so.
 *
 * @class GuidedStepper
 */
export default class GuidedStepper extends Component<GuidedStepperSignature> {
  get currentStep() {
    // Steps are *not* necessarily zero-based.
    const { currentStep } = this.args;

    assert(
      `@currentStep must be a string or a number.`,
      typeOf(currentStep) === 'string' || typeOf(currentStep) === 'number'
    );

    // Ensure this currentStep is parsed to a Number.
    return parseInt(currentStep as string, 10);
  }

  <template>
    <div class="guided-stepper" data-test-guided-stepper ...attributes>
      <nav class="guided-stepper__nav" {{boxPadding "0" "0" "xl"}}>
        <ol>
          {{yield
            (hash Tab=(component Tab currentStep=this.currentStep))
            to="tabs"
          }}
        </ol>
      </nav>
    </div>
  </template>
}
