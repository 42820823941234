import {
  Billing20201105ChargeType,
  Billing20201105PricingModel,
  Billing20201105StatementState,
  Billing20201105ContractUpdateUpdateCause,
} from '@clients/cloud-billing';

// Currently only one account should exist per HCP organization,
// so we hard-code all billing account public IDs to the same constant.
export const DEFAULT_ACCOUNT_ID = 'default-account';
export const DEFAULT_ACCOUNT_NAME = 'Default Account';

// Must match PricingModel enum in public API
// https://github.com/hashicorp/cloud-billing/blob/main/proto-public/hashicorp/cloud/billing_20201105/billing_account_public.proto
export const PRICING_MODEL = Object.freeze({
  PAYG: Billing20201105PricingModel.PAYG,
  FLEX: Billing20201105PricingModel.FLEX,
  ENTITLEMENT: Billing20201105PricingModel.ENTITLEMENT,
  UNSPECIFIED: Billing20201105PricingModel.UNSPECIFIED,
});

// Must match StatementState enum in public API
// https://github.com/hashicorp/cloud-billing/blob/main/proto-public/hashicorp/cloud/billing_20201105/statement_service_public.proto
export const STATEMENT_STATE = Object.freeze({
  PAID: Billing20201105StatementState.PAID,
  RUNNING: Billing20201105StatementState.RUNNING,
  SUBMITTED: Billing20201105StatementState.SUBMITTED,
  VOID: Billing20201105StatementState.VOID,
  WONTPAY: Billing20201105StatementState.WONTPAY,
  UNSET: Billing20201105StatementState.UNSET,
});

// Must match UpdateCause enum in public API
// https://github.com/hashicorp/cloud-billing/blob/main/proto-public/hashicorp/cloud/billing_20201105/contract_service.proto
export const UPDATE_CAUSES = Object.freeze({
  ADDON: Billing20201105ContractUpdateUpdateCause.ADDON,
  EARLY_RENEWAL: Billing20201105ContractUpdateUpdateCause.EARLYRENEWAL,
});

// Used for PAYG pricing page
export const PRODUCTS = Object.freeze({
  TERRAFORM: {
    RESOURCE_TYPE: 'TYPE_TERRAFORM_ORGANIZATION',
    DISPLAY_TEXT: 'Terraform',
  },
  VAULT: {
    RESOURCE_TYPE: 'TYPE_VAULT',
    DISPLAY_TEXT: 'Vault Dedicated',
  },
});

export const CHARGE_TYPES = Object.freeze({
  USAGE: {
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.USAGE,
  },
  // all charge types below are non-usage charge types
  ONE_TIME_CHARGE: {
    RESOURCE_TYPE: 'hashicorp.billing.account',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - One-time Charge',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.ONETIMECHARGE,
  },
  CHARGE_CREDIT_APPLIED: {
    RESOURCE_TYPE: 'hashicorp.billing.credit',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Credit',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.CHARGECREDITAPPLIED,
  },
  TRIAL_TERMINATION_CREDIT: {
    RESOURCE_TYPE: 'hashicorp.billing.credit',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Credit',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.TRIALTERMINATIONCREDIT,
  },
  CHARGE_LEFTOVER_BALANCE: {
    RESOURCE_TYPE: 'hashicorp.billing.flex_consumption_pool',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Flex Consumption Pool',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.CHARGELEFTOVERBALANCE,
  },
  CHARGE_EXPIRED_BALANCE: {
    RESOURCE_TYPE: 'hashicorp.billing.flex_consumption_pool',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Flex Consumption Pool',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.CHARGEEXPIREDBALANCE,
  },
  FLEX_BALANCE_ROLLOVER: {
    RESOURCE_TYPE: 'hashicorp.billing.flex_consumption_pool',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Flex Consumption Pool',
    LINE_ITEM_CHARGE_TYPE: Billing20201105ChargeType.FLEXBALANCEROLLOVER,
  },
  FLEX_NEGATIVE_BALANCE_REPAYMENT: {
    RESOURCE_TYPE: 'hashicorp.billing.flex_consumption_pool',
    RESOURCE_DESCRIPTION: 'HashiCorp Billing - Flex Consumption Pool',
    LINE_ITEM_CHARGE_TYPE:
      Billing20201105ChargeType.FLEXNEGATIVEBALANCEREPAYMENT,
  },
});
