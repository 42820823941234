import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { HdsIcon } from '@hashicorp/design-system-components/components';

import { flattenNetworkOptionGroups } from '../../../utils/hvn-select-network-options.js';
import Box from '../../box.gts';
import FlexGrid from '../../flex-grid.gts';

interface HvnSelectKeyboardCuesSignature {
  Args: {
    select?: {
      options?: unknown[];
    };
  };
}

export default class FormInputsHvnSelectKeyboardCues extends Component<HvnSelectKeyboardCuesSignature> {
  get numNetworkOptions() {
    const { options: networkOptions = [] } = this.args?.select || {};

    const flattenedNetworkOptions = networkOptions.reduce(
      flattenNetworkOptionGroups,
      []
    );

    return flattenedNetworkOptions.length;
  }

  <template>
    <Box
      @padding="xs"
      class="form-inputs__hvn-select__keyboard-cues"
      data-test-hvn-select-keyboard-cues
    >
      <FlexGrid as |Grid|>
        <Grid.Item @xs="9" data-test-keyboard-cues>
          <HdsIcon @name="arrow-down" class="keyboard-key" />
          <HdsIcon @name="arrow-up" class="keyboard-key" />
          {{t "components.form-inputs.hvn-select.keyboard-cues.help-text"}}
        </Grid.Item>

        <Grid.Item @xs="3" class="num-results" data-test-num-results>
          {{t
            "components.form-inputs.hvn-select.num-network-options.help-text"
            numNetworkOptions=this.numNetworkOptions
          }}
        </Grid.Item>
      </FlexGrid>
    </Box>
  </template>
}
