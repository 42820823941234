import Component from '@glimmer/component';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { HdsDropdown } from '@hashicorp/design-system-components/components';

import { supportTicketPrefillHref } from '../utils/support-ticket.js';

import type UserContextService from '../services/user-context.ts';

interface HelpMenuSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

/**
 *
 * `HelpMenu` renders the HCP help menu using HDS drop-down.
 *  It includes a getter that can be used to generate a support ticket URL with query params that are specific to the user.
 *
 *
 * ```
 * <HelpMenu />
 * ```
 *
 * @class HelpMenu
 *
 */

export default class HelpMenu extends Component<HelpMenuSignature> {
  @service declare readonly userContext: UserContextService;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  <template>
    <HdsDropdown
      class="hds-side-nav__dropdown"
      @listPosition="bottom-left"
      data-test-help-menu
      as |dd|
    >
      <dd.ToggleIcon
        @icon="help"
        @text={{t "components.help-menu.toggle-icon.text"}}
      />
      <dd.Title @text={{t "components.help-menu.title"}} />
      <dd.Interactive
        @href="https://developer.hashicorp.com/hcp/docs"
        @text={{t "components.help-menu.links.documentation"}}
      />
      <dd.Interactive
        @href="https://developer.hashicorp.com/hcp/tutorials"
        @text={{t "components.help-menu.links.tutorials"}}
      />
      <dd.Interactive
        @href="https://registry.terraform.io/providers/hashicorp/hcp/latest"
        @text={{t "components.help-menu.links.terraform"}}
      />
      <dd.Interactive
        @href="https://developer.hashicorp.com/hcp/docs/changelog"
        @text={{t "components.help-menu.links.changelog"}}
      />
      <dd.Separator />
      <dd.Interactive
        @href={{this.supportTicketHref}}
        @text={{t "components.help-menu.links.support-ticket"}}
      />
      <dd.Interactive
        @href="https://hashicorp.sjc1.qualtrics.com/jfe/form/SV_bOElUPCdFXaQOfr"
        @text={{t "components.help-menu.links.feedback"}}
      />
    </HdsDropdown>
  </template>
}
