import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { HdsDropdown } from '@hashicorp/design-system-components/components';

import terraformDeploymentUrl from '../../../helpers/terraform-deployment-url.js';

interface CoreDropdownGoToTerraformSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class CoreDropdownGoToTerraform extends Component<CoreDropdownGoToTerraformSignature> {
  <template>
    <HdsDropdown ...attributes as |dd|>
      <dd.ToggleButton
        @text={{t
          "components.core.dropdown.go-to-terraform.go-to-hcp-terraform"
        }}
        @color="secondary"
        @size="small"
        data-test-core-dropdown-go-to-terraform-toggle
      />

      <dd.Interactive
        @icon="external-link"
        @href={{terraformDeploymentUrl "us"}}
        @text={{t "components.core.dropdown.go-to-terraform.terraform-us"}}
        data-test-core-dropdown-go-to-terraform-us-app-link
      />

      <dd.Interactive
        @icon="external-link"
        @href={{terraformDeploymentUrl "eu"}}
        @text={{t "components.core.dropdown.go-to-terraform.terraform-europe"}}
        data-test-core-dropdown-go-to-terraform-eu-app-link
      />
    </HdsDropdown>
  </template>
}
