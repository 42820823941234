import BillingFCPBalance from './billing-fcp-balance.js';
import { BILLING_ACCOUNT_STANDINGS } from '../constants/status.js';
import {
  getTimeSince,
  getTimeUntil,
  sevenDays,
  thirtyDays,
} from '../helpers/time.js';
export default class Flex {
  #fcp;
  #flexibleConsumptionBillingMethodDetails;
  #hasFlexPayment;

  constructor(fcp, flexibleConsumptionBillingMethodDetails, hasFlexPayment) {
    this.#fcp = fcp;
    this.#flexibleConsumptionBillingMethodDetails =
      flexibleConsumptionBillingMethodDetails;
    this.#hasFlexPayment = hasFlexPayment;
  }

  /** @type {BillingFCPBalance} */
  get balance() {
    return this.#hasFlexPayment
      ? new BillingFCPBalance(
          this.latestContract?.flexDetails,
          this.#flexibleConsumptionBillingMethodDetails?.estimatedRemainingBalance,
        )
      : null;
  }

  /** @type {object|null} */
  // returns a contract with a future start date or null
  // if there are no upcoming contracts
  get upcomingContract() {
    return this.#fcp?.upcomingContract || null;
  }

  /** @type {object|null} */
  // returns the most recent contract that has already started,
  // including expired contracts if they exist.
  get latestContract() {
    return this.#fcp?.latestContract || null;
  }

  /** @type {boolean} */
  // returns the existence of expired contract(s), which indicates that the active contract is a renewal.
  get hasExpiredContract() {
    return this.#fcp?.hasExpiredContract;
  }

  /** @type {boolean} */
  get renewalRecentlyActivated() {
    if (
      this.latestContract &&
      this.latestContract.status === BILLING_ACCOUNT_STANDINGS.ACTIVE &&
      this.hasExpiredContract
    ) {
      let timeSinceActivation = getTimeSince(this.latestContract.activeFrom);

      return (
        this.#hasFlexPayment && timeSinceActivation.milliseconds <= sevenDays
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpiringSoon() {
    if (this.latestContract) {
      let timeUntilExpiration = getTimeUntil(this.latestContract.activeUntil);

      return (
        this.#hasFlexPayment &&
        timeUntilExpiration.milliseconds <= thirtyDays &&
        timeUntilExpiration.milliseconds > 0
      );
    }

    return false;
  }

  /** @type {boolean} */
  get contractExpired() {
    if (this.latestContract) {
      let timeUntilExpiration = getTimeUntil(this.latestContract.activeUntil);
      return (
        this.#hasFlexPayment &&
        this.latestContract &&
        timeUntilExpiration.milliseconds <= 0
      );
    }

    return false;
  }

  /** @type {boolean} */
  get expiredDepletedNoRenewal() {
    return (
      this.contractExpired && !this.upcomingContract && this.balance.isZero
    );
  }

  /** @type {boolean} */
  get depletedNoRenewal() {
    return !this.upcomingContract && this.balance.isZero;
  }
}
