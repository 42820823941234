import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import {
  HdsTextBody,
  HdsIcon,
} from '@hashicorp/design-system-components/components';

export interface ChartsPercentageBarSubtitleSignature {
  Args: {
    state: unknown;
  };
  Block: {
    default: [];
  };
  Element: HTMLElement;
}

export default class ChartsPercentageBarSubtitle extends Component<ChartsPercentageBarSubtitleSignature> {
  <template>
    <HdsTextBody
      aria-hidden={{eq @state "ok"}}
      data-test-chart-subtitle
      @color="var(--token-color-foreground-faint)"
      @weight="regular"
      @size="100"
      @tag="p"
    >
      {{#if (eq @state "danger")}}
        <HdsIcon
          class="percentage-bar__status-icon {{@state}}"
          @name="x-square-fill"
        />
        <span class="iconBadge__label">
          {{t "components.charts.percentage-bar.status.danger"}}
        </span>
      {{else if (eq @state "warning")}}
        <HdsIcon
          class="percentage-bar__status-icon {{@state}}"
          @name="alert-triangle-fill"
        />
        <span class="iconBadge__label">
          {{t "components.charts.percentage-bar.status.warning"}}
        </span>
      {{else}}
        <div class="percentage-bar__status-icon {{@state}}"></div>
      {{/if}}
    </HdsTextBody>
  </template>
}
