import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { dasherize, underscore } from '@ember/string';
import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { next } from '@ember/runloop';

import {
  HashicorpCloudGlobalNetworkManager20220215ClusterState,
  HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus,
} from '@clients/cloud-global-network-manager-service';

import {
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
  TYPE_CONSUL_SNAPSHOT,
  TYPE_NETWORK,
  TYPE_ORGANIZATION,
  TYPE_PACKER,
  TYPE_PROJECT,
  TYPE_TERRAFORM_WORKSPACE,
  TYPE_VAULT,
  TYPE_VAULT_RADAR,
  TYPE_VAULT_SNAPSHOT,
  TYPE_VAULT_SECRETS_APP,
  TYPE_WAYPOINT,
} from 'common/utils/cloud-resource-types';

import type LocaleService from '../services/locale.ts';
import type IntlService from 'ember-intl/services/intl';

export const I18N_PREFIX = 'helpers.option-for-source-state';

export enum COLOR {
  CRITICAL = 'critical',
  HIGHLIGHT = 'highlight',
  NEUTRAL = 'neutral',
  NEUTRAL_DARK_MODE = 'neutral-dark-mode',
  SUCCESS = 'success',
  WARNING = 'warning',
}

type Color = `${COLOR}`;
export const ICON_COLOR: Record<Color, string> = {
  [COLOR.CRITICAL]: 'var(--token-color-foreground-critical)',
  [COLOR.HIGHLIGHT]: 'var(--token-color-foreground-highlight)',
  [COLOR.NEUTRAL]: 'var(--token-color-foreground-primary)',
  [COLOR.NEUTRAL_DARK_MODE]: 'var(--token-color-foreground-high-contrast)',
  [COLOR.SUCCESS]: 'var(--token-color-foreground-success)',
  [COLOR.WARNING]: 'var(--token-color-foreground-warning)',
};

export const ICON = {
  ALERT_TRIANGLE: `alert-triangle`,
  ALERT_TRIANGLE_FILL: `alert-triangle-fill`,
  AWS_COLOR: `aws-color`,
  AZURE_COLOR: `azure-color`,
  BOUNDARY_COLOR: `boundary-color`,
  CHECK: `check`,
  CHECK_CIRCLE: `check-circle`,
  CHECK_CIRCLE_FILL: `check-circle-fill`,
  CLOCK_FILLED: `clock-filled`,
  CLOUD_OFF: `cloud-off`,
  CLOUDWATCH: 'aws-cloudwatch',
  CONSUL_COLOR: `consul-color`,
  DATADOG: `datadog`,
  DOCKER_COLOR: `docker-color`,
  ELASTICSEARCH: 'elastic-observability',
  UNDEFINED: undefined,
  FILE_TEXT: `file-text`,
  FOLDER: `folder`,
  GCP_COLOR: `gcp-color`,
  GITHUB_COLOR: `github-color`,
  GRAFANA: `grafana`,
  HTTP: 'hexagon',
  HELP: `help`,
  KEY: `key`,
  LOADING: `loading`,
  LOCK: `lock`,
  MINUS_CIRCLE: `minus-circle`,
  MINUS_SQUARE_FILL: `minus-square-fill`,
  NETWORK: `network`,
  NEWRELIC: 'new-relic-color',
  ORACLE_COLOR: `oracle-color`,
  ORG: `org`,
  PACKER_COLOR: `packer-color`,
  ROBOT: `robot`,
  RUNNING: `running`,
  SPLUNK: `splunk`,
  SYNC: `sync`,
  SYNC_ALERT: 'sync-alert',
  TERRAFORM_COLOR: 'terraform-color',
  USER: `user`,
  USERS: `users`,
  VAULT_COLOR: `vault-color`,
  VAULT_RADAR_COLOR: `vault-radar-color`,
  VAULT_SECRETS_COLOR: `vault-secrets-color`,
  VMWARE_COLOR: `vmware-color`,
  WAYPOINT_COLOR: `waypoint-color`,
  X: `x`,
  X_CIRCLE_FILL: `x-circle-fill`,
  X_SQUARE: `x-square`,
  X_SQUARE_FILL: `x-square-fill`,
};

export const IS_ICON_ONLY = {
  FALSE: false,
  TRUE: true,
};

export const SIZE = {
  SMALL: `small`,
  MEDIUM: `medium`,
  LARGE: `large`,
};

export const TYPE = {
  FILLED: 'filled',
  INVERTED: 'inverted',
  OUTLINED: 'outlined',
};

export const DEFAULT_COLOR = COLOR.NEUTRAL;
export const DEFAULT_ICON = ICON.HELP;
export const DEFAULT_ICON_COLOR = ICON_COLOR[DEFAULT_COLOR];
export const DEFAULT_IS_ICON_ONLY = IS_ICON_ONLY.FALSE;
export const DEFAULT_SIZE = SIZE.MEDIUM;
export const DEFAULT_TYPE = TYPE.FILLED;

export interface State {
  text: string;
  color?: string;
  icon?: string;
}

export type StateMapping<T extends string = string> = Record<T, State>;

export interface States {
  AWS: State;
  AZURE: State;
  ACCEPTED: State;
  ACTIVE: State;
  AUDIT_LOG_STATE_INVALID?: State;
  CANCELLED: State;
  CREATING: State;
  CLUSTER_SCALING: State;
  CLUSTER_STATE_INVALID?: State;
  CLOUDWATCH: State;
  COMPLETE: State;
  CONNECTING: State;
  CREATING_FAILED: State;
  DATADOG: State;
  DELETED: State;
  DELETING: State;
  DELETING_FAILED: State;
  DISABLED: State;
  DISCONNECTED: State;
  DOCKER: State;
  DONE: State;
  ELASTICSEARCH: State;
  ENABLED: State;
  ERROR: State;
  EXPIRED: State;
  FAILED: State;
  FAILING: State;
  GCE: State;
  GCP: State;
  GOOGLECOMPUTE: State;
  GITHUB: State;
  GRAFANA: State;
  HTTP: State;
  INVALID: State;
  LINKING: State;
  LINKED: State;
  LOCKED: State;
  LOCKING: State;
  MODIFIED: State;
  NEWRELIC: State;
  NOT_STARTED: State;
  OUT_OF_DATE: State;
  ORACLE: State;
  PACKER: State;
  PASSING: State;
  PENDING: State;
  PENDING_ACCEPTANCE: State;
  PENDING_DELETE: State;
  QUEUED: State;
  READY: State;
  REJECTED: State;
  RESTORING: State;
  REVOCATION_SCHEDULED: State;
  REVOKED: State;
  RUNNING: State;
  SEALED: State;
  SEALING: State;
  SNAPSHOT_STATE_INVALID?: State;
  SPLUNK: State;
  STABLE: State;
  STORED: State;
  TOKEN_INVALID: State;
  UNDETERMINED: State;
  UNLINKING: State;
  UNLINKED: State;
  UNKNOWN: State;
  UNLOCKING: State;
  UNRELEASED: State;
  UNSET: State;
  UNSEALING: State;
  UNSPECIFIED: State;
  UNSYNCED: State;
  UPDATING: State;
  UPLOADING: State;
  UP_TO_DATE: State;
  VMWARE: State;
  VSPHERE: State;
}
export const STATES: States = {
  AWS: {
    icon: ICON.AWS_COLOR,
    text: `${I18N_PREFIX}.provider.aws`,
  },
  AZURE: {
    icon: ICON.AZURE_COLOR,
    text: `${I18N_PREFIX}.provider.azure`,
  },
  ACCEPTED: {
    text: `${I18N_PREFIX}.accepted`,
    icon: ICON.LOADING,
  },
  ACTIVE: {
    text: `${I18N_PREFIX}.active`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  CANCELLED: {
    text: `${I18N_PREFIX}.cancelled`,
    icon: ICON.X_SQUARE_FILL,
  },
  CREATING: {
    text: `${I18N_PREFIX}.creating`,
    icon: ICON.LOADING,
  },
  CLUSTER_SCALING: {
    text: `${I18N_PREFIX}.cluster-scaling`,
    icon: ICON.LOADING,
  },
  CLOUDWATCH: {
    text: `${I18N_PREFIX}.observability-provider.cloudwatch`,
    icon: ICON.CLOUDWATCH,
  },
  COMPLETE: {
    text: `${I18N_PREFIX}.complete`,
    icon: ICON.CHECK_CIRCLE,
    color: COLOR.SUCCESS,
  },
  CONNECTING: {
    icon: ICON.LOADING,
    text: `${I18N_PREFIX}.connecting`,
  },
  CREATING_FAILED: {
    text: `${I18N_PREFIX}.creating-failed`,
    icon: 'alert-circle-fill',
    color: COLOR.CRITICAL,
  },
  DATADOG: {
    icon: ICON.DATADOG,
    text: `${I18N_PREFIX}.observability-provider.datadog`,
  },
  DELETED: {
    text: `${I18N_PREFIX}.deleted`,
    icon: ICON.MINUS_CIRCLE,
    color: COLOR.CRITICAL,
  },
  DELETING: {
    text: `${I18N_PREFIX}.deleting`,
    icon: ICON.LOADING,
  },
  DELETING_FAILED: {
    text: `${I18N_PREFIX}.deleting-failed`,
    icon: 'alert-circle-fill',
    color: COLOR.CRITICAL,
  },
  DISABLED: {
    text: `${I18N_PREFIX}.disabled`,
    icon: ICON.MINUS_CIRCLE,
    color: COLOR.CRITICAL,
  },
  DISCONNECTED: {
    text: `${I18N_PREFIX}.disconnected`,
    icon: ICON.CLOUD_OFF,
  },
  DOCKER: {
    icon: ICON.DOCKER_COLOR,
    text: `${I18N_PREFIX}.vendor.docker`,
  },
  DONE: {
    text: `${I18N_PREFIX}.done`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  ELASTICSEARCH: {
    icon: ICON.ELASTICSEARCH,
    text: `${I18N_PREFIX}.observability-provider.elasticsearch`,
  },
  ENABLED: {
    text: `${I18N_PREFIX}.enabled`,
    icon: ICON.CHECK_CIRCLE,
    color: COLOR.SUCCESS,
  },
  ERROR: {
    text: `${I18N_PREFIX}.error`,
    icon: ICON.X_SQUARE,
    color: COLOR.CRITICAL,
  },
  EXPIRED: {
    text: `${I18N_PREFIX}.expired`,
    icon: ICON.X_SQUARE,
    color: COLOR.CRITICAL,
  },
  FAILED: {
    text: `${I18N_PREFIX}.failed`,
    icon: ICON.ALERT_TRIANGLE,
    color: COLOR.CRITICAL,
  },
  FAILING: {
    text: `${I18N_PREFIX}.failing`,
    icon: ICON.X_SQUARE,
    color: COLOR.CRITICAL,
  },
  GCE: {
    icon: ICON.GCP_COLOR,
    text: `${I18N_PREFIX}.provider-service.gce`,
  },
  GCP: {
    icon: ICON.GCP_COLOR,
    text: `${I18N_PREFIX}.provider.gcp`,
  },
  GOOGLECOMPUTE: {
    icon: ICON.GCP_COLOR,
    text: `${I18N_PREFIX}.provider.gcp`,
  },
  GITHUB: {
    icon: ICON.GITHUB_COLOR,
    text: `${I18N_PREFIX}.identity.github`,
  },
  GRAFANA: {
    icon: ICON.GRAFANA,
    text: `${I18N_PREFIX}.observability-provider.grafana`,
  },
  HTTP: {
    icon: ICON.HTTP,
    text: `${I18N_PREFIX}.observability-provider.http`,
  },
  INVALID: {
    text: `${I18N_PREFIX}.invalid`,
    icon: ICON.UNDEFINED,
  },
  LINKING: {
    text: `${I18N_PREFIX}.linking`,
    icon: ICON.LOADING,
  },
  LINKED: {
    text: `${I18N_PREFIX}.linked`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  LOCKED: {
    text: `${I18N_PREFIX}.locked`,
    icon: ICON.LOCK,
    color: COLOR.CRITICAL,
  },
  LOCKING: {
    text: `${I18N_PREFIX}.locking`,
    icon: ICON.LOADING,
  },
  MODIFIED: {
    text: `${I18N_PREFIX}.modified`,
    icon: ICON.ALERT_TRIANGLE_FILL,
    color: COLOR.WARNING,
  },
  NEWRELIC: {
    icon: ICON.NEWRELIC,
    text: `${I18N_PREFIX}.observability-provider.newrelic`,
  },
  NOT_STARTED: {
    text: `${I18N_PREFIX}.not-started`,
    icon: ICON.MINUS_SQUARE_FILL,
  },
  OUT_OF_DATE: {
    text: `${I18N_PREFIX}.out-of-date`,
  },
  ORACLE: {
    text: `${I18N_PREFIX}.vendor.oracle`,
    icon: ICON.ORACLE_COLOR,
  },
  PACKER: {
    text: `${I18N_PREFIX}.packer`,
    icon: ICON.PACKER_COLOR,
  },
  PASSING: {
    text: `${I18N_PREFIX}.passing`,
    icon: ICON.CHECK_CIRCLE,
    color: COLOR.SUCCESS,
  },
  PENDING: {
    text: `${I18N_PREFIX}.pending`,
    icon: ICON.LOADING,
  },
  PENDING_ACCEPTANCE: {
    text: `${I18N_PREFIX}.pending-acceptance`,
    icon: ICON.LOADING,
  },
  PENDING_DELETE: {
    text: `${I18N_PREFIX}.pending-delete`,
    icon: ICON.SYNC,
    color: COLOR.CRITICAL,
  },
  QUEUED: {
    text: `${I18N_PREFIX}.queued`,
    icon: ICON.LOADING,
  },
  READY: {
    text: `${I18N_PREFIX}.ready`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  REJECTED: {
    text: `${I18N_PREFIX}.rejected`,
    icon: ICON.X_SQUARE,
    color: COLOR.CRITICAL,
  },
  RESTORING: {
    text: `${I18N_PREFIX}.restoring`,
    icon: ICON.LOADING,
  },
  REVOCATION_SCHEDULED: {
    text: `${I18N_PREFIX}.revocation-scheduled`,
    icon: ICON.CLOCK_FILLED,
    color: COLOR.WARNING,
  },
  REVOKED: {
    text: `${I18N_PREFIX}.revoked`,
    icon: ICON.X_CIRCLE_FILL,
  },
  RUNNING: {
    text: `${I18N_PREFIX}.running`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  SEALED: {
    text: `${I18N_PREFIX}.sealed`,
    icon: ICON.LOCK,
    color: COLOR.CRITICAL,
  },
  SEALING: {
    text: `${I18N_PREFIX}.sealing`,
    icon: ICON.LOADING,
  },
  SPLUNK: {
    icon: ICON.SPLUNK,
    text: `${I18N_PREFIX}.observability-provider.splunk`,
  },
  STABLE: {
    text: `${I18N_PREFIX}.stable`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  STORED: {
    text: `${I18N_PREFIX}.stored`,
    icon: ICON.CHECK_CIRCLE_FILL,
    color: COLOR.SUCCESS,
  },
  TOKEN_INVALID: {
    text: `${I18N_PREFIX}.token-invalid`,
    icon: ICON.ALERT_TRIANGLE,
    color: COLOR.WARNING,
  },
  UNDETERMINED: {
    text: `${I18N_PREFIX}.undetermined`,
  },
  UNLINKING: {
    text: `${I18N_PREFIX}.unlinking`,
    icon: ICON.LOADING,
  },
  UNLINKED: {
    text: `${I18N_PREFIX}.unlinked`,
    icon: ICON.MINUS_CIRCLE,
    color: COLOR.CRITICAL,
  },
  UNKNOWN: {
    text: `${I18N_PREFIX}.unknown`,
  },
  UNLOCKING: {
    text: `${I18N_PREFIX}.unlocking`,
    icon: ICON.LOADING,
  },
  UNRELEASED: {
    text: `${I18N_PREFIX}.unreleased`,
  },
  UNSET: {
    text: `${I18N_PREFIX}.unset`,
    icon: ICON.ALERT_TRIANGLE,
    color: COLOR.WARNING,
  },
  UNSEALING: {
    text: `${I18N_PREFIX}.unsealing`,
    icon: ICON.LOADING,
  },
  UNSPECIFIED: {
    text: `${I18N_PREFIX}.unspecified`,
  },
  UNSYNCED: {
    icon: ICON.SYNC_ALERT,
    text: `${I18N_PREFIX}.not-synced`,
  },
  UPDATING: {
    text: `${I18N_PREFIX}.updating`,
    icon: ICON.LOADING,
  },
  UPLOADING: {
    text: `${I18N_PREFIX}.uploading`,
    icon: ICON.LOADING,
  },
  UP_TO_DATE: {
    text: `${I18N_PREFIX}.up-to-date`,
  },
  VMWARE: {
    icon: ICON.VMWARE_COLOR,
    text: `${I18N_PREFIX}.vendor.vmware`,
  },
  VSPHERE: {
    icon: ICON.VMWARE_COLOR,
    text: `${I18N_PREFIX}.vendor.vsphere`,
  },
};

// Use this space for any overrides that use defaults. We should aim to keep
// this override section empty. A state in this object indicates that it should
// be defined and scoped by the engine.

// HashicorpCloudVault20201125AuditLogState.AUDITLOGSTATEINVALID
STATES.AUDIT_LOG_STATE_INVALID = STATES.INVALID;

// HashicorpCloudVault20201125ClusterState.CLUSTERSTATEINVALID
STATES.CLUSTER_STATE_INVALID = STATES.INVALID;

// HashicorpCloudVault20201125SnapshotState.SNAPSHOTSTATEINVALID
STATES.SNAPSHOT_STATE_INVALID = STATES.INVALID;

export const SOURCE_CONSUL_GNM_STATE_MAPPING: StateMapping<HashicorpCloudGlobalNetworkManager20220215ClusterState> =
  {
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.PENDING]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.RUNNING]: {
      text: `${I18N_PREFIX}.running`,
      icon: ICON.CHECK_CIRCLE_FILL,
      color: COLOR.SUCCESS,
    },
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.DISCONNECTEDINVALIDTOKEN]:
      STATES.TOKEN_INVALID,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.PENDINGINVALIDTOKEN]:
      STATES.TOKEN_INVALID,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.UPDATINGTOKEN]: {
      text: `${I18N_PREFIX}.token-updating`,
      icon: ICON.LOADING,
    },
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.UPDATINGACCESSLEVEL]:
      STATES.PENDING,

    // The following states are not in use yet
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.UNSPECIFIED]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.CREATING]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.FAILED]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.UPDATING]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.RESTORING]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.DELETING]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.DELETED]:
      STATES.PENDING,
    [HashicorpCloudGlobalNetworkManager20220215ClusterState.DISCONNECTED]:
      STATES.DISCONNECTED,
  };

export const SOURCE_CONSUL_GNM_PEER_STATE_MAPPING: StateMapping<HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus> =
  {
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.UNDEFINED]:
      {
        // Use default icon (help)
        text: `${I18N_PREFIX}.unknown`,
      },
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.ACTIVE]:
      STATES.ACTIVE,
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.FAILING]:
      STATES.FAILING,
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.DELETING]:
      {
        ...STATES.DELETING,
        color: COLOR.WARNING,
        icon: ICON.LOADING,
      },
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.CONNECTING]:
      {
        ...STATES.CONNECTING,
        color: COLOR.HIGHLIGHT,
      },
    [HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus.UNSYNCED]:
      STATES.UNSYNCED,
  };

export const SOURCE_HVN_PEERING_STATE_MAPPING: StateMapping = Object.freeze({
  ACCEPTED: {
    text: `${I18N_PREFIX}.pending-acceptance`,
    icon: ICON.ALERT_TRIANGLE_FILL,
    color: COLOR.WARNING,
  },
  ACTIVE: {
    ...STATES.ACTIVE,
    icon: ICON.CHECK_CIRCLE,
  },
  PENDING_ACCEPTANCE: {
    text: `${I18N_PREFIX}.pending-acceptance`,
    icon: ICON.ALERT_TRIANGLE_FILL,
    color: COLOR.WARNING,
  },
});

export const SOURCE_OPERATION_STATE_MAPPING: StateMapping = Object.freeze({
  UIERROR: STATES.ERROR,
});

export const SOURCE_IDENTITY_TYPES_STATE_MAPPING: StateMapping = Object.freeze({
  SOCIAL_GITHUB: STATES.GITHUB,
  EMAIL_PASSWORD: {
    icon: ICON.USER,
    text: `${I18N_PREFIX}.identity.email`,
  },
  SAMLP: {
    ...STATES.ACTIVE,
    text: `${I18N_PREFIX}.identity.saml`,
  },
  HASHICORP_SSO: {
    ...STATES.ACTIVE,
    text: `${I18N_PREFIX}.identity.oidc`,
  },
});

export const SOURCE_VAULT_METRICS_STATUS_STATE_MAPPING: StateMapping =
  Object.freeze({
    CONFIGURED: {
      ...STATES.ACTIVE,
      text: `${I18N_PREFIX}.sending-metrics`,
    },
  });

export const SOURCE_VAULT_AUDIT_LOGS_STATUS_STATE_MAPPING: StateMapping =
  Object.freeze({
    CONFIGURED: {
      ...STATES.ACTIVE,
      text: `${I18N_PREFIX}.configured`,
    },
  });

export const SOURCE_BOUNDARY_STATE_MAPPING: StateMapping = {
  STATE_INVALID_UNSPECIFIED: {
    text: `${I18N_PREFIX}.unknown`,
  },
  STATE_PENDING: STATES.PENDING,
  STATE_CREATING: STATES.CREATING,
  STATE_RUNNING: STATES.RUNNING,
  STATE_FAILED: STATES.FAILED,
  STATE_DELETING: STATES.DELETING,
  STATE_DELETED: STATES.DELETED,
  STATE_UPDATING: STATES.UPDATING,
};

export const SOURCE_HVN_PEERING_QUICK_CREATE_STATE_MAPPING: StateMapping = {
  ACCEPTED: {
    text: `${I18N_PREFIX}.pending-acceptance`,
    icon: ICON.LOADING,
  },
  ACTIVE: {
    ...STATES.ACTIVE,
    icon: ICON.CHECK_CIRCLE,
  },
};

export const SOURCE_PACKER_BUILD_STATE_MAPPING: StateMapping = {
  DONE: STATES.ACTIVE,
  FAILED: {
    ...STATES.FAILED,
    icon: ICON.ALERT_TRIANGLE_FILL,
  },
  RUNNING: {
    ...STATES.RUNNING,
    color: COLOR.NEUTRAL,
    icon: ICON.RUNNING,
  },
  CANCELLED: {
    ...STATES.CANCELLED,
    color: COLOR.NEUTRAL,
    icon: ICON.X_SQUARE_FILL,
  },
  UNSET: STATES.NOT_STARTED,
};

export const SOURCE_PACKER_VERSION_STATE_MAPPING: StateMapping = {
  ...SOURCE_PACKER_BUILD_STATE_MAPPING,
};

export const SOURCE_RESOURCE_TYPE_STATE_MAPPING: StateMapping = {
  [TYPE_BOUNDARY]: {
    text: `${I18N_PREFIX}.resource.boundary-cluster`,
    icon: ICON.BOUNDARY_COLOR,
  },
  [TYPE_CONSUL]: {
    text: `${I18N_PREFIX}.resource.consul-cluster`,
    icon: ICON.CONSUL_COLOR,
  },
  [TYPE_CONSUL_GLOBAL_NETWORK_MANAGER]: {
    text: `${I18N_PREFIX}.resource.consul-cluster`,
    icon: ICON.CONSUL_COLOR,
  },
  [TYPE_CONSUL_SNAPSHOT]: {
    text: `${I18N_PREFIX}.resource.consul-cluster-snapshot`,
    icon: ICON.CONSUL_COLOR,
  },
  [TYPE_NETWORK]: {
    text: `${I18N_PREFIX}.resource.network`,
    icon: ICON.NETWORK,
  },
  [TYPE_ORGANIZATION]: {
    text: `${I18N_PREFIX}.organization`,
    icon: ICON.ORG,
  },
  [TYPE_PACKER]: {
    text: `${I18N_PREFIX}.resource.packer-registry`,
    icon: ICON.PACKER_COLOR,
  },
  [TYPE_PROJECT]: {
    text: `${I18N_PREFIX}.project`,
    icon: ICON.FILE_TEXT,
  },
  [TYPE_TERRAFORM_WORKSPACE]: {
    text: `${I18N_PREFIX}.resource.terraform-workspace`,
    icon: ICON.TERRAFORM_COLOR,
  },
  [TYPE_VAULT]: {
    text: `${I18N_PREFIX}.resource.vault-cluster`,
    icon: ICON.VAULT_COLOR,
  },
  [TYPE_VAULT_RADAR]: {
    text: `${I18N_PREFIX}.resource.vault-radar-scan-target`,
    icon: ICON.VAULT_RADAR_COLOR,
  },
  [TYPE_VAULT_SNAPSHOT]: {
    text: `${I18N_PREFIX}.resource.vault-cluster-snapshot`,
    icon: ICON.VAULT_COLOR,
  },
  [TYPE_VAULT_SECRETS_APP]: {
    text: `${I18N_PREFIX}.resource.vault-secrets-application`,
    icon: ICON.VAULT_SECRETS_COLOR,
  },
  [TYPE_WAYPOINT]: {
    text: `${I18N_PREFIX}.resource.waypoint-namespace`,
    icon: ICON.WAYPOINT_COLOR,
  },
};

// { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager'
export const SOURCE_POLICY_TYPE_STATE_MAPPING: StateMapping = {
  UNSET: STATES.UNSET,
  USER: {
    text: `${I18N_PREFIX}.user`,
    icon: ICON.USER,
  },
  GROUP: {
    text: `${I18N_PREFIX}.group`,
    icon: ICON.USERS,
  },
  SERVICE_PRINCIPAL: {
    text: `${I18N_PREFIX}.service-principal`,
    icon: ICON.ROBOT,
  },
};

export const SOURCE_CENSUS_REPORTING_STATE_MAPPING: StateMapping =
  Object.freeze({
    ABORTED: {
      text: `${I18N_PREFIX}.aborted`,
      icon: ICON.ALERT_TRIANGLE_FILL,
      color: COLOR.WARNING,
    },
    FAILED: {
      text: `${I18N_PREFIX}.failed`,
      icon: ICON.X,
      color: COLOR.CRITICAL,
    },
    TIMED_OUT: {
      text: `${I18N_PREFIX}.timed-out`,
      icon: ICON.ALERT_TRIANGLE_FILL,
      color: COLOR.WARNING,
    },
    MODIFIED: {
      text: `${I18N_PREFIX}.modified`,
      icon: ICON.ALERT_TRIANGLE_FILL,
      color: COLOR.WARNING,
    },
    UPLOADING: {
      text: `${I18N_PREFIX}.in-progress`,
      icon: ICON.LOADING,
      color: COLOR.NEUTRAL,
    },
    UPLOADED: {
      text: `${I18N_PREFIX}.uploaded`,
      icon: ICON.CHECK,
      color: COLOR.SUCCESS,
    },
    QUEUED: {
      text: `${I18N_PREFIX}.queued`,
      icon: ICON.LOADING,
      color: COLOR.NEUTRAL,
    },
  });

export const SOURCE_CENSUS_REPORTING_STATE_KEY = 'CENSUS_REPORTING';
export const SOURCE_CONSUL_GNM_KEY = 'GNM';
export const SOURCE_CONSUL_GNM_PEER_STATE_KEY = 'GNM_PEER_STATE';
export const SOURCE_HVN_PEERING_KEY = 'HVN_PEERING';
export const SOURCE_HVN_PEERING_QUICK_CREATE_KEY = 'HVN_PEERING_QUICK_CREATE';
export const SOURCE_OPERATION_KEY = 'OPERATION';
export const SOURCE_IDENTITY_TYPES_KEY = 'IDENTITY_TYPES';
export const SOURCE_VAULT_METRICS_STATUS_KEY = 'VAULT_METRICS_STATUS';
export const SOURCE_VAULT_AUDIT_LOG_STATUS_KEY = 'VAULT_AUDIT_LOG_STATUS';
export const SOURCE_PACKER_BUILD_KEY = 'PACKER_BUILD';
export const SOURCE_PACKER_VERSION_KEY = 'PACKER_VERSION';
export const SOURCE_BOUNDARY_KEY = 'BOUNDARY';
export const SOURCE_RESOURCE_TYPE_KEY = 'RESOURCE_TYPE';
export const SOURCE_RESOURCE_POLICY_TYPE = 'POLICY_TYPE';

export const SOURCES: Record<string, StateMapping> = {
  [SOURCE_HVN_PEERING_KEY]: SOURCE_HVN_PEERING_STATE_MAPPING,
  [SOURCE_HVN_PEERING_QUICK_CREATE_KEY]:
    SOURCE_HVN_PEERING_QUICK_CREATE_STATE_MAPPING,
  [SOURCE_OPERATION_KEY]: SOURCE_OPERATION_STATE_MAPPING,
  [SOURCE_IDENTITY_TYPES_KEY]: SOURCE_IDENTITY_TYPES_STATE_MAPPING,
  [SOURCE_VAULT_METRICS_STATUS_KEY]: SOURCE_VAULT_METRICS_STATUS_STATE_MAPPING,
  [SOURCE_VAULT_AUDIT_LOG_STATUS_KEY]:
    SOURCE_VAULT_AUDIT_LOGS_STATUS_STATE_MAPPING,
  [SOURCE_BOUNDARY_KEY]: SOURCE_BOUNDARY_STATE_MAPPING,
  [SOURCE_CENSUS_REPORTING_STATE_KEY]: SOURCE_CENSUS_REPORTING_STATE_MAPPING,
  [SOURCE_CONSUL_GNM_KEY]: SOURCE_CONSUL_GNM_STATE_MAPPING,
  [SOURCE_CONSUL_GNM_PEER_STATE_KEY]: SOURCE_CONSUL_GNM_PEER_STATE_MAPPING,
  [SOURCE_PACKER_BUILD_KEY]: SOURCE_PACKER_BUILD_STATE_MAPPING,
  [SOURCE_PACKER_VERSION_KEY]: SOURCE_PACKER_VERSION_STATE_MAPPING,
  [SOURCE_RESOURCE_TYPE_KEY]: SOURCE_RESOURCE_TYPE_STATE_MAPPING,
  [SOURCE_RESOURCE_POLICY_TYPE]: SOURCE_POLICY_TYPE_STATE_MAPPING,
};

// The mapped value of presentational data for a state key.
interface SourceStateOptions {
  color: string;
  // The HdsIcon `name` string.
  icon: string;
  // The HdsIcon `color` string.
  iconColor: string;
  // A preferred value for displaying if the output should only render the icon.
  isIconOnly: boolean;
  /**
   * A flag which indicates that this state
   * has been defined.
   * The default state values will still be returned, but
   * this can allow the developer to prefer raw values instead.
   */
  isRegistered: boolean;
  // The size of Hds::Badge to render
  size: string;
  // The type of Hds::Badge to render
  type: string;
  // The untranslated i18n string.
  text: string;
}

interface OptionForSourceStateSignature {
  Args: {
    Positional: [string | undefined, string | undefined];
    Named: {
      locale?: string;
    };
  };
  Return: SourceStateOptions;
}

type OptionalStatesRecord = Partial<Record<keyof States, State>>;
const buildOption = (
  stateId: string,
  sourceId: string
): State | NonNullable<unknown> => {
  const formattedSourceIdUnderscored = underscore(sourceId);
  const formattedSourceIdUppercase = formattedSourceIdUnderscored.toUpperCase();

  const formattedStateUnderscored = underscore(stateId);
  const formattedStateUppercase = formattedStateUnderscored.toUpperCase();
  const formattedStateSanitized = formattedStateUppercase.replace('STATE_', '');

  const source = {
    ...(SOURCES[formattedSourceIdUnderscored] ?? {}),
    ...(SOURCES[formattedSourceIdUppercase] ?? {}),
    ...(SOURCES[sourceId] ?? {}),
  };

  // no need for order here - stateId is the most important priority after that is not needed any match will do
  const keys = [
    formattedStateSanitized,
    formattedStateUppercase,
    formattedStateUnderscored,
  ];
  // source has priority over STATES
  const arraysToSearch: OptionalStatesRecord[] = [source, STATES];
  for (const array of arraysToSearch) {
    const stateIdState = array[stateId as keyof OptionalStatesRecord];
    // stateId has priority over all other keys
    if (stateIdState) {
      return stateIdState;
    }
    for (const key of keys) {
      const value = array[key as keyof OptionalStatesRecord];
      if (value) {
        return value;
      }
    }
  }
  // If no matching state is found return empty object defaults will be used
  if (macroCondition(isDevelopingApp())) {
    console.warn(
      `option-for-source-state helper: No state found for ${stateId}`
    );
  }
  return {};
};

export default class OptionForSourceState extends Helper<OptionForSourceStateSignature> {
  @service declare readonly intl: IntlService;
  @service declare readonly locale: LocaleService;

  /**
   * Gets presentational data based on a source id and a state id. The source id
   * allows a developer the chance to override the default value of a state if
   * the UX calls for slight variations per product. If a variation is needed,
   * define a new source or define a state on an existing source. If the source
   * is mapped in the `SOURCES` object, the override will take precedence.
   *
   * It is recommended to always scope your option helper by a source ID unless
   * you know an override will never apply. For example, usually regions will not
   * need any overrides but potentially resource states might. If you do not need
   * or have a source and want to simply use the default settings, pass in an
   * empty string for sourceId.
   *
   * @param {array} args - Positional args for mapping the source and state.
   * @param {string} args[0] - The source mapping id. A key to select a state
   *     override. Most of the states are, by default, registered, however, if
   *     the client does not have a standard status enum or if UX calls for a
   *     variation in any of the configuration, source allows you to override
   *     any of the properties for that context. If a context is a provided but
   *     a mapping does not exist, the state will safely fallback to the default.
   * @param {string} args[1] - The state. A string matching an available state.
   * @returns {SourceStateOption} - The mapped state values for a source and state.
   */
  compute([sourceId = '', stateId = '']:
    | (string | undefined)[]
    | undefined = []): SourceStateOptions {
    let temporaryI18nKey: string = '';

    const option = buildOption(stateId, sourceId);

    const isRegistered = Object.keys(option).length ? true : false;

    if (!isRegistered) {
      temporaryI18nKey = `${I18N_PREFIX}.temporary-${dasherize(
        stateId.toLowerCase()
      )}`;

      // This adds a temporary translation into memory for i18n strings because
      // this helper returns an un-translated `text` key that contains only the
      // dot-notation value of the string. The templates that use the output are
      // running that string through the `t` helper and it will need to have a
      // viable key/value set. A possible refactor could be to translate the
      // string here if it exists and if not, just return the original value.
      // This approach would require a refactor of all of the call sites of this
      // helper.
      // eslint-disable-next-line ember/no-runloop
      next(this, () => {
        if (
          temporaryI18nKey &&
          !this.intl.exists(temporaryI18nKey, this.locale.locale)
        ) {
          this.intl.addTranslations(this.locale.locale, {
            [temporaryI18nKey]: `${stateId}`,
          });
        }
      });
    }
    let iconColor = DEFAULT_ICON_COLOR;
    if (
      'color' in option &&
      isRegistered &&
      option.color &&
      option.color in ICON_COLOR
    ) {
      iconColor = ICON_COLOR[option.color as Color];
    }

    return {
      color: DEFAULT_COLOR,
      icon: DEFAULT_ICON,
      iconColor,
      isIconOnly: DEFAULT_IS_ICON_ONLY,
      isRegistered,
      size: DEFAULT_SIZE,
      type: DEFAULT_TYPE,
      text: temporaryI18nKey,
      ...option,
    };
  }
}
